import React from 'react'
import PropTypes from 'prop-types'
import {
  GridList,
  EmptyState,
  BufferLoading,
} from '~publish/legacy/shared-components'
import {
  ChannelAvatar,
  type ChannelType,
  Heading,
  Tooltip,
} from '@buffer-mono/popcorn'
import { Button, Text } from '@bufferapp/ui'
import CopyIcon from '@bufferapp/ui/Icon/Icons/Copy'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import ArrowRightIcon from '@bufferapp/ui/Icon/Icons/ArrowRight'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import styled from 'styled-components'
import { grayLight, grayDark, gray } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { fontFamily, fontSize } from '@bufferapp/ui/style/fonts'
import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import { openPreviewPage } from '../../util'
import CustomLinks from '../CustomLinks'

const ErrorBoundary = getErrorBoundary(true)

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
  width: 100%;
`

const StlyedLoadingGridContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 5rem;
`

const StyledProfileBadge = styled.div`
  padding: 0.05rem 0.25rem;
  display: flex;
  align-items: center;
  gap: 16px;
`

const StyledProfileHeader = styled.div`
  display: flex;
  align-items: center;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 340px;
`

const StyledLinkField = styled.div`
  margin-right: 12px;
  width: 100%;
`

const StyledCopyLinkButton = styled.button`
  align-items: center;
  background-color: ${grayLight};
  border: 1px solid ${grayLight};
  border-radius: ${borderRadius};
  color: ${grayDark};
  cursor: pointer;
  display: flex;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  height: 40px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`

const StyledCopyLink = styled.div`
  width: 16px;
  height: 16px;
  margin-left: auto;
`

const StyledCopyLinkIcon = styled.div`
  display: inline-block;
  position: relative;
  top: 0;
  left: 5px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Title = styled(Text)`
  color: ${grayDark};
  margin: 16px 0;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'publicGridUrl' implicitly has an ... Remove this comment to see the full error message
const onCopyToClipboard = ({ publicGridUrl, handleCopyToClipboard }) => {
  const el = document.createElement('textarea')
  el.value = publicGridUrl
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  const copied = document.execCommand('copy')
  document.body.removeChild(el)
  if (copied) {
    handleCopyToClipboard({ copySuccess: true, publicGridUrl })
  } else {
    handleCopyToClipboard({ copySuccess: false })
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'publicGridUrl' implicitly has an 'any' ... Remove this comment to see the full error message
const onPreviewClick = (publicGridUrl) => {
  openPreviewPage(publicGridUrl)
}

const GridPosts = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'total' implicitly has an 'any' ty... Remove this comment to see the full error message
  total,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'gridPosts' implicitly has an 'any... Remove this comment to see the full error message
  gridPosts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChangePostUrl' implicitly has a... Remove this comment to see the full error message
  onChangePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSavePostUrl' implicitly has an ... Remove this comment to see the full error message
  onSavePostUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trackPagePreviewed' implicitly ha... Remove this comment to see the full error message
  trackPagePreviewed,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDisconnectedProfile' implicitly... Remove this comment to see the full error message
  isDisconnectedProfile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
  profile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleCopyToClipboard' implicitly... Remove this comment to see the full error message
  handleCopyToClipboard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'publicGridUrl' implicitly has an ... Remove this comment to see the full error message
  publicGridUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previewGridUrl' implicitly has an... Remove this comment to see the full error message
  previewGridUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customLinksDetails' implicitly ha... Remove this comment to see the full error message
  customLinksDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinks' implicitly h... Remove this comment to see the full error message
  onUpdateCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinksColor' implici... Remove this comment to see the full error message
  onUpdateCustomLinksColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinksButtonType' im... Remove this comment to see the full error message
  onUpdateCustomLinksButtonType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteCustomLink' implicitly ha... Remove this comment to see the full error message
  onDeleteCustomLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateLinkText' implicitly has ... Remove this comment to see the full error message
  onUpdateLinkText,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateLinkUrl' implicitly has a... Remove this comment to see the full error message
  onUpdateLinkUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'maxCustomLinks' implicitly has an... Remove this comment to see the full error message
  maxCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onToggleEditMode' implicitly has ... Remove this comment to see the full error message
  onToggleEditMode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSwapCustomLinks' implicitly has... Remove this comment to see the full error message
  onSwapCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCancelCustomLinkEdit' implicitl... Remove this comment to see the full error message
  onCancelCustomLinkEdit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSaveNewLinkClick' implicitly ha... Remove this comment to see the full error message
  onSaveNewLinkClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateSingleCustomLink' implici... Remove this comment to see the full error message
  onUpdateSingleCustomLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isValidItem' implicitly has an 'a... Remove this comment to see the full error message
  isValidItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}): JSX.Element => {
  if (loading) {
    return (
      <StlyedLoadingGridContainer>
        <BufferLoading size={64} />
      </StlyedLoadingGridContainer>
    )
  }

  return (
    <ErrorBoundary>
      {isDisconnectedProfile && <ProfilesDisconnectedBanner />}
      {!isDisconnectedProfile && (
        <div>
          <StyledHeader>
            <StyledProfileHeader>
              <StyledProfileBadge>
                <ChannelAvatar
                  src={profile.avatar_https}
                  alt={profile.service}
                  channel={profile.service as ChannelType}
                  size="medium"
                />
                <Heading as="h1" size="medium">
                  {profile.handle}
                </Heading>
              </StyledProfileBadge>
            </StyledProfileHeader>
            <StyledButtonWrapper>
              <StyledLinkField>
                <Tooltip arrow content="Copy Page Link">
                  <StyledCopyLinkButton
                    type="button"
                    onClick={() => {
                      onCopyToClipboard({
                        publicGridUrl,
                        handleCopyToClipboard,
                      })
                    }}
                  >
                    {publicGridUrl}
                    <StyledCopyLink>
                      <StyledCopyLinkIcon>
                        <CopyIcon size="medium" />
                      </StyledCopyLinkIcon>
                    </StyledCopyLink>
                  </StyledCopyLinkButton>
                </Tooltip>
              </StyledLinkField>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; onClick: () =... Remove this comment to see the full error message */}
              <Button
                label="Preview Page"
                type="secondary"
                onClick={() => {
                  onPreviewClick(previewGridUrl)
                  trackPagePreviewed(profile)
                }}
                icon={<ArrowRightIcon />}
                iconEnd
              />
            </StyledButtonWrapper>
          </StyledHeader>
          <CustomLinks
            customLinksDetails={customLinksDetails}
            onUpdateCustomLinks={onUpdateCustomLinks}
            onUpdateCustomLinksColor={onUpdateCustomLinksColor}
            // @ts-expect-error TS(2322) FIXME: Type '{ customLinksDetails: any; onUpdateCustomLin... Remove this comment to see the full error message
            onUpdateCustomLinksButtonType={onUpdateCustomLinksButtonType}
            onDeleteCustomLink={onDeleteCustomLink}
            onUpdateLinkText={onUpdateLinkText}
            onUpdateLinkUrl={onUpdateLinkUrl}
            maxCustomLinks={maxCustomLinks}
            onToggleEditMode={onToggleEditMode}
            onSwapCustomLinks={onSwapCustomLinks}
            onCancelCustomLinkEdit={onCancelCustomLinkEdit}
            onSaveNewLinkClick={onSaveNewLinkClick}
            onUpdateSingleCustomLink={onUpdateSingleCustomLink}
            isValidItem={isValidItem}
            hasWriteAccess={hasWriteAccess}
          />
          <TitleWrapper>
            <Title type="h3" color="grayDark">
              Posts
            </Title>
            <Tooltip
              arrow
              content="Only posts with links will appear on your public Shop Grid page."
              align="center"
              side="right"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoIcon color={gray} />
              </div>
            </Tooltip>
          </TitleWrapper>
          {total < 1 && (
            <EmptyState
              height="auto"
              title="You haven’t published any posts to this Instagram account yet."
              subtitle="Once you’ve posted to your Instagram feed, use Shop Grid as your link in bio tool to drive traffic from Instagram to anywhere you’d like — your online store, articles, events, and more!"
              heroImg="https://s3.amazonaws.com/buffer-publish/images/empty-sent2x.png"
              heroImgSize={{ width: '270px', height: '150px' }}
            />
          )}
          {total >= 1 && (
            <GridList
              gridPosts={gridPosts}
              onChangePostUrl={onChangePostUrl}
              onSavePostUrl={onSavePostUrl}
              timezone={profile.timezone}
              hasWriteAccess={hasWriteAccess}
            />
          )}
        </div>
      )}
    </ErrorBoundary>
  )
}

GridPosts.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.number, // eslint-disable-line
  gridPosts: PropTypes.arrayOf(
    PropTypes.shape({
      posts: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ),
  total: PropTypes.number,
  onChangePostUrl: PropTypes.func,
  onSavePostUrl: PropTypes.func,
  handleCopyToClipboard: PropTypes.func,
  trackPagePreviewed: PropTypes.func.isRequired,
  publicGridUrl: PropTypes.string,
  previewGridUrl: PropTypes.string,
  isDisconnectedProfile: PropTypes.bool,
  profile: PropTypes.shape({
    service: PropTypes.string,
    avatar_https: PropTypes.string,
    timezone: PropTypes.string,
    handle: PropTypes.string,
  }),
  customLinksDetails: PropTypes.shape({
    customLinks: PropTypes.array,
    maxCustomLinks: PropTypes.number,
    buttonColor: PropTypes.string,
  }),
  isValidItem: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
  maxCustomLinks: PropTypes.number,
  onUpdateCustomLinks: PropTypes.func,
  onUpdateCustomLinksColor: PropTypes.func,
  onUpdateCustomLinksButtonType: PropTypes.func,
  onDeleteCustomLink: PropTypes.func,
  onUpdateLinkText: PropTypes.func,
  onUpdateLinkUrl: PropTypes.func,
  onToggleEditMode: PropTypes.func,
  onSwapCustomLinks: PropTypes.func,
  onCancelCustomLinkEdit: PropTypes.func,
  onSaveNewLinkClick: PropTypes.func,
  onUpdateSingleCustomLink: PropTypes.func,
}

GridPosts.defaultProps = {
  loading: true,
  total: 0,
  gridPosts: [],
  publicGridUrl: '',
  previewGridUrl: '',
  isDisconnectedProfile: false,
  onChangePostUrl: () => {},
  onSavePostUrl: () => {},
  handleCopyToClipboard: () => {},
  profile: {},
  isValidItem: () => {},
  hasWriteAccess: false,
  customLinksDetails: {
    customLinks: [],
    maxCustomLinks: 0,
    buttonColor: null,
  },
  maxCustomLinks: 3,
  onUpdateCustomLinks: () => {},
  onUpdateCustomLinksColor: () => {},
  onUpdateCustomLinksButtonType: () => {},
  onDeleteCustomLink: () => {},
  onUpdateLinkText: () => {},
  onUpdateLinkUrl: () => {},
  onToggleEditMode: () => {},
  onSwapCustomLinks: () => {},
  onCancelCustomLinkEdit: () => {},
  onSaveNewLinkClick: () => {},
  onUpdateSingleCustomLink: () => {},
}

export default GridPosts
