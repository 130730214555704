import { useState, useEffect, useMemo, type MutableRefObject } from 'react'
import { calculateLines } from './calculateLines'
import {
  getMeasureTextProps,
  type MeasureTextOptions,
  measureText,
} from './measureText'
import { collapseLines } from './lines'
import { resizeLine } from './resizeLine'

type UseTruncatedTextOptions = {
  /** The text to truncate */
  text: string
  /** The number of lines of text to show (similar to line-clamp) */
  lineClamp: number
  /** The container of the element where text will be rendered. This is used to derive font size, family, and weight */
  container: MutableRefObject<HTMLElement | null>
  /** Expansion text the user will add to the end of the text, typically as a link (eg "read more"). This will be subtracted from the final line length */
  expansionText?: string
  /** The multiplier to apply to the expansion text buffer. This is used to give extra space for the expansion text to make it clear that text is truncated */
  expansionTextBufferMultiplier?: number
}

/***
 * Truncates text to the specified number of lines, with an optional expansion text (like "see more").
 * This utility does not add the expansion text to the DOM, but instead subtracts it from the final line length.
 * Use this in place of clamp-text, which does not support expansion text being inline with the source text
 * @returns [truncated text, did truncate]
 */
export const useTruncatedText = (
  opts: UseTruncatedTextOptions,
): [string, boolean] => {
  const {
    text,
    lineClamp,
    container,
    expansionText,
    expansionTextBufferMultiplier = 2,
  } = opts
  const [measurementProps, setMeasurementProps] = useState<{
    options: MeasureTextOptions
    width: number
  } | null>(null)
  useEffect(() => {
    if (container.current) {
      setMeasurementProps({
        options: getMeasureTextProps(container.current),
        width: container.current.offsetWidth,
      })
    }
  }, [container, setMeasurementProps])
  return useMemo((): [string, boolean] => {
    // loading, don't render text
    if (!measurementProps) return ['', false]

    const [lines, didTruncate] = calculateLines(
      text,
      measurementProps.width,
      lineClamp,
      (text: string) => measureText(text, measurementProps.options),
    )

    // If we didn't truncate, just return the text
    if (!didTruncate) {
      return [text, false]
    }

    // Logic for adding expansion text (eg "...read more")

    // calculate the space needed for the user to render ".. see more"
    // or whatever their expansion text is
    // NOTE: we need to measure the real width of the expansion text
    const expansionSize = expansionText
      ? measureText(expansionText, measurementProps.options)
      : 0
    const finalRowBuffer = expansionSize * expansionTextBufferMultiplier
    const lastLineTargetSize = measurementProps.width - finalRowBuffer

    // resize the last line to fit the expansion text
    lines[lines.length - 1] = resizeLine(
      lines[lines.length - 1],
      lastLineTargetSize,
    )

    // once we've made space, return the collapsed lines
    return [collapseLines(lines), true]
  }, [
    text,
    measurementProps,
    lineClamp,
    expansionText,
    expansionTextBufferMultiplier,
  ])
}
