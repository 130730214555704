import {
  type ExtendedIdea,
  type ExtendedMedia,
  type Idea,
  MediaType,
  type Media,
} from '~publish/pages/Create/types'
import { isExtendedMedia } from '~publish/pages/Create/helpers'
import type {
  RpcUpdateImage,
  RpcUpdateVideo,
  RpcMediaVideo,
  RpcUpdate,
  RpcUpdateMedia,
  RpcUpdateDocument,
} from '~publish/legacy/post/types'

export function getFirstVideo(
  media: Media[] | ExtendedMedia[],
): Media | undefined {
  if (!media) return
  return media.find((m) => m.type === MediaType.video)
}

export function getFirstDocument(media: Media[]): Media | undefined {
  if (!media) return
  return media.find((m) => m.type === MediaType.document)
}

const getPostTypeFromIdea = (idea: Idea): string => {
  let type = 'text'
  if (idea.content.media) {
    const mediaLength = idea.content.media.length
    type = mediaLength > 0 ? 'multipleImage' : 'image'

    const document = idea.content.media.find(
      (m) => m.type === MediaType.document,
    )
    if (document) {
      type = 'document'
    }
  }
  return type
}

function parseIdeaMedia(media: ExtendedMedia[]): RpcUpdateVideo[]
function parseIdeaMedia(media: ExtendedMedia[]): RpcUpdateImage[]
function parseIdeaMedia(media: ExtendedMedia[]): RpcUpdateDocument[]
function parseIdeaMedia(media: ExtendedMedia[] = []): RpcUpdateMedia[] {
  // only one video is allowed in the composer, no extra media here
  const video = getFirstVideo(media)
  if (video && isExtendedMedia(video)) {
    return [
      {
        video: {
          id: video.id ?? 'no-id?',
          title: '',
          thumbnails: [video.thumbnailUrl ?? ''],
          details: {
            height: video.height,
            width: video.width,
            duration: video.duration,
            duration_millis: video.durationMs,
            file_size: video.size,
            location: video.location ?? '',
            transcoded_location: video.transcodedLocation ?? '',
          } as RpcMediaVideo['details'],
        },
        thumbnail: video.thumbnailUrl ?? '',
        uploaded: 'true',
        progress: '100',
        alt_text: video.alt,
        source: video.url,
        uploading_type: 'video',
      },
    ]
  }

  const document = getFirstDocument(media)
  if (document) {
    return [
      {
        progress: '100',
        uploaded: 'true',
        thumbnail: document.thumbnailUrl ?? '',
        uploading_type: 'document',
        document: {
          id: document.id,
          title: '',
          details: {
            location: document.url,
            pageCount: 0,
            fileSizeBytes: 0,
          },
        },
      },
    ]
  }
  // if we don't have a video, map throug images and gifs
  return media
    .filter((m) => m.type !== MediaType.video)
    .map((m) => {
      return {
        alt_text: m.alt,
        progress: '100',
        source: '',
        uploaded: 'true',
        thumbnail: m.thumbnailUrl || '',
        photo: m.url,
        picture: m.url,
        width: `${m.width}`,
        height: `${m.height}`,
      }
    })
}

// Parse to accommodate text only ideas for now
export const parseIdeaToComposerPost = (
  idea: ExtendedIdea,
  user: { id: string; email: string },
): RpcUpdate => {
  // eslint-disable-next-line camelcase
  const [media, ...extra_media] = parseIdeaMedia(
    idea?.content.media as ExtendedMedia[],
  )
  const type = getPostTypeFromIdea(idea)
  const postText = idea.content.text ?? ''

  return {
    id: '',
    status: '',
    annotations: [],
    notes: [],
    createdAt: 0,
    day: '',
    dueAt: 0,
    due_at: 0,
    hasPushNotifications: false,
    headerDetails: {},
    imageUrls: [],
    isDraft: false,
    isFixed: false,
    isManager: false,
    isPastDue: false,
    isReminder: false,
    isPastReminder: false,
    isSent: false,
    linkAttachment: {},
    links: [],
    media,
    // eslint-disable-next-line camelcase
    extra_media,
    needsApproval: false,
    pinned: false,
    postDetails: {
      postAction: '',
      isRetweet: false,
      isCustomScheduled: false,
      isInstagramReminder: false,
      isPendingTiktokPost: false,
    },
    profileId: '',
    profileService: '',
    profileServiceType: '',
    profileTimezone: '',
    profile_service: '',
    retweet: false,
    retweetCommentLinks: [],
    scheduledAt: 0,
    scheduled_at: 0,
    serviceLink: '',
    serviceUpdateId: '',
    source_url: '',
    statistics: {},
    subprofile_id: '',
    title: '',
    type,
    updateType: '',
    user: {
      id: user.id,
      avatar: '',
      email: user.email,
    },
    imageSrc: '',
    campaignDetails: undefined,
    text: postText,
    postContent: {
      text: postText,
      type,
    },
    ideaId: idea.id,
    fromIdea: true,
    thread: undefined,
    tags: idea.content.tags || [],
  }
}
