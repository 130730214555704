import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Dialog, EmptyState, WarningIcon, Text } from '@buffer-mono/popcorn'

export function PostErrorDialog({
  error,
  parent,
}: {
  error: { message: string }
  parent: string
}): JSX.Element {
  const [open, setOpen] = useState(true)
  const history = useHistory()

  const onOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      history.push(parent)
    }
  }
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size={error ? 'medium' : 'large'}
        style={{ overflow: 'visible', padding: 0 }}
      >
        <EmptyState
          variant={error.message === 'Bad Request' ? 'warning' : 'critical'}
        >
          <EmptyState.Icon>
            <WarningIcon />
          </EmptyState.Icon>
          {error.message === 'Bad Request' && (
            <>
              <EmptyState.Heading>Post not found</EmptyState.Heading>
              <EmptyState.Description>
                The post you are trying to access does not exist.
              </EmptyState.Description>
            </>
          )}
          {error.message !== 'Bad Request' && (
            <>
              <EmptyState.Heading>Error happened</EmptyState.Heading>
              <EmptyState.Description>
                Error happened fetching the post, please let our team know about
                it. <Text color="critical">{error.message}</Text>
              </EmptyState.Description>
            </>
          )}
        </EmptyState>
      </Dialog.Content>
    </Dialog>
  )
}
