import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { selectPostById } from '~publish/legacy/post/slice'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import { PostEntity } from '../PostEntity'
import {
  PostActionNotificationSuccess,
  PostActionNotificationError,
} from '../types'

export const approveDraft = createAsyncThunk<
  boolean,
  { postId: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/approveDraft',
  async ({ postId }, { dispatch, rejectWithValue, getState }) => {
    const state = getState()
    const post = selectPostById(state, postId)
    const isScheduled = PostEntity.isScheduled(post)

    try {
      const response = await callRpc('approveDraft', {
        updateId: postId,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      const message = isScheduled
        ? PostActionNotificationSuccess.SCHEDULE_POST
        : PostActionNotificationSuccess.ADD_TO_QUEUE

      dispatch(
        notificationActions.createNotification({
          notificationType: 'success',
          message,
        }),
      )

      return true
    } catch (error) {
      const message = isScheduled
        ? PostActionNotificationError.SCHEDULE_POST
        : PostActionNotificationError.ADD_TO_QUEUE

      dispatch(
        notificationActions.createNotification({
          notificationType: 'error',
          message,
        }),
      )

      return rejectWithValue(message)
    }
  },
)
