import React from 'react'
import { Button, Notice } from '@buffer-mono/popcorn'

import { useSelectedChannel } from './ChannelContext'
import { useReconnectChannel } from '~publish/hooks/useReconnectChannel'
import { useRegisteredBanners } from '~publish/components/RegisteredBanner'

export const DisconnectedChannelNotice = (): JSX.Element | null => {
  const selectedChannel = useSelectedChannel()
  const reconnectChannel = useReconnectChannel()

  const canRender = selectedChannel.isDisconnected

  // Suppress banners if this notice is visible
  const { suppressBanners, unsuppressBanners } = useRegisteredBanners()
  React.useLayoutEffect(() => {
    if (canRender) {
      suppressBanners()
    }
    return () => unsuppressBanners()
  }, [canRender, suppressBanners, unsuppressBanners])

  if (!canRender) {
    return null
  }

  const canRefreshConnection = selectedChannel.accessLevel === 'fullPosting'
  const handleReconnect = (): void => {
    reconnectChannel({
      id: selectedChannel.id,
      service: selectedChannel.service,
      cta: 'publish-channelPage-notice-refreshConnection-1',
    })
  }

  return (
    <Notice variant="error">
      <Notice.Heading>We&apos;ve lost access to this channel</Notice.Heading>
      <Notice.Text>
        Posts for this channel will not get published until you refresh the
        connection.
      </Notice.Text>
      {canRefreshConnection && (
        <Notice.Actions>
          <Button variant="critical" onClick={handleReconnect}>
            Refresh Connection
          </Button>
        </Notice.Actions>
      )}
    </Notice>
  )
}
