import React, { type FC, type HTMLAttributes } from 'react'

interface DropdownContentProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
  type?: string
}

const DropdownContent: FC<DropdownContentProps> = ({
  children,
  className = '',
  ...dropdownContentProps
}) => {
  return (
    <div className={`dropdown__content ${className}`} {...dropdownContentProps}>
      {children}
    </div>
  )
}

DropdownContent.displayName = 'DropdownContent'

export default DropdownContent
