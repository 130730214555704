import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useRef, useState } from 'react'

/**
 * A custom React hook that determines the animation direction based on date changes.
 * Right animation is shown when the selected date is after the previous selected date
 * and left animation is shown when the selected date is before the previous selected date.
 *
 * @param {Temporal.ZonedDateTime} selectedDate - The currently selected date.
 * @returns {Object} An object containing:
 *   - showRightAnimation: A boolean indicating if the right animation should be shown.
 *   - showLeftAnimation: A boolean indicating if the left animation should be shown.
 *   - handleAnimationEnd: A function to be called when the animation ends.
 *
 * @example
 * const { showRightAnimation, showLeftAnimation, handleAnimationEnd } = useAnimationDirection(selectedDate)
 * <div className={`${showRightAnimation ? 'animate-right' : ''} ${showLeftAnimation ? 'animate-left' : ''}`} onAnimationEnd={handleAnimationEnd}>
 *   ...
 * </div>
 */
export function useAnimationDirection(selectedDate: Temporal.ZonedDateTime): {
  showRightAnimation: boolean
  showLeftAnimation: boolean
  handleAnimationEnd: () => void
} {
  const prevSelectedDateRef = useRef(selectedDate)
  const [showRightAnimation, setShowRightAnimation] = useState(false)
  const [showLeftAnimation, setShowLeftAnimation] = useState(false)

  useEffect(() => {
    if (
      isAfter(
        selectedDate.toPlainDate(),
        prevSelectedDateRef.current.toPlainDate(),
      )
    ) {
      setShowRightAnimation(true)
      setShowLeftAnimation(false)
    } else if (
      isBefore(
        selectedDate.toPlainDate(),
        prevSelectedDateRef.current.toPlainDate(),
      )
    ) {
      setShowLeftAnimation(true)
      setShowRightAnimation(false)
    }
    prevSelectedDateRef.current = selectedDate
  }, [selectedDate])

  /**
   * Resets both animation states to false.
   * This function should be called when the animation ends.
   */
  const handleAnimationEnd = (): void => {
    setShowRightAnimation(false)
    setShowLeftAnimation(false)
  }

  return { showRightAnimation, showLeftAnimation, handleAnimationEnd }
}

function isAfter(
  day: Temporal.PlainDate,
  otherDay: Temporal.PlainDate,
): boolean {
  return Temporal.PlainDate.compare(day, otherDay) > 0
}

function isBefore(
  day: Temporal.PlainDate,
  otherDay: Temporal.PlainDate,
): boolean {
  return Temporal.PlainDate.compare(day, otherDay) < 0
}
