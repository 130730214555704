import { MediaAttachmentTypes } from '~publish/legacy/constants'

const DEFAULT_SIZE_IMAGE = 10 * 1024 * 1024
const DEFAULT_SIZE_GIF = 15 * 1024 * 1024
const DEFAULT_SIZE_VIDEO = 1024 * 1024 * 1024
const DEFAULT_FILE_SIZE_PDF = 100 * 1024 * 1024

const JPG = [MediaAttachmentTypes.JPG, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const JPEG = [
  MediaAttachmentTypes.JPEG,
  { maxSize: DEFAULT_SIZE_IMAGE },
] as const

const GIF = [MediaAttachmentTypes.GIF, { maxSize: DEFAULT_SIZE_GIF }] as const

const PNG = [MediaAttachmentTypes.PNG, { maxSize: DEFAULT_SIZE_IMAGE }] as const

const WEBP = [
  MediaAttachmentTypes.WEBP,
  { maxSize: DEFAULT_SIZE_IMAGE },
] as const

const HEIC = [
  MediaAttachmentTypes.HEIC,
  { maxSize: DEFAULT_SIZE_IMAGE },
] as const

const PDF = [
  MediaAttachmentTypes.PDF,
  { maxSize: DEFAULT_FILE_SIZE_PDF },
] as const

const WEBM = [
  MediaAttachmentTypes.WEBM,
  { maxSize: DEFAULT_SIZE_VIDEO },
] as const

const MP4 = [MediaAttachmentTypes.MP4, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const M4V = [MediaAttachmentTypes.M4V, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MOV = [MediaAttachmentTypes.MOV, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const AVI = [MediaAttachmentTypes.AVI, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MPG = [MediaAttachmentTypes.MPG, { maxSize: DEFAULT_SIZE_VIDEO }] as const

const MPEG = [
  MediaAttachmentTypes.MPEG,
  { maxSize: DEFAULT_SIZE_VIDEO },
] as const

/**
 * This is a map of maps, allowing to quickly access a content type group's
 * allowed formats as keys, and that format's specifics in an object literal:
 *
 * FileUploadFormatsConfigs = {
 *   IMAGE: Map {
 *     'JPG' => { maxSize: 10 * 1024 * 1024 },
 *     'JPEG' => { maxSize: 10 * 1024 * 1024 },
 *     'PNG' => { maxSize: 10 * 1024 * 1024 }
 *   },
 *   ...
 * }
 */
export type FileUploadConfig = Map<string, { maxSize: number }>

const MEDIA = new Map([
  JPG,
  JPEG,
  GIF,
  PNG,
  WEBP,
  MOV,
  MP4,
  M4V,
  AVI,
  WEBM,
  HEIC,
])

export const FileUploadConfig: Record<string, FileUploadConfig> = {
  IMAGE: new Map([JPG, JPEG, PNG, WEBP, HEIC]),
  VIDEO: new Map([MOV, MP4, M4V, AVI, WEBM]),
  GIF: new Map([GIF]),
  MEDIA,
  LINKEDIN: new Map([...MEDIA, PDF]),
  OMNI: new Map([...MEDIA, PDF]),
  IDEAS: new Map([JPG, JPEG, GIF, PNG, WEBP, MOV, MP4, M4V, WEBM, HEIC, PDF]),
  STORIES: new Map([JPG, JPEG, GIF, PNG, MOV, MP4, M4V, AVI, HEIC]),
  REELS: new Map([MOV, MP4, M4V, AVI]),
  FACEBOOK_REELS: new Map([MOV, MP4, M4V, AVI]),
  FACEBOOK_STORIES: new Map([JPG, JPEG, GIF, PNG, MOV, MP4, M4V, AVI]),
  SHORTS: new Map([MOV, MP4, MPG, MPEG, AVI, WEBM]),
} as const
