import { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  calendarMonth,
  calendarWeek,
  getMatch,
  newCalendarAllChannels,
  newCalendarSingleChannel,
} from '~publish/legacy/routes'

/**
 * Represents the view mode of the calendar.
 * Can be either 'week' or 'month'.
 */
export type ViewMode = 'week' | 'month'

/**
 * A custom hook that manages the view mode of the calendar.
 * It handles the routing logic for switching between week and month views,
 * and supports both legacy and new calendar routes.
 *
 * @returns {[ViewMode, (mode: ViewMode | ((mode: ViewMode) => ViewMode)) => void]}
 * A tuple containing:
 *   - The current view mode ('week' or 'month')
 *   - A function to set the view mode
 */
export const useViewMode = (): [
  ViewMode,
  (mode: ViewMode | ((mode: ViewMode) => ViewMode)) => void,
] => {
  const location = useLocation()
  const history = useHistory()

  const isLegacyView = !!getMatch({
    pathname: location.pathname,
    route: [calendarWeek.route, calendarMonth.route],
  })

  const isLegacyWeekView = !!getMatch({
    pathname: location.pathname,
    route: [calendarWeek.route],
  })

  const isSingleChannel = !!getMatch({
    pathname: location.pathname,
    route: newCalendarSingleChannel.route,
  })

  const params = useParams<{ granularity: ViewMode; id: string }>()

  const viewMode =
    isLegacyWeekView || params.granularity === 'week' ? 'week' : 'month'

  /**
   * Navigates to the specified calendar view mode.
   *
   * @param {ViewMode} mode - The view mode to navigate to ('week' or 'month')
   */
  const goTo = useCallback(
    (mode: 'week' | 'month') => {
      let route: string
      if (isLegacyView) {
        if (mode === 'week') {
          route = calendarWeek.getRoute()
        } else {
          route = calendarMonth.getRoute()
        }
      } else {
        if (isSingleChannel) {
          route = newCalendarSingleChannel.getRoute(params.id, mode)
        } else {
          route = newCalendarAllChannels.getRoute(mode)
        }
      }
      history.push(route)
    },
    [history, isLegacyView, isSingleChannel, params.id],
  )

  /**
   * Sets the view mode of the calendar.
   * It can accept either a ViewMode directly, or a function that receives the current ViewMode
   * and returns a new ViewMode.
   *
   * @param {ViewMode | ((mode: ViewMode) => ViewMode)} mode - The new view mode or a function to compute it
   */
  const setViewMode = useCallback(
    (mode: ViewMode | ((mode: ViewMode) => ViewMode)): void => {
      if (typeof mode === 'function') {
        const newViewMode = mode(viewMode)
        goTo(newViewMode)
      } else {
        goTo(mode)
      }
    },
    [goTo, viewMode],
  )

  return [viewMode, setViewMode]
}
