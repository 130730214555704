import type { Reducer } from 'redux'
import { LOCATION_CHANGE } from 'redux-first-history'
import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import {
  campaignItemParser,
  campaignParser,
} from '~publish/legacy/duplicate-server/parsers'
import { campaignTab, getParams, tagTab } from '~publish/legacy/routes'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { type Campaign, CampaignTab, type Tag } from './types'
import { noteAdded, noteDeleted, noteUpdated } from '~publish/legacy/post/slice'
import { createNextState } from '@reduxjs/toolkit'
import { parseNotes } from '~publish/legacy/duplicate-server/parsers/postParser'
import keyWrapper from '~publish/helpers/keywrapper'

const determineIfMoreToLoad = (
  totalCount: number,
  newPostsCount: number,
  currentPostsCount: number,
): boolean => {
  return totalCount > newPostsCount + currentPostsCount
}

export const actionTypes = keyWrapper('CAMPAIGN_VIEW', {
  FETCH_CAMPAIGN: 0,
  PUSHER_CAMPAIGN_CREATED: 0,
  PUSHER_CAMPAIGN_DELETED: 0,
  PUSHER_CAMPAIGN_UPDATED: 0,
  CLOSE_COMPOSER: 0,
  GO_TO_ANALYZE_REPORT: 0,
  POST_CONFIRMED_DELETE: 0,
  POST_SHARE_NOW: 0,
})

interface BaseStateData {
  isLoading: boolean
  hideSkeletonHeader: boolean
  campaignId?: string
  editMode: boolean
  draftMode: boolean
  editingPostId?: string
  selectedProfileId?: string
  page?: CampaignTab
  loadingMore: boolean
  moreToLoad: boolean
  pageNumber: number
  total: number
}

export interface StateWithCampaignData extends BaseStateData {
  campaign: Campaign
  campaignPosts: RpcUpdate[] | []
}

export interface StateWithoutCampaignData extends BaseStateData {
  campaign: undefined
  campaignPosts: []
}

export type InitialState = StateWithCampaignData | StateWithoutCampaignData

export const initialState: InitialState = {
  campaign: undefined,
  campaignPosts: [],
  isLoading: true,
  hideSkeletonHeader: false,
  campaignId: undefined,
  editMode: false,
  draftMode: false,
  editingPostId: undefined,
  selectedProfileId: undefined,
  page: undefined,
  loadingMore: false,
  moreToLoad: false,
  pageNumber: 1,
  total: 0,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'campaignPosts' implicitly has an ... Remove this comment to see the full error message
const postReducer = ({ campaignPosts, action, newState }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  return campaignPosts.map((campaign) => {
    if (campaign?.id === action.updateId) {
      return {
        ...campaign,
        ...newState,
      }
    }
    return campaign
  })
}

const reducer: Reducer<InitialState> = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { pathname } = action.payload.location
      const params: { id?: string; tab?: CampaignTab } | null = getParams({
        pathname,
        route: [campaignTab.route, tagTab.route],
      })
      const newCampaignId = params?.id
      const currentCampaignId = state?.campaign?.id
      // Not showing a header loader when the campaign stored in the state is the same.
      const isFirstTimeLoading = currentCampaignId !== newCampaignId
      return {
        ...state,
        campaignId: newCampaignId,
        isLoading: true,
        hideSkeletonHeader: !isFirstTimeLoading,
        page: params?.tab,
        campaignPosts: [],
        moreToLoad: false,
        pageNumber: 1,
        total: 0,
      }
    }
    case `getCampaign_${dataFetchActionTypes.FETCH_START}`: {
      return {
        ...state,
        loadingMore: action.args.isFetchingMore,
        hideSkeletonHeader: false,
      }
    }
    case `getCampaign_${dataFetchActionTypes.FETCH_FAIL}`: {
      return {
        ...state,
        isLoading: false,
        loadingMore: false,
        hideSkeletonHeader: false,
      }
    }
    case actionTypes.PUSHER_CAMPAIGN_UPDATED: {
      const { campaign } = state
      const updatedCampaign: Campaign = campaignParser(action.campaign)

      let campaignToLoad = updatedCampaign
      if (campaign) {
        campaignToLoad =
          updatedCampaign.id === campaign.id ? updatedCampaign : campaign
      }

      const channels = updatedCampaign.channels || campaign?.channels

      return {
        ...state,
        campaign: {
          ...campaignToLoad,
          channels,
        },
      }
    }
    case `getCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const { fullItems, status } = action.args
      const { items, total, ...campaign } = action.result
      const campaignPosts = (fullItems && items) || []
      return {
        ...state,
        campaign,
        campaignId: campaign.id,
        campaignPosts:
          status === state.page
            ? [...state.campaignPosts, ...campaignPosts]
            : [...state.campaignPosts],
        isLoading: false,
        hideSkeletonHeader: false,
        pageNumber:
          status === state.page ? state.pageNumber + 1 : state.pageNumber,
        total,
        loadingMore: false,
        moreToLoad:
          status === state.page
            ? determineIfMoreToLoad(
                total,
                campaignPosts.length,
                state.campaignPosts.length,
              )
            : state.moreToLoad,
      }
    }

    case `canViewReport_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const canViewReport = action.result
      if (state.campaign) {
        return {
          ...state,
          campaign: { ...state.campaign, canViewReport },
        }
      }
      return state
    }

    // Pusher events for Drafts
    case draftActionTypes.DRAFT_UPDATED:
    case draftActionTypes.DRAFT_CREATED:
    case draftActionTypes.DRAFT_DELETED: {
      const isDeleteAction = action.type === draftActionTypes.DRAFT_DELETED
      const isCreateAction = action.type === draftActionTypes.DRAFT_CREATED
      const isUpdateAction = action.type === draftActionTypes.DRAFT_UPDATED
      const inDraftsOrApprovalPage =
        state.page === CampaignTab.Drafts ||
        state.page === CampaignTab.Approvals
      const { campaign } = state
      const tagsIds = action.draft.tags.map((tag: Tag) => tag.id)
      if (
        campaign &&
        tagsIds.includes(campaign?.id) &&
        inDraftsOrApprovalPage
      ) {
        const parsedItem =
          (isCreateAction || isUpdateAction) &&
          campaignItemParser(
            {
              content: action.draft,
              type: 'update',
            },
            state?.campaign?.channels,
            true,
          )
        if (isDeleteAction) {
          const newCampaignPosts = state?.campaignPosts?.filter(
            (post) => post.id !== action.draft.id,
          )
          return {
            ...state,
            campaignPosts: newCampaignPosts,
          }
        }
        if (isCreateAction) {
          return {
            ...state,
            campaignPosts: [...(state?.campaignPosts ?? {}), parsedItem],
          }
        }
        if (isUpdateAction) {
          const newCampaignPosts = state?.campaignPosts?.map((post) => {
            if (post.id === action.draft.id) {
              return parsedItem
            }
            return post
          })
          return {
            ...state,
            campaignPosts: newCampaignPosts,
          }
        }
      }
      return state
    }
    // Pusher events for Posts
    case queueActionTypes.POST_UPDATED: {
      const inSentPage = state.page === CampaignTab.Sent
      const postCampaignId =
        action?.post?.campaignDetails?.id || action?.post?.campaignId
      const { campaign } = state
      if (
        campaign &&
        postCampaignId === campaign.id &&
        typeof postCampaignId === 'string' &&
        !inSentPage
      ) {
        if (action.post.isDraft) {
          const newCampaignPosts = state?.campaignPosts?.filter(
            (post) => post.id !== action?.post?.id,
          )
          return {
            ...state,
            campaignPosts: newCampaignPosts,
          }
        }
        const newCampaignPosts = state?.campaignPosts?.map((post) => {
          if (post.id === action.post.id) {
            const parsedItem = campaignItemParser(
              {
                content: action.post,
                type: 'update',
              },
              campaign.channels,
              true,
            )
            return parsedItem
          }
          return post
        })
        return {
          ...state,
          campaignPosts: newCampaignPosts,
        }
      }
      return state
    }
    case queueActionTypes.POST_CREATED:
    case draftActionTypes.DRAFT_MOVED:
    case draftActionTypes.DRAFT_APPROVED: {
      const inScheduledPage = state.page === CampaignTab.Scheduled
      const inDraftsOrApprovalPage =
        state.page === CampaignTab.Drafts ||
        state.page === CampaignTab.Approvals
      const postCampaignId = action?.post?.campaignDetails?.id
      if (
        postCampaignId === state.campaign?.id &&
        typeof postCampaignId === 'string' &&
        inScheduledPage
      ) {
        const parsedItem = campaignItemParser(
          {
            content: action.post || action.draft,
            type: 'update',
          },
          state?.campaign?.channels,
          true,
        )
        return {
          ...state,
          campaignPosts: [...(state?.campaignPosts ?? {}), parsedItem],
        }
      }
      if (inDraftsOrApprovalPage) {
        const newCampaignPosts = state?.campaignPosts?.filter(
          (post) => post.id !== action?.draft?.id,
        )
        return {
          ...state,
          campaignPosts: newCampaignPosts,
        }
      }
      return state
    }
    case queueActionTypes.POST_DELETED: {
      const inScheduledPage = state.page === CampaignTab.Scheduled
      const postCampaignId = action?.post?.campaignDetails?.id
      if (
        postCampaignId === state.campaign?.id &&
        typeof postCampaignId === 'string' &&
        inScheduledPage
      ) {
        const newCampaignPosts = state?.campaignPosts?.filter(
          (post) => post.id !== action.post.id,
        )
        return {
          ...state,
          campaignPosts: newCampaignPosts,
        }
      }
      return state
    }
    case queueActionTypes.POST_SENT: {
      const inScheduledPage = state.page === CampaignTab.Scheduled
      const inSentPage = state.page === CampaignTab.Sent
      const postCampaignId = action?.post?.campaignDetails?.id
      const currentCampaign = state?.campaign
      if (
        currentCampaign &&
        postCampaignId === currentCampaign.id &&
        typeof postCampaignId === 'string' &&
        (inScheduledPage || inSentPage)
      ) {
        const campaignPostsFiltered = state.campaignPosts.filter(
          (post) => post.id !== action.post.id,
        )
        const parsedItem = campaignItemParser(
          {
            content: action.post,
            type: 'update',
          },
          state?.campaign?.channels,
          true,
        )
        const newCampaignPosts = () => {
          if (inScheduledPage) return campaignPostsFiltered
          if (inSentPage) return [...state.campaignPosts, parsedItem]
        }
        const { scheduled, sent } = currentCampaign
        return {
          ...state,
          campaign: {
            ...state.campaign,
            scheduled: scheduled - 1,
            sent: sent + 1,
          },
          campaignPosts: newCampaignPosts(),
        }
      }
      return state
    }
    // Post events
    case actionTypes.POST_CONFIRMED_DELETE:
    case actionTypes.POST_SHARE_NOW:
    case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
    case `deletePost_${dataFetchActionTypes.FETCH_FAIL}`: {
      let newState = {}
      switch (action.type) {
        case actionTypes.POST_CONFIRMED_DELETE:
          newState = {
            isConfirmingDelete: false,
            isDeleting: true,
          }
          break
        case `deletePost_${dataFetchActionTypes.FETCH_FAIL}`:
          newState = {
            isDeleting: false,
          }
          break
        case actionTypes.POST_SHARE_NOW:
          newState = {
            isWorking: true,
          }
          break
        case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
          newState = {
            isWorking: false,
          }
          break
        default:
      }

      return {
        ...state,
        campaignPosts: postReducer({
          campaignPosts: state.campaignPosts,
          action,
          newState,
        }),
      }
    }
    case noteAdded.type:
    case noteUpdated.type:
    case noteDeleted.type: {
      const { postId, notes } = action.payload
      return createNextState(state, (draftState) => {
        const post = draftState?.campaignPosts?.find(
          (post) => post.id === postId,
        )
        if (post) post.notes = parseNotes(notes)
        return draftState
      })
    }
    case `getPostsCountForTag_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      if (state.campaign) {
        return {
          ...state,
          campaign: {
            ...state.campaign,
            sent: action.result.sent_count,
            draftsCount: action.result.draft_count,
            scheduled: action.result.scheduled_count,
            approvalsCount: action.result.draft_pending_count,
          },
        }
      }
      return state
    }
    default:
      return state
  }
}

export const actions = {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
  fetchCampaign: ({
    campaignId,
    past,
    status,
    fullItems,
    count,
    pageNumber,
    sortDirection,
    sortField,
    isFetchingMore = false,
  }: {
    campaignId: string
    past: boolean
    status?: string
    fullItems?: boolean
    count?: number
    pageNumber?: number
    sortDirection: string
    sortField: string
    isFetchingMore?: boolean
  }) => ({
    type: actionTypes.FETCH_CAMPAIGN,
    campaignId,
    past,
    status,
    fullItems,
    count,
    pageNumber,
    sortDirection,
    sortField,
    isFetchingMore,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  goToAnalyzeReport: (campaign) => ({
    type: actionTypes.GO_TO_ANALYZE_REPORT,
    campaign,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  handleDeleteConfirmClick: ({ post }) => ({
    type: actionTypes.POST_CONFIRMED_DELETE,
    updateId: post.id,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  handleShareNowClick: ({ post }) => ({
    type: actionTypes.POST_SHARE_NOW,
    updateId: post.id,
  }),
}

export default reducer
