import type { Line } from './lines'

/**
 * Resize a line to fit within a target size
 * This will return a new line with words removed from the end
 * of the line until the line width is less than or equal to the target size
 * @param line input line
 * @param targetSize target size in pixels
 * @returns output line
 */
export const resizeLine = (line: Line, targetSize: number): Line => {
  if (line.width <= targetSize) return line

  const updatedLine = { ...line, words: [...line.words] }

  // remove a few tokens to add back in the expansion text
  while (updatedLine.words.length && updatedLine.width > targetSize) {
    const lastWord = updatedLine.words.pop()
    updatedLine.width -= lastWord?.widthWithSpace ?? 0
  }

  return updatedLine
}
