import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { Popover } from '@buffer-mono/legacy-bufferapp-components'

import { createOrUpdateIdea } from '~publish/pages/Create/helpers/ideas'
import { useCreateIdea } from '~publish/pages/Create/hooks/useCreateIdea'
import { useEditIdea } from '~publish/pages/Create/hooks/useEditIdea'

import type { Idea as IdeaType, NewIdea } from '~publish/pages/Create/types'

import { actions } from '../../reducer'
import ComposerWrapper from '../ComposerWrapper'
import { useAppSelector } from '~publish/legacy/store'
import { useDispatch } from 'react-redux'

const ComposerPopover = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'transparentOverlay' implicitly ha... Remove this comment to see the full error message
  transparentOverlay,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldResetComposerData' implicit... Remove this comment to see the full error message
  shouldResetComposerData,
}): JSX.Element | null => {
  const dispatch = useDispatch()
  const [showDismissComposerModal, setShowDismissComposerModal] =
    useState(false)

  const showComposer = useAppSelector(
    (state) => state.composerPopover.showComposer,
  )

  const { createIdea } = useCreateIdea()
  const { updateIdea } = useEditIdea()

  const idea = useAppSelector((state) => state.composerPopover.idea)

  // create or update idea after the "Create Post" button is clicked
  const saveConvertedIdea = (
    convertedIdea: IdeaType | NewIdea | null,
  ): void => {
    createOrUpdateIdea({
      idea: convertedIdea,
      updateIdea,
      createIdea,
    })
  }

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      onOverlayClick()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const onOverlayClick = (): void => {
    setShowDismissComposerModal(true)
  }
  const handleCancelDismissModal = (): void => {
    setShowDismissComposerModal(false)
  }
  const handleConfirmDismissComposer = (): void => {
    setShowDismissComposerModal(false)
    dispatch(actions.hideComposer(true))
  }

  const handleOnSave = (): void => {
    dispatch(actions.hideComposer())
    if (idea) {
      saveConvertedIdea(idea)
    }
  }

  // TODO: This should use the default portals container, but it's not working.
  // We should review all the z-indexes of the composer and move it to a Dialog.
  const portalContainer =
    document.getElementById('composer-container') ?? document.body

  if (showComposer) {
    return createPortal(
      <Popover
        width="100%"
        transparentOverlay={transparentOverlay}
        onOverlayClick={onOverlayClick}
      >
        <ComposerWrapper
          type="composerPopover"
          onSave={handleOnSave}
          shouldResetComposerData={shouldResetComposerData}
          onCancelDismissModal={handleCancelDismissModal}
          onConfirmDismissComposer={handleConfirmDismissComposer}
          showDismissComposerModal={showDismissComposerModal}
          onComposerOverlayClick={onOverlayClick}
        />
      </Popover>,
      portalContainer,
    )
  }

  return null
}

ComposerPopover.propTypes = {
  shouldResetComposerData: PropTypes.bool,
  transparentOverlay: PropTypes.bool,
}

ComposerPopover.defaultProps = {
  transparentOverlay: false,
  shouldResetComposerData: false,
}

export default ComposerPopover
