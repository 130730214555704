import type { RootState } from '../store'
import type { UserS3UploadSignature } from './types'

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserId = (state) => state.user.id

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectFeatureFlip = (state, featureFlip) =>
  state?.user?.features?.includes(featureFlip)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectWeekStartsMonday = (state) =>
  !!state.user?.week_starts_monday

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasTwentyFourHourTimeFormat = (state) =>
  state.user?.hasTwentyFourHourTimeFormat

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserHasFeature = (state, feature) =>
  state?.user?.features?.includes(feature)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectS3Signature = (state): UserS3UploadSignature =>
  state?.user?.s3_upload_signature

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserIdAndEmail = (state) => {
  return {
    id: state.user.id,
    email: state.user.email,
  }
}

export const selectCalendarAsHomepage = (state: RootState): boolean =>
  !!state.user?.showCalendarAsHomepage
