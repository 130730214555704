import React from 'react'

import type { FilterByChannelFragment } from '~publish/components/FilterByChannel'
import type { MultiSelectFilterState } from '~publish/pages/Calendar/hooks/useMultiSelectFilter'

import DayOfWeekHeader from '../DayOfWeekHeader'
import DaysOfMonth from '../DaysOfMonth'
import DaysOfWeek from '../DaysOfWeek'
import { CalendarGridWrapper, CalendarTable } from './style'
import type { UseNavigationReturn } from '~publish/pages/Calendar/hooks/useNavigation'
import useHandleScroll from '../hooks/useHandleScroll'
import CreateCalendarPostContext from '../hooks/createCalendarPostContext'
import { graphql } from '~publish/gql'
import type { PostForCalendarHookResponse } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'

export const GetAllChannelsInfoForCalendar = graphql(/* GraphQL */ `
  query GetAllChannelsInfoForCalendar($organizationId: OrganizationId!) {
    # TODO: channels should be a top-level query
    account {
      currentOrganization {
        channels(product: publish) {
          id
          accessLevel
          isQueuePaused
          ...FilterByChannel_Channel
        }
      }
    }

    tags(input: { organizationId: $organizationId }) {
      ...FilterByTag_Tag
    }
  }
`)

type CalendarPageProps = {
  hasTwentyFourHourTimeFormat: boolean
  onDropPostSuccess: (args: { dueAt: string; isDraft: boolean }) => void
  onDropPostError: (error?: { message: string } | Error | null) => void
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  isViewOnly: boolean
  navigation: UseNavigationReturn
  granularity: 'week' | 'month'
  channelsFilter: MultiSelectFilterState<FilterByChannelFragment>
  postsQuery: PostForCalendarHookResponse
}

export const CalendarGrid = ({
  hasTwentyFourHourTimeFormat,
  onDropPostSuccess,
  onDropPostError,
  setOpenModal,
  isViewOnly,
  navigation,
  granularity,
  channelsFilter,
  postsQuery,
}: CalendarPageProps): JSX.Element => {
  const handleScroll = useHandleScroll()

  return (
    <CalendarGridWrapper ref={handleScroll.childRef}>
      <CalendarTable
        showRightAnimation={navigation.rightAnimation}
        showLeftAnimation={navigation.leftAnimation}
        onAnimationEnd={(): void => {
          if (navigation.rightAnimation) {
            navigation.setRightAnimation(false)
          }
          if (navigation.leftAnimation) {
            navigation.setLeftAnimation(false)
          }
        }}
      >
        <tbody>
          <CreateCalendarPostContext.Provider
            value={{
              hasPermissions: !isViewOnly,
              selectedProfileIds: channelsFilter.sanitized,
            }}
          >
            {granularity === 'week' ? (
              <DaysOfWeek
                postsQuery={postsQuery}
                currentDate={navigation.currentDate}
                selectedChannelIds={channelsFilter.queryParams}
                parentRef={handleScroll.childRef}
                hasTwentyFourHourTimeFormat={hasTwentyFourHourTimeFormat}
                onDropPostSuccess={onDropPostSuccess}
                onDropPostError={onDropPostError}
                setOpenModal={setOpenModal}
              />
            ) : (
              <DaysOfMonth
                postsQuery={postsQuery}
                currentDate={navigation.currentDate}
                selectedChannelIds={channelsFilter.queryParams}
                onDropPostSuccess={onDropPostSuccess}
                onDropPostError={onDropPostError}
                setOpenModal={setOpenModal}
              />
            )}
          </CreateCalendarPostContext.Provider>
        </tbody>
        <DayOfWeekHeader
          currentDate={navigation.currentDate}
          withDay={granularity === 'week'}
          headerShadow={handleScroll.headerShadow}
        />
      </CalendarTable>
    </CalendarGridWrapper>
  )
}
