import twitterText from 'twitter-text'
import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import {
  getMaxHashtagMessage,
  getMaxMentionsMessage,
  getReelsVideoTooLongMessage,
  getReelsVideoTooShortMessage,
  getVideoFrameRateMessage,
  getVideoTooLargeMessage,
  getVideoTooWideMessage,
  INVALID_LINK,
} from './utils/validationErrorMessages'
import { validateMedia } from '~publish/legacy/media/validation/validateMedia'
import {
  getImageRestrictionsForDraft,
  getVideoRestrictionsForDraft,
} from '~publish/legacy/media/config/posts-media-restrictions'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

export default class InstagramValidator extends BaseValidator {
  protected isValidShopgridUrl(): ValidationResult {
    const { channelData, isReminder } = this.draft
    const link = channelData?.instagram?.link

    if (
      !isReminder &&
      link &&
      link.replace(/\s+/g, '') !== '' &&
      !twitterText.isValidUrl(link, true, false)
    ) {
      return new ValidationFail(INVALID_LINK, VALIDATION_CODE.INVALID_VALUE)
    }

    return new ValidationSuccess()
  }

  protected maxHashtags(): ValidationResult {
    if (
      this.draft.getNumberOfHashtags() >
      (this.draft.service.maxHashtags as number)
    ) {
      return new ValidationFail(
        getMaxHashtagMessage(this.draft.service.maxHashtags as number),
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }
    return new ValidationSuccess()
  }

  protected maxMentions(): ValidationResult {
    if (
      this.draft.getNumberOfMentions() > (this.draft.service.maxMentions ?? 0)
    ) {
      return new ValidationFail(
        getMaxMentionsMessage(this.draft.service.maxMentions as number),
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }
    return new ValidationSuccess()
  }

  protected maxCharactersInComment(): ValidationResult {
    if (
      !this.draft.isReminder &&
      (this.draft.characterCommentCount ?? 0) >
        (this.draft.service.commentCharLimit ?? 0)
    ) {
      return new ValidationFail(
        `We can only fit ${this.draft.service.commentCharLimit} characters for comments`,
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }
    return new ValidationSuccess()
  }

  protected isVideoTooWide(maxWidth: number): boolean {
    return this.draft.video !== null && this.draft.video.width > maxWidth
  }

  protected isFrameRateIncorrectForVideo(
    minFrameRate: number,
    maxFrameRate: number,
  ): boolean {
    if (this.draft.video !== null && !!this.draft.video.frameRate) {
      const { frameRate } = this.draft.video
      return frameRate < minFrameRate || frameRate > maxFrameRate
    }
    return false
  }

  protected isVideoTooLong(maxDuration: number): boolean {
    return (
      this.draft.video !== null &&
      !!maxDuration &&
      this.draft.video.durationMs > maxDuration
    )
  }

  protected isVideoTooShort(minDuration: number): boolean {
    return (
      this.draft.video !== null &&
      !!minDuration &&
      this.draft.video.durationMs < minDuration
    )
  }

  protected isVideoTooBig(maxSize: number): boolean {
    return (
      this.draft.video !== null && !!maxSize && this.draft.video.size > maxSize
    )
  }

  protected shouldValidateImage(): boolean {
    return !this.draft.isReminder
  }

  protected validateImage(): ValidationResult[] {
    const rules = getImageRestrictionsForDraft(
      this.draft.service.name,
      this.draft.updateType,
    )
    if (!rules) return [new ValidationSuccess()]

    return this.draft.images.map((image) => {
      const result = validateMedia(rules, image)

      if (result?.isValidationFail()) {
        result.addMetadata(image)
        return result
      }
      return new ValidationSuccess()
    })
  }

  protected validateVideo(): ValidationResult {
    if (this.draft.isReminder) return new ValidationSuccess()

    const rules = getVideoRestrictionsForDraft(
      this.draft.service.name,
      this.draft.updateType,
    )
    const video = this.draft.video

    // aspect ratio validation is done via rules and config
    if (video && rules) {
      const result = validateMedia(rules, video)
      if (result?.isValidationFail()) {
        result.addMetadata(video)
        return result
      }
    }

    if (this.draft.isReelsPost()) {
      return this.validateReelsVideo()
    }

    if (this.draft.isStoryPost()) {
      return this.validateStoriesVideo()
    }

    return super.validateVideo()
  }

  protected validateReelsVideo(): ValidationResult {
    if (
      this.draft.service.videoMaxDurationMsReels &&
      this.isVideoTooLong(this.draft.service.videoMaxDurationMsReels)
    ) {
      const messageOptions = getReelsVideoTooLongMessage(
        (this.draft.service.videoMaxDurationMsReels ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service.videoMinDurationMsReels &&
      this.isVideoTooShort(this.draft.service.videoMinDurationMsReels)
    ) {
      const messageOptions = getReelsVideoTooShortMessage(
        (this.draft.service.videoMinDurationMsReels ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service?.videoMaxSizeReels &&
      this.isVideoTooBig(this.draft.service?.videoMaxSizeReels)
    ) {
      const maxFileSizeInMb =
        (this.draft.service.videoMaxSizeReels ?? 0) / 1024 / 1024

      const messageOptions = getVideoTooLargeMessage(maxFileSizeInMb)
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMaxWidth &&
      this.isVideoTooWide(this.draft.service.videoMaxWidth)
    ) {
      const messageOptions = getVideoTooWideMessage(
        this.draft.service.videoMaxWidth as number,
        this.draft.video?.width as number,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.isFrameRateIncorrectForVideo(
        this.draft.service.videoMinFrameRate as number,
        this.draft.service.videoMaxFrameRate as number,
      )
    ) {
      const messageOptions = getVideoFrameRateMessage(
        this.draft.service.videoMinFrameRate as number,
        this.draft.service.videoMaxFrameRate as number,
        this.draft.video?.frameRate as number,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_FRAME_RATE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    return new ValidationSuccess()
  }

  protected validateStoriesVideo(): ValidationResult {
    if (
      this.draft.service.videoMaxWidth &&
      this.isVideoTooWide(this.draft.service.videoMaxWidth)
    ) {
      const messageOptions = getVideoTooWideMessage(
        this.draft.service.videoMaxWidth as number,
        this.draft.video?.width as number,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMaxDurationMsStories &&
      this.isVideoTooLong(this.draft.service.videoMaxDurationMsStories)
    ) {
      const messageOptions = getReelsVideoTooLongMessage(
        (this.draft.service.videoMaxDurationMsStories ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.isFrameRateIncorrectForVideo(
        this.draft.service.videoMinFrameRate as number,
        this.draft.service.videoMaxFrameRate as number,
      )
    ) {
      const messageOptions = getVideoFrameRateMessage(
        this.draft.service.videoMinFrameRate as number,
        this.draft.service.videoMaxFrameRate as number,
        this.draft.video?.frameRate as number,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_FRAME_RATE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMinDurationMsStories &&
      this.isVideoTooShort(this.draft.service.videoMinDurationMsStories)
    ) {
      const messageOptions = getReelsVideoTooShortMessage(
        (this.draft.service.videoMinDurationMsStories ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service.videoMaxSizeStories &&
      this.isVideoTooBig(this.draft.service.videoMaxSizeStories)
    ) {
      const maxFileSizeInMb =
        (this.draft.service.videoMaxSizeStories ?? 0) / 1024 / 1024
      const messageOptions = getVideoTooLargeMessage(maxFileSizeInMb)
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    return new ValidationSuccess()
  }

  protected validateForChannel(): ValidationResult[] {
    return [
      this.isValidShopgridUrl(),
      this.maxHashtags(),
      this.maxMentions(),
      this.maxCharactersInComment(),
    ]
  }
}
