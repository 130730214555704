import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'
import type { Organization } from './types'

export const actionTypes = keyWrapper('ORGS', {
  ORGANIZATION_SELECTED: 0,
  SET_CURRENT_ORGANIZATION: 0,
})

export type OrganizationAction = {
  type: string
  selected?: Organization
  result?: Organization[]
}

export type OrganizationState = {
  loaded: boolean
  selected: Organization | null
  list: Organization[]
}

const initialState: OrganizationState = {
  loaded: false,
  selected: null,
  list: [],
}

export default function organizationsReducer(
  state = initialState,
  action: OrganizationAction,
): OrganizationState {
  switch (action.type) {
    case `organizations_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const organizations = action.result

      return {
        list: organizations ?? [],
        selected: null,
        loaded: true,
      }
    }
    case actionTypes.ORGANIZATION_SELECTED: {
      return {
        ...state,
        selected: action.selected ?? null,
      }
    }
    default:
      return state
  }
}

export const actions = {
  setCurrentOrganization: (
    organizationId: string,
  ): {
    type: string
    organizationId: string
  } => ({
    type: actionTypes.SET_CURRENT_ORGANIZATION,
    organizationId,
  }),
}
