import React from 'react'
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom'

import {
  postDetailsRoute,
  postEditRoute,
  postNewRoute,
} from '~publish/legacy/routes'

import { PostCreationDialog } from './PostCreationDialog'
import { PostDetailsDialog } from './PostDetailsDialog'
import { PostEditDialog } from './PostEditDialog'

export function PostManagementRouter(): JSX.Element {
  const match = useRouteMatch()
  const { search } = useLocation()

  return (
    <Switch>
      <Route path={`${match.path}${postNewRoute.route}`}>
        <PostCreationDialog parent={match.url + search} />
      </Route>
      <Route path={`${match.path}${postEditRoute.route}`}>
        <PostEditDialog parent={match.url + search} />
      </Route>
      <Route path={`${match.path}${postDetailsRoute.route}`}>
        <PostDetailsDialog parent={match.url + search} />
      </Route>
    </Switch>
  )
}
