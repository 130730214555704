import React from 'react'
import PropTypes from 'prop-types'
import { Link, Divider } from '@buffer-mono/legacy-bufferapp-components'
import { Row } from '~publish/legacy/shared-components'
import { Button, Text } from '@bufferapp/ui'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'

const rowBlockStyle = {
  display: 'block',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '2rem',
  marginRight: '1rem',
}

const AppsManager = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'connectedApps' implicitly has an ... Remove this comment to see the full error message
  connectedApps,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showModalAppId' implicitly has an... Remove this comment to see the full error message
  showModalAppId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showModalAppName' implicitly has ... Remove this comment to see the full error message
  showModalAppName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequestOpenModal' implicitly ha... Remove this comment to see the full error message
  onRequestOpenModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequestCloseModal' implicitly h... Remove this comment to see the full error message
  onRequestCloseModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onConfirmRevokeApp' implicitly ha... Remove this comment to see the full error message
  onConfirmRevokeApp,
  // @ts-expect-error TS(7031) FIXME: Binding element 'submitting' implicitly has an 'an... Remove this comment to see the full error message
  submitting,
}) => {
  const { t } = useTranslation()

  return (
    <section style={rowBlockStyle}>
      <div>
        <Text type="h2">{t('preferences.appsAndExtras.connectedApps')}</Text>
        <Text type="p">
          {t('preferences.appsAndExtras.connectedApps')}
          {t('preferences.appsAndExtras.connectedAppsDesc')}
          {/* @ts-expect-error */}
          <Link newTab href="https://buffer.com/extras">
            {t('preferences.appsAndExtras.getMoreApps')}
          </Link>
        </Text>
        <Divider />
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'app' implicitly has an 'any' type. */}
        {connectedApps.map((app) => (
          <div key={app.id}>
            <Row>
              <Text type="h3">{app.name}</Text>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: () => a... Remove this comment to see the full error message */}
              <Button
                type="secondary"
                label={t('preferences.appsAndExtras.revokeAccess')}
                onClick={() =>
                  onRequestOpenModal({ appId: app.id, appName: app.name })
                }
              />
            </Row>
            <Divider />
          </div>
        ))}
      </div>
      {showModalAppId !== null && (
        <Modal
          appId={showModalAppId}
          appName={showModalAppName}
          onCancelClick={onRequestCloseModal}
          onConfirmClick={onConfirmRevokeApp}
          submitting={submitting}
        />
      )}
    </section>
  )
}

AppsManager.propTypes = {
  connectedApps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  showModalAppId: PropTypes.string,
  showModalAppName: PropTypes.string,
  onRequestCloseModal: PropTypes.func.isRequired,
  onRequestOpenModal: PropTypes.func.isRequired,
  onConfirmRevokeApp: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

AppsManager.defaultProps = {
  connectedApps: [],
  showModalAppId: null,
  showModalAppName: '',
  submitting: false,
}

export default AppsManager
