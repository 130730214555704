import { createAsyncThunk } from '@reduxjs/toolkit'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import type { PublishingPost } from '~publish/gql/graphql'
import { client, gql } from '~publish/legacy/apollo-client'
import {
  PostActionNotificationError,
  PostActionNotificationSuccess,
} from '~publish/legacy/post/types'

const MOVE_POST_TO_DRAFTS = gql`
  mutation movePostToDrafts($input: PostIdInput!) {
    movePostToDrafts(input: $input) {
      ... on PostUpdated {
        success
        message
      }
      ... on PostNotUpdated {
        success
        message
      }

      ... on PostNotFound {
        success
        message
      }

      ... on CoreWebAppCommonError {
        success
        message
      }
    }
  }
`

export const movePostToDrafts = createAsyncThunk<
  unknown,
  // Can optionally pass profileId for use in
  // queue reducer
  { postId: string; profileId?: string },
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/movePostToDrafts',
  async ({ postId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate<
        {
          movePostToDrafts: {
            code: string
            success: boolean
            message: string
            post: PublishingPost
          }
        },
        { input: { postId: string } }
      >({
        mutation: MOVE_POST_TO_DRAFTS,
        variables: { input: { postId } },
      })

      if (!response.data?.movePostToDrafts?.success) {
        const msg = response.data?.movePostToDrafts?.message
        throw new Error(msg || 'Unexpected movePostToDrafts response')
      }

      dispatch(
        notificationActions.createNotification({
          notificationType: 'success',
          message: PostActionNotificationSuccess.MOVE_POST_TO_DRAFTS,
        }),
      )

      return response.data
    } catch (error) {
      dispatch(
        notificationActions.createNotification({
          notificationType: 'error',
          message: PostActionNotificationError.MOVE_POST_TO_DRAFTS,
        }),
      )
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      return rejectWithValue(error.message)
    }
  },
)
