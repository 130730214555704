import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import { DragSource, DropTarget } from 'react-dnd'
import SwapIcon from '@bufferapp/ui/Icon/Icons/Swap'

const swapWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}

const swapIconStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 19px',
  background: '#2C4BFF',
  borderRadius: '24px',
  color: '#FFFFFF',
}

const swapTextStyle: React.CSSProperties = {
  marginLeft: '8px',
  fontSize: '14px',
  fontWeight: 'bold',
}

const postSource = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  canDrag(props) {
    return props.userCanEdit && !props.postProps.postDetails.error
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  beginDrag(props, monitor, component) {
    return {
      id: props.id,
      index: props.index,
      postComponent: props.postComponent,
      postProps: props.postProps,
      width: component.containerNode.offsetWidth,
      onDropPost: props.postProps.onDropPost,
      onSwapPosts: props.postProps.onSwapPosts,
      profileId: props.profileId,
      basic: props.basic,
    }
  },
}

const postTarget = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props, monitor) {
    const { onSwapPosts } = monitor.getItem()
    /* postSource, postTarget */
    onSwapPosts(monitor.getItem(), props)
  },
}

const getBgStyle = (isHovering: boolean, focus: boolean): string => {
  if (isHovering || focus) return '#FFFFFF'
  return '#F5F5F5'
}

class PostDragWrapper extends Component {
  constructor() {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    super()

    this.state = {
      isHovering: false,
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  componentDidMount() {
    const img = new Image()
    img.src =
      'https://s3.amazonaws.com/buffer-publish/images/drag-placeholder.png'
    // @ts-expect-error TS(2339) FIXME: Property 'connectDragPreview' does not exist on ty... Remove this comment to see the full error message
    img.onload = () => this.props.connectDragPreview(img)
  }

  onMouseEnter() {
    this.setState((state) => ({ ...state, isHovering: true }))
  }

  onMouseLeave() {
    this.setState((state) => ({ ...state, isHovering: false }))
  }

  /**
   * These styles ensure we don't show the focus ring when using
   * the mouse for drag and drop.
   */
  getStyle(
    isHovering: boolean,
    focus: boolean,
    isDragging: boolean,
  ): React.CSSProperties {
    const transition = 'all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)'
    const hideOutline = { outline: 'none' }

    if (!isHovering && !isDragging) {
      return {
        background: getBgStyle(isHovering, focus),
        boxShadow: focus ? '0 0 2px 2px #ABB7FF' : 'none',
        position: 'relative',
        borderRadius: '4px',
        transition,
        width: '100%',
        ...hideOutline,
      }
    }
    return { borderRadius: '4px', width: '100%', transition, ...hideOutline }
  }

  renderSwapIcon() {
    return (
      <div style={swapWrapperStyle}>
        <div style={swapIconStyle}>
          <SwapIcon size="medium" color="#FFFFFF" />
          <span style={swapTextStyle}>Swap Posts</span>
        </div>
      </div>
    )
  }

  render(): JSX.Element {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'Readonly<{}>... Remove this comment to see the full error message
      id: postId,
      // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      post,
      // @ts-expect-error TS(2339) FIXME: Property 'postComponent' does not exist on type 'R... Remove this comment to see the full error message
      postComponent: PostComponent,
      // @ts-expect-error TS(2339) FIXME: Property 'postProps' does not exist on type 'Reado... Remove this comment to see the full error message
      postProps,
      // @ts-expect-error TS(2339) FIXME: Property 'isDragging' does not exist on type 'Read... Remove this comment to see the full error message
      isDragging,
      // @ts-expect-error TS(2339) FIXME: Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
      connectDragSource,
      // @ts-expect-error TS(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
      connectDropTarget,
      // @ts-expect-error TS(2339) FIXME: Property 'isOver' does not exist on type 'Readonly... Remove this comment to see the full error message
      isOver,
      // @ts-expect-error TS(2339) FIXME: Property 'basic' does not exist on type 'Readonly<... Remove this comment to see the full error message
      basic,
      // @ts-expect-error TS(2339) FIXME: Property 'userCanEdit' does not exist on type 'Readonly<... Remove this comment to see the full error message
      userCanEdit,
    } = this.props

    // @ts-expect-error TS(2339) FIXME: Property 'isHovering' does not exist on type 'Read... Remove this comment to see the full error message
    const { isHovering } = this.state

    // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Element'
    return compose(
      connectDragSource,
      connectDropTarget,
    )(
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      <div
        aria-dropeffect="move"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={(node) => {
          // @ts-expect-error TS(2339) FIXME: Property 'containerNode' does not exist on type 'P... Remove this comment to see the full error message
          this.containerNode = node
        }}
        draggable
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        style={this.getStyle(isHovering, isOver, isDragging)}
      >
        {!isHovering && !isDragging && isOver && this.renderSwapIcon()}
        <PostComponent
          {...postProps}
          postId={postId}
          post={post}
          draggable={!postProps.postDetails.error}
          dragging={isDragging}
          hovering={isHovering}
          isOver={isOver}
          fixed={postProps.isFixed}
          basic={basic}
        />
      </div>,
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PostDragWrapper.propTypes = {
  handleDragPost: PropTypes.func, // eslint-disable-line
  profileId: PropTypes.string, // eslint-disable-line
  postComponent: PropTypes.func.isRequired,
  postProps: PropTypes.object.isRequired, // eslint-disable-line
  basic: PropTypes.bool,
  id: PropTypes.string.isRequired, // eslint-disable-line
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool,
  userCanEdit: PropTypes.bool,
}

export default DropTarget('post', postTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('post', postSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(PostDragWrapper),
)
