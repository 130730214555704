import React from 'react'
import PropTypes from 'prop-types'

import { Link } from '@buffer-mono/legacy-bufferapp-components'
import { Text } from '@bufferapp/ui'
import { SensitiveData } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'

// @ts-expect-error TS(7006) FIXME: Parameter 't' implicitly has an 'any' type.
const formattedMethod = (t) => ({
  '': t('common.disabled'),
  sms: t('preferences.security.sms'),
  app: t('preferences.security.authenticatorApp'),
})

// @ts-expect-error TS(7031) FIXME: Binding element 'transition' implicitly has an 'an... Remove this comment to see the full error message
const TwoFactorPreferencesRow = ({ transition, method, phoneNumber }) => {
  const { t } = useTranslation()

  return (
    <>
      <Text type="p">
        {t('preferences.security.tfaExplanation')}
        <Link
          // @ts-expect-error
          newTab
          href={`https://support.buffer.com/article/503-enabling-two-factor-authentication?${HC_UTM_PARAMS}`}
        >
          {t('common.learnMore')}
        </Link>
      </Text>
      {method && (
        <>
          <div style={{ margin: '16px 0 0 0' }}>
            <Text type="p">
              {t('preferences.security.method')}:{' '}
              {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              <b>{formattedMethod(t)[method]}</b> {/* @ts-expect-error */}
              <Link href="#" onClick={() => transition('CHANGE_METHOD')}>
                {t('common.edit')}
              </Link>
            </Text>
          </div>
          {method === 'sms' && (
            <div style={{ margin: '8px 0 0 0' }}>
              <Text type="p">
                {t('preferences.security.phoneNumber')}:{' '}
                <SensitiveData>
                  <b>{phoneNumber}</b>
                </SensitiveData>{' '}
                {/* @ts-expect-error */}
                <Link href="#" onClick={() => transition('CHANGE_SMS')}>
                  {t('common.edit')}
                </Link>
              </Text>
            </div>
          )}
        </>
      )}
    </>
  )
}

TwoFactorPreferencesRow.propTypes = {
  transition: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
}

export default TwoFactorPreferencesRow
