import React, { useMemo } from 'react'

import { useSplitEnabled } from '@buffer-mono/features'

import { Thread as ThreadIcon } from '@bufferapp/ui/Icon'
import MessageIcon from '@bufferapp/ui/Icon/Icons/Message'
import {
  CircleInstReminderIcon,
  ClockIcon,
  WarningIcon,
} from '@buffer-mono/legacy-bufferapp-components'

import { grayDark, red } from '@bufferapp/ui/style/colors'

import PostFooterButtons from '~publish/legacy/post/components/PostFooterButtons'

import type { PostAction, RpcUpdate } from '~publish/legacy/post/types'
import CardFooter from '../CardFooter'
import {
  CardFooterContent,
  CommentIconWrapper,
  IconWrapper,
  Message,
  ThreadCounter,
  ThreadIconWrapper,
} from '../CardFooter/styles'
import type { ExecutePostActionById } from '~publish/legacy/post/hooks/usePostActions'

type DraftFooterProps = {
  isScheduled?: boolean
  isPastDue?: boolean
  description?: string
  hasFirstComment?: boolean
  actionMessage?: string
  isPerformingAction?: boolean
  hasEditPermission?: boolean
  singleActions: PostAction[]
  stackedActions: PostAction[]
  executePostActionById: ExecutePostActionById
  onDeleteClick?: (args?: unknown) => void
  onEditClick?: (args?: unknown) => void
  threadCounter: number
  shouldShowThreadsMigrationModal: boolean
  threadsMigrationModalService: string
  isReminder: boolean
  post: RpcUpdate
}

const DraftFooter = ({
  isScheduled,
  isPastDue,
  description,
  hasFirstComment,
  actionMessage,
  isPerformingAction,
  hasEditPermission,
  singleActions,
  stackedActions,
  executePostActionById,
  onDeleteClick,
  onEditClick,
  threadCounter,
  shouldShowThreadsMigrationModal,
  threadsMigrationModalService,
  isReminder,
  post,
}: DraftFooterProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  const renderButtons = hasEditPermission && !actionMessage
  const icon = useMemo(() => {
    if (isPastDue) {
      return <WarningIcon color={red} />
    }
    if (isReminder && !isRemindersEnabled) {
      return <CircleInstReminderIcon color={'instagram'} />
    }
    if (isScheduled) {
      return <ClockIcon />
    }
  }, [isPastDue, isScheduled, isRemindersEnabled, isReminder])

  return (
    <CardFooter>
      <CardFooterContent>
        {threadCounter > 0 && (
          <>
            <ThreadIconWrapper>
              <ThreadIcon
                color={grayDark}
                size="medium"
                role="img"
                title="This is a Thread post"
              />
            </ThreadIconWrapper>
            <ThreadCounter
              type="p"
              textColor={grayDark}
              data-testid="threads-counter"
            >
              {threadCounter}
            </ThreadCounter>
          </>
        )}
        {description && (
          <>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            <Message type="p" textColor={isPastDue ? red : ''}>
              {description}
            </Message>
          </>
        )}
        {hasFirstComment && (
          <CommentIconWrapper title="Post includes a comment">
            <MessageIcon
              data-tip="Includes first comment"
              data-padding="8px 8px 10px 8px"
            />
          </CommentIconWrapper>
        )}
      </CardFooterContent>

      {renderButtons && (
        <PostFooterButtons
          isLoading={isPerformingAction}
          singleActions={singleActions}
          stackedActions={stackedActions}
          executePostActionById={executePostActionById}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          shouldShowThreadsMigrationModal={shouldShowThreadsMigrationModal}
          threadsMigrationModalService={threadsMigrationModalService}
          post={post}
        />
      )}
      {!!actionMessage && <Message type="p">{actionMessage}</Message>}
    </CardFooter>
  )
}

DraftFooter.defaultProps = {
  isScheduled: false,
  isPastDue: false,
  description: null,
  hasFirstComment: false,
  actionMessage: null,
  isPerformingAction: false,
  hasEditPermission: false,
}

export default DraftFooter
