import React from 'react'
import { useCalendarContext } from './context'
import type { CalendarItem } from './types'

const RawItem = function Item({
  id,
  timestamp,
  index,
}: CalendarItem & { index: number }): JSX.Element | null {
  const { renderItem } = useCalendarContext()
  return renderItem({ id, timestamp, index })
}

export const Item = React.memo(RawItem)
