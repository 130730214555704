import React from 'react'
import PropTypes from 'prop-types'
import { Text, Button } from '@bufferapp/ui'
import { Input } from '@buffer-mono/legacy-bufferapp-components'

class Confirm extends React.Component {
  constructor() {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    super()
    this.state = {
      code: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'inputContainer' does not exist on type '... Remove this comment to see the full error message
    if (this.inputContainer) {
      // @ts-expect-error TS(2339) FIXME: Property 'inputContainer' does not exist on type '... Remove this comment to see the full error message
      const input = this.inputContainer.querySelector('input')
      input.focus()
    }
  }

  handleSubmit() {
    // @ts-expect-error TS(2339) FIXME: Property 'submitCode' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.submitCode(this.state.code)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleCodeChange(event) {
    this.setState({ code: event.target.value })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'transition' does not exist on type 'Read... Remove this comment to see the full error message
    const { transition, updateMethod, loading, error } = this.props
    return (
      <>
        <Text type="h3">Enter confirmation code</Text>
        <div style={{ margin: '12px 0 8px' }}>
          <Text type="p">
            Awesome! Now we just need to confirm everything.{' '}
          </Text>
          {updateMethod === 'app' && (
            <Text type="p">
              Open your authenticator app and input the generated code.
            </Text>
          )}
          {updateMethod === 'sms' && (
            <Text type="p">Please input the code that we just texted you.</Text>
          )}
        </div>
        <div
          style={{ padding: '0 0 24px' }}
          ref={(el) => {
            // @ts-expect-error TS(2339) FIXME: Property 'inputContainer' does not exist on type '... Remove this comment to see the full error message
            this.inputContainer = el
          }}
        >
          <div style={{ paddingBottom: '4px' }}>
            <Text type="label">Code</Text>
          </div>
          <Input
            // @ts-expect-error
            type="text"
            input={{
              // @ts-expect-error TS(2339) FIXME: Property 'code' does not exist on type 'Readonly<{... Remove this comment to see the full error message
              value: this.state.code,
              onChange: this.handleCodeChange,
            }}
            meta={{
              error,
              touched: Boolean(error),
              submitting: loading,
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button type="text" label="Back" onClick={() => transition('BACK')} />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label={loading ? 'Please wait…' : 'Next'}
            onClick={this.handleSubmit}
            disabled={loading}
          />
        </div>
      </>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Confirm.propTypes = {
  transition: PropTypes.func.isRequired,
  updateMethod: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  submitCode: PropTypes.func.isRequired,
}

export default Confirm
