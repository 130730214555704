import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Dialog } from '@buffer-mono/popcorn'

import { getFragmentData, graphql } from '~publish/gql'
import {
  IdeaCard,
  IdeaCard_Idea,
} from '~publish/pages/Create/components/IdeaCard'
import { IdeaErrorDialog } from './IdeaErrorDialog'

export const GetIdea = graphql(/* GraphQL */ `
  query GetIdea($input: IdeaIdInput!) {
    idea(input: $input) {
      ... on Idea {
        id
        ...IdeaCard_Idea
      }
      ... on CoreWebAppCommonError {
        message
      }
    }
  }
`)

export function IdeaDetailsDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const { ideaId } = useParams<{ ideaId: string; id: string }>()
  const history = useHistory()
  const { data, loading } = useQuery(GetIdea, {
    variables: {
      input: {
        id: ideaId,
      },
    },
  })

  const [isOpen, setIsOpen] = useState(false)

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      history.push(parent)
    },
    [parent, history],
  )

  useEffect(() => {
    if (data?.idea) {
      setIsOpen(true)
    }
  }, [data?.idea])

  if (loading) {
    return <></>
  }

  if (data?.idea?.__typename === 'CoreWebAppCommonError') {
    return <IdeaErrorDialog error={data?.idea} parent={parent} />
  }

  if (data?.idea?.__typename !== 'Idea' || !data?.idea) {
    history.push(parent)
    return null
  }

  const idea = getFragmentData(IdeaCard_Idea, data.idea)

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Content style={{ padding: 0 }} size="large">
        <IdeaCard idea={idea} />
      </Dialog.Content>
    </Dialog>
  )
}
