import React from 'react'

import { PostCardActions } from '../PostCardActions'
import { usePostData } from '../PostCardContext'

import styles from './PostCardFooter.module.css'
import { PostedFrom } from './PostedFrom'
import { PostCardAuthor } from './PostCardAuthor'

export const PostCardFooter = (): JSX.Element | null => {
  const { via } = usePostData()
  const isApiPost = via === 'api' || via === 'buffer'
  return (
    <footer className={styles.footer}>
      {!isApiPost && <PostedFrom />}
      {isApiPost && <PostCardAuthor />}
      <PostCardActions />
    </footer>
  )
}

PostCardFooter.displayName = 'PostCardFooter'
