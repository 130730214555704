import {
  getNodeEntries,
  type GetNodeEntriesOptions,
  getParentNode,
  insertNodes,
  isText,
  type TText,
  type TTextEntry,
} from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { editorDebug } from '~publish/legacy/editor/editorDebug'
import { Url } from '../../../helpers/Url'
import { LinkElement } from '../nodes/LinkElement'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

const debug = editorDebug.extend('plugins:link')

const isLinkTextChild = (
  editor: BufferEditor,
  [, path]: TTextEntry,
): boolean => {
  const parent = getParentNode(editor, path)

  return LinkElement.is(parent?.[0])
}

export const findAndWrapNewLinks = (
  editor: BufferEditor,
  options: Pick<GetNodeEntriesOptions, 'at'> = {},
) => {
  const textNodes = getNodeEntries<TText>(editor, {
    at: [],
    ...options,
    match: (n, p) =>
      isText(n) &&
      !!n.text &&
      Url.hasUrl(n.text) &&
      !isLinkTextChild(editor, [n, p]),
    mode: 'all',
    reverse: true,
  })

  Array.from(textNodes).forEach(([node, path]) => {
    Url.extractUrlsWithRange([node, path]).forEach(({ url, range }) => {
      const element = LinkElement.new({
        url,
        ensureProtocol: [Service.Threads, Service.Mastodon].includes(editor.id),
      })

      if (element) {
        debug('wrapping link: %s at %o', url, range)
        insertNodes<LinkElement>(editor, element, {
          at: range,
        })
      }
    })
  })
}
