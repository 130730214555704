import React, { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { DndContext, DragOverlay, type UniqueIdentifier } from '@dnd-kit/core'
import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  CloseIcon,
  EmptyState,
  Flex,
  SimpleSpinner,
  toast,
} from '@buffer-mono/popcorn'

import type {
  MoveIdeaInput,
  IdeaCard_IdeaFragment as Idea,
} from '~publish/gql/graphql'
import type { NewIdea } from '~publish/pages/Create/types'
import { usePlan } from '~publish/legacy/accountContext'
import { DROP_ANIMATION } from '~publish/helpers/dndkit/constants'

import { useDeleteIdea } from '../../hooks/useDeleteIdea'
import type { OpenEditorOptions } from '../../hooks/useIdeasEditor'
import { IdeaCard } from '../IdeaCard'

import { useDndBoard } from './hooks/useDndBoard'
import { useBoardData } from './hooks/useBoardData'
import { useMoveIdea } from './hooks/useMoveIdea'
import { useCreateIdeaGroup } from './hooks/useCreateIdeaGroup'
import { useMoveIdeaGroup } from './hooks/useMoveIdeaGroup'
import { restrictToRef } from '../../../../helpers/dndkit/restrictToRefModifier'
import { createAnnouncements } from './helpers/createAnnouncements'
import { Column } from './Column'
import SortableItem from './SortableItem'
import { SortableColumn } from './SortableColumn'
import { NewColumn } from './NewColumn'
import { UNASSIGNED_GROUP_ID, UNASSIGNED_GROUP_NAME } from './helpers/helpers'

import styles from './Board.module.css'
import { CreateUploadDropzone } from '../CreateUploadDropzone'

type BoardProps = {
  // TODO: make editor open based on URL params
  onCreateIdea: (newIdea?: NewIdea, opts?: OpenEditorOptions) => void
  onEditIdea: (idea: Idea, source?: string) => void
  onFileDrop?: (files: File[], groupId?: string) => void
}

const MAX_FREE_GROUPS = 3

export const Board = ({
  onCreateIdea,
  onEditIdea,
  onFileDrop,
}: BoardProps): JSX.Element => {
  const boardRef = useRef<HTMLDivElement>(null)
  const { data, loading, error } = useBoardData()
  const deleteIdea = useDeleteIdea()
  const moveIdea = useMoveIdea()
  const moveGroup = useMoveIdeaGroup()
  const createIdeaGroup = useCreateIdeaGroup()
  const plan = usePlan()

  const handleCreateIdea = useCallback(
    ({
      groupId,
      placeAfterId,
      source,
    }: {
      groupId?: string
      placeAfterId?: string
      source?: string
    }): void => {
      // TODO: update this to use the new editor
      onCreateIdea({ groupId, content: { text: '' } }, { placeAfterId, source })
    },
    [onCreateIdea],
  )

  const handleCreateColumn = async (name: string): Promise<void> => {
    try {
      await createIdeaGroup(name)
      toast.success(`Group ${name} created successfully`)
    } catch (error) {
      toast.error(`Failed to create group ${name}. Please try again later.`)
    }
  }

  async function handleItemMoved({
    groupId,
    ideaId,
    placeAfterId,
  }: MoveIdeaInput): Promise<void> {
    try {
      await moveIdea({
        groupId,
        ideaId,
        placeAfterId:
          placeAfterId === UNASSIGNED_GROUP_ID ? undefined : placeAfterId,
      })
    } catch (error: unknown) {
      const message = (error as Error).message ?? 'Please try again later'
      toast.error(`Failed to move idea. ${message}.`)
      throw error
    }
  }

  async function handleColumnMoved({
    id,
    placeAfterId,
  }: {
    id: string
    placeAfterId?: string
  }): Promise<void> {
    try {
      await moveGroup(
        id,
        placeAfterId === UNASSIGNED_GROUP_ID ? undefined : placeAfterId,
      )
    } catch (error) {
      toast.error('Failed to move group. Please try again later.')
      throw error
    }
  }

  const {
    activeId: activeDraggedId,
    items,
    columns,
    lockOverlayId,
    updateItems,
    contextProps,
  } = useDndBoard(
    data.itemsMap ?? {},
    handleItemMoved,
    handleColumnMoved,
    isGroupLocked,
  )

  useEffect(() => {
    updateItems(data.itemsMap)
  }, [data.itemsMap, updateItems])

  function isGroup(id: UniqueIdentifier): boolean {
    return data.groups.some((group) => group.id === id)
  }

  function isGroupLocked(id: UniqueIdentifier): boolean {
    return data.groups.find((group) => group.id === id)?.isLocked ?? false
  }

  function isIdea(id: UniqueIdentifier): boolean {
    return data.ideas.some((idea) => idea.id === id)
  }

  // TODO: add skeleton loading state
  if (loading) {
    return (
      <Flex align="center" justify="center">
        <SimpleSpinner size="medium" role="progressbar" />
      </Flex>
    )
  }

  if (error) {
    return (
      <EmptyState>
        <EmptyState.Icon variant="critical">
          <CloseIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Error while loading ideas</EmptyState.Heading>
        <EmptyState.Description>
          Try refreshing a page, let us know if error persists
        </EmptyState.Description>
      </EmptyState>
    )
  }

  const renderIdea = (id: UniqueIdentifier): JSX.Element | null => {
    const idea = data.ideas.find((idea) => idea.id === id)
    if (!idea) return null
    return (
      <IdeaCard
        idea={idea}
        variant="compact"
        onOpen={(idea): void => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error Idea and IdeaFragment are not compatible because of null
          onEditIdea(idea, 'create-board-ideaCard-card-1')
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error Idea and IdeaFragment are not compatible because of null
        onDelete={deleteIdea}
      />
    )
  }

  const columnsToRender = columns.filter((id) =>
    data.groups.some((g) => g.id === id),
  )
  const sortableColumns = columnsToRender.filter((id) => !isGroupLocked(id))
  const isGroupLimitReached =
    plan.id === 'free' && columnsToRender.length >= MAX_FREE_GROUPS

  return (
    <DndContext
      autoScroll={true}
      {...contextProps}
      // This is a custom modifier to restrict the drag to the board container only
      modifiers={[restrictToRef(boardRef)]}
      accessibility={{
        announcements: createAnnouncements(data.groups, data.ideas),
      }}
    >
      <div className={styles.wrapper} ref={boardRef}>
        {/* Unassigned column */}
        <SortableColumn id={UNASSIGNED_GROUP_ID} disabled={true}>
          <CreateUploadDropzone
            onDrop={(files: File[]): void =>
              onFileDrop?.(files, `${UNASSIGNED_GROUP_ID}`)
            }
          >
            <Column
              id={UNASSIGNED_GROUP_ID}
              name={UNASSIGNED_GROUP_NAME}
              editable={false}
              items={items[UNASSIGNED_GROUP_ID] ?? []}
              renderItem={renderIdea}
              onCreateIdea={handleCreateIdea}
            />
          </CreateUploadDropzone>
        </SortableColumn>
        {/* Sortable column */}
        {columnsToRender.length > 0 && (
          <SortableContext
            id="board"
            items={sortableColumns}
            strategy={horizontalListSortingStrategy}
          >
            {columnsToRender.map((id) => (
              <SortableColumn id={id} key={id} disabled={isGroupLocked(id)}>
                <CreateUploadDropzone
                  disabled={isGroupLocked(id)}
                  onDrop={(files: File[]): void => onFileDrop?.(files, `${id}`)}
                >
                  <Column
                    id={id}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    name={data.groups.find((g) => g.id === id)!.name}
                    items={items[id] ?? []}
                    renderItem={renderIdea}
                    onCreateIdea={handleCreateIdea}
                    locked={isGroupLocked(id)}
                    showLockOverlay={isGroupLocked(id) && lockOverlayId === id}
                  />
                </CreateUploadDropzone>
              </SortableColumn>
            ))}
          </SortableContext>
        )}

        {/* Create new column */}
        <NewColumn
          onCreate={handleCreateColumn}
          limitReached={isGroupLimitReached}
        />
      </div>

      {createPortal(
        <DragOverlay
          dropAnimation={DROP_ANIMATION}
          className={styles.dragOverlay}
        >
          {/* Render dragged column */}
          {activeDraggedId && isGroup(activeDraggedId) && (
            <SortableColumn id={activeDraggedId} dragOverlay={true}>
              <Column
                id={activeDraggedId}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                name={data.groups.find((g) => g.id === activeDraggedId)!.name}
                items={items[activeDraggedId]}
                renderItem={renderIdea}
              />
            </SortableColumn>
          )}

          {/* Render dragged idea */}
          {activeDraggedId && isIdea(activeDraggedId) && (
            <SortableItem id={activeDraggedId} dragOverlay={true}>
              {renderIdea(activeDraggedId)}
            </SortableItem>
          )}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}
