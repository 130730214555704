import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'

type SplitProtectedProps = {
  name: string
  children: React.ReactNode
}

/**
 * Use this component to restrict part of the UI behind the split feature flag.
 *
 * @example
 * ```tsx
 * <SplitProtected name="mySplit">
 *  <MyComponent />
 * </SplitProtected>
 * ````
 */
const SplitProtected = ({
  name,
  children,
}: SplitProtectedProps): JSX.Element | null => {
  const { isEnabled } = useSplitEnabled(name)

  if (isEnabled) {
    return <>{children}</>
  }

  return null
}

export { SplitProtected }
export type { SplitProtectedProps }
