import { useEffect, useState } from 'react'
import { usePostComposer } from '~publish/hooks/usePostComposer'

export function useOnPostComposerClose(callback: () => void): void {
  const { isOpen } = usePostComposer()
  const [previousIsOpen, setPreviousIsOpen] = useState(isOpen)
  useEffect(() => {
    setPreviousIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => {
      if (!isOpen && previousIsOpen !== isOpen) {
        callback()
      }
    }, 1000)
  }, [isOpen, previousIsOpen, callback])
}
