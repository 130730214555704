import React from 'react'
import { BarChartIcon, ClockIcon, IconButton, Text } from '@buffer-mono/popcorn'

import { usePostData, useTrackActionClicked } from '../PostCardContext'
import { PostMetric } from './PostMetric'

import styles from './PostCardMetrics.module.css'
import { formatDistanceToNowStrict } from 'date-fns'
import { SplitProtected } from '~publish/components/SplitProtected'

// TODO: indication if channel supports analyze shopud come from API
// See task in Jira: https://buffer.atlassian.net/browse/CT-718
const servicesSupportingAnalyze = [
  'twitter',
  'instagram',
  'facebook',
  'linkedin',
  'shopify',
]

export const PostCardMetrics = (): JSX.Element | null => {
  const { schedulingType, status, metrics, channel, metricsUpdatedAt } =
    usePostData()
  const trackPostAction = useTrackActionClicked()

  // Notifications don't have metrics
  // TODO: metrics array should be null for notifications
  // see: https://buffer.atlassian.net/browse/CT-389
  if (schedulingType === 'notification') {
    return null
  }

  if (status !== 'sent' || !metrics) {
    return null
  }

  const hasNoData = metrics.every(
    (metric) => typeof metric.nullableValue !== 'number',
  )

  const analyzeLink = servicesSupportingAnalyze.includes(channel.service)
    ? `https://analyze.buffer.com/${channel.service}/overview/${channel.id}`
    : null

  return (
    <section className={styles.metrics} aria-label="Analytics">
      {metrics.map((metric) => (
        <PostMetric
          key={metric.name}
          channel={channel.service}
          type={metric.type}
          name={metric.displayName}
          value={metric.nullableValue}
          unit={metric.unit}
        />
      ))}
      {analyzeLink && (
        <IconButton
          label="See More Analytics"
          tooltip="See more analytics in Analyze"
          size="medium"
          as="a"
          href={analyzeLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(): void => trackPostAction('seeMoreAnalytics-2')}
          className={styles.analyzeCta}
        >
          <BarChartIcon />
        </IconButton>
      )}
      <SplitProtected name="CT-show-metric-refresh">
        {metricsUpdatedAt ? (
          <div className={styles.lastUpdated}>
            <ClockIcon size="xsmall" color="subtle" />
            <Text size="xs" color="subtle">
              Refreshed{' '}
              {formatDistanceToNowStrict(metricsUpdatedAt, { addSuffix: true })}
            </Text>
          </div>
        ) : null}
        {!metricsUpdatedAt && hasNoData ? (
          <div className={styles.lastUpdated}>
            <ClockIcon size="xsmall" color="subtle" />
            <Text size="xs" color="subtle">
              Metrics not available yet
            </Text>
          </div>
        ) : null}
      </SplitProtected>
    </section>
  )
}

PostCardMetrics.displayName = 'PostCardMetrics'
