import React from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { useSplitEnabled } from '@buffer-mono/features'
import AppStore from '../stores/AppStore'
import ComposerStore from '../stores/ComposerStore'
import AppActionCreators from '../action-creators/AppActionCreators'
import { NotificationScopes } from '../AppConstants'
import Composer from './Composer'
import ProductRolloutTooltip from './ProductRolloutTooltip'
import NotificationContainer from './NotificationContainer'
import styles from './css/ComposerSection.module.css'
import {
  profilesPropType,
  visibleNotificationsPropType,
} from './ComposerPropTypes'

const getComposerState = () => ({
  enabledDrafts: ComposerStore.getEnabledDrafts(),
  draftsSharedData: ComposerStore.getDraftsSharedData(),
  omniDraft: ComposerStore.getDraft('omni'),
  forceEditorFocus: ComposerStore.getMeta().forceEditorFocus,
})

/* eslint-disable react/prop-types */
const ComposerComponent = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  draft,
  // @ts-expect-error TS(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
  index,
  // @ts-expect-error TS(7031) FIXME: Binding element 'state' implicitly has an 'any' ty... Remove this comment to see the full error message
  state,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
  profiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isOmniboxEnabled' implicitly has ... Remove this comment to see the full error message
  isOmniboxEnabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'visibleNotifications' implicitly ... Remove this comment to see the full error message
  visibleNotifications,
}) => {
  const canUserPostToMultipleNetworks =
    // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
    uniqBy(profiles, (p) => p.service.name).length > 1
  const showRolloutTooltip =
    AppStore.getOptions().canSelectProfiles &&
    canUserPostToMultipleNetworks &&
    (isOmniboxEnabled || index === ComposerStore.getEnabledDrafts().length - 1)

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const { isEnabled: isFloatingComposerBarEnabled } = useSplitEnabled(
    'floatingComposerBar',
  )

  const children = showRolloutTooltip ? (
    <ProductRolloutTooltip
      visibleNotifications={visibleNotifications}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      isOmniboxEnabled={isOmniboxEnabled}
    />
  ) : null

  // When focus should be forced, figure out *which* editor instance to force-focus
  const forceEditorInstanceFocus =
    state.forceEditorFocus &&
    (isOmniboxEnabled || AppStore.getAppState().expandedComposerId === draft.id)

  return (
    <Composer
      draft={draft}
      key={draft.id}
      forceEditorFocus={forceEditorInstanceFocus}
      expandedComposerId={AppStore.getExpandedComposerId()}
      isRemindersEnabled={isRemindersEnabled}
      isFloatingComposerBarEnabled={isFloatingComposerBarEnabled}
    >
      {children}
    </Composer>
  )
}

/* eslint-enable react/prop-types */

class ComposerSection extends React.Component {
  state = getComposerState()

  componentDidMount() {
    return ComposerStore.addChangeListener(this.onStoreChange)
  }

  componentWillUnmount() {
    return ComposerStore.removeChangeListener(this.onStoreChange)
  }

  onStoreChange = () => this.setState(getComposerState())

  onTwitterMaxProfileNotificationClose = () => {
    AppActionCreators.rememberTwitterMaxProfileNotificationClosedOnce()
  }

  render() {
    const { omniDraft } = this.state

    const {
      // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Readon... Remove this comment to see the full error message
      profiles,
      // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
      visibleNotifications,
      // @ts-expect-error TS(2339) FIXME: Property 'isOmniboxEnabled' does not exist on type... Remove this comment to see the full error message
      isOmniboxEnabled,
    } = this.props

    const hasEnabledDrafts =
      ComposerStore.getEnabledDrafts().length > 0 || isOmniboxEnabled
    const { composersHaveBeenExpanded } = AppStore.getAppState()

    const twitterMaxProfileNotificationClassNames = {
      container: styles.twitterMaxProfileNotificationContainer,
      notification: `${styles.twitterMaxProfileNotification} bi-notification`,
      notificationCloseButton: styles.twitterMaxProfileNotificationCloseButton,
    }

    return (
      <section
        className={[
          styles.composerSection,
          'post-box',
          styles.newComposerSection,
        ].join(' ')}
      >
        {!hasEnabledDrafts && AppStore.hasProfiles() && (
          <div className={styles.emptyState}>
            {composersHaveBeenExpanded
              ? 'Your work has been saved. Select a Channel to create a post.'
              : 'Select a Channel to create a post.'}
          </div>
        )}

        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.TWITTER_MAX_ONE_PROFILE_SELECTED}
          classNames={twitterMaxProfileNotificationClassNames}
          onClose={this.onTwitterMaxProfileNotificationClose}
          showCloseIcon
        />

        {isOmniboxEnabled && (
          // @ts-expect-error TS(2741) FIXME: Property 'index' is missing in type '{ state: { en... Remove this comment to see the full error message
          <ComposerComponent
            state={this.state}
            draft={omniDraft}
            profiles={profiles}
            isOmniboxEnabled={isOmniboxEnabled}
            visibleNotifications={visibleNotifications}
          />
        )}

        {!isOmniboxEnabled &&
          ComposerStore.getEnabledDrafts().map((draft, index) => (
            <ComposerComponent
              key={draft.id}
              state={this.state}
              draft={draft}
              index={index}
              profiles={profiles}
              isOmniboxEnabled={isOmniboxEnabled}
              visibleNotifications={visibleNotifications}
            />
          ))}
      </section>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ComposerSection.propTypes = {
  visibleNotifications: visibleNotificationsPropType.isRequired,
  profiles: profilesPropType,
  isOmniboxEnabled: PropTypes.bool,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ComposerSection.defaultProps = {
  profiles: [],
  isOmniboxEnabled: null,
}

export default ComposerSection
