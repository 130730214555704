import { useSplitEnabled } from '@buffer-mono/features'
import { useLocalStorageState } from '~publish/hooks/useLocalStorageState'

export const NEW_PUBLISH_KEY = 'preference:single-channel-new-ui-v2'
export const NEW_PUBLISH_SPLIT_NAME = 'publish-revamp'

type PublishRevamp = [
  boolean,
  {
    setPreference: (value: 'enabled' | 'disabled') => void
    isReady: boolean
    isSplitEnabled: boolean
  },
]

export function usePublishRevamp(): PublishRevamp {
  const [isNewPublishEnabled, setIsNewPublishEnabled] = useLocalStorageState(
    NEW_PUBLISH_KEY,
    'enabled',
  )

  const { isEnabled: isPublishRevampEnabled, isReady: isPublishRevampReady } =
    useSplitEnabled(NEW_PUBLISH_SPLIT_NAME)

  const isEnabled =
    isPublishRevampEnabled &&
    (isNewPublishEnabled === 'enabled' || isNewPublishEnabled === null)

  const setPreference = (value: 'enabled' | 'disabled'): void => {
    setIsNewPublishEnabled(value)
  }

  return [
    isEnabled,
    {
      setPreference,
      isReady: isPublishRevampReady,
      isSplitEnabled: isPublishRevampEnabled,
    },
  ]
}
