import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'

import { CloseIcon } from '~publish/pages/Create/components/IdeaEditor/styles'
import { getHumanReadableSize } from '~publish/legacy/composer/composer/utils/StringUtils'
import { Upload } from '../../entities/Upload'
import {
  StyledOverlay,
  ModalWrapper,
  InnerWrapper,
  MediaContainer,
  FileSize,
  Image,
  Video,
} from './styles'
import ImageDescription from './ImageDescription'
import { white } from '@bufferapp/ui/style/colors'
import { DocumentPreview } from '~publish/legacy/composer/composer/components/document-attachment/DocumentPreview'

export const MediaPreviewer = ({
  upload,
  containerRef,
  onClose,
}: {
  upload: Upload | null
  containerRef: HTMLElement | null | undefined
  onClose: () => void
}): JSX.Element => {
  const isOpen = !!upload

  const setIsOpen = (open: boolean): void => {
    if (!open) {
      onClose()
    }
  }

  if (!upload) return <></>

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal container={containerRef}>
        <StyledOverlay />
        <ModalWrapper>
          <InnerWrapper>
            {(Upload.isImage(upload) || Upload.isGif(upload)) && (
              <>
                <MediaContainer>
                  <Image alt={upload.alt} src={upload.url} />
                </MediaContainer>
                <ImageDescription upload={upload} onClose={onClose} />
              </>
            )}
            {Upload.isVideo(upload) && (
              <MediaContainer>
                <Video poster={upload.thumbnailUrl} controls src={upload.url}>
                  <track kind="captions" />
                </Video>
                <FileSize>{getHumanReadableSize(upload.size)}</FileSize>
              </MediaContainer>
            )}
            {Upload.isDocumentUpload(upload) && (
              <MediaContainer>
                <DocumentPreview
                  title={upload.alt || 'Document'}
                  url={upload.url}
                  height={520}
                />
                <FileSize>{getHumanReadableSize(upload.size)}</FileSize>
              </MediaContainer>
            )}
            <Dialog.Close asChild>
              <CloseIcon size="medium" color={white} />
            </Dialog.Close>
          </InnerWrapper>
        </ModalWrapper>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
