import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import Button from '@bufferapp/ui/Button'
import { Flash } from '@bufferapp/ui/Icon'
import { blue } from '@bufferapp/ui/style/colors'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  useAccount,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

const CustomMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    color: ${blue};
    padding-right: 0px;
  }
`

export const CustomMessage = ({
  isAdmin,
  isAtTagsLimit,
  tagsLimit,
  source,
}: {
  isAdmin: boolean
  isAtTagsLimit: boolean
  tagsLimit: number
  source: string
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization
  const billing = useOrganizationBilling()
  const commonTrackingProps =
    currentOrganization?.commonTrackingProperties || undefined
  const canStartTrial = billing?.canStartTrial
  const upgradePathName = canStartTrial
    ? 'tagsLimit-trial'
    : 'tagsLimit-upgrade'

  const cta = canStartTrial
    ? `publish-tagsSelector-${source}-proTrial-1`
    : `publish-tagsSelector-${source}-proUpgrade-1`

  useEffect(() => {
    if (isAtTagsLimit) {
      BufferTracker.cTAViewed({
        organizationId: currentOrganization?.id || null,
        cta,
        upgradePathName,
        product: 'publish',
        ...commonTrackingProps,
      })
    }
  }, [isAtTagsLimit])

  const TrackCTAClicked = (): void => {
    BufferTracker.cTAClicked({
      organizationId: currentOrganization?.id || undefined,
      clientName: 'publishWeb',
      cta,
      upgradePathName,
      product: 'publish',
      ...commonTrackingProps,
    })
    dispatch(modalActions.showTagsLimitUpgradeModal())
  }

  if (!isAdmin) return <></>

  return (
    <CustomMessageWrapper>
      {t('campaigns.limits.defaultMessage', { tagsLimit })}
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
      <Button
        type="text"
        icon={<Flash color="blue" />}
        label={t('campaigns.limits.ctaMessage')}
        onClick={TrackCTAClicked}
        disabled={false}
        size="small"
      />
    </CustomMessageWrapper>
  )
}
