import { Flex } from '@buffer-mono/popcorn'
import React, { useEffect } from 'react'
import type { Time } from '~publish/legacy/posting-schedule/types'
import { selectCurrentProfilePostingDaysIsEmpty } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { ScheduleTableCell } from '../ScheduleTableCell/ScheduleTableCell'
import { ScheduleTableHeader } from '../ScheduleTableHeader'
import styles from './ScheduleTableColumn.module.css'

type ScheduleTableColumnProps = {
  dayName: string
  paused: boolean
  times: Time[]
}

const ScheduleTableColumn = ({
  dayName,
  paused,
  times,
}: ScheduleTableColumnProps): JSX.Element => {
  const emptySchedule = useAppSelector(selectCurrentProfilePostingDaysIsEmpty)
  const hasTimes = times?.length > 0
  const [isPaused, setIsPaused] = React.useState(paused)
  const disabledColumn = (isPaused || !hasTimes) && !emptySchedule

  // ensure paused state updates
  useEffect(() => {
    setIsPaused(paused)
  }, [paused])

  return (
    <Flex
      className={`${styles.column} ${
        disabledColumn ? styles.disabledColumn : ''
      }`}
      direction="column"
      align="center"
    >
      <ScheduleTableHeader
        dayName={dayName}
        displayOn={!isPaused}
        onTogglePause={(): void => setIsPaused(!isPaused)}
      />
      {hasTimes && (
        <Flex align="center" direction="column" className={styles.timesWrapper}>
          {times.map((time, index) => (
            <ScheduleTableCell
              key={`cell-${dayName}-${time.value.hours}-${time.value.minutes}-${index}`}
              time={time}
              dayName={dayName}
              timeIndex={index}
              paused={isPaused}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export { ScheduleTableColumn }
