import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import {
  BufferLoading,
  EmptyState,
  QueueItems,
} from '~publish/legacy/shared-components'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'

import { useAppSelector } from '~publish/legacy/store'
import {
  selectProfiles,
  selectShouldShowLinkedinProfileAnalyticsUpgradeBanner,
} from '~publish/legacy/profile-sidebar/selectors'
import {
  QUEUE_PAGE,
  SEGMENT_NAMES,
  SERVICE_LINKEDIN,
} from '~publish/legacy/constants'
import getQueryParamsFromURL from '~publish/legacy/queue/util/getQueryParams'
import { setLastVisitedPage } from '~publish/legacy/utils/page-visits'

import { CleanUpPrompt } from '../CleanUpPrompt'
import QueuePausedBar from '../QueuePausedBar'

import { BannerTypes } from '~publish/legacy/profile-page/hooks/useDismissBanner'
import {
  SetupInstagramRemindersBanner,
  TwitterThreadsPromoBanner,
  useBannersInQueue,
} from '../Banners'
import QueueLimitBar from '../QueueLimitBar'
import { MetaThreadsPromoBanner } from '~publish/legacy/queue/components/Banners/MetaThreadsPromoBanner'
import { LinkedinProfileRefreshBanner } from '~publish/legacy/queue/components/Banners/LinkedinProfileRefreshBanner'
import { BlueskyPromoBanner } from '~publish/legacy/queue/components/Banners/BlueskyPromoBanner'
import { TwitterPremiumBanner } from '~publish/legacy/queue/components/Banners/TwitterPremiumBanner'
import { TiktokImagesBanner } from '~publish/legacy/queue/components/Banners/TiktokImagesBanner'

const ErrorBoundary = getErrorBoundary(true)

const loadingContainerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  textAlign: 'center',
  paddingTop: '5rem',
}

const QueuedPosts = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'showEmptyQueueMessage' implicitly... Remove this comment to see the full error message
  showEmptyQueueMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'items' implicitly has an 'any' ty... Remove this comment to see the full error message
  items,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequeueClick' implicitly has an... Remove this comment to see the full error message
  onRequeueClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteConfirmClick' implicitly ... Remove this comment to see the full error message
  onDeleteConfirmClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditClick' implicitly has an 'a... Remove this comment to see the full error message
  onEditClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmptySlotClick' implicitly has ... Remove this comment to see the full error message
  onEmptySlotClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onShareNowClick' implicitly has a... Remove this comment to see the full error message
  onShareNowClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDropPost' implicitly has an 'an... Remove this comment to see the full error message
  onDropPost,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSwapPosts' implicitly has an 'a... Remove this comment to see the full error message
  onSwapPosts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'paused' implicitly has an 'any' t... Remove this comment to see the full error message
  paused,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draggingEnabled' implicitly has a... Remove this comment to see the full error message
  draggingEnabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUnpauseClick' implicitly has an... Remove this comment to see the full error message
  onUnpauseClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subprofiles' implicitly has an 'a... Remove this comment to see the full error message
  subprofiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isManager' implicitly has an 'any... Remove this comment to see the full error message
  isManager,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasFirstCommentFlip' implicitly h... Remove this comment to see the full error message
  hasFirstCommentFlip,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasPushNotifications' implicitly ... Remove this comment to see the full error message
  hasPushNotifications,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSetRemindersClick' implicitly h... Remove this comment to see the full error message
  onSetRemindersClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCampaignTagClick' implicitly ha... Remove this comment to see the full error message
  onCampaignTagClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCampaignsFeature' implicitly h... Remove this comment to see the full error message
  hasCampaignsFeature,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldRenderCalendarButtons' impl... Remove this comment to see the full error message
  shouldRenderCalendarButtons,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldDisplaySingleSlots' implici... Remove this comment to see the full error message
  shouldDisplaySingleSlots,
  // @ts-expect-error TS(7031) FIXME: Binding element 'shouldDisplayTimezone' implicitly... Remove this comment to see the full error message
  shouldDisplayTimezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onAutoOpenComposer' implicitly ha... Remove this comment to see the full error message
  onAutoOpenComposer,
  // @ts-expect-error TS(7031) FIXME: Binding element 'composerType' implicitly has an '... Remove this comment to see the full error message
  composerType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isProfileDisconnected' implicitly... Remove this comment to see the full error message
  isProfileDisconnected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileData' implicitly has an 'a... Remove this comment to see the full error message
  profileData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCreateThreadClick' implicitly h... Remove this comment to see the full error message
  onCreateThreadClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly h... Remove this comment to see the full error message
  profileId,
}) => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const bannersToDisplay = [
    BannerTypes.remindersSetup,
    BannerTypes.twitterThreadsPromo,
    BannerTypes.threadsByMetaPromo,
    BannerTypes.blueskyPromo,
    BannerTypes.twitterPremium,
    BannerTypes.tiktokImagesPromo,
  ]
  const { showBanners } = useBannersInQueue(
    bannersToDisplay,
    isProfileDisconnected,
    profileData,
  )

  /**
   * Watch out for below, it has to have the same order as bannersToDisplay
   */
  const [
    showRemindersBanner,
    showTwitterThreadsPromoBanner,
    showThreadsByMetaPromoBanner,
    showBlueskyPromoBanner,
    showTwitterPremiumBanner,
    showTiktokImagesBanner,
  ] = showBanners

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const queryData = getQueryParamsFromURL(search)

  const shouldAutoOpenComposer = queryData?.shouldAutoOpenComposer

  const hasThreadsProfile = useAppSelector(selectProfiles).some(
    (profile) => profile.service === 'threads',
  )
  const hasBlueskyProfile = useAppSelector(selectProfiles).some(
    (profile) => profile.service === 'bluesky',
  )
  const hasTiktokProfile = useAppSelector(selectProfiles).some(
    (profile) => profile.service === 'tiktok',
  )
  const shouldShowLinkedinProfileAnalyticsUpgradeBanner = useAppSelector(
    selectShouldShowLinkedinProfileAnalyticsUpgradeBanner(profileId),
  )

  useEffect(() => {
    setLastVisitedPage(QUEUE_PAGE)
    if (shouldAutoOpenComposer) {
      const selectedProfileIds = queryData?.selectedProfileIds || null
      onAutoOpenComposer({
        selectedProfileIds,
      })
    }
  }, [])

  useEffect(() => {
    dispatch(
      dataFetchActions.fetch({
        name: 'queuedPosts',
        args: {
          profileId,
          isFetchingMore: false,
          count: 300,
        },
      }),
    )
  }, [profileId])

  if (loading) {
    return (
      <div style={loadingContainerStyle}>
        <BufferLoading size={64} />
      </div>
    )
  }

  const shouldShowTiktokImagesBanner = showTiktokImagesBanner // && !hasTiktokProfile
  const shouldShowLinkedinProfileNotice =
    profileData?.service === SERVICE_LINKEDIN &&
    profileData?.service_type === 'profile' &&
    shouldShowLinkedinProfileAnalyticsUpgradeBanner
  const shouldShowBlueskyPromoBanner =
    showBlueskyPromoBanner &&
    !hasBlueskyProfile &&
    !shouldShowLinkedinProfileNotice &&
    !showTwitterPremiumBanner
  const shouldShowThreadsByMetaPromoBanner =
    showThreadsByMetaPromoBanner &&
    !hasThreadsProfile &&
    !shouldShowBlueskyPromoBanner &&
    !showTwitterPremiumBanner &&
    !shouldShowTiktokImagesBanner
  const shouldDisplayRemindersBanner =
    showRemindersBanner &&
    !shouldShowThreadsByMetaPromoBanner &&
    !shouldShowTiktokImagesBanner
  const shouldShowTwitterThreadsPromoBanner =
    showTwitterThreadsPromoBanner &&
    !showThreadsByMetaPromoBanner &&
    !shouldShowBlueskyPromoBanner &&
    !showTwitterPremiumBanner

  const openRemindersWizard = (): void => {
    dispatch(
      modalActions.showRemindersWizardModal({ placement: 'queuedPosts' }),
    )
  }

  return (
    <ErrorBoundary>
      {isProfileDisconnected && <ProfilesDisconnectedBanner />}
      <CleanUpPrompt />
      {shouldShowTiktokImagesBanner && <TiktokImagesBanner />}
      {shouldShowLinkedinProfileNotice && <LinkedinProfileRefreshBanner />}
      {shouldShowTwitterThreadsPromoBanner && (
        <TwitterThreadsPromoBanner onCreateThreadClick={onCreateThreadClick} />
      )}
      {shouldDisplayRemindersBanner && <SetupInstagramRemindersBanner />}
      {shouldShowThreadsByMetaPromoBanner && (
        <MetaThreadsPromoBanner service={profileData.service} />
      )}
      {showTwitterPremiumBanner && <TwitterPremiumBanner />}
      {shouldShowBlueskyPromoBanner && (
        <BlueskyPromoBanner service={profileData.service} />
      )}
      {!!paused && (
        <QueuePausedBar
          isManager={isManager}
          handleClickUnpause={onUnpauseClick}
        />
      )}
      <QueueLimitBar cta={SEGMENT_NAMES.QUEUE_LIMIT_BANNER_UPGRADE} />
      {showEmptyQueueMessage && (
        <EmptyState
          title="It looks like you haven't got any posts in your queue!"
          subtitle="Click the box above to add a post to your queue :)"
          heroImg="https://s3.amazonaws.com/buffer-publish/images/fresh-queue%402x.png"
          heroImgSize={{ width: '229px', height: '196px' }}
        />
      )}
      <QueueItems
        items={items}
        subprofiles={subprofiles}
        onRequeueClick={onRequeueClick}
        onDeleteConfirmClick={onDeleteConfirmClick}
        onEditClick={onEditClick}
        onEmptySlotClick={onEmptySlotClick}
        onShareNowClick={onShareNowClick}
        onDropPost={onDropPost}
        onSwapPosts={onSwapPosts}
        draggable={draggingEnabled}
        hasFirstCommentFlip={hasFirstCommentFlip}
        hasPushNotifications={hasPushNotifications}
        onSetRemindersClick={openRemindersWizard}
        onCampaignTagClick={onCampaignTagClick}
        hasCampaignsFeature={hasCampaignsFeature}
        shouldRenderCalendarButtons={shouldRenderCalendarButtons}
        shouldDisplayTimezone={shouldDisplayTimezone}
        pinned={!shouldDisplaySingleSlots}
      />
    </ErrorBoundary>
  )
}

QueuedPosts.propTypes = {
  loading: PropTypes.bool,
  moreToLoad: PropTypes.bool,
  page: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  subprofiles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  showEmptyQueueMessage: PropTypes.bool,
  onRequeueClick: PropTypes.func.isRequired,
  onDeleteConfirmClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onEmptySlotClick: PropTypes.func.isRequired,
  onShareNowClick: PropTypes.func.isRequired,
  onCampaignTagClick: PropTypes.func,
  onDropPost: PropTypes.func.isRequired,
  onSwapPosts: PropTypes.func.isRequired,
  onSetRemindersClick: PropTypes.func.isRequired,
  paused: PropTypes.bool,
  draggingEnabled: PropTypes.bool,
  onUnpauseClick: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
  hasPushNotifications: PropTypes.bool,
  hasFirstCommentFlip: PropTypes.bool,
  hasCampaignsFeature: PropTypes.bool,
  shouldDisplaySingleSlots: PropTypes.bool,
  shouldRenderCalendarButtons: PropTypes.bool,
  shouldDisplayTimezone: PropTypes.bool,
  onAutoOpenComposer: PropTypes.func.isRequired,
  composerType: PropTypes.string,
  isProfileDisconnected: PropTypes.bool,
  profileData: PropTypes.object,
  onCreateThreadClick: PropTypes.func,
  profileId: PropTypes.string,
}

QueuedPosts.defaultProps = {
  shouldDisplayTimezone: false,
  profileTimezone: '',
  loading: true,
  moreToLoad: false,
  page: 1,
  showComposer: false,
  showEmptyQueueMessage: false,
  paused: false,
  subprofiles: [],
  hasPushNotifications: true,
  hasFirstCommentFlip: false,
  draggingEnabled: false,
  isManager: false,
  hasCampaignsFeature: false,
  shouldRenderCalendarButtons: false,
  shouldDisplaySingleSlots: false,
  onCampaignTagClick: (): void => undefined,
  composerType: 'queue',
  isProfileDisconnected: false,
  profileData: {},
  onCreateThreadClick: (): void => undefined,
  profileId: '',
}

export default QueuedPosts
