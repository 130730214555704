import type { Channel } from '~publish/gql/graphql'

export const CHANNEL_SUGGESTION_THRESHOLD = 4

export type ChannelSuggestion = Pick<Channel, 'name' | 'service'>

export const CHANNEL_SUGGESTIONS: ChannelSuggestion[] = [
  {
    name: 'Connect Facebook',
    service: 'facebook',
  },
  {
    name: 'Connect Instagram',
    service: 'instagram',
  },
  {
    name: 'Connect Twitter/X',
    service: 'twitter',
  },
  {
    name: 'Connect LinkedIn',
    service: 'linkedin',
  },
  {
    name: 'Connect Threads',
    service: 'threads',
  },
  {
    name: 'Connect TikTok',
    service: 'tiktok',
  },
  {
    name: 'Connect Pinterest',
    service: 'pinterest',
  },
  {
    name: 'Connect Google Business',
    service: 'googlebusiness',
  },
  {
    name: 'Connect Bluesky',
    service: 'bluesky',
  },
  {
    name: 'Connect Mastodon',
    service: 'mastodon',
  },
  {
    name: 'Connect YouTube',
    service: 'youtube',
  },
  {
    name: 'Create Start Page',
    service: 'startPage',
  },
]

export const FALLBACK_AVATAR =
  'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'
export const NEW_CHANNELS: string[] = ['bluesky']
