import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { IdeaComposer } from '../IdeaComposer'

export function IdeaCreationDialog({
  parent,
}: {
  parent: string
}): JSX.Element {
  const history = useHistory()
  const [source] = useQueryParam('source')
  const [text = undefined] = useQueryParam<string>('text')

  const [isOpen, setIsOpen] = useState(true)

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      history.push(parent)
    },
    [parent, history],
  )

  return (
    <IdeaComposer
      cta={source as CTA}
      open={isOpen}
      onOpenChange={handleOpenChange}
      idea={{ content: { text } }}
    />
  )
}
