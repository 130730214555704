import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { CLIENT_NAMES } from '~publish/legacy/constants'

export const trackButtonClick = (
  buttonName: string,
  organizationId: string | null,
  placement?: string,
): void => {
  BufferTracker.remindersSetupButtonClicked({
    clientName: CLIENT_NAMES.web,
    organizationId,
    buttonName,
    placement,
  })
}

export const trackStepViewed = (
  stepNumber: number,
  organizationId: string | null,
  placement?: string,
): void => {
  BufferTracker.remindersSetupStepViewed({
    clientName: CLIENT_NAMES.web,
    organizationId,
    stepNumber,
    placement,
  })
}
