import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, Button, Tooltip } from '@bufferapp/ui'
import Select from '@bufferapp/ui/Select'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import TrashIcon from '@bufferapp/ui/Icon/Icons/Trash'
import PencilIcon from '@bufferapp/ui/Icon/Icons/Pencil'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import * as styles from './styles'

class HashtagGroupItem extends Component {
  state = {
    hover: false,
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'snippetId' does not exist on type 'Reado... Remove this comment to see the full error message
      snippetId,
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      name,
      // @ts-expect-error TS(2339) FIXME: Property 'numberOfHashtags' does not exist on type... Remove this comment to see the full error message
      numberOfHashtags,
      // @ts-expect-error TS(2339) FIXME: Property 'hashtags' does not exist on type 'Readon... Remove this comment to see the full error message
      hashtags,
      // @ts-expect-error TS(2339) FIXME: Property 'onInsertHashtagGroupClick' does not exis... Remove this comment to see the full error message
      onInsertHashtagGroupClick,
      // @ts-expect-error TS(2339) FIXME: Property 'onEditHashtagGroupClick' does not exist ... Remove this comment to see the full error message
      onEditHashtagGroupClick,
      // @ts-expect-error TS(2339) FIXME: Property 'onDeleteHashtagGroupClick' does not exis... Remove this comment to see the full error message
      onDeleteHashtagGroupClick,
    } = this.props
    const { hover } = this.state

    return (
      <>
        <styles.Card
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          hover={hover}
        >
          <styles.TooltipWrapper>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; label: any; position:... Remove this comment to see the full error message */}
            <Tooltip label={hashtags} position="left">
              <styles.Header>
                <styles.NameWrapper>
                  <Text type="label">{name}</Text>
                </styles.NameWrapper>
                <Text color="gray" type="label" hover={hover}>
                  {numberOfHashtags}
                </Text>
              </styles.Header>
              {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
              <styles.HashtagText hover={hover}>{hashtags} </styles.HashtagText>
            </Tooltip>
          </styles.TooltipWrapper>
          {hover && (
            <styles.ButtonWrapper>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: string;... Remove this comment to see the full error message */}
              <Button
                type="primary"
                size="small"
                label="Insert"
                onClick={onInsertHashtagGroupClick}
              />
              <Select
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                onSelectClick={(selectedItem) => selectedItem.onItemClick()}
                label="Click me"
                icon={<MoreIcon />}
                type="primary"
                items={[
                  {
                    id: 'editHashtagGroup',
                    title: 'Edit Group',
                    icon: <PencilIcon color="gray" />,
                    onItemClick: () =>
                      onEditHashtagGroupClick(name, hashtags, snippetId),
                  },
                  {
                    id: 'deleteHashtagGroup',
                    title: 'Delete Group',
                    icon: <TrashIcon color="gray" />,
                    onItemClick: () =>
                      onDeleteHashtagGroupClick(name, hashtags, snippetId),
                  },
                ]}
                hasIconOnly
                xPosition="right"
                yPosition="bottom"
                hideSearch
              />
            </styles.ButtonWrapper>
          )}
        </styles.Card>
        <Divider marginTop="0" marginBottom="0" />
      </>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
HashtagGroupItem.propTypes = {
  onInsertHashtagGroupClick: PropTypes.func.isRequired,
  onEditHashtagGroupClick: PropTypes.func.isRequired,
  onDeleteHashtagGroupClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  numberOfHashtags: PropTypes.number.isRequired,
  hashtags: PropTypes.string.isRequired,
  snippetId: PropTypes.string.isRequired,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
HashtagGroupItem.defaultProps = {}

export default HashtagGroupItem
