import React from 'react'
import { Link } from 'react-router-dom'
import {
  ChannelAvatar,
  type ChannelAvatarProps,
  ClockIcon,
  PinIcon,
  Text,
  Tooltip,
  YtShortIcon,
  IgStoryIcon,
  IgReelIcon,
  Badge,
  GbpEventIcon,
  GbpOfferIcon,
  GbpWhatsNewIcon,
  type IconProps,
} from '@buffer-mono/popcorn'

import { channel as channelPage } from '~publish/legacy/routes'
import { mapPostStatusToTab } from '~publish/helpers/post'
import type { PostType } from '~publish/gql/graphql'

import { usePostData } from '../PostCardContext'
import { TextSeparator } from './TextSeparator'
import { ChannelName } from './ChannelName'
import { PostDueDate } from './PostDueDate'

import styles from './PostCardHeader.module.css'

const postTypeMap: Partial<
  Record<PostType, { icon: React.ComponentType<IconProps>; label: string }>
> = {
  story: { icon: IgStoryIcon, label: 'Story' },
  reel: { icon: IgReelIcon, label: 'Reel' },
  short: { icon: YtShortIcon, label: 'Short' },
  event: { icon: GbpEventIcon, label: 'Event' },
  offer: { icon: GbpOfferIcon, label: 'Offer' },
  whats_new: { icon: GbpWhatsNewIcon, label: "What's New" },
}

const PostTypeDisplay = (): JSX.Element => {
  const { metadata } = usePostData()
  const type = metadata?.type

  if (type && postTypeMap[type] !== undefined) {
    const { icon: Icon, label } = postTypeMap[type]

    return (
      <Badge>
        <Icon color="subtle" />
        {label}
      </Badge>
    )
  }

  return <></>
}

export const PostCardHeader = (): JSX.Element | null => {
  const { channel, status, isCustomScheduled, isPinned, dueAt } = usePostData()
  const channelLink = channelPage.getRoute(channel.id, {
    tab: mapPostStatusToTab(status),
  })

  return (
    <header className={styles.wrapper}>
      <Link to={channelLink} className={styles.avatar}>
        <ChannelAvatar
          size="medium"
          alt={`${channel.name}, ${channel.service}`}
          src={channel.avatar ?? undefined}
          channel={channel.service as ChannelAvatarProps['channel']}
        />
      </Link>
      <ChannelName />

      <div className={styles.info}>
        <PostDueDate
          date={dueAt}
          status={status}
          channelTimeZone={channel.timezone}
        />
        {isCustomScheduled && (
          <>
            <TextSeparator />
            <Tooltip content="Post scheduled for a custom time outside of fixed posting times of the channel queue">
              <Text className={styles.addon}>
                <ClockIcon size="xsmall" />
                Custom
              </Text>
            </Tooltip>
          </>
        )}
        {isPinned && (
          <>
            <TextSeparator />
            <Text className={styles.addon}>
              <PinIcon size="xsmall" />
              Pinned
            </Text>
          </>
        )}
        <PostTypeDisplay />
      </div>
    </header>
  )
}

PostCardHeader.displayName = 'PostCardHeader'
