import PropTypes from 'prop-types'

import { googleBusinessDataPropType } from './google-business/GoogleBusinessPropTypes'
import { startPageDataPropType } from './start-page/StartPagePropTypes'

export const instagramDataPropType = PropTypes.shape({
  shareToFeed: PropTypes.bool,
  link: PropTypes.string,
})

export const userDataPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  s3UploadSignature: PropTypes.shape({
    algorithm: PropTypes.string.isRequired,
    base64Policy: PropTypes.string.isRequired,
    bucket: PropTypes.string.isRequired,
    credentials: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    expires: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    successActionStatus: PropTypes.string.isRequired,
  }).isRequired,
  uses24hTime: PropTypes.bool.isRequired,
  weekStartsMonday: PropTypes.bool.isRequired,
  shouldAlwaysSkipEmptyTextAlert: PropTypes.bool.isRequired,
  profileGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profileIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
  ).isRequired,
  profilesSchedulesSlots: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          isSlotFree: PropTypes.bool.isRequired,
          timestamp: PropTypes.number.isRequired,
        }),
      ),
    ),
  ),
})

export const appStatePropType = PropTypes.shape({
  draftSaveQueueingType: PropTypes.string,
  isOmniboxEnabled: PropTypes.bool,
  composersWhichHaveBeenCollapsed: PropTypes.instanceOf(Set),
  whatPreventsSaving: PropTypes.arrayOf(
    PropTypes.shape({
      composerId: PropTypes.string,
      code: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  whatPreventsSavingDraft: PropTypes.arrayOf(
    PropTypes.shape({
      composerId: PropTypes.string,
      code: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
})

export const profilesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    instagramDirectEnabled: PropTypes.bool,
    canAssociateLocation: PropTypes.bool,
    canPostComment: PropTypes.bool,
    disabledMessage: PropTypes.string,
    hasPushNotifications: PropTypes.bool,
    images: PropTypes.shape({
      avatar: PropTypes.string,
    }),
    isContributor: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isManager: PropTypes.bool,
    isPinterestBusiness: PropTypes.bool,
    isTwitterPremium: PropTypes.bool,
    isSelected: PropTypes.bool,
    profileHasPostingSchedule: PropTypes.bool,
    selectedSubprofileId: PropTypes.string,
    service: PropTypes.shape({
      canHaveMediaAttachmentType: PropTypes.func,
      canHaveSomeAttachmentType: PropTypes.func,
      shouldShowDuplicateContentWarning: PropTypes.bool,
      isOmni: PropTypes.bool,
      name: PropTypes.string,
      charLimit: PropTypes.number,
      usesImageFirstLayout: PropTypes.bool,
      commentCharLimit: PropTypes.number,
      maxAttachableImagesCount: PropTypes.func,
    }),
  }),
)

export const channelDataPropType = PropTypes.shape({
  googlebusiness: googleBusinessDataPropType,
  instagram: instagramDataPropType,
  startPage: startPageDataPropType,
})

export const metaDataPropType = PropTypes.shape({
  environment: PropTypes.string.isRequired,
  appEnvironment: PropTypes.string.isRequired,
  shouldUseNewTwitterAutocomplete: PropTypes.bool.isRequired,
  enableTwitterChanges: PropTypes.bool.isRequired,
  disableTelemetry: PropTypes.bool.isRequired,
  updateId: PropTypes.string,
  scheduledAt: PropTypes.number,
  isPinnedToSlot: PropTypes.bool,
  isPrefillingExistingUpdate: PropTypes.bool,
  existingUpdateProfileService: PropTypes.string,
  didUserSetScheduledAt: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  sourceUrl: PropTypes.string,
  via: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    durationMs: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    originalUrl: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    thumbOffset: PropTypes.string.isRequired,
    availableThumbnails: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  browser: PropTypes.string,
  extensionVersion: PropTypes.string,
  retweetData: PropTypes.shape({
    text: PropTypes.string.isRequired,
    tweetId: PropTypes.string.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    userName: PropTypes.string.isRequired,
    userDisplayName: PropTypes.string.isRequired,
    tweetUrl: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }),
  facebookMentionEntities: PropTypes.arrayOf(
    PropTypes.shape({
      indices: PropTypes.arrayOf(PropTypes.number).isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  userTags: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  ),
  campaignDetails: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  composerSidebarVisible: PropTypes.bool,
  composerLeftSidebarVisible: PropTypes.bool,
  tabId: PropTypes.string,
  emptySlotMode: PropTypes.bool,
  editMode: PropTypes.bool,
  channelData: channelDataPropType,
  updateType: PropTypes.string,
})

export const visibleNotificationsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    message: PropTypes.string,
    scope: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
)

export const imagePropType = PropTypes.shape({
  url: PropTypes.string,
})

export const draftPropType = PropTypes.shape({
  id: PropTypes.string,
  updateType: PropTypes.oneOf([
    'whats_new',
    'offer',
    'event',
    'post',
    'reels',
    'thread',
    'story',
    'short',
    'video',
    'facebook_reel',
    'carousel',
  ]),
  instagramFeedback: PropTypes.array,
  isEnabled: PropTypes.bool,
  isSaved: PropTypes.bool,
  hasSavingError: PropTypes.bool,
  link: PropTypes.shape({
    availableThumbnails: PropTypes.array,
    thumbnail: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  sourceLink: PropTypes.shape({
    availableImages: PropTypes.arrayOf(imagePropType),
    url: PropTypes.string,
  }),
  enabledAttachmentType: PropTypes.string,
  images: PropTypes.arrayOf(imagePropType),
  video: PropTypes.shape({
    url: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
  gif: PropTypes.shape({
    url: PropTypes.string,
    stillGifUrl: PropTypes.string,
  }),
  service: PropTypes.shape({
    canHaveMediaAttachmentType: PropTypes.func,
    canHaveSomeAttachmentType: PropTypes.func,
    shouldShowDuplicateContentWarning: PropTypes.bool,
    isOmni: PropTypes.bool,
    name: PropTypes.string,
    charLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    usesImageFirstLayout: PropTypes.bool,
    commentCharLimit: PropTypes.number,
    maxAttachableImagesCount: PropTypes.func,
  }),
  retweet: PropTypes.shape({
    avatarUrl: PropTypes.string,
  }),
  characterCount: PropTypes.number,
  tempImage: PropTypes.string,
  composerSidebarVisible: PropTypes.bool,
  characterCommentCount: PropTypes.number,
  channelData: channelDataPropType,
})

export const notifiersPropTypes = PropTypes.shape({
  uploadStarted: PropTypes.func,
  uploadedLinkThumbnail: PropTypes.func,
  uploadedDraftImage: PropTypes.func,
  uploadedDraftVideo: PropTypes.func,
  draftGifUploaded: PropTypes.func,
  queueError: PropTypes.func,
})
