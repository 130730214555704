import { useSplitEnabled } from '@buffer-mono/features'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

export const usePopcornCalendarPostCard = (): boolean => {
  const [newCal] = usePublishRevamp()
  const { isEnabled: newCalPostItems } = useSplitEnabled(
    'popcorn-calendar-posts',
  )
  return newCal && newCalPostItems
}
