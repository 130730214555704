import React, { type HTMLAttributes } from 'react'
import clsx from 'clsx'

import styles from './MediaMarker.module.css'

/**
 * This is a simple marker that we use on top of media items to indicate their type or other additional details.
 */
export const MediaMarker = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  return (
    <div className={clsx(styles.badge, className)} {...props}>
      {children}
    </div>
  )
}

MediaMarker.displayName = 'MediaMarker'
