/**
 * Shorten urls.
 *
 * Returns a Promise that resolves with the short url as a string.
 *
 * Results are cached, and a same request done simultaneously will be
 * queued to return the same info as the already-running request.
 */

import RPCClient from './RPCClient'
import type { ServiceDefinition } from '~publish/legacy/constants/services/types'

// Map uses === semantics, so in order to compare by value, we're
// using a string hash rather than an object as the key
function hashCacheKey(profileId: string, url: string): string {
  return `${profileId}-${url}`
}

const CHARS_LIMIT_SHORT_URLS = 22

class Shortener {
  // Stores pending shortener promises
  static pendingRequests = new Map()

  // Stores results
  static cache = new Map()

  static shorten(profileId: string, url: string): Promise<string> {
    const params = {
      profile_id: profileId,
      url,
    }

    const cacheKey = hashCacheKey(profileId, url)

    // If the result is cached, return it right away
    if (this.cache.has(cacheKey)) {
      return Promise.resolve(this.cache.get(cacheKey))
    }

    // If a request with the same params is already running, return a
    // Promise that'll resolve with that request's data
    if (this.pendingRequests.has(cacheKey)) {
      return Promise.resolve(this.pendingRequests.get(cacheKey))
    }

    const request = RPCClient.call('composerApiProxy', {
      url: '/1/links/shorten.json',
      args: params,
      // @ts-expect-error FIXME: Argument of type 'string' is not assignable to parameter of type 'ServiceDefinition'
    }).then((result: { url: string }) => {
      const shortLink = result.url

      this.cache.set(cacheKey, shortLink)
      if (shortLink !== url)
        this.cache.set(hashCacheKey(profileId, shortLink), shortLink)

      this.pendingRequests.delete(cacheKey)

      return shortLink
    })

    this.pendingRequests.set(cacheKey, request)

    return request
  }

  static clearCache(): void {
    this.cache.clear()
  }

  static isLinkTooShort(url: string): boolean {
    return url.length < CHARS_LIMIT_SHORT_URLS
  }

  static shouldShorten(args: {
    service?: ServiceDefinition
    url: string
  }): boolean {
    const { service, url } = args

    if (!service) {
      return true
    }

    if (!service.isShorteningLinksAvailable) {
      return false
    }

    if (this.isLinkTooShort(url)) {
      return false
    }

    return true
  }
}

export default Shortener
