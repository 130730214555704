/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInCalendarDays, addHours } from 'date-fns'
import { DragDropContext } from 'react-beautiful-dnd'

import usePostLimit from '~publish/pages/Calendar/hooks/usePostLimit'
import { actions as calendarActions } from '../../reducer'
import HoursCells from '../Hours'
import type { PostForCalendarHookResponse } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import { useHandleDragEndLegacy } from '~publish/pages/Calendar/hooks/useHandleDragEndLegacy'

type DaysOfWeekProps = {
  currentDate: Date
  parentRef: React.RefObject<HTMLDivElement | null>
  selectedChannelIds: string[] | void
  hasTwentyFourHourTimeFormat: boolean
  onDropPostSuccess: (args: { dueAt: string; isDraft: boolean }) => void
  onDropPostError: (error?: { message: string } | Error | null) => void
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  postsQuery: PostForCalendarHookResponse
}

const DaysOfWeek = ({
  currentDate,
  parentRef,
  selectedChannelIds,
  hasTwentyFourHourTimeFormat,
  onDropPostSuccess,
  onDropPostError,
  setOpenModal,
  postsQuery,
}: DaysOfWeekProps): JSX.Element => {
  const dispatch = useDispatch()
  const scrollToRef = useRef<HTMLTableRowElement | null>(null)
  const currentLocalHour = new Date().getHours()
  const { weekStart, weekEnd } = useWeeklyDates(currentDate)

  const { data, loading, refetch } = postsQuery
  const handleDragEnd = useHandleDragEndLegacy({
    onDropPostError,
    onDropPostSuccess,
    postsQuery,
    preserveTime: false,
  })

  const shouldRefetch = useSelector(
    (state: { calendar: { shouldRefetch: boolean } }) =>
      state.calendar.shouldRefetch,
  )
  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(calendarActions.clearShouldRefetch())
    }
  }, [shouldRefetch, dispatch, refetch])

  const postLimit = usePostLimit(24)

  const parentEl = parentRef?.current
  const scrollToEl = scrollToRef?.current

  useEffect(() => {
    // Wait for content to finish loading before scrolling because
    // it will most likely change the scroll height of the parent
    if (!loading && scrollToEl && parentEl) {
      // Account for the table header and a bit extra to give it some space
      const offset = scrollToEl.offsetTop - 60
      // Scroll table container to the current hour
      parentEl.scrollTo(0, offset)
    }
  }, [scrollToEl, parentEl, loading])

  const numberDaysToDisplay =
    Math.abs(differenceInCalendarDays(weekStart, weekEnd)) + 1
  // Generates 24 rows, one per hour
  const weekRows = [...Array(24)].map((_, i) => {
    const hour = addHours(weekStart, i)
    return (
      <tr
        key={`weekly-row-${hour}`}
        data-testid={`weekly-row-${hour}`}
        ref={currentLocalHour === i ? scrollToRef : null}
      >
        <HoursCells
          hour={i}
          numberDaysToDisplay={numberDaysToDisplay}
          startDate={weekStart}
          data={data}
          is24HourFormat={hasTwentyFourHourTimeFormat}
          postLimit={postLimit}
          setOpenModal={setOpenModal}
          selectedChannelIds={selectedChannelIds}
        />
      </tr>
    )
  })

  return <DragDropContext onDragEnd={handleDragEnd}>{weekRows}</DragDropContext>
}

export default DaysOfWeek
