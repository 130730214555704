import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { AnyAction } from 'redux'
import { actionTypes as orgActionTypes } from '~publish/legacy/organizations/reducer'
import { actionTypes as composerPopoverActionTypes } from '~publish/legacy/composer-popover/reducer'

import { movePostToDrafts } from '~publish/legacy/post/thunks'
import { selectPostById as selectPublishingPostById } from '~publish/legacy/post/slice'
import { selectPostById as selectRpcPostById } from '~publish/legacy/queue/selectors'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import getCtaProperties from './utils/CtaStrings'
import { actions, actionTypes } from './actions'

let eventQueue: AnyAction[] = []

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    const { analytics } = window as Window &
      typeof globalThis & {
        analytics: unknown
        PRODUCT_TRACKING_KEY: string
        CLIENT_NAME: string
      }

    next(action)
    const state = getState()
    switch (action.type) {
      case actionTypes.INIT:
        if (analytics) {
          analytics.identify(action.userId, action.payload)
        }
        break
      case actionTypes.TRACK_EVENT: {
        const orgId = state.organizations.selected?.id

        if (orgId) {
          if (analytics) {
            analytics.track(action.eventName, {
              ...action.payload,
              product: window.PRODUCT_TRACKING_KEY,
              clientName: window.CLIENT_NAME,
              organizationId: orgId,
            })
          }
        } else {
          eventQueue.push(action)
        }

        break
      }
      case orgActionTypes.ORGANIZATION_SELECTED: {
        try {
          if (analytics) {
            analytics.group(action.selected.id, {
              organizationId: action.selected.id,
              organizationName: action.selected.name,
            })
          }
        } catch (err) {
          // no-op
        }
        eventQueue.forEach((event) => {
          dispatch(
            actions.trackEvent(event.eventName, {
              ...event.payload,
            }),
          )
        })

        eventQueue = []
        break
      }
      case actionTypes.PAGE_CHANGE:
        // currently using track event for page change logic, leaving here for now
        if (analytics) {
          analytics.page(action.pageName, {
            product: window.PRODUCT_TRACKING_KEY,
            ...action.payload,
          })
        }
        break

      case composerPopoverActionTypes.OPEN_COMPOSER: {
        if (action.cta) {
          const ctaProperties = getCtaProperties(action.cta)
          dispatch(actions.trackEvent('Composer Opened', ctaProperties))
        }
        break
      }
      case `${movePostToDrafts.pending.type}`:
      case `${movePostToDrafts.rejected.type}`: {
        const { postId } = action.meta.arg

        // First try find the post in the post state,
        // if not found try to check the queue state
        let post = selectPublishingPostById(state, postId)
        if (!post) post = selectRpcPostById(postId)(state)
        if (!post) {
          // eslint-disable-next-line no-console
          console.error(`Could not retrieve post at id: ${postId}`)
        }
        break
      }
      case composerPopoverActionTypes.HIDE_COMPOSER:
        const { isDiscarded } = action
        if (isDiscarded) {
          BufferTracker.composerDiscarded({
            product: 'publish',
            clientName: 'publishWeb',
            organizationId: selectCurrentOrganizationId(state),
          })
        }

        break
      default:
        break
    }
  }
