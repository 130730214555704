import React from 'react'
import styled, { css } from 'styled-components'
import {
  white,
  grayDark,
  grayDarker,
  grayLighter,
} from '@bufferapp/ui/style/colors'
import VolumeOffIcon from '@bufferapp/ui/Icon/Icons/VolumeOff'

export const Video = css`
  width: 100%;
`

export const PlayButton = css`
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: ${white};
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  &:hover {
    background-color: ${grayLighter};
    svg {
      fill: ${grayDark};
      color: ${grayDark};
    }
  }

  svg {
    fill: ${grayDark};
    color: ${grayDark};
    width: 30px;
    height: 30px;
  }
`

export const Wrapper = css`
  width: 100%;
  position: relative;

  video {
    ${Video}
  }

  button {
    ${PlayButton}
  }
`

export const SoundOffWrapper = styled.div`
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 26px;
  height: 26px;
  background-color: ${grayDarker};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${white};
    color: ${white};
    width: 10px;
    height: 10px;
  }
`

export const ShowMore = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #e0e0e0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`

export const SoundOff = () => {
  return (
    <SoundOffWrapper>
      <VolumeOffIcon size="smedium" />
    </SoundOffWrapper>
  )
}
