import type { PostMediaAsset_AssetFragment } from '~publish/gql/graphql'
import { isOfType } from '~publish/helpers/typeGuards'

export const getAltText = (asset: PostMediaAsset_AssetFragment): string => {
  if (isOfType(asset, 'ImageAsset')) {
    return asset.image.altText ?? 'Image preview'
  }

  if (isOfType(asset, 'VideoAsset')) {
    return 'Video preview'
  }

  if (isOfType(asset, 'DocumentAsset')) {
    return 'Document preview'
  }

  return 'Media preview'
}
