import React, { type ChangeEvent, useEffect, useState } from 'react'

import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import { Input } from '../../channel-fields/styles'
import FooterField from '../../shared/FooterField'

export default function SourceUrl({
  draftId,
  sourceUrl,
}: {
  draftId: string
  sourceUrl: string | null
}): JSX.Element {
  const [localSourceUrl, setLocalSourceUrl] = useState(sourceUrl || '')

  useEffect(() => {
    setLocalSourceUrl(sourceUrl || '')
  }, [sourceUrl])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLocalSourceUrl(event.target.value)
  }

  const handleBlur = (): void => {
    const newSourceUrl = localSourceUrl.trim()
    ComposerActionCreators.updateDraftSourceLink(draftId, newSourceUrl)
  }

  return (
    <FooterField
      label="Destination Link"
      ariaLabel="destinationLink"
      fieldContent={
        <Input
          aria-labelledby="destinationLink"
          type="text"
          value={localSourceUrl}
          name="destinationLink"
          placeholder="Enter destination link..."
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      }
    />
  )
}
