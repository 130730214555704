import React from 'react'
import BulletPoints from '~publish/legacy/shared-components/BulletPoints/BulletPoints'

import translations from '~publish/legacy/i18n/translations/en-us.json'

import {
  PaywallImage,
  PaywallMainContent,
  PaywallSubHeading,
  PaywallTitle,
} from './styles'

/**
 * This component is used to render the content of the paywall
 * in the Paywall component.
 * In order to use it, you need to pass the key of the object
 * that contains the texts in en-us.json file and the link of the
 * image that will be displayed.
 *
 * Example for object in en-us.json:
 *
 * "hashtag-manager-paywall": {
 *   "title": "Upgrade your plan to save and organize hashtags",
 *  "plus": "Plus",
 *   "features": [
 *     "Advanced publishing tools",
 *     "Analytics & reporting",
 *     "Engagement tools"
 *   ],
 *  "buttonText": "See Upgrade Options"
 * },
 */

type ContentForSimplePaywallProps = {
  keyForTexts: string
  paywalImage: string
}

export const ContentForSimplePaywall = ({
  keyForTexts,
  paywalImage,
}: ContentForSimplePaywallProps): JSX.Element => {
  const paywallTexts = (translations as any)[keyForTexts]
  return (
    <PaywallMainContent>
      <PaywallImage src={paywalImage} />
      <PaywallTitle type="h3" data-testid="paywall-title">
        {paywallTexts.title}
      </PaywallTitle>
      <PaywallSubHeading bold>{paywallTexts.plus}</PaywallSubHeading>
      <BulletPoints items={paywallTexts.features} />
    </PaywallMainContent>
  )
}
