// import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch';
import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('MANAGE_APPS', {
  REQUEST_OPEN_MODAL: 0,
  REQUEST_CLOSE_MODAL: 0,
  REQUEST_REVOKE_APP: 0,
})

const initialState = {
  showModalAppId: null,
  showModalAppName: '',
  connectedApps: [],
  submitting: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `connectedApps_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        connectedApps: action.result,
      }
    }
    case `revokeConnectedApp_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        submitting: true,
      }
    case `revokeConnectedApp_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `revokeConnectedApp_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        submitting: false,
      }
    case actionTypes.REQUEST_OPEN_MODAL:
      return {
        ...state,
        showModalAppId: action.appId,
        showModalAppName: action.appName,
      }
    case actionTypes.REQUEST_CLOSE_MODAL:
      return {
        ...state,
        showModalAppId: null,
        showModalAppName: '',
      }
    case actionTypes.REQUEST_REVOKE_APP:
      return {
        ...initialState,
        connectedApps: state.connectedApps.filter(
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          (app) => app.id !== action.appId,
        ),
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'appId' implicitly has an 'any' ty... Remove this comment to see the full error message
  requestOpenModal: ({ appId, appName }) => ({
    type: actionTypes.REQUEST_OPEN_MODAL,
    appId,
    appName,
  }),
  requestCloseModal: () => ({
    type: actionTypes.REQUEST_CLOSE_MODAL,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'appId' implicitly has an 'any' ty... Remove this comment to see the full error message
  requestRevokeApp: ({ appId }) => ({
    type: actionTypes.REQUEST_REVOKE_APP,
    appId,
  }),
}
