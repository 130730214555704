// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import { GifIcon, PdfIcon, PlayFilledIcon, Text } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'

import { type FragmentType, getFragmentData, graphql } from '~publish/gql'
import type { PostMediaAsset_AssetFragment } from '~publish/gql/graphql'
import { isOfType } from '~publish/helpers/typeGuards'
import { getAltText } from './helpers'

import { MediaMarker } from '../../MediaMarker/MediaMarker'

import styles from './PostMediaAsset.module.css'

export const PostMediaAsset_Asset = graphql(/* GraphQL */ `
  fragment PostMediaAsset_Asset on Asset {
    __typename
    mimeType
    thumbnail
    source
    ... on ImageAsset {
      image {
        altText
        width
        height
        isAnimated
      }
    }
    ... on VideoAsset {
      video {
        width
        height
      }
    }
    ... on DocumentAsset {
      document {
        filesize
        numPages
      }
    }
  }
`)

type PostCardMediaProps = {
  asset: FragmentType<typeof PostMediaAsset_Asset>
  className?: string
}

const getIcon = (asset: PostMediaAsset_AssetFragment): JSX.Element | null => {
  if (isOfType(asset, 'VideoAsset')) {
    return <PlayFilledIcon />
  }

  if (isOfType(asset, 'ImageAsset') && asset.image.isAnimated) {
    return <GifIcon />
  }

  if (isOfType(asset, 'DocumentAsset')) {
    return <PdfIcon />
  }

  return null
}

export const PostMediaAsset = ({
  asset: passedAsset,
  className,
}: PostCardMediaProps): JSX.Element | null => {
  const asset = getFragmentData(PostMediaAsset_Asset, passedAsset)

  const icon = getIcon(asset)
  const altText = getAltText(asset)

  return (
    <div className={clsx(styles.asset, className)}>
      {asset.thumbnail ? (
        <img
          src={asset.thumbnail}
          alt={altText}
          className={styles.thumbnail}
          loading="lazy"
        />
      ) : (
        <div className={clsx(styles.thumbnail, styles.empty)}>
          <Text color="subtle" size="sm" align="center">
            No thumbnail
          </Text>
        </div>
      )}
      {icon && (
        <MediaMarker className={styles.badge} aria-hidden>
          {icon}
        </MediaMarker>
      )}
    </div>
  )
}
