import React from 'react'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import TimeFormatPreferences from '../TimeFormatPreferences'
import SelectDayToStartTheWeek from '../SelectDayToStartTheWeek'
import { NewPublishExperience } from '../NewPublishExperience'

type DateTimePreferencesProps = {
  changeTwentyFourHourFormat: (params: { format: string }) => void
  changeStartOfWeek: (params: { dayToStartTheWeek: string }) => void
  initialValues: { format: string; dayToStartTheWeek: string }
}

const DateTimePreferences = ({
  changeTwentyFourHourFormat,
  changeStartOfWeek,
  initialValues,
}: DateTimePreferencesProps): JSX.Element => (
  <div>
    <TimeFormatPreferences
      onChange={changeTwentyFourHourFormat}
      initialValues={initialValues}
    />
    <Divider />
    <SelectDayToStartTheWeek
      onChange={changeStartOfWeek}
      initialValues={initialValues}
    />
    <Divider />
    <NewPublishExperience />
  </div>
)

export default DateTimePreferences
