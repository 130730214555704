import React, { useEffect } from 'react'
import { Text } from '@bufferapp/ui'
import { UpgradePathNotesContainer, Button } from './styles'
import {
  useAccount,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'
import {
  BufferTrackerReact as BufferTracker,
  Product,
} from '@buffer-mono/tracking-plan'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { selectSelectedTabId } from '~publish/legacy/profile-nav/selectors'

export const UpgradePathNotes = (): JSX.Element => {
  const { account } = useAccount()
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )
  // Note: this will need to be updated when we add notes outside of publish tabs
  const tabId = useAppSelector((state) => selectSelectedTabId(state))
  const { canStartTrial } = useOrganizationBilling()

  const state = canStartTrial ? 'trial' : 'upgrade'
  const cta = `${tabId}-notesFeed-${state}-1`
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    BufferTracker.cTAViewed({
      organizationId,
      cta: `${Product.Publish}-${cta}`,
      upgradePathName: `notesFeed-${state}`,
      product: Product.Publish,
      ...commonTrackingProperties,
    })
  })

  const handleUpgradePathClick = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    if (canStartTrial) {
      actions.openModal(MODALS.startTrial, {
        ctaView: tabId,
        ctaLocation: 'post',
        ctaButton: 'startA14DaysFreeTrial',
        cta,
        upgradePathName: 'notesFeed-trial',
      })
    } else {
      actions.openModal(MODALS.planSelector, {
        cta,
        upgradePathName: `notesFeed-upgrade`,
      })
    }
    BufferTracker.cTAClicked({
      organizationId,
      product: Product.Publish,
      cta,
      clientName: 'publishWeb',
      upgradePathName: `notesFeed-${state}`,
      ...commonTrackingProperties,
    })
  }

  return (
    <UpgradePathNotesContainer>
      <img
        alt="Collaboration icons"
        src="https://buffer-publish.s3.amazonaws.com/images/collaboration-icons.svg"
      />
      {canStartTrial ? (
        <Text type="p">
          Want to collaborate with your team using Notes?
          <Button onClick={handleUpgradePathClick}>
            Start a 14-day free trial,
          </Button>
          no credit card required.
        </Text>
      ) : (
        <Text type="p">
          <Button onClick={handleUpgradePathClick}>Invite your team</Button>
          to share and receive notes.
        </Text>
      )}
    </UpgradePathNotesContainer>
  )
}
