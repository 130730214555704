import React, { useMemo } from 'react'
import { PostCard, type PostCard_Post } from '~publish/components/PostCard'
import type { FragmentType } from '~publish/gql'
import type { PostCard_PostFragment } from '~publish/gql/graphql'

import { QueueSlot } from './QueueSlot'
import type { SlotOrPost, Slot } from './postSlotManager'
import { DraggablePostCard } from './DraggablePostCard'
import { OccupiedQueueSlot } from './OccupiedQueueSlot'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

const isPost = (item: unknown): item is PostCard_PostFragment => {
  return (
    typeof item === 'object' &&
    item !== null &&
    '__typename' in item &&
    item.__typename === 'Post'
  )
}

/**
 * Utility to split types into isolated types rather than requiring polymorphism,
 * user can now just check if the one they want is null or not.
 * @param item queue slot or post
 * @returns Type split items as tuple
 */
const toSlotAndPost = (
  item: SlotOrPost,
): [Slot | null, PostCard_PostFragment | null] => {
  if (isPost(item)) {
    return [null, item]
  }
  return [item, null]
}

type SlotOrPostCardProps = {
  item: SlotOrPost
  tagIds: string[]
  isDragAndDropEnabled: boolean
  channelId: string
}

export const SlotOrPostCard = (props: SlotOrPostCardProps): JSX.Element => {
  const { item, tagIds, channelId, isDragAndDropEnabled } = props
  const [slot, post] = toSlotAndPost(item)
  const selectedTags = useSelectedTags()

  const isHiddenPost = useMemo(() => {
    if (!post) return false
    if (tagIds.length === 0) return false
    return !post.tags.some((tag) => tagIds.includes(tag.id))
  }, [post, tagIds])

  // Available Slot
  if (slot) {
    return (
      <QueueSlot date={slot.date} channelId={channelId} tags={selectedTags} />
    )
  }

  if (!post || !post.dueAt) {
    // this can't happen, but type checker doesn't enforce it
    // additionally, dueAt will never be null for a post in the queue
    return <></>
  }

  // Occupied slot
  if (isHiddenPost) {
    return <OccupiedQueueSlot date={post.dueAt} />
  }

  // DND supporting post
  if (isDragAndDropEnabled && post.allowedActions.includes('updatePost')) {
    return (
      <DraggablePostCard post={post as FragmentType<typeof PostCard_Post>} />
    )
  }

  // Regular post
  return <PostCard post={post as FragmentType<typeof PostCard_Post>} />
}
