import React from 'react'
import PropTypes from 'prop-types'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { Field, reduxForm } from 'redux-form'
import { Select } from '@buffer-mono/legacy-bufferapp-components'
import { Text } from '@bufferapp/ui'
import { Row } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'

// @ts-expect-error TS(7031) FIXME: Binding element 'input' implicitly has an 'any' ty... Remove this comment to see the full error message
const DayToStartTheWeek = ({ input, translate }) => (
  <Select
    value={input.value}
    color="outerSpace"
    options={[
      {
        value: 'Sunday',
        name: translate('preferences.general.sunday'),
      },
      {
        value: 'Monday',
        name: translate('preferences.general.monday'),
      },
    ]}
    onChange={input.onChange}
  />
)

DayToStartTheWeek.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
}

const SelectDayToStartTheWeek = () => {
  const { t } = useTranslation()

  return (
    <Row>
      <div>
        <Text type="h3">{t('preferences.general.dayToStartWeek')}</Text>
      </div>
      <div>
        <form style={{ minWidth: '185px' }}>
          <Field
            component={DayToStartTheWeek}
            name="dayToStartTheWeek"
            translate={t}
          />
        </form>
      </div>
    </Row>
  )
}

export default reduxForm({
  form: 'day-to-start-the-week',
})(SelectDayToStartTheWeek)
