/* eslint-disable camelcase */
import { useCallback } from 'react'
import { set, getDate, getMonth, getYear } from 'date-fns'
import type { DropResult } from 'react-beautiful-dnd'
import { useDropPostInSlot } from '~publish/pages/Calendar/hooks/useDropPostInSlot'
import {
  getPosts,
  type PostForCalendarHookResponse,
} from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { isDraft as checkIsDraft } from '~publish/pages/Calendar/util/isDraft'
import type { CalendarPostCard_PostFragment } from '~publish/gql/graphql'

const getNewTimestamp = (
  previousDueAt: string,
  toDateMilliseconds: string,
  preserveTime: boolean,
): string => {
  const fromDate = new Date(previousDueAt)
  const toDate = new Date(Number(toDateMilliseconds))
  const newDate = preserveTime
    ? set(fromDate, {
        date: getDate(toDate),
        month: getMonth(toDate),
        year: getYear(toDate),
      })
    : toDate

  return newDate.toISOString()
}

const findPostById = (
  posts: CalendarPostCard_PostFragment[],
  postId: string,
): CalendarPostCard_PostFragment | undefined =>
  posts.find((post) => post.id === postId)

type OnDragEndProps = {
  result: DropResult
  posts: CalendarPostCard_PostFragment[]
  updatePostDueAt: (post: {
    id: string
    dueAt: string
    isPinned: boolean
  }) => void
  preserveTime: boolean
}

const onDragEnd = ({
  result,
  posts = [],
  updatePostDueAt,
  preserveTime,
}: OnDragEndProps): void => {
  const { destination, source, draggableId: postId } = result

  if (!destination) {
    return
  }

  if (destination.droppableId === source.droppableId) {
    return
  }

  const post = findPostById(posts, postId)
  if (!post || !post.dueAt) return

  const dueAt = getNewTimestamp(
    post.dueAt,
    destination.droppableId,
    preserveTime,
  )

  updatePostDueAt({ id: post.id, dueAt, isPinned: false })
}

type UseUpdatePostDueAtCallbackProps = {
  postsQuery: PostForCalendarHookResponse
  onDropPostError: (error?: { message: string } | Error | null) => void
  onDropPostSuccess: (args: { dueAt: string; isDraft: boolean }) => void
  preserveTime: boolean
}

type UpdatePostDueAtResponse = (result: DropResult) => void

export const useHandleDragEndLegacy = (
  props: UseUpdatePostDueAtCallbackProps,
): UpdatePostDueAtResponse => {
  const { onDropPostError, onDropPostSuccess, postsQuery, preserveTime } = props
  const { data, variables } = postsQuery
  const posts = getPosts(data)
  const { updatePostDueAt } = useDropPostInSlot({
    onSuccess: ({ dueAt, id }): void => {
      const post = posts.find((post) => post.id === id)
      if (!dueAt || !post) return
      const isDraft = checkIsDraft(post.status)
      onDropPostSuccess({ dueAt, isDraft })
    },
    onError: onDropPostError,
    variables,
  })

  const handleDragEnd = useCallback(
    (dragResult: DropResult): void =>
      onDragEnd({ result: dragResult, updatePostDueAt, posts, preserveTime }),
    [updatePostDueAt, posts, preserveTime],
  )

  return handleDragEnd
}
