import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useOnPostComposerClose } from './helpers'

export function PostCreationDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const { id: channelId = 'all' } = useParams<{ id: string }>()
  const history = useHistory()

  const { createNewPostInComposer } = usePostComposer()
  const [text = undefined] = useQueryParam<string>('text')

  useEffect(() => {
    createNewPostInComposer({
      channels: channelId === 'all' ? channelId : [channelId],
      prefillPostData: { text },
    })
  }, [channelId, createNewPostInComposer, text])

  useOnPostComposerClose(() => {
    history.push(parent)
  })

  return null
}
