import {
  AlertDialog,
  Button,
  Flex,
  Label,
  Paragraph,
  toast,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useState } from 'react'
import { shuffleQueue } from '~publish/legacy/general-settings/thunks/shuffleQueue'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import commonStyles from '../../../GeneralSettings.module.css'
import { selectPostsCount } from '~publish/legacy/queue/selectors'

const ShuffleQueueAction = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const postsCount = useAppSelector(selectPostsCount)
  const [isModalShowing, setModalShowing] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const showModal = (): void => setModalShowing(true)
  const hideModal = (): void => setModalShowing(false)

  if (!profile) return <></>

  const onConfirmShuffleQueueClick = async (): Promise<void> => {
    const profileId = profile.id
    setLoading(true)
    const result = await dispatch(
      shuffleQueue({ profileId, count: postsCount }),
    )
    if (shuffleQueue.fulfilled.match(result)) {
      toast.success('Awesome! Your queue has been successfully shuffled.')
    } else {
      toast.error(
        `Sorry! Something went wrong while shuffling your queue: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    BufferTracker.queueShuffled({
      organizationId: profile.organizationId,
      channel: profile.service,
      clientName: 'publishWeb',
    })
    setLoading(false)
    hideModal()
  }

  return (
    <>
      <Flex
        justify="between"
        className={commonStyles.fullWidth}
        gap="space-400"
      >
        <Flex direction="column">
          <Label>Shuffle Queue</Label>
          <Paragraph className={commonStyles.settingsParagraph}>
            Your first 200 queued updates will be shuffled into a completely
            random order.
          </Paragraph>
        </Flex>
        <Flex className={commonStyles.actionsWrapper}>
          <Button
            className={commonStyles.actionButton}
            size="large"
            variant="secondary"
            onClick={showModal}
            data-testid="shuffle-queue-trigger"
          >
            Shuffle Queue
          </Button>
        </Flex>
      </Flex>
      <AlertDialog open={isModalShowing}>
        <AlertDialog.Content>
          <AlertDialog.Title>Shuffle Queue?</AlertDialog.Title>
          <AlertDialog.Description>
            Your first 200 queued updates for{' '}
            <strong>{profile.serviceUsername}</strong> will be shuffled into a
            completely random order
          </AlertDialog.Description>
          <AlertDialog.Separator />
          <AlertDialog.Actions>
            <AlertDialog.Cancel>
              <Button onClick={hideModal} variant="tertiary">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                onClick={onConfirmShuffleQueueClick}
                loading={isLoading}
                variant="primary"
                data-testid="shuffle-queue-confirm"
              >
                Shuffle Queue
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  )
}

export { ShuffleQueueAction }
