import { createNextState } from '@reduxjs/toolkit'
import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import { postParser } from '~publish/legacy/duplicate-server/parsers'
import { movePostToDrafts } from '~publish/legacy/post/thunks/movePostToDrafts'
import { actionTypes } from './actionTypes'
import { noteAdded, noteDeleted, noteUpdated } from '~publish/legacy/post/slice'
import { parseNotes } from '~publish/legacy/duplicate-server/parsers/postParser'
import { shuffleQueue } from '../general-settings/thunks/shuffleQueue'

export const initialState = {
  byProfileId: {},
  composerType: 'queue',
}

const profileInitialState = {
  loading: true,
  loadingMore: false,
  moreToLoad: false,
  page: 1,
  posts: {},
  total: 0,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const determineIfMoreToLoad = (action, currentPosts) => {
  const currentPostCount = Object.keys(currentPosts ?? []).length
  const resultUpdatesCount = Object.keys(action.result.updates || {}).length
  return action.result.total > currentPostCount + resultUpdatesCount
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const getProfileId = (action) => {
  if (action.profileId) {
    return action.profileId
  }
  if (action.args) {
    return action.args.profileId
  }
  if (action.profile) {
    return action.profile.id
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const getPostUpdateId = (action) => {
  if (action.updateId) {
    return action.updateId
  }
  if (action.args) {
    return action.args.updateId
  }
  if (action.post) {
    return action.post.id
  }
  if (action.draft) {
    return action.draft.id
  }
}

/**
 * handlePostsReordered()
 *
 * This method is similar to the above, but instead of responding live to drag/hover events
 * it is responsible for handling the Pusher event we dispatch when a drop operation completes.
 * This allows us to keep other clients up-to-date with drag and drop changes.
 *
 * The Pusher event originates from buffer-web and contains an array `order` with the new order
 * of posts (their IDs).
 *
 * @param  {Object} posts         Current post map
 * @param  {Array}  action.order  The new order of posts (IDs)
 * @return {Object}               New post map
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'posts' implicitly has an 'any' type.
const handlePostsReordered = (posts, { order: newOrder }) => {
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  const orderedPosts = Object.values(posts).sort((a, b) => a.due_at - b.due_at)

  //  Save values that should be fixed
  const fixedValues = orderedPosts.map((p) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    due_at: p.due_at,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    postAction: p.postDetails.postAction,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    day: p.day,
  }))

  // Move the posts into their correct positions
  // @ts-expect-error TS(7006) FIXME: Parameter 'postId' implicitly has an 'any' type.
  const reorderedPosts = newOrder.map((postId) => posts[postId])

  // Finally, apply the fixed values we saved
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  const finalPosts = reorderedPosts.map((p, idx) => {
    p.day = fixedValues[idx].day
    p.postDetails.postAction = fixedValues[idx].postAction
    p.due_at = fixedValues[idx].due_at
    return p
  })

  // Return a new post map
  // @ts-expect-error TS(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
  const newPostsMap = finalPosts.reduce((map, post) => {
    map[post.id] = post
    return map
  }, {})

  return newPostsMap
}

// @ts-expect-error TS(7006) FIXME: Parameter 'campaigns' implicitly has an 'any' type... Remove this comment to see the full error message
export const sortCampaignsByUpdatedAt = (campaigns) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'first' implicitly has an 'any' type.
  campaigns?.sort((first, second) => second.updatedAt - first.updatedAt)

/**
 * Reducers
 */

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const postReducer = (state, action) => {
  switch (action.type) {
    case draftActionTypes.DRAFT_APPROVED:
      return action.draft
    case actionTypes.POST_CREATED:
    case actionTypes.POST_UPDATED:
      return action.post
    case actionTypes.POST_ERROR:
      return state
    case actionTypes.POST_CONFIRMED_DELETE:
      return {
        ...state,
        isConfirmingDelete: false,
        isDeleting: true,
      }
    case actionTypes.POST_SHARE_NOW:
      return {
        ...state,
        isWorking: true,
        workingMessage: 'Sharing...',
      }
    case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isWorking: false,
        workingMessage: null,
      }
    case actionTypes.POST_DROPPED: {
      const newPost = {
        profile_timezone: state.profileTimezone,
        pinned: true,
        due_at: action.timestamp,
        scheduled_at: action.timestamp,
        // we also have the same field in camelCase for some reason...
        scheduledAt: action.timestamp,
        day: action.day,
      }
      // Generate new `postAction` text...
      const {
        postDetails: { postAction },
      } = postParser(newPost)
      return {
        ...state,
        ...newPost,
        postDetails: {
          ...state.postDetails,
          isCustomScheduled: true,
          postAction,
        },
      }
    }
    case actionTypes.POSTS_SWAPPED: {
      const { id: targetId, postProps: postPropsTarget } = action.postTarget
      const { postProps: postPropsSource } = action.postSource
      const isTargetPost = state.id === targetId
      let newPost = {}
      let isCustomScheduled

      if (isTargetPost) {
        isCustomScheduled = postPropsSource.postDetails.isCustomScheduled
        newPost = {
          profile_timezone: state.profileTimezone,
          due_at: postPropsSource.due_at,
          scheduled_at: postPropsSource.scheduled_at,
          scheduledAt: postPropsSource.scheduledAt,
          day: postPropsSource.day,
          pinned: postPropsSource.pinned,
        }
      } else {
        isCustomScheduled = postPropsTarget.postDetails.isCustomScheduled
        newPost = {
          profile_timezone: state.profileTimezone,
          due_at: postPropsTarget.due_at,
          scheduled_at: postPropsTarget.scheduled_at,
          scheduledAt: postPropsTarget.scheduledAt,
          day: postPropsTarget.day,
          pinned: postPropsTarget.pinned,
        }
      }
      // Generate new `postAction` text...
      const {
        postDetails: { postAction },
      } = postParser(newPost)

      return {
        ...state,
        ...newPost,
        postDetails: { ...state.postDetails, isCustomScheduled, postAction },
      }
    }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const postsReducer = (state = {}, action) => {
  switch (action.type) {
    case `queuedPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `shuffleQueue_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `${shuffleQueue.fulfilled}`: {
      const updates = shuffleQueue.fulfilled.match(action)
        ? action.payload
        : action.result.updates
      if (action.args.isFetchingMore) {
        return { ...state, ...updates }
      }
      return updates
    }
    case actionTypes.POST_DELETED:
    case actionTypes.POST_SENT: {
      // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [getPostUpdateId(action)]: deleted, ...currentState } = state
      return currentState
    }
    case draftActionTypes.DRAFT_APPROVED:
    case actionTypes.POST_CREATED:
    case actionTypes.POST_UPDATED:
    case actionTypes.POST_CONFIRMED_DELETE:
    case actionTypes.POST_SHARE_NOW:
    case actionTypes.POST_ERROR:
    case actionTypes.POST_DROPPED:
    case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        [getPostUpdateId(action)]: postReducer(
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state[getPostUpdateId(action)],
          action,
        ),
      }
    case actionTypes.POSTS_SWAPPED:
      return {
        ...state,
        [action.postSource.id]: postReducer(
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state[action.postSource.id],
          action,
        ),
        [action.postTarget.id]: postReducer(
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state[action.postTarget.id],
          action,
        ),
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const profileReducer = (state = profileInitialState, action) => {
  switch (action.type) {
    case `queuedPosts_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading:
          !action.args.isFetchingMore &&
          !action.args.isReordering &&
          !action.args.hideLoading,
        loadingMore: action.args.isFetchingMore,
      }
    case `queuedPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        moreToLoad: determineIfMoreToLoad(action, state.posts),
        page: state.page + 1,
        posts: postsReducer(state.posts, action),
        total: action.result.total,
      }
    case `shuffleQueue_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `${shuffleQueue.fulfilled}`:
      return {
        ...state,
        posts: postsReducer(state.posts, action),
      }
    case `queuedPosts_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.REORDERED_UPDATES: {
      return {
        ...state,
        posts: handlePostsReordered(state.posts, action),
      }
    }
    case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
    case actionTypes.POST_ERROR:
    case actionTypes.POST_CREATED:
    case actionTypes.POST_UPDATED:
    case actionTypes.POST_CONFIRMED_DELETE:
    case actionTypes.POST_DELETED:
    case actionTypes.POST_SHARE_NOW:
    case actionTypes.POST_SENT:
    case draftActionTypes.DRAFT_APPROVED:
    case actionTypes.POST_DROPPED:
    case actionTypes.POSTS_SWAPPED:
      return {
        ...state,
        posts: postsReducer(state.posts, action),
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REORDERED_UPDATES:
    case actionTypes.POST_DROPPED:
    case actionTypes.POSTS_SWAPPED:
    case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
    case `queuedPosts_${dataFetchActionTypes.FETCH_START}`:
    case `queuedPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `queuedPosts_${dataFetchActionTypes.FETCH_FAIL}`:
    case `shuffleQueue_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case actionTypes.POST_CREATED:
    case actionTypes.POST_UPDATED:
    case actionTypes.POST_CONFIRMED_DELETE:
    case actionTypes.POST_DELETED:
    case actionTypes.POST_ERROR:
    case actionTypes.POST_SHARE_NOW:
    case actionTypes.POST_SENT:
    case draftActionTypes.DRAFT_APPROVED: {
      const profileId = getProfileId(action)
      if (profileId) {
        return {
          ...state,
          byProfileId: {
            ...state.byProfileId,
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            [profileId]: profileReducer(state.byProfileId[profileId], action),
          },
        }
      }
      return state
    }
    case noteAdded.type:
    case noteUpdated.type:
    case noteDeleted.type: {
      const { postId, profileId: currentProfileId, notes } = action.payload
      return createNextState(state, (draftState) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const post = draftState.byProfileId[currentProfileId]?.posts?.[postId]
        if (post) post.notes = parseNotes(notes)
        return draftState
      })
    }
    case `${movePostToDrafts.pending}`:
    case `${movePostToDrafts.rejected}`:
    case `${movePostToDrafts.fulfilled}`: {
      const { postId, profileId } = action.meta.arg
      if (!profileId) return state

      if (movePostToDrafts.fulfilled.match(action)) {
        // request fullfilled, post can be removed from queue
        return createNextState(state, (draftState) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          draftState.byProfileId[profileId].total -= 1
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          delete draftState.byProfileId[profileId].posts[postId]
        })
      }

      // request is either pending or rejected
      return createNextState(state, (draftState) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const posts = draftState?.byProfileId?.[profileId]?.posts
        const post = posts?.[postId]

        const isPending = movePostToDrafts.pending.match(action)

        if (post) {
          post.isWorking = isPending
          post.workingMessage = isPending ? 'Moving...' : null
        }
      })
    }

    case actionTypes.SET_COMPOSER_TYPE:
      return {
        ...state,
        composerType: action.composerType,
      }

    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleDeleteConfirmClick: ({ post, profileId }) => ({
    type: actionTypes.POST_CONFIRMED_DELETE,
    updateId: post.id,
    post,
    profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleShareNowClick: ({ post, profileId }) => ({
    type: actionTypes.POST_SHARE_NOW,
    updateId: post.id,
    post,
    profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleRequeue: ({ post, profileId }) => ({
    type: actionTypes.POST_REQUEUE,
    updateId: post.id,
    post,
    profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'campaignId' implicitly has an 'an... Remove this comment to see the full error message
  handleCampaignTagClick: ({ campaignId }) => ({
    type: actionTypes.VIEW_CAMPAIGN_PAGE,
    campaignId,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  onDropPost: (id, timestamp, day, profileId) => ({
    type: actionTypes.POST_DROPPED,
    updateId: id,
    profileId,
    day,
    timestamp,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'postSource' implicitly has an 'any' typ... Remove this comment to see the full error message
  onSwapPosts: (postSource, postTarget, profileId) => ({
    type: actionTypes.POSTS_SWAPPED,
    postSource,
    postTarget,
    profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'composerType' implicitly has an '... Remove this comment to see the full error message
  handleUpdateComposerType: ({ composerType }) => {
    return {
      type: actionTypes.SET_COMPOSER_TYPE,
      composerType,
    }
  },
}
