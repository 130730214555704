import styled from 'styled-components'
import { offWhite } from '@buffer-mono/legacy-bufferapp-components'

import { Text, Button } from '@bufferapp/ui'
import {
  grayLight,
  grayDarker,
  red,
  redDark,
  grayDark,
  gray,
} from '@bufferapp/ui/style/colors'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'

export const ALLOWED_COLORS = [grayDark, red]

export const CommentIconWrapper = styled.span`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  border-left: 1px solid ${grayLight};
  color: ${grayDarker};
`

export const UserTagsIconWrapper = styled.span<{ hasFirstComment?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  color: ${grayDarker};
  ${({ hasFirstComment }): string =>
    !hasFirstComment
      ? `margin-left: 0.5rem; border-left: 1px solid ${grayLight};`
      : ''}
`

export const CardFooterWrapper = styled.div<{ isDragging?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 1rem;
  opacity: ${({ isDragging }): number => (isDragging ? 0 : 1)};
  border-radius: 0 0 4px 4px;
  justify-content: space-between;
`

export const CardFooterWrapperWithBorder = styled(CardFooterWrapper)`
  padding: 8px 16px;
  background-color: ${offWhite};
  border-top: 1px solid ${gray};
`

export const CardFooterContent = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.span`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0;
`

export const ThreadIconWrapper = styled.span`
  margin-right: 4px;
  margin-top: 2px;
  display: flex;
  align-items: center;
`

export const Message = styled(Text)`
  font-size: 12px;
  margin: 0;
  color: ${({ textColor }): string => textColor};
`

export const ThreadCounter = styled(Text)<{ textColor: string }>`
  font-size: 12px;
  margin: 0 12px 0 0;
  color: ${({ textColor }): string => textColor};
`

export const PreMessage = styled(Text)`
  font-size: 12px;
  margin: 0;
  font-weight: ${fontWeightBold};
`

export const EditButton = styled(Button)``

export const ButtonStyled = styled(Button)`
  height: 32px;
`

export const RequeueButton = styled(ButtonStyled)``

export const DraftActionButton = styled(ButtonStyled)``

export const DeleteButton = styled(Button)`
  color: ${red};

  :hover {
    color: ${redDark};
  }
`

export const ButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`
