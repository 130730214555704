import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { toast } from '@buffer-mono/popcorn'

import getNotificationMessage from './utils/getNotificationMessage'

/* NOTE: This module gets the query params when the app is reached, save them in window object
   and display a toast notification when the profiles are fetched, usually this action
   happens after the redirection in routes are finalished.
   The module is going to be DEPRECATED but currently is being used.
*/

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'APP_INIT': {
        /**
         * APP_INIT also includes the query params at the time of load
         * so we'll put those in the global window object for use later.
         */
        let notification = null
        const { queryParams: query } = action
        const type = query.get('nt')
        const key = query.get('nk')
        const variable = query.get('nv') || null
        if (type && key) {
          notification = {
            type: query.get('nt'), // Notification Type
            key: query.get('nk'), // Notification Key
            variable, // Notification variable to be included in the text
          }
        }
        window._notification = notification || undefined
        break
      }
      case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const profilesLoaded = getState().profileSidebar.loading === false
        if (!profilesLoaded) {
          break
        }

        if (window._notification) {
          const notificationType = window._notification.type
          const notificationKey = window._notification.key
          const notificationVar = window._notification.variable

          const message = getNotificationMessage(
            notificationType,
            notificationKey,
            notificationVar,
          )

          if (message) {
            switch (notificationType) {
              case 'success': {
                /* We want the user be awared about the email being verified or not, 
                so the duration is longer */
                const duration = {
                  duration:
                    notificationKey !== 'email-verification-success' &&
                    notificationKey !== 'email-verification-error'
                      ? 5_000
                      : 25_000,
                }
                toast.success(message, duration)
                break
              }
              case 'error': {
                toast.error(message)
                break
              }
            }
          }
        }

        break
      }
      default:
        break
    }
  }
