/* eslint-disable @typescript-eslint/naming-convention */
import { Link, Text } from '@bufferapp/ui'
import { Clock } from '@bufferapp/ui/Icon'
import {
  blueLight,
  grayDark,
  greenLight,
  orangeLight,
  red,
  redDark,
  redDarker,
  white,
} from '@bufferapp/ui/style/colors'
import {
  fontSize,
  fontSizeSmall,
  fontWeightMedium,
} from '@bufferapp/ui/style/fonts'
import styled, { type FlattenSimpleInterpolation } from 'styled-components'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

const cardBorderRadius = `7px`

export const PostPreviewWrapper = styled.div<{
  inLastTwoRows?: boolean
  isEndOfWeek?: boolean
  isLastThirdDayOfWeek?: boolean
  hasSlap?: boolean
}>`
  > :first-child {
    border-top-left-radius: ${cardBorderRadius};
    border-top-right-radius: ${cardBorderRadius};
  }

  > :last-child {
    border-bottom-left-radius: ${cardBorderRadius};
    border-bottom-right-radius: ${cardBorderRadius};
  }
  border-radius: ${cardBorderRadius};
  display: flex;
  flex-direction: column;
  background: ${white};
  box-sizing: border-box;
  width: 364px;
  transition: all 0.3s ease-in-out;
  padding-top: ${(props): CSSRule => (props.hasSlap ? '0' : '8px')};
  padding-bottom: 8px;
`

export const DraftWrapper = styled.div<{
  needsApproval?: boolean
}>`
  display: flex;
  align-items: center;
  background-color: ${(props): CSSRule =>
    props.needsApproval ? orangeLight : blueLight};
  padding: 8px;
  color: ${white};
`

export const BlueprintWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${greenLight};
  padding: 8px;
  color: ${white};
`

export const DraftWrapperText = styled.span`
  margin-left: 8px;
  font-weight: ${fontWeightMedium};
  font-size: ${fontSize};
`

export const ErrorWrapper = styled.div`
  display: flex;
  background-color: ${redDark};
  padding: 8px;
`

export const ParagraphText = styled(Text)`
  margin: 0 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${fontWeightMedium};
`

export const ParagraphErrorText = styled(ParagraphText)`
  white-space: normal;
  width: 100%;
`

export const ChannelDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChannelName = styled(ParagraphText)`
  max-width: 132px;
`

export const ChannelSubtitle = styled(ParagraphText)`
  max-width: 132px;
  font-size: ${fontSizeSmall};
`

export const PostPreviewHeader = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  > :not(:first-child) {
    margin-left: 8px;
  }
`

export const PostTitle = styled(Text)`
  margin: 0 0 8px;
  font-weight: ${fontWeightMedium};
`

export const PostLink = styled(Text)`
  margin: 0 0 8px;
`

export const PostText = styled(Text)`
  margin: 0;
`

export const Content = styled.div`
  padding: 16px;
  flex-direction: column;
  display: flex;
  max-height: 280px;
  overflow: auto;
`

export const MediaWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  > :not(:first-child) {
    margin-left: 8px;
  }
`

export const TextWrapper = styled.div`
  text-align: left;
  flex: 1;

  p {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const AttachmentCard = styled.div`
  background-color: ${white};
  border-radius: ${cardBorderRadius};
  padding: 16px;
  margin-top: 8px;
`

export const PostPreviewFooter = styled.div<{
  alignContentToRight?: boolean
}>`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props): CSSRule =>
    props.alignContentToRight ? 'flex-end' : 'flex-start'};
  min-height: 32px;
`

export const PhoneIconWrapper = styled.div<{
  small?: boolean
}>`
  margin-top: ${(props): CSSRule => (props.small ? '0' : '6px')};
  margin-left: ${(props): CSSRule => (props.small ? '8px' : '0')};
  background-color: ${red};
  border-radius: 100%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 12px;
    width: 12px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    fill: ${grayDark};
  }
`

export const ThreadIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 14px;
    width: 14px;
    margin-right: 4px;
    margin-top: 2px;
  }
`

export const ThreadCounter = styled.span`
  color: ${grayDark};
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 14px;
`

export const StyledLink = styled(Link)`
  color: ${white};
  padding: 8px 10px;
  margin-left: 2px;
  height: 16px;
  background-color: ${redDarker};
  border-radius: 5px;

  :hover {
    color: ${white} !important;
  }

  :first-letter {
    text-transform: capitalize;
  }
`

export const TooltipWrapper = styled.div`
  svg {
    display: block;
  }
`

export const ClockIconStyled = styled(Clock)`
  visibility: visible;
`

export const PostTagsWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`
