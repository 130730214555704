import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'
import { actionTypes as modalActionTypes } from '~publish/legacy/modals/reducer'

export const actionTypes = keyWrapper('DELETE_CAMPAIGN_MODAL', {
  DELETE_CAMPAIGN: 0,
})

export const initialState = {
  loading: false,
  campaign: {},
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `deleteCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
    case `deleteCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
      }
    case `deleteCampaign_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: true,
      }
    case modalActionTypes.SHOW_DELETE_CAMPAIGN_MODAL:
      return {
        ...state,
        campaign: action.campaign,
      }
    case actionTypes.DELETE_CAMPAIGN:
      return {
        ...state,
      }
    default:
      return state
  }
}

export const actions = {
  deleteCampaign: () => ({
    type: actionTypes.DELETE_CAMPAIGN,
  }),
}
