import { useQuery } from '@apollo/client'
import {
  BetaBadge,
  Button,
  CriticalIcon,
  EmptyState,
  MessageCircleHeartIcon,
  PlusIcon,
  Text,
  AllChannelsIcon,
  NewBadge,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useEffect } from 'react'

import { FilterByChannel } from '~publish/components/FilterByChannel'
import {
  useCurrentOrganization,
  useOrganizationId,
} from '~publish/legacy/accountContext'
import { useFullStory } from '~publish/legacy/thirdParty/hooks/useFullStory'

import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { PostViewToggle } from '~publish/components/PostViewToggle/PostViewToggle'

import { AllChannelsPausedQueueNotice } from './AllChannelsPausedQueueNotice'
import { PostList } from './PostList'
import { usePostCounts } from './PostList/usePostCounts'

import styles from './AllChannelsPage.module.css'
import { PageLayout } from '~publish/components/PageLayout'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { graphql } from '~publish/gql'
import { FilterByTag } from '~publish/components/FilterByTag'
import { TimezoneDisplay } from '~publish/components/TimezoneDisplay'
import {
  type PostTab,
  PostTabs,
  PostEmptyStateByTab,
  TAB_VALUES,
} from '~publish/components/PostTabs'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { useSplitEnabled } from '@buffer-mono/features'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import type { PostStatus } from '~publish/gql/graphql'
import { mapPostStatusToTab } from '~publish/helpers/post'
import { PostManagementRouter } from '~publish/components/PostManagementRouter'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

export const GetAllChannelsInfo = graphql(/* GraphQL */ `
  query GetAllChannelsInfo($organizationId: OrganizationId!) {
    # TODO: channels should be a top-level query
    account {
      id
      currentOrganization {
        id
        channels(product: publish) {
          id
          isQueuePaused
          ...FilterByChannel_Channel
        }
      }
    }

    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

export const AllChannelsPage = (): JSX.Element => {
  const { initFullStory } = useFullStory({ onlyBetaUsers: true })

  const organizationId = useOrganizationId() ?? ''

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )
  const [isPublishRevampEnabled] = usePublishRevamp()

  // URL query parameters
  const [tab = 'queue', setTab] = useQueryParam<PostTab>('tab')
  const [channelQueryParam = [], setChannelFilter] =
    useQueryParam<string[]>('channels')
  const [tagsQueryParam = [], setTagFilter] = useQueryParam<string[]>('tags')
  const selectedTags = useSelectedTags()

  const { data, error } = useQuery(GetAllChannelsInfo, {
    variables: { organizationId },
  })
  const channels = sanitizeNullableArray(
    data?.account?.currentOrganization?.channels,
  )
  const tags = sanitizeNullableArray(data?.tags)

  const hasPausedQueues = channels.some((channel) => channel.isQueuePaused)

  const { counts, loading: countsLoading } = usePostCounts({
    organizationId,
    channelIds: channelQueryParam.length ? channelQueryParam : undefined,
    tagIds: tagsQueryParam,
  })

  useEffect(
    function checkTabQueryParam() {
      if (!TAB_VALUES.includes(tab)) {
        setTab(mapPostStatusToTab(tab as PostStatus))
      }
    },
    [setTab, tab],
  )

  // tracking
  useEffect(() => {
    initFullStory()
  }, [initFullStory])

  // TODO: create more sophisticated tracking
  useEffect(() => {
    BufferTracker.allChannelsOpened({
      tab,
      organizationId,
      accountId: data?.account?.id || '',
      channelsSelected:
        channelQueryParam.length === 0 ? undefined : channelQueryParam,
      tagsSelected: tagsQueryParam.length === 0 ? undefined : tagsQueryParam,
    })
  }, [organizationId, channelQueryParam, tagsQueryParam, tab, data?.account])

  if (organizationId && error) {
    return (
      <EmptyState size="xlarge" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  if (!isPublishRevampEnabled) {
    return (
      <EmptyState size="xlarge">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>
          All Channels is not enabled for you
        </EmptyState.Heading>
        <EmptyState.Description>
          Please contact your account manager to enable it.
        </EmptyState.Description>
      </EmptyState>
    )
  }

  const filterApplied =
    channelQueryParam.length > 0 || tagsQueryParam.length > 0

  const tabs: { id: PostTab; count: number | null | undefined }[] = [
    { id: 'queue', count: counts?.queue },
    { id: 'drafts', count: counts?.drafts },
    { id: 'approvals', count: counts?.approvals },
    { id: 'sent', count: counts?.sent },
  ]

  const tabCountLimit = counts?.limit

  return (
    <>
      <PostManagementRouter />

      <RegisteredBannersProvider key={`registered-banner-provider-allChannels`}>
        <PostTabs asChild value={tab ?? undefined} onChange={setTab}>
          <PageLayout className={styles.page}>
            <PageLayout.NotificationsContainer>
              {hasPausedQueues && <AllChannelsPausedQueueNotice />}
            </PageLayout.NotificationsContainer>
            <PageLayout.Header>
              <PageLayout.HeaderRow>
                <AllChannelsPageTitle />

                <PageLayout.Actions>
                  <FeedbackWidget id="all-channels-1" source="publish">
                    <Button variant="tertiary" size="large">
                      <MessageCircleHeartIcon />
                      Share Feedback
                    </Button>
                  </FeedbackWidget>
                  <PostViewToggle />
                  <NewPostComposerTrigger
                    cta="publish-allChannels-header-newPost-1"
                    channels={
                      channelQueryParam.length ? channelQueryParam : 'all'
                    }
                    prefillPostData={{
                      tags: selectedTags,
                    }}
                  >
                    <Button
                      size="large"
                      variant={
                        isSecondaryButtonTreatmentEnabled
                          ? 'secondary'
                          : 'primary'
                      }
                    >
                      <PlusIcon /> New Post
                    </Button>
                  </NewPostComposerTrigger>
                </PageLayout.Actions>
              </PageLayout.HeaderRow>
              <PageLayout.HeaderRow>
                <PostTabs.TabList>
                  {tabs.map((tab) => (
                    <PostTabs.Tab
                      key={tab.id}
                      value={tab.id}
                      count={tab.count}
                      countLimit={tabCountLimit}
                      countLoading={countsLoading}
                    />
                  ))}
                </PostTabs.TabList>

                <PageLayout.Actions>
                  <FilterByChannel
                    channels={channels}
                    value={channelQueryParam}
                    onSelect={setChannelFilter}
                  />
                  <FilterByTag
                    data-tour-id="tags-filter"
                    tags={tags}
                    value={tagsQueryParam}
                    onSelect={setTagFilter}
                  />
                  <TimezoneDisplay />
                </PageLayout.Actions>
              </PageLayout.HeaderRow>
            </PageLayout.Header>

            <PageLayout.Container
              as="main"
              size="narrow"
              className={styles.content}
            >
              {tabs.map((tab) => (
                <PostTabs.Panel key={tab.id} value={tab.id}>
                  <PostList
                    // this is needed so that each tab loads independently and data is not cached between them
                    key={tab.id}
                    status={tab.id ?? 'queue'}
                    channelIds={
                      channelQueryParam.length ? channelQueryParam : undefined
                    }
                    tagIds={tagsQueryParam.length ? tagsQueryParam : undefined}
                    showChannelNotices={true}
                    emptyState={
                      !filterApplied && (
                        <PostEmptyStateByTab tab={tab.id}>
                          <NewPostComposerTrigger
                            cta="publish-allChannels-emptyState-newPost-1"
                            channels={
                              channelQueryParam.length
                                ? channelQueryParam
                                : 'all'
                            }
                          >
                            <Button size="large">
                              <PlusIcon /> New Post
                            </Button>
                          </NewPostComposerTrigger>
                        </PostEmptyStateByTab>
                      )
                    }
                  />
                </PostTabs.Panel>
              ))}
            </PageLayout.Container>
          </PageLayout>
        </PostTabs>
      </RegisteredBannersProvider>
    </>
  )
}

export const AllChannelsPageTitle = (): JSX.Element => {
  const { isBetaEnabled } = useCurrentOrganization()
  return (
    <PageLayout.Title>
      <div className={styles.allChannelsAvatar}>
        <AllChannelsIcon size={24} />
      </div>
      All Channels
      {isBetaEnabled ? <BetaBadge size="small" /> : <NewBadge size="small" />}
    </PageLayout.Title>
  )
}
