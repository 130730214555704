import {
  Button,
  CloseIcon,
  DropdownMenu,
  Flex,
  IconButton,
  ScrollArea,
  toast,
} from '@buffer-mono/popcorn'
import React from 'react'
import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'
import { updatePausedSchedules } from '~publish/legacy/posting-schedule/thunks/updatePausedSchedules'
import type { Time } from '~publish/legacy/posting-schedule/types'
import {
  deleteTimeFromSchedule,
  updateScheduleTimeForApi,
} from '~publish/legacy/posting-schedule/utils/scheduleUtils'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import type { Schedule } from '~publish/legacy/profile-sidebar/types'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { updateSchedules } from '../../../thunks/updateSchedules'
import { DAYSMAP } from '../utils'
import styles from './ScheduleTableCell.module.css'
import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { useCurrentOrganization } from '~publish/legacy/accountContext'

const twelveHours = Array.from({ length: 12 }, (_, i) =>
  (i + 1).toString().padStart(2, '0'),
)
const twentyFourHours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0'),
)
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0'),
)

type ScheduleTableCellProps = {
  time: Time
  dayName: string
  timeIndex: number
  paused: boolean
}

const ScheduleTableCell = ({
  time,
  dayName,
  timeIndex,
  paused,
}: ScheduleTableCellProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const currentOrganization = useCurrentOrganization()
  const currentOrganizationId = currentOrganization.id
  const twentyfourHourTime = useTimeFormatPreference() === '24'
  const displayHours = twentyfourHourTime ? twentyFourHours : twelveHours

  // NOTE: We're deliberately checking that the hour DOES NOT match 24 here
  // as 24 equates to 00:00, so is not larger than 12, so should not be PM.
  // This is to fix a bug where it was possible to save 24:00 as a time.
  const amPm = time.value.hours >= 12 && time.value.hours !== 24 ? 'PM' : 'AM'
  const hour = twentyfourHourTime
    ? time.value.hours
    : Number(time.value.hours) % 12 || 12

  const [selectedHour, setSelectedHour] = React.useState(hour)
  const [selectedMinutes, setSelectedMinutes] = React.useState(
    time.value.minutes,
  )
  const [selectedAmPm, setSelectedAmPm] = React.useState(amPm)

  if (!profile) return <></>
  const profileId = profile.id

  const onRemoveTimeClick = async (): Promise<void> => {
    const schedules = paused ? profile.pausedSchedules : profile.schedules
    const newSchedules = deleteTimeFromSchedule(schedules, {
      dayName: DAYSMAP[dayName],
      timeIndex,
    })
    if (paused) {
      updatePausedSchedulesInDatabase(
        profileId,
        profile.schedules,
        newSchedules,
      )
    } else {
      updateSchedulesInDatabase(profileId, newSchedules)
    }
    BufferTracker.postingTimeRemoved({
      product: Product.Publish,
      organizationId: currentOrganizationId ?? '',
      clientName: Client.PublishWeb,
      dayRemoved: dayName,
      timeRemoved: `${selectedHour
        .toString()
        .padStart(2, '0')}:${selectedMinutes.toString().padStart(2, '0')}`,
      channel: profile.service,
      channelType: profile.service_type,
      channelId: profile.id,
      channelServiceId: profile.serviceId,
      channelUsername: profile.username,
    })
  }

  const updateTime = async (
    hours: number,
    minutes: number,
    amPm: string,
  ): Promise<void> => {
    const finalHours = amPm === 'PM' && !twentyfourHourTime ? hours + 12 : hours
    const schedules = paused ? profile.pausedSchedules : profile.schedules
    const newSchedules = updateScheduleTimeForApi(schedules, {
      dayName: DAYSMAP[dayName],
      timeIndex,
      hours: finalHours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
    })

    BufferTracker.postingTimeUpdated({
      product: Product.Publish,
      organizationId: currentOrganizationId ?? '',
      clientName: Client.PublishWeb,
      channel: profile.service,
      channelType: profile.service_type,
      channelId: profile.id,
      channelServiceId: profile.serviceId,
      channelUsername: profile.username,
      dayChanged: dayName,
      timeRemoved: `${time.value.hours
        .toString()
        .padStart(2, '0')}:${time.value.minutes.toString().padStart(2, '0')}`,
      timeAdded: `${finalHours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`,
    })

    if (paused) {
      updatePausedSchedulesInDatabase(
        profileId,
        profile.schedules,
        newSchedules,
      )
    } else {
      updateSchedulesInDatabase(profileId, newSchedules)
    }
  }

  const updatePausedSchedulesInDatabase = async (
    profileId: string,
    schedules: Schedule[],
    pausedSchedules: Schedule[],
  ): Promise<void> => {
    const result = await dispatch(
      updatePausedSchedules({ profileId, schedules, pausedSchedules }),
    )
    if (updatePausedSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
  }

  const updateSchedulesInDatabase = async (
    profileId: string,
    schedules: Schedule[],
  ): Promise<void> => {
    const result = await dispatch(updateSchedules({ profileId, schedules }))
    if (updateSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
  }

  return (
    <Flex justify="center" align="center" className={styles.cell}>
      <Flex align="center" justify="center">
        <DropdownMenu
          trigger={
            <Button size="small" variant="tertiary" className={styles.label}>
              {selectedHour.toString().padStart(2, '0')}
            </Button>
          }
          className={styles.dropdown}
        >
          <ScrollArea>
            {displayHours.map((hour) => (
              <DropdownMenu.Item
                onClick={async (): Promise<void> => {
                  setSelectedHour(Number(hour))
                  updateTime(Number(hour), selectedMinutes, selectedAmPm)
                }}
                key={hour}
              >
                {hour}
              </DropdownMenu.Item>
            ))}
          </ScrollArea>
        </DropdownMenu>
        :
        <DropdownMenu
          trigger={
            <Button size="small" variant="tertiary" className={styles.label}>
              {selectedMinutes.toString().padStart(2, '0')}
            </Button>
          }
          className={styles.dropdown}
        >
          <ScrollArea>
            {minutes.map((minute) => (
              <DropdownMenu.Item
                onClick={async (): Promise<void> => {
                  setSelectedMinutes(Number(minute))
                  updateTime(selectedHour, Number(minute), selectedAmPm)
                }}
                key={minute}
              >
                {minute}
              </DropdownMenu.Item>
            ))}
          </ScrollArea>
        </DropdownMenu>
      </Flex>
      {!twentyfourHourTime && (
        <DropdownMenu
          trigger={
            <Button size="small" variant="tertiary" className={styles.label}>
              {selectedAmPm}
            </Button>
          }
          className={styles.dropdown}
        >
          {['AM', 'PM'].map((amPm) => (
            <DropdownMenu.Item
              onClick={async (): Promise<void> => {
                setSelectedAmPm(amPm)
                updateTime(selectedHour, selectedMinutes, amPm)
              }}
              key={amPm}
            >
              {amPm}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu>
      )}
      <IconButton
        size="small"
        label="Remove time"
        variant="tertiary"
        onClick={async (): Promise<void> => {
          onRemoveTimeClick()
        }}
        className={styles.removeButton}
        data-testid="schedule-table-cell-remove-button"
      >
        <CloseIcon />
      </IconButton>
    </Flex>
  )
}

export { ScheduleTableCell }
