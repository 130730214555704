import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { useCallback, useMemo } from 'react'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCommonTrackingProperties } from './useCommonTrackingProperties'

export type CtaProperties = {
  product?: string
  clientName?: string
  upgradePathName?: string
  extraProperties?: Record<string, unknown>
}

export const useCta = (
  cta: CTA,
  options?: CtaProperties,
): { viewed: () => void; clicked: () => void } => {
  const {
    product = Product.Publish,
    clientName = Client.PublishWeb,
    upgradePathName = null,
    extraProperties = {},
  } = options ?? {}

  const organizationId = useOrganizationId()
  const commonTrackingProperties = useCommonTrackingProperties()

  const trackingData = useMemo(
    () => ({
      organizationId,
      clientName,
      product,
      cta,
      upgradePathName,
      ...commonTrackingProperties,
      ...extraProperties,
    }),
    [
      organizationId,
      clientName,
      product,
      cta,
      upgradePathName,
      commonTrackingProperties,
      extraProperties,
    ],
  )

  const viewed = useCallback((): void => {
    if (!organizationId) return
    BufferTracker.cTAViewed(trackingData)
  }, [organizationId, trackingData])

  const clicked = useCallback((): void => {
    if (!organizationId) return
    BufferTracker.cTAClicked(trackingData)
  }, [organizationId, trackingData])

  return useMemo(() => ({ viewed, clicked }), [viewed, clicked])
}
