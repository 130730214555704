import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import type { ServiceDefinition } from '~publish/legacy/constants/services/types'
import {
  PostTypeFacebookReel,
  PostTypeFacebookStory,
  PostTypeReel,
  PostTypeShort,
  PostTypeStory,
} from '~publish/legacy/post/constants'

import type { Gif, Image, Link, Retweet, SourceLink, Video } from '../factories'
import {
  AttachmentTypes,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  SERVICE_MASTODON,
  SERVICE_STARTPAGE,
  SERVICE_TIKTOK,
  SERVICE_YOUTUBE,
  Services,
} from '~publish/legacy/constants'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import countHashtagsInText from '../../lib/validation/HashtagCounter'
import { MENTION_REGEX } from '../../utils/custom-plugins/mention'
import { DraftMethods } from './DraftMethods'
import type {
  ChannelData,
  ChannelDataProperties,
  InstagramChannelData,
  LinkedinChannelData,
} from './types'
import { getYoutubeDefaultsFromStorage } from '../../components/youtube/utils'
import type { ValidationFeedback } from '../../stores/types'
import { Document, type DocumentTitle } from '../Document'
import type { Service } from '~publish/gql/graphql'
import type { PostFields } from '@buffer-mono/reminders-config'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import * as ValidatorFactory from '../../lib/validation/ValidatorFactory'
import { EditorStateProxy } from '../EditorStateProxy/EditorStateProxy'

dayjs.extend(utc)
dayjs.extend(timezone)

export type Post = {
  text: string
  characterCount?: number | null
  enabledAttachmentType: string | null
  link: Link | null
  images: Image[]
  video: Video | null
  gif: Gif | null
  retweet: Retweet | null
  urls: string[]
  unshortenedUrls: string[]
}
export type Thread = Post[] | null
type RequiredThread = { thread: NonNullable<Thread> }

export class Draft {
  /**
   * Unique identifier; currently that's the service name
   */
  id: 'omni' | Service

  /**
   * Type to differentiate between different update types in one channel
   * (e.g. reel or post for IG).
   * The mobile apps team uses a `post_type` property that is similar,
   * but Publish doesn't know anything about this field. Instead,
   * Publish relies on `updateType`.
   */
  updateType: string | null = null

  /**
   * Service data structure in AppConstants.js
   */
  service: ServiceDefinition

  editorState: BufferEditor

  /**
   * Text content of the post
   */
  text = ''

  /**
   * Urls contained in the text
   * @type {string[]}
   */
  urls: string[] = []

  /**
   * Urls unshortened by user
   * @type {string[]}
   */
  unshortenedUrls: string[] = []

  /**
   * Link Attachment; data structure in getNewLink()
   */
  link: Link | null = null

  /**
   * Thumbnail of media actively considered for Media Attachment
   */
  tempImage = null

  /**
   * Media Attachment (type: image); data structure in getNewImage()
   */
  images: Image[] = []

  availableImages: Image[] = []

  /**
   * Media Attachment (type: video); data structure in getNewVideo()
   */
  video: Video | null = null

  /**
   * Reference to the media object being actively edited
   */
  attachedMediaEditingPayload = null

  /**
   * Media Attachment (type: gif); data structure in getNewGif()
   */
  gif: Gif | null = null

  /**
   * Media Attachment (type: document)
   */
  document: Document | DocumentTitle | null = null

  /**
   * Data structure in getNewRetweet();
   */
  retweet: Retweet | null = null

  get characterCount(): number {
    return DraftMethods.getFullCharacterCount(this, this.text)
  }

  /**
   * Only updated for services w/ comment char limit
   * @type {number|null}
   */
  characterCommentCount: number | null = null

  /**
   * Only updated for services w/ title char limit
   * @type {number|null}
   */
  characterTitleCount: number | null = null

  isEnabled = false

  /**
   * @type {AttachmentTypes}
   */
  enabledAttachmentType: string | null = null

  /**
   * Source url and page metadata; data structure in getNewSourceLink()
   */
  sourceLink: SourceLink | null = null

  isSaved = false

  hasSavingError = false

  aiAssisted = false

  shortLinkLongLinkMap = new Map()

  scheduledAt: number | null = null

  /**
   * null when scheduledAt is null; true/false otherwise
   */
  isPinnedToSlot: boolean | null = null

  instagramFeedback: ValidationFeedback[] = []

  isTaggingPageLocation = null

  title: string | null = null

  /**
   * Object holding data for channel-specific fields (e.g. GBP)
   */
  channelData: ChannelData | null = null

  places: unknown[] | null = null

  composerSidebarVisible = false

  isReminder?: boolean

  isTwitterPremium = false

  // Should we shorten links automatically in the text using bit.ly
  shortenLinksToggle = true

  // When a message is too short to be shortened, we show a message
  // to the user. This flag is used to control the visibility of that message.
  showShortLinkMessage = false

  /**
   * Used to store what stickers are selected for reminder fields
   */
  selectedStickers: PostFields[] = []

  thread: Thread = null

  constructor(service: ServiceDefinition, editorState: BufferEditor) {
    this.id = service.name
    this.service = service
    this.editorState = editorState
    this.text = EditorStateProxy.getPlainText(editorState)

    this.characterCommentCount = service.commentCharLimit === null ? null : 0
    this.characterTitleCount = service.titleCharLimit === null ? null : 0
    this.isTwitterPremium = false
    this.enabledAttachmentType = service.canHaveAttachmentType(
      AttachmentTypes.MEDIA,
    )
      ? AttachmentTypes.MEDIA
      : null

    if (service.isThreads()) {
      this.shortenLinksToggle = false
    }

    if (service.isGoogleBusiness()) {
      this.updateType = 'whats_new'
      this.channelData = {
        googlebusiness: {
          start_date: dayjs.utc().startOf('day').unix(),
          end_date: dayjs.utc().add(7, 'day').startOf('day').unix(),
        },
      }
    }

    if (service.isInstagram()) {
      this.updateType = 'post'
      this.channelData = {
        instagram: {
          share_to_feed: true,
        },
      }
    }

    if (service.isStartPage()) {
      this.channelData = {
        startPage: {},
      }
    }

    if (service.isYoutube()) {
      const defaultChannelData = getYoutubeDefaultsFromStorage()
      this.updateType = PostTypeShort
      this.channelData = {
        youtube: defaultChannelData,
      }
    }

    if (service.hasPostTypes()) {
      this.updateType = service.getDefaultPostType()
    }
  }

  isEnabledOrOmni(): boolean {
    return this.isEnabled || this.service.isOmni
  }

  isEmpty(): boolean {
    return (
      this.isTextEmpty() &&
      (this.enabledAttachmentType !== AttachmentTypes.LINK ||
        this.link === null) &&
      (this.canHaveMedia() || this.images.length === 0) &&
      (this.canHaveMedia() || this.video === null) &&
      (this.canHaveMedia() || this.gif === null) &&
      (this.canHaveMedia() || this.document === null) &&
      (this.enabledAttachmentType !== AttachmentTypes.RETWEET ||
        this.retweet === null) &&
      this.sourceLink === null
    )
  }

  hasLinkAttachmentEnabled(): boolean {
    return DraftMethods.hasLinkAttachmentEnabled(this)
  }

  hasMediaAttachmentEnabled(): boolean {
    return DraftMethods.hasMediaAttachmentEnabled(this)
  }

  hasRetweetAttachmentEnabled(): boolean {
    return DraftMethods.hasRetweetAttachmentEnabled(this)
  }

  hasLinkAttachment(): boolean {
    return DraftMethods.hasLinkAttachment(this)
  }

  hasLinkAttachmentWithThumbnail(): boolean {
    return DraftMethods.hasLinkAttachmentWithThumbnail(this)
  }

  hasMediaAttachment(): boolean {
    return DraftMethods.hasMediaAttachment(this)
  }

  hasRetweetAttachment(): boolean {
    return DraftMethods.hasRetweetAttachment(this)
  }

  hasAttachment(): boolean {
    return DraftMethods.hasAttachment(this)
  }

  hasNoMediaAttached(): boolean {
    return DraftMethods.hasNoMediaAttached(this)
  }

  hasVideoAttachment(): boolean {
    return DraftMethods.hasVideoAttachment(this)
  }

  hasImagesAttachment(): boolean {
    return DraftMethods.hasImagesAttachment(this)
  }

  isReelsPost(): boolean {
    return this.service.isInstagram() && this.updateType === PostTypeReel
  }

  isFacebookReelPost(): boolean {
    return this.service.isFacebook() && this.updateType === PostTypeFacebookReel
  }

  isFacebookStoryPost(): boolean {
    return (
      this.service.isFacebook() && this.updateType === PostTypeFacebookStory
    )
  }

  isStoryPost(): boolean {
    return this.service.isInstagram() && this.updateType === PostTypeStory
  }

  isShortPost(): boolean {
    return this.service.isYoutube() && this.updateType === PostTypeShort
  }

  hasGifAttachment(): boolean {
    return this.hasMediaAttachmentEnabled() && this.gif !== null
  }

  canAttachMoreImages(): boolean {
    return (
      this.hasMediaAttachmentEnabled() &&
      // if we have a document, no images can be attached
      !Document.isDocument(this.document) &&
      this.service.maxAttachableImagesCount(this) > this.images.length
    )
  }

  canAttachMoreMedia(): boolean {
    return (
      this.canAttachMoreImages() &&
      !this.hasVideoAttachment() &&
      !this.hasGifAttachment()
    )
  }

  getTotalImageUploadsRemaining(): number {
    const maxImages = this.service.maxAttachableImagesCount(this)
    const attachedImages = this.images?.length || 0
    return maxImages - attachedImages
  }

  getImageByUrl(url: string): Image | null {
    if (this.hasImagesAttachment()) {
      return this.images.find((image) => image.url === url) || null
    }

    return null
  }

  hasText(): boolean {
    return DraftMethods.hasText(this)
  }

  hasRequiredAttachmentAttached(): boolean {
    return (
      this.service.requiredAttachmentType === null ||
      (this.service.requiredAttachmentType === AttachmentTypes.LINK &&
        this.hasLinkAttachment()) ||
      (this.service.requiredAttachmentType === AttachmentTypes.RETWEET &&
        this.hasRetweetAttachment()) ||
      (this.service.requiredAttachmentType === AttachmentTypes.MEDIA &&
        this.hasAttachment())
    )
  }

  hasMediaAttachmentSwitch(): boolean {
    return (
      !this.hasMediaAttachmentEnabled() &&
      this.service.canHaveSomeAttachmentType([
        AttachmentTypes.LINK,
        AttachmentTypes.RETWEET,
      ])
    )
  }

  hasLinkAttachmentSwitch(): boolean {
    return this.hasMediaAttachmentEnabled() && this.link !== null
  }

  hasRetweetAttachmentSwitch(): boolean {
    return this.hasMediaAttachmentEnabled() && this.retweet !== null
  }

  hasAttachmentSwitch(): boolean {
    return (
      this.hasMediaAttachmentSwitch() ||
      this.hasLinkAttachmentSwitch() ||
      this.hasRetweetAttachmentSwitch()
    )
  }

  canType(): boolean {
    return !(this.isStoryPost() || this.isFacebookStoryPost())
  }

  canTypeMoreCharacters(): boolean {
    const charLimit = DraftMethods.getCharLimit(this)

    return (
      charLimit !== null &&
      this.characterCount !== null &&
      (charLimit - this.characterCount <= 280 || !this.service.isFacebook())
    )
  }

  canTypeMoreCommentCharacters(): boolean {
    return (
      this.service.commentCharLimit !== null &&
      this.characterCommentCount !== null &&
      this.service.commentCharLimit - this.characterCommentCount <= 280
    )
  }

  isTextEmpty(): boolean {
    return this.text.length === 0
  }

  canHaveMedia(): boolean {
    return this.enabledAttachmentType !== AttachmentTypes.MEDIA
  }

  getNumberOfMentionsInText(): number {
    const captionText = this.text
    const matchesInCaption = captionText.match(MENTION_REGEX)

    return matchesInCaption !== null ? matchesInCaption.length : 0
  }

  getNumberOfMentionsInComment(): number {
    const commentText = this.getCommentText()
    const matchesInComment = commentText.match(MENTION_REGEX)
    return matchesInComment !== null ? matchesInComment.length : 0
  }

  getNumberOfMentions(): number {
    return (
      this.getNumberOfMentionsInText() + this.getNumberOfMentionsInComment()
    )
  }

  getNumberOfHashtagsInText(): number {
    return countHashtagsInText(this.text)
  }

  getCommentText(): string {
    return (
      (
        this?.channelData?.[this.service.name] as
          | InstagramChannelData
          | LinkedinChannelData
      )?.comment_text || ''
    )
  }

  getNumberOfHashtagsInComment(): number {
    const commentText = this.getCommentText()
    return countHashtagsInText(commentText)
  }

  getNumberOfHashtags(): number {
    return (
      this.getNumberOfHashtagsInText() + this.getNumberOfHashtagsInComment()
    )
  }

  getAttachmentThumbnails(): string[] | null {
    let thumbnails: string[] | null = null

    if (this.hasVideoAttachment() && this.video !== null) {
      thumbnails = [this.video.thumbnail]
    } else if (
      this.hasGifAttachment() &&
      this.gif !== null &&
      this.gif.stillGifUrl !== null
    ) {
      thumbnails = [this.gif.stillGifUrl]
    } else if (this.hasImagesAttachment()) {
      thumbnails = this.images.map((image) => image.url)
    } else if (
      this.hasLinkAttachmentWithThumbnail() &&
      this.link !== null &&
      this.link.thumbnail !== null
    ) {
      thumbnails = [this.link.thumbnail.url]
    } else if (this.hasRetweetAttachment() && this.retweet !== null) {
      thumbnails = [this.retweet.avatarUrl]
    } else if (DraftMethods.hasDocumentAttachment(this)) {
      const thumb = DraftMethods.getDocumentAttachment(this)?.thumbnailUrl
      if (thumb) thumbnails = [thumb]
    }

    return thumbnails
  }

  isVerticalVideoOnlySupported(): boolean {
    const serviceConfig = Services.get(this.service.name)
    return (
      (serviceConfig && serviceConfig.onlyAllowsVerticalVideo) ||
      this.isReelsPost() ||
      this.isFacebookReelPost()
    )
  }

  isImageFirst(): boolean {
    return (
      this.service.usesImageFirstLayout &&
      (this.hasNoMediaAttached() || this.isVerticalVideoOnlySupported())
    )
  }

  canHaveChannelData(): boolean {
    return [
      SERVICE_GOOGLEBUSINESS,
      SERVICE_INSTAGRAM,
      SERVICE_STARTPAGE,
      SERVICE_YOUTUBE,
      SERVICE_LINKEDIN,
      SERVICE_MASTODON,
      SERVICE_TIKTOK,
    ].includes(this.id)
  }

  setChannelData(channelData?: ChannelDataProperties | null): void {
    if (!this.canHaveChannelData()) {
      this.channelData = null
      return
    }

    this.channelData = channelData ? { [this.id]: channelData } : {}
  }

  canHaveThread(): boolean {
    return this.updateType === 'thread'
  }

  hasThread(): this is Draft & RequiredThread {
    return Array.isArray(this.thread) && this.thread.length > 1
  }

  isUnderThreadPostsCountLimit(): boolean {
    if (this.service.maxThreads && Array.isArray(this.thread)) {
      return this.thread.length < this.service.maxThreads
    } else {
      return true
    }
  }

  canAddToThread(activeThreadId: number): {
    hasErrors: boolean
    shouldJumpToNextThreadedPost: boolean
    errors: string[]
  } {
    const foundErrors = ValidatorFactory.validate(this, false, activeThreadId)

    const errorsForActiveThread = foundErrors.results.filter((error) => {
      return (
        error.metadata?.threadId === activeThreadId &&
        error.code === VALIDATION_CODE.MISSING_VALUE
      )
    })

    let foundErrorsForActiveThread = errorsForActiveThread.map(
      (error) => error.message,
    )

    if (!this.isUnderThreadPostsCountLimit()) {
      foundErrorsForActiveThread = [
        `Number of ${this.service.nameOfPost}s reached the limit of ${this.service.maxThreads}`,
      ]
    }

    const hasErrors = foundErrorsForActiveThread.length > 0
    const errorsForNextThread = foundErrors.results.filter((error) => {
      return (
        error.metadata?.threadId === activeThreadId + 1 &&
        error.code === VALIDATION_CODE.MISSING_VALUE
      )
    })

    return {
      errors: foundErrorsForActiveThread,
      hasErrors,
      shouldJumpToNextThreadedPost:
        !hasErrors && errorsForNextThread.length > 0,
    }
  }

  shouldShortenLinks(): boolean {
    return this.shortenLinksToggle && this.service.isShorteningLinksAvailable
  }

  shouldShowShortLinkMessage(): boolean {
    return this.shouldShortenLinks() && this.showShortLinkMessage
  }

  isStickerSelected(sticker: PostFields): boolean {
    return this.selectedStickers.includes(sticker)
  }
}
export interface SlateDraft extends Draft {
  editorState: BufferEditor
}

export default Draft
