import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Dialog } from '@buffer-mono/popcorn'
import { PortalContainerProvider } from '@buffer-mono/popcorn/src/helpers/usePortalContainer'

import { getFragmentData, graphql } from '~publish/gql'

import { PostCard, PostCard_Post } from '../PostCard'
import { PostErrorDialog } from './PostErrorDialog'

export const GetPost = graphql(/* GraphQL */ `
  query GetPost($id: PostId!) {
    post(input: { id: $id }) {
      id
      ...PostCard_Post # TODO: Update this once the PostDetailsDialog has his own implementation
    }
  }
`)

export function PostDetailsDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const match = useRouteMatch()
  const history = useHistory()
  const { postId, id: channelId } = useParams<{ postId: string; id: string }>()
  const [open, setOpen] = useState(true)
  const { data, loading, error } = useQuery(GetPost, {
    variables: {
      id: postId,
    },
  })
  const portalContainerId = `portal-container-${postId}`

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  useEffect(
    function setInnerPortalContainer() {
      if (portalContainerId) {
        setContainer(document.getElementById(portalContainerId))
      }
      // We don't want to set the container until we have the data because it will not be present yet
    },
    [data, portalContainerId],
  )

  const closeDialogWhenComposerOpens = (
    evt: React.MouseEvent<HTMLElement>,
  ): void => {
    // Look for the closest ancestor that has the aria-controls attribute set to "composer-root"
    const openComposerTrigger = (evt.target as Element)?.closest(
      '[aria-controls="composer-root"]',
    )
    if (openComposerTrigger) {
      setOpen(false)
      history.push(parent)
    }
  }

  const onOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      history.push(parent)
    }
  }

  if (loading && !data) {
    return null
  }

  const post = getFragmentData(PostCard_Post, data?.post)
  if (channelId && post?.channel?.id && channelId !== post.channel.id) {
    history.push(match.url.replace(channelId, post.channel.id))
  }

  if (error || !post) {
    return (
      <PostErrorDialog
        error={error ?? { message: 'Bad Request' }}
        parent={parent}
      />
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size={error ? 'medium' : 'large'}
        style={{ overflow: 'visible', padding: 0 }}
        onClick={closeDialogWhenComposerOpens}
      >
        {data?.post && (
          <PortalContainerProvider container={container ?? document.body}>
            <PostCard post={data.post} />
          </PortalContainerProvider>
        )}
        {/* We need to render any portal from PostCard into the Dialog.Content to avoid the dialog to close when interacting with the portal */}
        <div id={portalContainerId} style={{ position: 'absolute' }} />
      </Dialog.Content>
    </Dialog>
  )
}
