import { useMemo, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { graphql, getFragmentData } from '~publish/gql'
import { actions as calendarActions } from '~publish/legacy/calendar/reducer'
import { useSplitEnabled } from '@buffer-mono/features'

import type {
  GetCalendarAndPostListQueryVariables,
  GetCalendarAndPostListQuery,
  CalendarPostCard_PostFragment,
} from '~publish/gql/graphql'
import { CalendarPostCard_Post } from '~publish/legacy/calendar/components/PostItem/components/PostItem/PostItemContent'
import { getTimezoneFromBrowser } from '~publish/legacy/utils/timezone'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import { useMonthlyDates } from '~publish/pages/Calendar/hooks/useMonthlyDates'

import {
  usePostIntervals,
  type IntervalsResponse,
} from '../util/generatePostIntervals'

export type QueryVariables = GetCalendarAndPostListQueryVariables
export type Data = GetCalendarAndPostListQuery

export const GetCalendarAndPostList = graphql(/* GraphQL */ `
  query GetCalendarAndPostList(
    $startDate: DateTime!
    $endDate: DateTime!
    $channelIds: [ChannelId!]
    $organizationId: OrganizationId!
    $postsLimit: Int!
    $tagIds: [TagId!]
    $status: [PostStatus!]
  ) {
    posts(
      first: $postsLimit
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          dueAt: { start: $startDate, end: $endDate }
          tagIds: $tagIds
          status: $status
        }
        sort: [
          { field: dueAt, direction: asc }
          { field: createdAt, direction: desc }
        ]
      }
    ) {
      edges {
        node {
          id
          dueAt
          ...CalendarPostCard_Post
        }
      }
    }
  }
`)

export type PostForCalendarHookResponse = {
  data: GetCalendarAndPostListQuery | undefined
  intervals: IntervalsResponse
  loading: boolean
  refetch: () => void
  variables: GetCalendarAndPostListQueryVariables
}

type PostForCalendarHookProps = {
  currentDate: Date
  calendarMode: 'week' | 'month'
  channelsSelected: string[] | void
  tagIds: string[] | void
  status: string[] | void
}

// TODO replace with fragment
type NewPostType = NonNullable<
  GetCalendarAndPostListQuery['posts']['edges']
>[number]['node']

export const asPostsFragment = (
  data: GetCalendarAndPostListQuery | null | undefined,
): (NewPostType | undefined | null)[] => {
  return data?.posts.edges?.map((edge) => edge.node) ?? []
}

export const usePostsFragment = asPostsFragment

export const getPosts = (
  data: GetCalendarAndPostListQuery | null | undefined,
): CalendarPostCard_PostFragment[] => {
  return (
    data?.posts.edges?.map((edge) =>
      getFragmentData(CalendarPostCard_Post, edge.node),
    ) ?? []
  )
}

export const useCalendarAndPostsList = (
  props: PostForCalendarHookProps,
): PostForCalendarHookResponse => {
  const { isEnabled: extendCalendarPostList } = useSplitEnabled(
    'extend-calendar-post-list',
  )
  const { currentDate, channelsSelected, calendarMode, tagIds, status } = props
  const weekDates = useWeeklyDates(currentDate)
  const monthDates = useMonthlyDates(currentDate)

  const [startDate, endDate] =
    calendarMode === 'week'
      ? [weekDates.weekStart, weekDates.weekEnd]
      : [monthDates.calendarStart, monthDates.calendarEnd]
  const organizationId = useOrganizationId() ?? ''
  const timezone = getTimezoneFromBrowser()
  const variables = useMemo(
    () =>
      ({
        postsLimit: extendCalendarPostList ? 1_000 : 200,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        organizationId,
        channelIds: channelsSelected,
        tagIds,
        status,
      } as QueryVariables),
    [
      channelsSelected,
      startDate,
      endDate,
      organizationId,
      tagIds,
      extendCalendarPostList,
      status,
    ],
  )

  const { data, loading, refetch } = useQuery<
    GetCalendarAndPostListQuery,
    QueryVariables
  >(GetCalendarAndPostList, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const intervals = usePostIntervals(
    startDate.toISOString(),
    endDate.toISOString(),
    timezone,
  )

  const dispatch = useDispatch()

  // TODO: type here is coerced. Ideally we delete this as we move off of redux
  const shouldRefetch = useSelector(
    (state: { calendar: { shouldRefetch: boolean } }) =>
      state.calendar.shouldRefetch,
  )
  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(calendarActions.clearShouldRefetch())
    }
  }, [shouldRefetch, dispatch, refetch])

  return useMemo(
    () => ({
      data,
      intervals,
      loading,
      refetch,
      variables,
    }),
    [data, intervals, loading, refetch, variables],
  )
}
