import React, { useState } from 'react'
import {
  Button,
  Flex,
  Heading,
  HoverCard,
  Paragraph,
} from '@buffer-mono/popcorn'

import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { actions as coachmarkActions } from '~publish/legacy/coachmarks/reducer'
import { selectShowRemindersConfirmation } from '~publish/legacy/coachmarks/selectors'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import PhoneIcon from '@bufferapp/ui/Icon/Icons/Phone'

import * as Styles from './styles'
import { orange } from '@bufferapp/ui/style/colors'
import capitalize from 'lodash/capitalize'
import type { ComposerProfile } from '~publish/legacy/composer/composer/stores/types'

type ConfirmationCoachMarkProps = {
  selectedProfiles: ComposerProfile[]
  children: JSX.Element
}

export function ConfirmationCoachMark({
  selectedProfiles,
  children,
}: ConfirmationCoachMarkProps): JSX.Element {
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => selectShowRemindersConfirmation(state))
  const { dismiss } = useDismissableBanner('notificationsCoachmarkConfirmation')

  if (!open) {
    return children
  }

  const handleDismissal = (): void => {
    if (dontShowAgain) {
      dismiss()
    }

    dispatch(coachmarkActions.setRemindersConfirmationViewed())
    dispatch(coachmarkActions.hideRemindersConfirmation())
  }

  const channelCopy =
    selectedProfiles.length === 1
      ? ` on ${capitalize(selectedProfiles?.[0]?.serviceName ?? undefined)}`
      : ''

  return (
    <HoverCard open={open}>
      <HoverCard.Trigger>{children}</HoverCard.Trigger>
      <HoverCard.Content>
        <Flex direction="column" style={{ width: 330, padding: '4px 12px' }}>
          <Styles.HeadingWrapper>
            <PhoneIcon color={orange} />
            <Heading size="small">Notifications are on</Heading>
          </Styles.HeadingWrapper>
          <Paragraph>
            This post <strong>will not</strong> be automatically published by
            Buffer. We&apos;ll send you a mobile notification when it&apos;s
            time to post so you can finish this post{channelCopy}.
          </Paragraph>

          <Styles.Label htmlFor="dontShowAgain">
            <input
              type="checkbox"
              id="dontShowAgain"
              name="dontShowAgain"
              onClick={(): void => setDontShowAgain(!dontShowAgain)}
            />{' '}
            Don&apos;t show this again
          </Styles.Label>

          <Styles.ActionsWrapper>
            <Button size="large" onClick={handleDismissal}>
              Got it
            </Button>
          </Styles.ActionsWrapper>
        </Flex>
      </HoverCard.Content>
    </HoverCard>
  )
}
