export function isFacebookGroup(channel: {
  service: string
  serverUrl?: string | null
}): boolean {
  return (
    (channel.service === 'facebook' &&
      channel.serverUrl?.startsWith('https://www.facebook.com/groups')) ??
    false
  )
}
