import React from 'react'
import PropTypes from 'prop-types'

import { MastodonPost } from './components/Post'
import { Empty } from '../Common/Empty'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { MastodonProfile, MastodonDraft, MastodonType } from '../types'

import * as Styles from '../Common/styles'

export const MastodonPreview = ({
  draft,
  profile,
}: {
  draft: MastodonDraft
  profile: MastodonProfile
}): JSX.Element => {
  const { username, serverUrl } = profile
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const { hasThread, isEmpty } = draft

  const getPosts = () => {
    const drafts = [] as MastodonType[]

    if (hasThread) {
      const { activeThreadId } = draft

      draft.thread?.forEach((threadedDraft, i) => {
        drafts.push({
          content:
            activeThreadId === i
              ? {
                  spoilerText: draft.channelData?.mastodon?.spoilerText,
                  text: draft.text,
                  enabledAttachmentType: draft.enabledAttachmentType,
                  images: draft.images,
                  video: draft.video,
                  gif: draft.gif,
                  link: draft.link,
                }
              : {
                  ...threadedDraft,
                  spoilerText: draft.channelData?.mastodon?.spoilerText,
                },
        })
      })
    } else {
      drafts.push({
        content: {
          ...draft,
          spoilerText: draft.channelData?.mastodon?.spoilerText,
          text: draft.text,
        },
      })
    }

    return drafts
  }

  const posts = getPosts()

  React.useEffect(() => {
    const container = containerRef.current
    if (!container) return
    container.scrollTop = container.scrollHeight
  }, [posts])

  if (isEmpty && !hasThread) {
    return <Empty />
  }

  return (
    <Styles.Wrapper data-testid="mastodon-preview" ref={containerRef}>
      {posts.map((post, index) => (
        <MastodonPost
          key={index}
          isReply={hasThread && index > 0}
          content={post.content}
          profile={{
            ...profile,
            handle: `@${username}`,
            serverUrl,
          }}
        />
      ))}
    </Styles.Wrapper>
  )
}

MastodonPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: Object.assign(profilePreviewPropType, {
    serverUrl: PropTypes.string,
  }).isRequired,
}
