import { useState } from 'react'
import {
  Canva,
  Unsplash,
  Dropbox,
  GoogleDrive,
  Onedrive,
  GooglePhotos,
} from '@bufferapp/ui/Icon'
import { useLazyQuery } from '@apollo/client'
import {
  getCanvaDesignConfig,
  openCanvaModal,
} from '~publish/legacy/canva/handlers'

import { fileFromUrl } from '~publish/legacy/uploads/lib/remoteFile'
import type { OnFileReadyCb } from '~publish/legacy/uploads/types'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { useAppDispatch } from '~publish/legacy/store'
import { addPermanentNotification } from '~publish/legacy/notifications-provider/notificationsSlice'

import { logError } from '~publish/legacy/utils/logError'
import { UploadSource } from '~publish/legacy/uploads/values/UploadSource'
import {
  CANVA_SERVICE,
  UNSPLASH_SERVICE,
  DROPBOX_SERVICE,
  GOOGLE_DRIVE_SERVICE,
  GOOGLE_PHOTOS_SERVICE,
  ONE_DRIVE_SERVICE,
} from './constants'
import type {
  GraphImage,
  IntegrationsContext,
  IntegrationsService,
} from './types'
import { openUploadcareModal } from '../uploadcare/openUploadcareModal'

import {
  UploadcareIntegrations,
  UploadcareSources,
} from '../uploadcare/constants'

import {
  BUTTON_CLICKED,
  MEDIA_ATTACHED,
  sendIntegrationsEvent,
} from './tracking'

import { TRACK_IMAGES_QUERY } from './queries'
/*
 * HOOK: useIntegrationServices
 */
export const useIntegrationServices = ({
  context,
  onFileReady,
  updateType,
  serviceName = 'omni',
  onError,
  enableCanva = true,
  enableUnsplash = true,
}: {
  context: IntegrationsContext
  onFileReady: OnFileReadyCb
  updateType?: string
  serviceName?: string
  onError?: ({ message }: { message: string }) => void
  enableCanva?: boolean
  enableUnsplash?: boolean
}): {
  services: Array<IntegrationsService>
  activeService: IntegrationsService | null
} => {
  const dispatch = useAppDispatch()
  const [activeService, setActiveService] =
    useState<IntegrationsService | null>(null)

  const serviceClose = (): void => {
    setActiveService(null)
  }

  const serviceOpen = (service: IntegrationsService): void => {
    service.onClose = serviceClose
    setActiveService(service)
    sendIntegrationsEvent(BUTTON_CLICKED, context, service.id)

    service.onClick?.()
  }

  const { onUploadStarted, onUploadFinished } = useIsIntegrationUploading()

  const [trackImages] = useLazyQuery(TRACK_IMAGES_QUERY)

  if (!onError) {
    onError = ({ message }: { message: string }): void => {
      dispatch(addPermanentNotification(`⚠️ ${message}`))
    }
  }

  /*
   * uploadMediaFile
   *
   * Fetchs the file from url, calls onFileReady function (Uploader tasks associated to the Editor),
   * sends MEDIA_ATTACHED event for integrations and set activeService=null
   */
  const uploadMediaFile = async (
    url: string,
    source: UploadSource,
    name?: string,
    downloadUrl?: string,
  ): Promise<void> => {
    const remoteFile = {
      remoteUrl: url,
      name,
    }
    try {
      onUploadStarted()
      serviceClose()
      const fileSelected = await fileFromUrl(remoteFile)
      onFileReady(fileSelected, source)
      onUploadFinished()
      sendIntegrationsEvent(
        MEDIA_ATTACHED,
        context,
        source.name,
        fileSelected?.type,
      )

      if (source.name === UNSPLASH_SERVICE) {
        trackImages({
          variables: {
            trackUrl: downloadUrl,
          },
        })
      }
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error, { metaData: { integration: source.name, remoteFile } })
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      onError?.({ message: `Failed to upload media ${error.message}` })
    }
  }

  const openUploadCare = (integration: string): void => {
    openUploadcareModal(
      serviceName,
      onFileReady,
      onError,
      integration,
      onUploadStarted,
      onUploadFinished,
      UploadcareSources.BAR,
      null,
      updateType,
      context,
    )
  }

  // INCLUDES HERE THE SERVICES FOR THE INTEGRATIONS BAR
  const services: IntegrationsService[] = [
    {
      id: CANVA_SERVICE,
      title: 'Canva',
      label: 'Design with Canva',
      placeholder: '', // not required for canva
      isNew: false,
      isEnabled: enableCanva,
      icon: Canva,
      openSearchImagesModal: false,
      onClick: (): void => {
        openCanvaModal({
          onFileReady,
          designConfig: getCanvaDesignConfig(serviceName),
          onClose: serviceClose,
        })
      },
    },
    {
      id: DROPBOX_SERVICE,
      title: 'Dropbox',
      label: 'Select from Dropbox',
      isNew: false,
      isEnabled: true,
      icon: Dropbox,
      openSearchImagesModal: false,
      onClick: (): void => {
        openUploadCare(UploadcareIntegrations.DROPBOX)
      },
    },
    {
      id: GOOGLE_DRIVE_SERVICE,
      title: 'Google Drive',
      label: 'Select from Google Drive',
      isNew: false,
      isEnabled: true,
      icon: GoogleDrive,
      openSearchImagesModal: false,
      onClick: (): void => {
        openUploadCare(UploadcareIntegrations.GOOGLE_DRIVE)
      },
    },
    {
      id: GOOGLE_PHOTOS_SERVICE,
      title: 'Google Photos',
      label: 'Select from Google Photos',
      isNew: false,
      isEnabled: true,
      icon: GooglePhotos,
      openSearchImagesModal: false,
      onClick: (): void => {
        openUploadCare(UploadcareIntegrations.GOOGLE_PHOTOS)
      },
    },

    {
      id: ONE_DRIVE_SERVICE,
      title: 'One Drive',
      label: 'Select from OneDrive',
      isNew: false,
      isEnabled: true,
      icon: Onedrive,
      openSearchImagesModal: false,
      onClick: (): void => {
        openUploadCare(UploadcareIntegrations.ONE_DRIVE)
      },
    },
    {
      id: UNSPLASH_SERVICE,
      title: 'Unsplash',
      label: 'Select from Unsplash',
      placeholder: 'Search free high resolution photos',
      isNew: false,
      isEnabled: enableUnsplash,
      icon: Unsplash,
      openSearchImagesModal: true,
      onFileSelected: async (imageTarget: GraphImage): Promise<void> => {
        uploadMediaFile(
          imageTarget.regularUrl,
          UploadSource.unsplash({
            id: imageTarget.id,
            author: imageTarget.author,
          }),
          `${imageTarget.id}-unsplash.jpg`,
          imageTarget.downloadUrl,
        )
      },
    },
  ]

  return {
    services: services
      .filter((s) => s.isEnabled)
      .map((s) => {
        return {
          ...s,
          onClick: () => serviceOpen(s),
        }
      }),
    activeService,
  }
}
