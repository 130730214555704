import React, { type CSSProperties, type KeyboardEventHandler } from 'react'
import { useDraggable } from '@dnd-kit/core'
import clsx from 'clsx'

import { GripVerticalIcon, IconButton } from '@buffer-mono/popcorn'

import useDraggingCursor from '~publish/hooks/useDraggingCursor'

import styles from './DraggableItem.module.css'

interface DraggableItemProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string
  index: number
  shouldDisableDrag?: boolean
  children: React.ReactNode
  timestamp: number
  onOverlay?: boolean
}

export const DraggableItem = React.memo(
  ({
    id,
    index,
    shouldDisableDrag = false,
    children,
    style,
    className,
    timestamp,
    onOverlay,
    ...props
  }: DraggableItemProps): JSX.Element => {
    const { attributes, listeners, setNodeRef, transform, isDragging } =
      useDraggable({
        id,
        disabled: shouldDisableDrag,
        data: {
          index,
          timestamp,
        },
      })
    const { onKeyDown, ...mouseListeners } = listeners ?? {}
    useDraggingCursor(isDragging)

    if (isDragging && !onOverlay) {
      return <></>
    }
    return (
      <div
        {...mouseListeners}
        ref={setNodeRef}
        className={clsx(
          styles.item,
          {
            [styles.isDragging]: isDragging,
          },
          className,
        )}
        style={
          {
            '--translate-x': transform?.x ? `${transform.x}px` : undefined,
            '--translate-y': transform?.y ? `${transform.y}px` : undefined,
            ...style,
          } as CSSProperties
        }
        {...props}
      >
        <IconButton
          variant="tertiary"
          label="Drag to reschedule"
          className={styles.dragHandle}
          {...attributes}
          onKeyDown={onKeyDown as KeyboardEventHandler}
          data-dnd
          size="small"
        >
          <GripVerticalIcon />
        </IconButton>
        {children}
      </div>
    )
  },
)

DraggableItem.displayName = 'DraggableItem'
