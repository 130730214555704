import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Text } from '@bufferapp/ui'
import styled from 'styled-components'
import { fontWeightSemiBold } from '@buffer-mono/legacy-bufferapp-components'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  margin: 0 16px;
  p {
    display: inline;
  }
`

const BoldText = styled(Text)`
  font-weight: ${fontWeightSemiBold};
`

const Title = styled(Text)`
  margin-top: 0px;
`

const DeleteCampaignModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'deleteCampaign' implicitly has an... Remove this comment to see the full error message
  deleteCampaign,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hideModal' implicitly has an 'any... Remove this comment to see the full error message
  hideModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'campaign' implicitly has an 'any'... Remove this comment to see the full error message
  campaign,
}) => {
  const { t } = useTranslation()
  const getDeleteText = () => t('campaigns.deleteTagModal.delete')

  const getTitleText = () => t('campaigns.deleteTagModal.title')

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: { label: string... Remove this comment to see the full error message
    <Modal
      action={{
        label: loading
          ? t('campaigns.deleteCampaignModal.loading')
          : getDeleteText(),
        disabled: false,
        callback: () => {
          deleteCampaign(true)
        },
      }}
      secondaryAction={{
        label: t('campaigns.deleteCampaignModal.cancel'),
        callback: () => {
          hideModal()
        },
      }}
      dismissable
    >
      <Container>
        <Title type="h2">{`${getTitleText()} "${campaign.name}"?`}</Title>
        <BoldText type="p" color="grayDark">
          {t('campaigns.deleteTagModal.boldSubtext')}
        </BoldText>
        <Text type="p" color="grayDark">
          {t('campaigns.deleteTagModal.subtext')}
        </Text>
      </Container>
    </Modal>
  )
}

DeleteCampaignModal.propTypes = {
  deleteCampaign: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
}

export default DeleteCampaignModal
