import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'

const initialState = {
  isLoadingGlobalAccount: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `globalAccount_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        isLoadingGlobalAccount: true,
      }
    case `globalAccount_${asyncDataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        email: action.result.email,
        _id: action.result._id,
        productSolutionName: action.result.productSolutionName,
        isLoadingGlobalAccount: false,
      }
    }
    case `globalAccount_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        isLoadingGlobalAccount: false,
      }
    default:
      return state
  }
}
