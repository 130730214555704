import { Temporal } from '@js-temporal/polyfill'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Calendar } from '~publish/components/Calendar'
import { useCalendar } from '~publish/components/Calendar/hooks/useCalendar'
import { type FragmentType, getFragmentData } from '~publish/gql'
import { format } from '~publish/helpers/temporal'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useTimezone } from '~publish/hooks/useTimezone'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { selectWeekStartsMonday } from '~publish/legacy/user/selectors'
import PostItemContent, {
  CalendarPostCard_Post,
} from '~publish/legacy/calendar/components/PostItem/components/PostItem/PostItemContent'

import type { PostForCalendarHookResponse } from './hooks/useCalendarAndPostsList'
import { useHandleDragEnd } from './hooks/useHandleDragEnd'
import { usePostDraggedTracking } from '~publish/hooks/usePostDraggedTracking'
import type { DragStartEvent } from '@dnd-kit/core'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

const CTA_ADD_HOUR = 'publish-calendar-hour-addPost-1'
const CTA_ADD_DAY = 'publish-calendar-day-addPost-1'

type RenderItemsParams = Parameters<
  React.ComponentPropsWithoutRef<typeof Calendar>['renderItem']
>[0]

interface NewCalendarProps {
  hasTwentyFourHourTimeFormat: boolean
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  isViewOnly: boolean
  selectedChannelIds: string[] | void
  postsQuery: PostForCalendarHookResponse
}

export function NewCalendar({
  hasTwentyFourHourTimeFormat,
  setOpenModal,
  isViewOnly: viewOnly = false,
  selectedChannelIds,
  postsQuery,
}: NewCalendarProps): JSX.Element {
  const timezone = useTimezone()
  const weekStartsOn = useSelector(selectWeekStartsMonday) ? 1 : 0
  const { viewMode } = useCalendar({ timezone, weekStartsOn })

  const { data, loading } = postsQuery
  const posts = useMemo(
    () => data?.posts.edges?.map((edge) => edge.node) || [],
    [data],
  )
  const items = useMemo(() => buildItemsFromPosts(posts), [posts])

  const renderItem = useCallback(
    ({ id, timestamp }: RenderItemsParams) => {
      const postIndex = posts.findIndex((post) => post?.id === id)
      const post = posts[postIndex]
      if (!post) return <></>
      return (
        <PostItem
          post={post}
          timestamp={timestamp}
          viewMode={viewMode}
          viewOnly={viewOnly}
          timezone={timezone}
          setOpenModal={setOpenModal}
        />
      )
    },
    [posts, setOpenModal, timezone, viewMode, viewOnly],
  )
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()
  const selectedTags = useSelectedTags()

  const openComposer = useCallback(
    (timestamp: number): void => {
      const channels =
        selectedChannelIds === undefined || selectedChannelIds.length === 0
          ? []
          : selectedChannelIds
      createNewPostInComposer({
        cta: viewMode === 'week' ? CTA_ADD_HOUR : CTA_ADD_DAY,
        channels,
        prefillPostData: {
          dueAt: Math.round(timestamp / 1000),
          tags: selectedTags,
        },
      })
    },
    [createNewPostInComposer, selectedChannelIds, selectedTags, viewMode],
  )

  // Track post drag events
  const trackPostDrag = usePostDraggedTracking()
  const handleDragStart = useCallback(
    (event: DragStartEvent): void => {
      if (!event.active.id) return
      const postRaw = posts?.find((p) => p.id === event.active.id)
      const post = getFragmentData(CalendarPostCard_Post, postRaw)
      if (post) trackPostDrag(post)
    },
    [posts, trackPostDrag],
  )

  const handleDragEnd = useHandleDragEnd({
    posts:
      data?.posts.edges?.map((edge) =>
        getFragmentData(CalendarPostCard_Post, edge.node),
      ) ?? [],
    preserveTime: viewMode === 'month',
    timezone,
    is24HourFormat: hasTwentyFourHourTimeFormat,
  })

  return (
    <Calendar
      timezone={timezone}
      weekStartsOn={weekStartsOn}
      is24HourFormat={hasTwentyFourHourTimeFormat}
      readOnly={viewOnly}
      items={loading && items.length === 0 ? undefined : items}
      renderItem={renderItem}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      addItemProps={{
        ...triggerAttributes,
        onClick: openComposer,
      }}
    />
  )
}

function buildItemsFromPosts(
  posts: Array<
    FragmentType<typeof CalendarPostCard_Post> & {
      dueAt?: string | null
    }
  >,
): { id: string; timestamp: number; shouldDisableDrag: boolean }[] {
  return posts
    .filter(
      (
        post,
      ): post is FragmentType<typeof CalendarPostCard_Post> & {
        dueAt: string
      } => Boolean(post?.dueAt),
    )
    .map((post) => {
      const _post = getFragmentData(CalendarPostCard_Post, post)
      return {
        id: _post.id as string,
        timestamp: new Date(post.dueAt).getTime(),
        shouldDisableDrag: PostEntity.isSent(post) || _post?.status === 'error',
      }
    })
}

/**
 * This component renders an individual post item within the calendar.
 * It handles the display of post details and integrates with the PostItemContent.
 *
 * @component
 * @param {Object} props
 * @param {FragmentType<typeof CalendarPostCard_Post>} props.post - The post data to display
 * @param {number} props.timestamp - The timestamp for the post
 * @param {'week' | 'month'} props.viewMode - The current calendar view mode
 * @param {boolean} props.viewOnly - Whether the post is in view-only mode
 * @param {string} props.timezone - The current timezone
 * @param {Function} props.setOpenModal - Function to control modal visibility
 *
 * @returns {JSX.Element | null} The rendered post item or null if no post data
 */
function PostItem({
  post,
  timestamp,
  viewMode,
  viewOnly,
  timezone,
  setOpenModal,
}: {
  post: FragmentType<typeof CalendarPostCard_Post>
  timestamp: number
  viewMode: 'week' | 'month'
  viewOnly: boolean
  timezone: string
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
}): JSX.Element | null {
  if (!post) {
    return null
  }
  const isSent = PostEntity.isSent(post)
  const day =
    Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
      timezone,
    )
  const legacyPostProps = {
    post,
    small: viewMode === 'month',
    inLastTwoRows: false, // TODO: review this
    legacyPost: post,
    scheduledDay: format(day, 'd MMMM EEEE yyyy'),
    setOpenModal,
    weekStartsOn: 1,
    canEdit: !viewOnly && !isSent,
  }
  return <PostItemContent {...legacyPostProps} />
}
