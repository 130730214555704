import { graphql } from '~publish/gql'
import { isOfType } from '~publish/helpers/typeGuards'

import { useTypedMutation } from './useTypedMutation'

export const DeletePost = graphql(/* GraphQL */ `
  mutation DeletePost($id: PostId!) {
    deletePostV2(input: { id: $id }) {
      __typename
      ... on MutationError {
        message
      }
      ... on DeletePostSuccess {
        id
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDeletePost = () =>
  useTypedMutation(DeletePost, (data) => data.deletePostV2, {
    successTypename: 'DeletePostSuccess',
    update: (cache, { data }) => {
      const result = data?.deletePostV2
      if (!isOfType(result, 'DeletePostSuccess')) {
        return
      }
      cache.gc()
    },
  })
