/**
 * Small abstraction wrapping 'react-simple-dropdown', using locally scoped
 * styles to handle showing/hiding the dropdown's contents.
 *
 * See UpdateSaver component for example usage.
 */

import React from 'react'
import PropTypes from 'prop-types'

import SimpleDropdown from './react-simple-dropdown/dropdown'
import DropdownTrigger from './react-simple-dropdown/dropdown-trigger'
import DropdownContent from './react-simple-dropdown/dropdown-content'
import styles from './css/Dropdown.module.css'

// @ts-expect-error TS(7031) FIXME: Binding element 'isDropdownExpanded' implicitly ha... Remove this comment to see the full error message
const getDropdownState = ({ isDropdownExpanded }) => ({
  isDropdownExpanded,
})

class Dropdown extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    isDropdownExpanded: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    title: PropTypes.string,
  }

  static defaultProps = {
    isDropdownExpanded: false,
    onHide: () => {},
    onShow: () => {},
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type 'Readonly<{}> & Readonly<{ childr... Remove this comment to see the full error message
  state = getDropdownState(this.props)

  // @ts-expect-error TS(7006) FIXME: Parameter 'nextProps' implicitly has an 'any' type... Remove this comment to see the full error message
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'isDropdownExpanded' does not exist on ty... Remove this comment to see the full error message
    if (this.props.isDropdownExpanded !== nextProps.isDropdownExpanded) {
      this.setState({
        isDropdownExpanded: nextProps.isDropdownExpanded,
      })
    }
  }

  onShow = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.disabled) return

    this.expandDropdown()
    // @ts-expect-error TS(2339) FIXME: Property 'onShow' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.onShow()
  }

  onHide = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.disabled) return

    this.collapseDropdown()
    // @ts-expect-error TS(2339) FIXME: Property 'onHide' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.onHide()
  }

  expandDropdown = () => this.setState({ isDropdownExpanded: true })
  collapseDropdown = () => this.setState({ isDropdownExpanded: false })

  render() {
    const isDropdownContentVisible =
      // @ts-expect-error TS(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      this.state.isDropdownExpanded && !this.props.disabled

    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const dropdownClassName = [styles.dropdown, this.props.className].join(' ')

    // Attach some default styles to DropdownTrigger and DropdownContent
    // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Re... Remove this comment to see the full error message
    const children = React.Children.map(this.props.children, (child) => {
      if (
        child.type.displayName !== 'DropdownTrigger' &&
        child.type.displayName !== 'DropdownContent'
      )
        return child

      let defaultClassName

      if (child.type.displayName === 'DropdownTrigger') {
        defaultClassName = styles.dropdownTrigger
      } else {
        defaultClassName = isDropdownContentVisible
          ? styles.visibleDropdownContent
          : styles.dropdownContent
      }

      const childClassName = [defaultClassName, child.props.className].join(' ')

      return React.cloneElement(child, {
        className: childClassName,
      })
    })

    return (
      <SimpleDropdown
        className={dropdownClassName}
        onShow={this.onShow}
        onHide={this.onHide}
        active={isDropdownContentVisible}
        // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
        title={this.props.title}
      >
        {children}
      </SimpleDropdown>
    )
  }
}

export { DropdownTrigger, DropdownContent }
export default Dropdown
