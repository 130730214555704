import React from 'react'
import type { PublishingPost, PostStatistics } from '~publish/gql/graphql'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import { CalendarPostHoveredFooter } from '~publish/pages/Calendar/CalendarPostHoverPreview/CalendarPostHoveredFooter'

import { PostPreviewActions } from '../Actions/PostPreviewActions'
import Analytics from '../Analytics'

import { PostPreviewFooter } from './styles'

type Props = {
  post: PublishingPost
  isSent: boolean
  isRetweet: boolean
  statistics?: PostStatistics
  channelName: string
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
}

export const Footer = ({
  post,
  isSent,
  isRetweet,
  statistics,
  channelName,
  setOpenModal,
}: Props): JSX.Element => {
  const isRepin = PostEntity.isRepin(post)
  const shouldShowAnalytics = isSent && !isRetweet && !isRepin && statistics
  const [newCalendar] = usePublishRevamp()
  if (!newCalendar) {
    return (
      <>
        {shouldShowAnalytics && (
          <PostPreviewFooter>
            <Analytics statistics={statistics} service={channelName} />
          </PostPreviewFooter>
        )}
        <PostPreviewActions post={post} setOpenModal={setOpenModal} />
      </>
    )
  }

  return (
    <>
      {shouldShowAnalytics && (
        <PostPreviewFooter>
          <Analytics statistics={statistics} service={channelName} />
        </PostPreviewFooter>
      )}
      <CalendarPostHoveredFooter />
    </>
  )
}
