import React from 'react'
import { Text } from '@bufferapp/ui'
import { Row } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import PreferencesRow from '../PreferencesRow'
import type { TwoFactorAuthProps } from '~publish/legacy/two-factor-auth/types'
import { Switch } from '@buffer-mono/popcorn'

const TwoFactorAuth = ({
  machineState,
  isEnabled,
  method,
  phoneNumber,
  updatePhoneNumber,
  editMode,
  recoveryCode,
  transition,
  setPhoneNumber,
  submitPhoneNumber,
  loading,
  error,
  setupApp,
  qrCode,
  updateMethod,
  submitCode,
  handleRecoveryCodeSelect,
  initKey,
  email,
}: TwoFactorAuthProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Row>
      <div
        style={{
          marginRight: '1rem',
        }}
      >
        <Text type="h3">
          {t('preferences.security.twoFactorAuthentication')}
        </Text>
        <div
          style={{
            marginTop: '0.5rem',
            maxWidth: '700px',
          }}
        >
          <PreferencesRow
            // @ts-expect-error TS(2322) FIXME: Type '{ machineState: string; transition: (arg0: s... Remove this comment to see the full error message
            machineState={machineState}
            transition={transition}
            method={method}
            phoneNumber={phoneNumber}
          />
          <Modal
            machineState={machineState}
            transition={transition}
            updatePhoneNumber={updatePhoneNumber}
            setPhoneNumber={setPhoneNumber}
            submitPhoneNumber={submitPhoneNumber}
            loading={loading}
            error={error}
            setupApp={setupApp}
            qrCode={qrCode}
            updateMethod={updateMethod}
            submitCode={submitCode}
            handleRecoveryCodeSelect={handleRecoveryCodeSelect}
            editMode={editMode}
            recoveryCode={recoveryCode}
            initKey={initKey}
            email={email}
          />
        </div>
      </div>
      <div
        style={{
          paddingRight: '0.5rem',
        }}
      >
        <Switch
          checked={isEnabled}
          onCheckedChange={(): void => {
            transition(isEnabled ? 'DISABLE' : 'ENABLE')
          }}
        >
          {isEnabled ? 'Enabled' : 'Disabled'}
        </Switch>
      </div>
    </Row>
  )
}

export default TwoFactorAuth
