import React, { useMemo } from 'react'
import type { Temporal } from '@js-temporal/polyfill'

import { Day } from './Day'

import styles from './Month.module.css'

type MonthProps = {
  days: Temporal.ZonedDateTime[]
}

const Month: React.FC<MonthProps> = ({ days }) => {
  const weeks = useMemo(() => groupDaysByWeek(days), [days])

  return (
    <>
      {weeks.map((week) => (
        <tr
          key={week[0].weekOfYear}
          className={styles.row}
          data-testid={`monthly-row-${week[0].weekOfYear}`}
          style={{ '--number-weeks': weeks.length } as React.CSSProperties}
        >
          {week.map((day) => (
            <Day key={day.toString()} day={day} />
          ))}
        </tr>
      ))}
    </>
  )
}

function groupDaysByWeek(
  days: Temporal.ZonedDateTime[],
): Temporal.ZonedDateTime[][] {
  return days.reduce((acc, day, index) => {
    const weekIndex = Math.floor(index / 7)
    if (!acc[weekIndex]) {
      acc[weekIndex] = []
    }
    acc[weekIndex].push(day)
    return acc
  }, [] as Temporal.ZonedDateTime[][])
}

Month.displayName = 'Month'

const memoMonth = React.memo(Month)
export { memoMonth as Month }
