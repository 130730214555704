import React from 'react'
import styled from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Nav, NavLink } from '~publish/legacy/shared-components'
import {
  preferencesAppsExtras,
  preferencesSecurity,
  preferencesNotifications,
  preferencesGeneral,
} from '~publish/legacy/routes'
import ManageAppsAndExtras from '~publish/legacy/manage-apps-extras'
import Notifications from '~publish/legacy/account-notifications'
import Security from '../Security'
import General from '../General'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

const PreferencesWrapper = styled.div`
  flex-grow: 1;
  padding: 0 1rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const TabStyle = styled.div`
  overflow-y: auto;
  height: calc(100vh - 161px); /* 56px appshell + 57px tabs + 48px banner*/
`

const ContainerStyle = styled.div`
  max-width: 864px;
  padding-top: 16px;
  margin: 0 auto;
`

export const Preferences = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <PreferencesWrapper>
        <Nav>
          <NavLink to={preferencesGeneral.route} activeOnlyWhenExact>
            {t('preferences.menu.general')}
          </NavLink>
          <NavLink to={preferencesSecurity.route} activeOnlyWhenExact>
            {t('preferences.menu.security')}
          </NavLink>
          <NavLink to={preferencesNotifications.route} activeOnlyWhenExact>
            {t('preferences.menu.notifications')}
          </NavLink>
          <NavLink to={preferencesAppsExtras.route} activeOnlyWhenExact>
            {t('preferences.menu.appsAndExtras')}
          </NavLink>
        </Nav>
        <TabStyle>
          <ContainerStyle>
            <main id="main">
              <Switch>
                <Route path={preferencesAppsExtras.route}>
                  <ManageAppsAndExtras />
                </Route>
                <Route path={preferencesSecurity.route}>
                  <Security />
                </Route>
                <Route path={preferencesNotifications.route}>
                  <Notifications />
                </Route>
                <Route path={preferencesGeneral.route}>
                  <General />
                </Route>
                <Route
                  render={(): JSX.Element => (
                    <Redirect to={preferencesGeneral.route} />
                  )}
                />
              </Switch>
            </main>
          </ContainerStyle>
        </TabStyle>
      </PreferencesWrapper>
    </Wrapper>
  )
}
