import React from 'react'

import { Button, PlusIcon, MessageCircleHeartIcon } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { type FragmentType, getFragmentData } from '~publish/gql'
import type { GetChannelInfoQuery } from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import { PostViewToggle } from '~publish/components/PostViewToggle/PostViewToggle'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { ChannelPageTitle } from '~publish/pages/Channel'
import { AllChannelsPageTitle } from '~publish/pages/AllChannels'
import {
  FilterByChannel,
  FilterByChannel_Channel,
} from '~publish/components/FilterByChannel'
import {
  FilterByTag,
  type MaskedFilterByTagFragment,
} from '~publish/components/FilterByTag'
import { TimezoneDisplay } from '~publish/components/TimezoneDisplay'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

import { TypeFilter } from './TypeFilter/TypeFilter'
import { CalendarControls } from './CalendarControls'

import styles from './CalendarHeader.module.css'

type CalendarHeaderProps = {
  channels: readonly FragmentType<typeof FilterByChannel_Channel>[]
  isSingleChannel: boolean
  onSelectChannels: (channelIds: string[]) => void
  onSelectTags: (tagIds: string[]) => void
  selectedChannelIds?: string[]
  selectedTagIds?: string[]
  tags: MaskedFilterByTagFragment
}

export const CalendarHeader = (props: CalendarHeaderProps): JSX.Element => {
  const {
    channels,
    isSingleChannel,
    onSelectChannels,
    onSelectTags,
    selectedChannelIds = [],
    selectedTagIds,
    tags,
  } = props
  const { createNewPostInComposer, triggerAttributes } = usePostComposer()
  const [isTimezonedCalendarEnabled] = usePublishRevamp()
  const channel = isSingleChannel
    ? channels
        .map((channel) => getFragmentData(FilterByChannel_Channel, channel))
        .find((channel) => channel.id === selectedChannelIds[0])
    : undefined

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )
  const selectedTags = useSelectedTags()

  return (
    <PageLayout.Header separator={false} className={styles.layout}>
      <PageLayout.HeaderRow>
        {isSingleChannel ? (
          <ChannelPageTitle
            // TODO Review the type of channel
            channel={channel as GetChannelInfoQuery['channel']}
          />
        ) : (
          <AllChannelsPageTitle />
        )}
        <PageLayout.Actions>
          <FeedbackWidget id="all-channels-1" source="publish">
            <Button variant="tertiary" size="large">
              <MessageCircleHeartIcon />
              Share Feedback
            </Button>
          </FeedbackWidget>
          <PostViewToggle />

          <Button
            size="large"
            variant={
              isSecondaryButtonTreatmentEnabled ? 'secondary' : 'primary'
            }
            {...triggerAttributes}
            onClick={(): Promise<void> =>
              createNewPostInComposer({
                cta: 'publish-calendar-header-newPost-1',
                channels:
                  selectedChannelIds && selectedChannelIds.length
                    ? selectedChannelIds
                    : [],
                prefillPostData: {
                  tags: selectedTags,
                },
              })
            }
          >
            <PlusIcon />
            New Post
          </Button>
        </PageLayout.Actions>
      </PageLayout.HeaderRow>
      <PageLayout.HeaderRow>
        <CalendarControls />
        <PageLayout.Actions>
          <TypeFilter />
          {!isSingleChannel && (
            <FilterByChannel
              channels={channels}
              value={selectedChannelIds ?? []}
              onSelect={onSelectChannels}
            />
          )}
          <FilterByTag
            data-tour-id="tags-filter"
            tags={tags}
            value={selectedTagIds ?? []}
            onSelect={onSelectTags}
          />
          <TimezoneDisplay
            channelId={channel?.id}
            timezone={channel?.timezone}
            disabled={!isTimezonedCalendarEnabled || !isSingleChannel}
          />
        </PageLayout.Actions>
      </PageLayout.HeaderRow>
    </PageLayout.Header>
  )
}
