import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('DISCONNECTED_PROFILES_MODAL', {
  RECONNECT_PROFILE: 0,
})

export const initialState = {
  disconnectedProfiles: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        disconnectedProfiles: action.result
          // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
          .filter((profile) => profile.isDisconnected)
          // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
          .map((profile) => ({ ...profile, reconnecting: false })),
      }
    }
    case actionTypes.RECONNECT_PROFILE:
      return {
        ...state,
        disconnectedProfiles: state.disconnectedProfiles.map((profile) => ({
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...profile,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          reconnecting: profile.id === action.id,
        })),
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  reconnectProfile: ({ id, service }) => ({
    type: actionTypes.RECONNECT_PROFILE,
    id,
    service,
  }),
}

export default reducer
