import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Badge,
  DropdownMenu,
  Flex,
  Heading,
  IconButton,
  EllipsisIcon,
  PlusIcon,
  PencilIcon,
  TrashIcon,
  Text,
} from '@buffer-mono/popcorn'

import { LockedColumnMarker } from './LockedColumnMarker'
import { DeleteIdeaGroupAlertDialog } from './DeleteIdeaGroupAlertDialog'
import { ColumnNameForm } from './ColumnNameForm'

import styles from './ColumnHeader.module.css'

export const ColumnHeader = ({
  name,
  count,
  editable = true,
  locked = false,
  onCreateIdea,
  onNameChange,
  onDelete,
}: {
  name: string
  count: number
  editable?: boolean
  locked?: boolean
  onCreateIdea: () => void
  onNameChange: (name: string) => void | Promise<void>
  onDelete: () => void
}): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const canEditHeader = editable && !locked

  const handleTriggerRename = (): void => {
    if (canEditHeader) {
      setIsEditing(true)
    }
  }

  const handleFormSubmit = async (newName: string): Promise<void> => {
    setIsEditing(false)
    onNameChange(newName)
  }

  return (
    <header
      className={clsx(
        styles.header,
        editable && styles.editable,
        isEditing && styles.editing,
        locked && styles.locked,
      )}
    >
      {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      {isEditing ? (
        <ColumnNameForm
          initialName={name}
          className={styles.form}
          onSubmit={handleFormSubmit}
          onCancel={(): void => setIsEditing(false)}
        />
      ) : (
        <div
          className={clsx(
            styles.content,
            canEditHeader && styles.contentEditable,
          )}
          onClick={handleTriggerRename}
        >
          <Heading as="h3" size="small" data-testid="board-column-name">
            {name}
          </Heading>
          <Badge variant={locked ? 'neutral' : 'light'}>{count}</Badge>
        </div>
      )}
      {/* eslint-enable */}

      <div className={styles.actions}>
        {!locked ? (
          <IconButton
            label="Add new idea"
            variant="tertiary"
            onClick={onCreateIdea}
            tooltip="Add new idea"
          >
            <PlusIcon />
          </IconButton>
        ) : (
          <LockedColumnMarker />
        )}

        {editable && (
          <DropdownMenu
            modal={false}
            trigger={
              <IconButton label="More" variant="tertiary">
                <EllipsisIcon />
              </IconButton>
            }
          >
            {!locked && (
              <>
                <DropdownMenu.Item onSelect={handleTriggerRename}>
                  <PencilIcon />
                  Rename
                </DropdownMenu.Item>
                <DropdownMenu.Separator />
              </>
            )}
            <DeleteIdeaGroupAlertDialog
              name={name}
              ideasCount={count}
              onDelete={onDelete}
            >
              <DropdownMenu.Item
                variant="critical"
                // Needed for item to be able to open dialog
                onSelect={(e): void => e.preventDefault()}
              >
                <TrashIcon />
                <Flex direction="column" gap="space-50">
                  Delete
                  <Text as="small" color="subtle">
                    Delete group and move ideas to &quot;Unassigned&quot;
                  </Text>
                </Flex>
              </DropdownMenu.Item>
            </DeleteIdeaGroupAlertDialog>
          </DropdownMenu>
        )}
      </div>
    </header>
  )
}
