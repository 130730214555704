import { Temporal } from '@js-temporal/polyfill'
import type {
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragCancelEvent,
} from '@dnd-kit/core'

import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from '~publish/helpers/temporal'

import type { CalendarItem } from './types'

/**
 * Generates an array of days within a given date range.
 *
 * @param {Object} dateRange - The start and end dates of the range.
 * @param {Temporal.ZonedDateTime} dateRange.start - The start date of the range.
 * @param {Temporal.ZonedDateTime} dateRange.end - The end date of the range.
 * @returns {Temporal.ZonedDateTime[]} Array of days in the date range.
 */
export const getDaysInRange = (dateRange: {
  start: Temporal.ZonedDateTime
  end: Temporal.ZonedDateTime
}): Temporal.ZonedDateTime[] => {
  const days: Temporal.ZonedDateTime[] = []
  let day = dateRange.start
  while (Temporal.ZonedDateTime.compare(day, dateRange.end) <= 0) {
    days.push(day)
    day = day.add({ days: 1 })
  }
  return days
}

/**
 * Generates an array of hours within a given date range.
 *
 * @param {Object} dateRange - The start and end dates of the range.
 * @param {Temporal.ZonedDateTime} dateRange.start - The start date of the range.
 * @param {Temporal.ZonedDateTime} dateRange.end - The end date of the range.
 * @returns {Temporal.ZonedDateTime[]} Array of hours in the date range.
 */
export const getHoursInRange = (dateRange: {
  start: Temporal.ZonedDateTime
  end: Temporal.ZonedDateTime
}): Temporal.ZonedDateTime[] => {
  const hours: Temporal.ZonedDateTime[] = []
  let hour = dateRange.start.startOfDay()
  const endDateTime = dateRange.end.add({ days: 1 }).startOfDay()

  while (Temporal.ZonedDateTime.compare(hour, endDateTime) < 0) {
    hours.push(hour)
    hour = hour.add({ hours: 1 })
  }

  return hours
}

/**
 * Calculates the start and end dates of a date range based on the current date, view mode, and week start day.
 *
 * @param {Temporal.ZonedDateTime} currentDate - The current date to center the range around.
 * @param {ViewMode} viewMode - The current view mode ('week' or 'month').
 * @param {0 | 1} weekStart - The day to start the week on (0 for Sunday, 1 for Monday).
 * @returns {{start: Temporal.ZonedDateTime, end: Temporal.ZonedDateTime}} An object containing the start and end dates of the range.
 */
export const getDateRange = (
  currentDate: Temporal.ZonedDateTime,
  viewMode: 'week' | 'month',
  weekStart: 0 | 1,
): { start: Temporal.ZonedDateTime; end: Temporal.ZonedDateTime } => {
  let start: Temporal.ZonedDateTime
  let end: Temporal.ZonedDateTime

  if (viewMode === 'week') {
    start = startOfWeek(currentDate, { weekStartsOn: weekStart })
    end = endOfWeek(currentDate, { weekStartsOn: weekStart })
  } else {
    // month view
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(currentDate)
    // Get the start of the week containing the first day of the month
    start = startOfWeek(monthStart, { weekStartsOn: weekStart })
    // Get the end of the week containing the last day of the month
    end = endOfWeek(monthEnd, { weekStartsOn: weekStart })
  }

  return { start, end }
}

/**
 * Generates announcement functions for drag and drop operations in a the calendar.
 *
 * @param {Object} options - The options for generating announcements.
 * @param {string} options.timezone - The timezone to use for formatting dates and times.
 * @param {boolean} options.is24HourFormat - Whether to use 24-hour time format.
 * @param {'week' | 'month'} options.viewMode - The current view mode of the calendar.
 * @param {CalendarItem[]} [options.items=[]] - An array of calendar items.
 *
 * @returns {Object} An object containing announcement functions for different drag and drop events.
 * @property {function} onDragStart - Announces when an item starts being dragged.
 * @property {function} onDragOver - Announces when a dragged item is over a droppable area.
 * @property {function} onDragEnd - Announces when a drag operation ends.
 * @property {function} onDragCancel - Announces when a drag operation is cancelled.
 */
export function getDndKitAnnouncements({
  timezone,
  is24HourFormat,
  viewMode,
  items = [],
}: {
  timezone: string
  is24HourFormat: boolean
  viewMode: 'week' | 'month'
  items: CalendarItem[] | undefined
}): {
  onDragStart: (event: DragStartEvent) => string
  onDragOver: (event: DragOverEvent) => string
  onDragEnd: (event: DragEndEvent) => string
  onDragCancel: (event: DragCancelEvent) => string
} {
  const dateTimeFormatter = (timestamp: number): string => {
    const zonedDate =
      Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
        timezone,
      )
    return format(
      zonedDate,
      `eee, MMMM d 'at' ${is24HourFormat ? 'HH:mm' : 'h:mm a'}`,
    )
  }
  const dateFormatter = (timestamp: number): string => {
    const zonedDate =
      Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
        timezone,
      )
    return format(zonedDate, 'eee, MMMM d')
  }

  return {
    onDragStart({ active }: DragStartEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``
      return `Picked up item scheduled for ${dateTimeFormatter(
        item.timestamp,
      )}.`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item || !over?.id) return ``

      if (over) {
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} is over  ${
          viewMode === 'month'
            ? dateFormatter(over.data.current?.timestamp)
            : dateTimeFormatter(over.data.current?.timestamp)
        }.`
      }
      return `Item is no longer over a droppable area.`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``
      if (over?.data.current?.timestamp === active.data.current?.timestamp)
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} was moved to its original position.`

      if (over) {
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} was rescheduled to  ${
          viewMode === 'month'
            ? dateFormatter(over.data.current?.timestamp)
            : dateTimeFormatter(over.data.current?.timestamp)
        }.`
      }
      return `Item was dropped back in its original position.`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``

      return `Dragging was cancelled. Item scheduled for ${dateTimeFormatter(
        item.timestamp,
      )} was returned to its original position.`
    },
  }
}
