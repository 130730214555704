import React from 'react'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import DateTimePreferences from '~publish/legacy/date-time-preferences'
import LanguagePreferences from '~publish/legacy/language-preferences'
import { Text } from '@bufferapp/ui'
import { useTranslation } from 'react-i18next'

const General = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text type="h2">{t('preferences.general.title')}</Text>
      <Text type="p">{t('preferences.general.changePreferences')}</Text>
      <Divider />
      <DateTimePreferences />
      <LanguagePreferences />
    </>
  )
}

export default General
