/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import React, { forwardRef, type JSX, type MouseEventHandler } from 'react'
import { graphql } from '~publish/gql'
import type { IdeaCard_IdeaFragment as IdeaFragment } from '~publish/gql/graphql'

import clsx from 'clsx'

import {
  IconButton,
  TrashIcon,
  Flex,
  Tag,
  Paragraph,
  VisuallyHidden,
  Heading,
  AlertDialog,
  Button,
  toast,
} from '@buffer-mono/popcorn'

import { type Idea, type Media, MediaType } from '~publish/pages/Create/types'

import { useSanitizedAndHighlightedText } from './helpers'

import styles from './IdeaCard.module.css'
import { MediaCombinedPreview } from '~publish/components/MediaCombinedPreview'
import {
  type MediaMarkerType,
  MediaTypeMarker,
} from '~publish/components/MediaMarker'

// This fragment is exported but not used yet
// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
export const IdeaCard_Idea = graphql(/* GraphQL */ `
  fragment IdeaCard_Idea on Idea {
    id
    content {
      title
      text
      aiAssisted
      media {
        id
        url
        alt
        thumbnailUrl
        type
        size
        source {
          name
          id
        }
      }
      tags {
        id
        name
        color
      }
    }
    organizationId
    groupId
    position
    createdAt
    updatedAt
  }
`)

export type IdeaCardProps = {
  idea: Idea | IdeaFragment
  variant?: 'default' | 'compact'
  // TODO: we should just pass id instead of the whole entity
  // for nowm keeping idea object for backward compatibility
  // after usage is refactored, we can remove update it
  onDelete?: (idea: Idea | IdeaFragment) => Promise<void>
  onOpen?: (idea: Idea | IdeaFragment) => void
}

const IdeaCard = forwardRef<HTMLDivElement, IdeaCardProps>(
  ({ variant = 'default', idea, onDelete, onOpen }, forwardedRef) => {
    const tags = idea?.content?.tags ?? []
    const hasTags = tags.length > 0

    const media = idea?.content?.media ?? []
    const hasMedia = media.length > 0

    const handleOpen: MouseEventHandler = (e): void => {
      e.stopPropagation()
      onOpen?.(idea)
    }

    const handleDelete = React.useCallback(async (): Promise<void> => {
      await onDelete?.(idea)
    }, [idea, onDelete])

    return (
      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
      <article
        ref={forwardedRef}
        data-testid="idea-card"
        data-idea-id={idea.id}
        data-idea-position={idea.position}
        className={clsx(styles.card, styles[variant])}
        onClick={handleOpen}
      >
        {hasMedia && (
          <div
            className={styles.mediaContainer}
            data-media-count={media.length}
          >
            <MediaCombinedPreview>
              {media.map((media) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error null is not assignable to type
                <IdeaMedia key={media.id} media={media} />
              ))}
            </MediaCombinedPreview>
          </div>
        )}

        <div className={styles.content}>
          {idea.content.title && (
            <Heading as="h4" size="small" className={styles.title}>
              {idea.content.title}
            </Heading>
          )}
          {idea.content.text && <SanitizedIdeaText text={idea.content.text} />}
          {hasTags ? (
            <Flex wrap="wrap" gap="space-100" className={styles.tags}>
              {tags.map((tag) => (
                <Tag key={tag.id} color={tag.color}>
                  {tag.name}
                </Tag>
              ))}
            </Flex>
          ) : null}
        </div>

        {/* visually hidden button to open idea via tab */}
        <VisuallyHidden
          as="button"
          className={styles.openButton}
          onClick={handleOpen}
        >
          Open idea
        </VisuallyHidden>

        <DeleteIdeaIconButton onDelete={handleDelete} />
      </article>
      /* eslint-enable */
    )
  },
)

IdeaCard.displayName = 'IdeaCard'

type SanitizedIdeaTextProps = {
  text?: string | null
}

const SanitizedIdeaText = ({
  text,
}: SanitizedIdeaTextProps): JSX.Element | null => {
  const htmlText = useSanitizedAndHighlightedText(text)

  if (!text) {
    return null
  }

  return (
    <Paragraph
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  )
}

type IdeaMediaProps = {
  media: Media | NonNullable<Idea['content']['media']>[number]
}

const ideaTypeToMarker: Partial<Record<MediaType, MediaMarkerType>> = {
  [MediaType.video]: 'video',
  [MediaType.gif]: 'gif',
  [MediaType.document]: 'pdf',
}

const IdeaMedia = ({ media }: IdeaMediaProps): JSX.Element | null => {
  // TODO: API should support resizing, this is a temporary solution
  // to alleviate performance concerns
  const optimizedThumbnail = `https://safeimage.buffer.com/500,fit/${
    media.thumbnailUrl || media.url
  }`

  const markerType = ideaTypeToMarker[media.type]

  return (
    <div className={styles.mediaWrapper}>
      <img
        src={optimizedThumbnail}
        alt={media.alt ?? 'Media attachment'}
        className={styles.media}
        loading="lazy"
      />
      {markerType && (
        <MediaTypeMarker
          className={styles.mediaTypeIndicator}
          type={markerType}
        />
      )}
    </div>
  )
}

const DeleteIdeaIconButton = ({
  onDelete,
}: {
  onDelete: () => Promise<void>
}): JSX.Element => {
  const [opened, setOpened] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handlePropagation = (e: React.MouseEvent): void => {
    // Stop propagation so the idea does not get opened
    e.stopPropagation()
  }

  const handleDelete = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()
    setIsDeleting(true)

    // TODO: move out global toasts display out of this component
    try {
      await onDelete()
      toast.success('The idea was deleted!')
    } catch (error) {
      toast.error('The idea could not be deleted. Please try again!')
    }

    setIsDeleting(false)
    setOpened(false)
  }

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialog.Trigger>
        <IconButton
          variant="critical"
          label="Delete idea"
          tooltip="Delete idea"
          disabled={isDeleting}
          className={styles.deleteButton}
          onClick={handlePropagation}
        >
          <TrashIcon />
        </IconButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content onClick={handlePropagation}>
        <AlertDialog.Title>Delete Idea?</AlertDialog.Title>
        <AlertDialog.Description>
          This idea will be permanently deleted. This action cannot be undone.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary" onClick={handlePropagation}>
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

DeleteIdeaIconButton.displayName = 'DeleteIdeaIconButton'

export type IdeaCardSkeletonProps = {
  variant: 1 | 2 | 3 | 4
}

const IdeaCardSkeleton = React.forwardRef<
  React.ElementRef<'div'>,
  IdeaCardSkeletonProps
>(({ variant = 1 }, ref): JSX.Element => {
  return (
    <article
      className={clsx(styles.skeleton, styles[`variant${variant}`])}
      aria-hidden
      ref={ref}
    >
      <div className={styles.media} />
    </article>
  )
})

IdeaCardSkeleton.displayName = 'IdeaCard.Skeleton'

const IdeaCardObject = Object.assign(IdeaCard, {
  Skeleton: IdeaCardSkeleton,
})

export { IdeaCardObject as IdeaCard }
