import React, { useEffect, useState } from 'react'

import type { Draft } from '../../composer/composer/entities/Draft'
import ComposerActionCreators from '../../composer/composer/action-creators/ComposerActionCreators'
import {
  ActionButtonMessage,
  SplitBarButtonLinkShortener,
  StyledTooltipLinkShortener,
} from '../styles'

import * as Tooltip from '@radix-ui/react-tooltip'

import { LinkShortenerIcon } from './LinkShortenerIcon'
import { trackLinkShortenerToggleClick } from '../tracking'
import { useAppDispatch } from '~publish/legacy/store'

// @ts-ignore
import { actions as asyncDataFetch } from '@buffer-mono/async-data-fetch'

const NO_SHORTENING_OPTION = 'No Shortening'
const DEFAULT_SHORTENER = 'buff.ly'

export const LinkShortenerButton = ({
  draft,
  selectedProfileId,
  domainForLinkShorteningSelectedProfile,
}: {
  draft: Draft
  selectedProfileId: string
  domainForLinkShorteningSelectedProfile: string
}): React.JSX.Element | null => {
  const [showMessage, setShowMessage] = useState(false)
  const [showingMessageInProgress, setShowingMessageInProgress] =
    useState(false)
  const hasBeenRendered = React.useRef(false)
  const dispatch = useAppDispatch()

  function changeLinkShortenerInDatabase({
    selectedProfileId,
    domain,
  }: {
    selectedProfileId: string
    domain: string
  }): void {
    dispatch(
      asyncDataFetch.fetch({
        name: 'changeLinkShortener',
        args: {
          profileId: selectedProfileId,
          domain,
        },
      }),
    )
  }

  useEffect(() => {
    function hasSelectedProfileEnabledShortening(): boolean {
      return domainForLinkShorteningSelectedProfile !== NO_SHORTENING_OPTION
    }

    const isFirstRender = !hasBeenRendered.current

    if (isFirstRender) {
      hasBeenRendered.current = true

      ComposerActionCreators.updateShouldShortenLinks(
        draft.id,
        hasSelectedProfileEnabledShortening(),
      )
    } else {
      changeLinkShortenerInDatabase({
        selectedProfileId,
        domain: draft.shortenLinksToggle
          ? DEFAULT_SHORTENER
          : NO_SHORTENING_OPTION,
      })
    }
  }, [])

  const onToggleLinkShortener = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.preventDefault()

    const newStatusShortenLinks = !draft.shortenLinksToggle

    ComposerActionCreators.updateShouldShortenLinks(
      draft.id,
      newStatusShortenLinks,
    )

    changeLinkShortenerInDatabase({
      selectedProfileId,
      domain: newStatusShortenLinks ? DEFAULT_SHORTENER : NO_SHORTENING_OPTION,
    })

    setShowMessage(true)
    setShowingMessageInProgress(true)

    setTimeout(() => {
      setShowMessage(false) // Hide the message after a delay
      setShowingMessageInProgress(false)

      // track the event if the toggle is turned on/off
      const isShortened = draft.shortenLinksToggle
      trackLinkShortenerToggleClick(isShortened)
    }, 1500)
  }

  const shouldDisplayLinkShortenerButton =
    !draft.isStoryPost() && !draft.service.isOmni

  if (!shouldDisplayLinkShortenerButton) return null

  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger asChild>
        <SplitBarButtonLinkShortener
          className="link-shortening-icon"
          data-testid="link-shortening-icon"
          onClick={onToggleLinkShortener}
          data-tip={
            'Link Shortening is ' + (draft.shortenLinksToggle ? 'on' : 'off')
          }
          aria-label="link shortening"
          enabled={draft.shortenLinksToggle}
          showMessage={showMessage}
        >
          <LinkShortenerIcon isDisabled={!draft.shortenLinksToggle} />
          <ActionButtonMessage shouldShow={showMessage}>
            {'Shortening ' + (draft.shortenLinksToggle ? 'on' : 'off')}{' '}
          </ActionButtonMessage>
        </SplitBarButtonLinkShortener>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        {!showingMessageInProgress && (
          <StyledTooltipLinkShortener data-state="instant-open" sideOffset={5}>
            {'Link shortening ' + (draft.shortenLinksToggle ? 'on' : 'off')}
          </StyledTooltipLinkShortener>
        )}
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
