import { useCallback } from 'react'

import { useTypedMutation } from '~publish/components/PostCard/useTypedMutation'
import { graphql } from '~publish/gql'
import type { UpdateCalendarPostDueAtMutation } from '~publish/gql/graphql'

export const UpdateCalendarPostDueAt = graphql(/* GraphQL */ `
  mutation UpdateCalendarPostDueAt($input: DropPostInput!) {
    dropPost(input: $input) {
      __typename
      ... on PostActionSuccess {
        post {
          id
          dueAt
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export type SuccessData = {
  id: string
  dueAt?: string | null | undefined
}

export const getSuccessFragmentData = (
  data: UpdateCalendarPostDueAtMutation | null | undefined,
): SuccessData | undefined | null => {
  const response = data?.dropPost
  const typename = response?.__typename
  if (typename !== 'PostActionSuccess') return null
  return response?.post
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useUpdatePostDueAt = () => {
  const [mutate, { loading, error, success, data }] = useTypedMutation(
    UpdateCalendarPostDueAt,
    (data) => data.dropPost,
    {
      successTypename: 'PostActionSuccess',
    },
  )

  const updatePostDueAt = useCallback(
    async ({
      id,
      dueAt,
      isPinned,
    }: {
      id: string
      dueAt: string
      isPinned: boolean
    }) => {
      const result = await mutate({
        variables: {
          input: {
            id,
            dueAt,
            isPinned,
          },
        },
        optimisticResponse: {
          dropPost: {
            __typename: 'PostActionSuccess',
            post: {
              __typename: 'Post',
              id,
              dueAt,
            },
          },
        },
      })

      if (!result.success) {
        throw new Error(result.error.message)
      }

      return result.data.post
    },
    [mutate],
  )

  return {
    updatePostDueAt,
    loading,
    error,
    data: success ? data : null,
  }
}
