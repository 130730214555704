import { toTemporalInstant } from '@js-temporal/polyfill'
import { useCallback } from 'react'
import { set, getDate, getMonth, getYear } from 'date-fns'
import type { DragEndEvent } from '@dnd-kit/core'
import { toast } from '@buffer-mono/popcorn'

import type { CalendarPostCard_PostFragment } from '~publish/gql/graphql'
import { format } from '~publish/helpers/temporal'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

import { useUpdatePostDueAt } from './useUpdatePostDueAt'

type UseHandleDragEndOptions = {
  posts: CalendarPostCard_PostFragment[]
  preserveTime: boolean
  timezone: string
  is24HourFormat: boolean
}

/**
 * A custom hook that returns a function to handle the end of a drag event for calendar posts.
 *
 * @param {Object} options - The options for configuring the drag end behavior.
 * @param {Array<FragmentType<typeof CalendarPostCard_Post>>} options.posts - An array of post objects, each containing fragment data from CalendarPostCard_Post.
 * @param {boolean} options.preserveTime - Determines whether to preserve the original time when rescheduling. If true, only the date will change; if false, both date and time will be updated.
 * @param {string} options.timezone - The timezone to use for date calculations and formatting.
 * @param {boolean} options.is24HourFormat - Whether to use 24-hour time format (true) or 12-hour format (false) for time display.
 *
 * @returns {(result: DragEndEvent) => void} A function that handles the end of a drag event.
 *   This function updates the due date of a post when it's dragged to a new position in the calendar.
 *
 * @example
 * const handleDragEnd = useHandleDragEnd({
 *   posts: data?.posts.edges?.map((edge) => getFragmentData(CalendarPostCard_Post, edge.node)) ?? [],
 *   preserveTime: viewMode === 'month',
 *   timezone,
 *   is24HourFormat: hasTwentyFourHourTimeFormat,
 * });
 *
 * // Later in your component:
 * <Calendar
 *   // ... other props
 *   onDragEnd={handleDragEnd}
 * />
 */
export const useHandleDragEnd = ({
  posts,
  preserveTime,
  timezone = getCurrentTimeZone(),
  is24HourFormat = true,
}: UseHandleDragEndOptions): ((result: DragEndEvent) => void) => {
  const { updatePostDueAt } = useUpdatePostDueAt()

  const handleDragEnd = useCallback(
    async ({ active, over }: DragEndEvent): Promise<void> => {
      if (
        !over ||
        !active ||
        active.data.current?.timestamp === over.data.current?.timestamp
      ) {
        return
      }

      const post = posts.find((post) => post.id === active.id.toString())
      if (!post || !post.dueAt) return

      const dueAt = getNewTimestamp(
        post.dueAt,
        over.data.current?.timestamp,
        preserveTime,
      )

      try {
        await updatePostDueAt({ id: post.id, dueAt, isPinned: false })
        toast.success(getSuccessMessage(dueAt, timezone, post, is24HourFormat))
      } catch (error) {
        toast.error(getErrorMessage(error))
      }
    },
    [posts, preserveTime, updatePostDueAt, timezone, is24HourFormat],
  )

  return handleDragEnd
}

function getNewTimestamp(
  previousDueAt: string,
  toDateMilliseconds: number,
  preserveTime: boolean,
): string {
  const fromDate = new Date(previousDueAt)
  const toDate = new Date(toDateMilliseconds)
  const newDate = preserveTime
    ? set(fromDate, {
        date: getDate(toDate),
        month: getMonth(toDate),
        year: getYear(toDate),
      })
    : toDate

  return newDate.toISOString()
}

function getErrorMessage(error: unknown): string {
  const maybeErrorMessage = (error as Error).message
  const fallbackErrorMessage =
    'Whoops, we had some problems updating the date for that post!'
  const message = maybeErrorMessage ?? fallbackErrorMessage
  return message
}

function getSuccessMessage(
  dueAt: string,
  timezone: string,
  post: CalendarPostCard_PostFragment,
  is24HourFormat: boolean,
): string {
  const date = new Date(dueAt)
  const instant = toTemporalInstant.call(date)
  const timeZonedDate = instant.toZonedDateTimeISO(timezone)
  const type = ['draft', 'needs_approval'].includes(post.status)
    ? 'draft'
    : 'post'
  const formattedTime = format(
    timeZonedDate,
    `eee, MMMM d 'at' ${is24HourFormat ? 'HH:mm' : 'h:mm a'}`,
  )
  const message = `Great! We’ve rescheduled your ${type} for ${formattedTime}`
  return message
}
