import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RefreshIcon from '@bufferapp/ui/Icon/Icons/Refresh'
import { blue } from '@bufferapp/ui/style/colors'
import { Tooltip } from '@bufferapp/ui'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  getCurrentPage,
  getSegmentChannelMetadata,
} from '~publish/legacy/composer/composer/utils/TrackingUtils'
import styles from './css/BoardSelector.module.css'
import BoardSelectorBoardItem from './boards/BoardSelectorBoardItem/BoardSelectorBoardItem'
import BoardCreator from './BoardCreator'
import Input from './Input'
import { visibleNotificationsPropType } from './ComposerPropTypes'
import AppActionCreators from '../action-creators/AppActionCreators'

const SearchBoardContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`

const RefreshIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RefreshIconStyled = styled(RefreshIcon)`
  margin-left: 8px;
  :hover {
    cursor: pointer;
    color: ${blue};
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

// @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
const onClick = (e) => {
  e.stopPropagation()
}

const BoardSelector = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
  profile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subprofiles' implicitly has an 'a... Remove this comment to see the full error message
  subprofiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canUnselectSubprofiles' implicitl... Remove this comment to see the full error message
  canUnselectSubprofiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
  onChange,
  // @ts-expect-error TS(7031) FIXME: Binding element 'visibleNotifications' implicitly ... Remove this comment to see the full error message
  visibleNotifications,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subprofilesCount' implicitly has ... Remove this comment to see the full error message
  subprofilesCount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'organizationId' implicitly has an... Remove this comment to see the full error message
  organizationId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDropdownItem' implicitly has an... Remove this comment to see the full error message
  isDropdownItem,
  needsTriangle = false,
}) => {
  const subprofilesContainer = useRef(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [refreshing, setRefreshing] = useState(false)

  useEffect(() => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    subprofilesContainer.current.scrollTop =
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      subprofilesContainer.current.scrollHeight
  }, [subprofilesCount && !profile.subprofilesOrignatedFromAPI])

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const refreshBoards = async () => {
    setRefreshing(true)
    await AppActionCreators.refreshSubprofileData(profile.id)
    setRefreshing(false)

    BufferTracker.refreshPinterestBoardsButtonClicked({
      clientName: 'publishWeb',
      product: 'publish',
      channelId: profile.id,
      organizationId,
    })
  }

  const trackChannelSelected = () => {
    const metadata = getSegmentChannelMetadata({
      profile,
      organizationId,
      page: getCurrentPage(),
    })
    BufferTracker.channelSelected(metadata)
  }

  const searchQueryLowerCase = searchQuery.toLowerCase()
  const boards = subprofiles.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'board' implicitly has an 'any' type.
    (board) =>
      board.name && board.name.toLowerCase().includes(searchQueryLowerCase),
  )

  const searchBoardIconClassName = [
    styles.searchBoardIcon,
    'bi bi-search',
  ].join(' ')

  const loadingIconClassName = [
    styles.searchBoardIcon,
    styles.loadingIcon,
    styles.rotate,
    'bi bi-refresh',
  ].join(' ')

  const hasBoardsDisplayed = boards.length > 0
  const profileHasBoards = subprofiles.length > 0

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={styles.boardSelector} onClick={onClick}>
      {needsTriangle && <div className={styles.boardTriangle}></div>}

      <SearchBoardContainer>
        <div className={styles.boardSearchContainer}>
          {refreshing ? (
            <i className={loadingIconClassName} />
          ) : (
            <i className={searchBoardIconClassName} />
          )}
          <Input
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            type="text"
            placeholder="Search your boards"
            className={styles.boardSearchInput}
            value={searchQuery}
            onChange={onSearchChange}
          />
        </div>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
        <Tooltip
          label={refreshing ? 'Refreshing boards...' : 'Refresh boards'}
          position="top"
        >
          <RefreshIconWrapper>
            <RefreshIconStyled
              size="medium"
              onClick={refreshBoards}
              disabled={refreshing}
            />
          </RefreshIconWrapper>
        </Tooltip>
      </SearchBoardContainer>
      <div className={styles.subprofilesContainer} ref={subprofilesContainer}>
        {[...boards].map((board) => (
          <>
            {!isDropdownItem && (
              <BoardSelectorBoardItem
                profile={profile}
                board={board}
                onChange={onChange}
                canUnselectSubprofiles={canUnselectSubprofiles}
                trackChannelSelected={trackChannelSelected}
                key={board.id}
              />
            )}
            {/* Below is needed for the dropdown to work after we implemented */}
            {/* scrollbar in the composer. When we decide in the future that */}
            {/* Composer should no longer be in the modal, we can remove */}
            {/* isDropdownItem prop and below conditional */}
            {isDropdownItem && (
              <DropdownMenu.Item key={board.id}>
                <BoardSelectorBoardItem
                  profile={profile}
                  board={board}
                  onChange={onChange}
                  canUnselectSubprofiles={canUnselectSubprofiles}
                  trackChannelSelected={trackChannelSelected}
                />
              </DropdownMenu.Item>
            )}
          </>
        ))}
      </div>

      {!hasBoardsDisplayed && (
        <div className={styles.emptySearchResults}>
          {profileHasBoards
            ? 'Sorry, no boards matched your search!'
            : `It looks like you don't have any boards associated with this profile. You can create your first board below.`}
        </div>
      )}

      <BoardCreator
        profile={profile}
        visibleNotifications={visibleNotifications}
      />
    </div>
  )
}

BoardSelector.propTypes = {
  subprofilesCount: PropTypes.number,
  organizationId: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectedSubprofileId: PropTypes.string.isRequired,
    subprofilesOrignatedFromAPI: PropTypes.bool,
  }).isRequired,
  subprofiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  visibleNotifications: visibleNotificationsPropType.isRequired,
  canUnselectSubprofiles: PropTypes.bool,
  onChange: PropTypes.func,
  isDropdownItem: PropTypes.bool,
  needsTriangle: PropTypes.bool,
}

BoardSelector.defaultProps = {
  subprofilesCount: 0,
  canUnselectSubprofiles: true,
  onChange: () => {},
  isDropdownItem: false,
  needsTriangle: false,
}

export default BoardSelector
