import { actionTypes } from '@buffer-mono/async-data-fetch'

// @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
export default () => (next) => (action) => {
  next(action)
  if (
    action.type.endsWith(actionTypes.FETCH_FAIL) &&
    action.error.includes(
      'Buffer is under maintenance, please, try again soon',
    ) &&
    action.error.includes('503')
  ) {
    window.location.replace('/maintenance')
  }
}
