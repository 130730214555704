import React from 'react'
import {
  Button,
  Dialog,
  Flex,
  Text,
  Heading,
  Image,
} from '@buffer-mono/popcorn'

import styles from './ChannelTour.module.css'

export const DialogTourIntro = ({
  open,
  onDialogClose,
  onTourStart,
}: {
  open: boolean
  onDialogClose: () => void
  onTourStart: () => void
}): JSX.Element => {
  const handleTourStart = (): void => {
    onTourStart()
  }

  const handleInteractOutside = (event: Event): void => {
    event.preventDefault()
  }

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <Dialog.Content
        size="medium"
        className={styles.dialogContent}
        onInteractOutside={handleInteractOutside}
      >
        <Dialog.Body className={styles.dialogBody}>
          <Flex direction="column" align="center" gap="space-200">
            <Image
              src="https://buffer-publish.s3.amazonaws.com/images/channels-tour-dialog.png"
              alt="New look"
            />
            <Heading as="h2">Welcome to a more modern Buffer </Heading>
            <Text align="center">
              We&apos;re continuing to make Buffer a flexible tool with our most
              recent changes.
            </Text>
            <Flex direction="column" align="center" gap="space-100">
              <Button variant="primary" size="large" onClick={handleTourStart}>
                See What&apos;s New
              </Button>
            </Flex>
          </Flex>
        </Dialog.Body>
        <Dialog.CloseButton className={styles.dialogCloseButton} />
      </Dialog.Content>
    </Dialog>
  )
}
