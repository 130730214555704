import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAccount } from '~publish/legacy/accountContext'

const TiktokImagesBanner = (): JSX.Element | null => {
  const { t } = useTranslation()
  const [dismissed, setDismissed] = useState(false)
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )
  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.tiktokImagesPromo,
  })

  const onTiktokConnectClick = (): void => {
    BufferTracker.cTAClicked({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      cta: `publish-queue-banner-connectTiktok-1`,
      upgradePathName: 'accountChannels-upgrade',
      ...commonTrackingProperties,
    })
    window.appshell.actions.connectChannel({
      selectedService: 'tiktok',
      cta: `publish-queue-banner-connectTiktok-1`,
    })
  }

  useEffect(() => {
    BufferTracker.cTAViewed({
      organizationId,
      product: 'publish',
      cta: `publish-queue-banner-connectTiktok-1`,
      upgradePathName: 'accountChannels-upgrade',
      ...commonTrackingProperties,
    })
  }, [commonTrackingProperties, organizationId])

  const onDismiss = (): void => {
    BufferTracker.bannerDismissed({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: 'tiktokImagesPromoBanner',
    })
    onDismissBanner()
    setDismissed(true)
  }

  if (dismissed) return null

  return (
    <PromotionalBanner onDismiss={onDismiss}>
      <PromotionalBanner.Content>
        <PromotionalBanner.Heading>
          {t('tiktokImages.headline')}
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          {t('tiktokImages.description')}
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button variant="primary" onClick={onTiktokConnectClick}>
            {t('tiktokImages.primaryLinkText')}
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/tiktok-images-promo.png" />
    </PromotionalBanner>
  )
}

export { TiktokImagesBanner }
