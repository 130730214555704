import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useAppSelector } from '~publish/legacy/store'
import { selectAllTags } from '~publish/legacy/campaign/slice'
import type { Tag } from '~publish/legacy/campaign/types'

export function useSelectedTags(queryParamName = 'tags'): Tag[] {
  const [selectedTagIds] = useQueryParam<string[]>(queryParamName)
  const allTags: Tag[] = useAppSelector(selectAllTags)

  return allTags.filter((tag) => selectedTagIds?.includes(tag.id))
}
