import styled from 'styled-components'
import { white } from '@bufferapp/ui/style/colors'
import {
  grayMedium,
  ImagesRowWrapperCss,
  ImagesWrapperCss,
  previewGrayMedium,
  WrapperBasicCss,
} from '../styles'

type WrapperProps = {
  reverse: boolean
  fullHeight: boolean
  isRowLayout: boolean
  maxHeight?: string
}

export const Wrapper = styled.div<WrapperProps>`
  ${ImagesWrapperCss};
  height: ${(props) => {
    const maxHeight = props.maxHeight || '300px'
    return props.fullHeight ? '100%' : maxHeight
  }};
  flex-direction: ${({ isRowLayout }) => (isRowLayout ? 'column' : 'row')};
  margin-top: 12px;
`

export const RowWrapper = styled.div`
  ${WrapperBasicCss};
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 3px;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`

export const ColWrapper = styled.div<{
  fullSize: boolean
  singleChild: boolean
  totalImagesInCol: number
}>`
  ${ImagesRowWrapperCss};
  width: ${(props) => (props.fullSize ? '100%' : '50%')};
  gap: 3px;

  & > div {
    height: ${({ singleChild, totalImagesInCol }) => {
      if (singleChild) {
        return '100%'
      }
      if (totalImagesInCol === 3) {
        return 'calc(33.33% - 2px)'
      }

      return 'calc(50% - 1.5px)'
    }};
  }
`

export const ImageWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  position: relative;
`

export const Image = styled.img<{
  roundedCorners?: boolean
  withBlur?: boolean
}>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  border-radius: ${(props): string =>
    props.roundedCorners ? 'var(--border-radius-md)' : '0'};
  filter: ${(props): string => (props.withBlur ? 'blur(35px)' : 'none')};
`

export const ShowMore = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${white};
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const Tag = styled.span`
  background-color: ${grayMedium};
  padding: 2px 4px;
  border-radius: 4px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  color: ${previewGrayMedium};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
`
