import { ArrowLeftIcon, Heading, IconButton } from '@buffer-mono/popcorn'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'

import { PageHeader } from '~publish/components/PageHeader'
import { PageLayout } from '~publish/components/PageLayout'
import { actions as profileSidebarActions } from '~publish/legacy/profile-sidebar/reducer'
import { selectProfileById } from '~publish/legacy/profile-sidebar/selectors'
import { actions as profileActions } from '~publish/legacy/profiles/reducer'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import ShopGrid from '~publish/legacy/grid'

/**
 * This page integarted legacy components as a temporary solution.
 * We should rewrite this page using GraphQL API and Popcorn or
 * deprecate this functionality
 */
export const ChannelShopGrid = (): JSX.Element => {
  const { id: channelId } = useParams<{
    id: string
  }>()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = React.useState(true)
  const profile = useAppSelector((state) => selectProfileById(state, channelId))

  React.useEffect(() => {
    if (loading && !profile) {
      dispatch(
        profileActions.fetchSingleProfile({
          profileId: channelId,
          message: '',
        }),
      )
    }
  }, [loading, profile, dispatch, channelId])

  React.useEffect(() => {
    if (loading && profile) {
      dispatch(profileSidebarActions.selectProfile({ profile }))
      setLoading(false)
    }
  }, [dispatch, channelId, profile, loading])

  React.useEffect(() => {
    if (profile) {
      dispatch(
        dataFetchActions.fetch({
          name: 'gridPosts',
          args: {
            profileId: channelId,
          },
        }),
      )
    }
  }, [channelId, dispatch, profile])

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.HeaderRow>
          <PageHeader.Title>
            <IconButton
              as={Link}
              to={`/channels/${channelId}`}
              label="Back to Channel"
              tooltip="Back to Channel"
              variant="tertiary"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Heading>Shop Grid</Heading>
          </PageHeader.Title>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2322) FIXME: Type '{ profileId: any; childTabId: any; }' is not... Remove this comment to see the full error message */}
        <ShopGrid profileId={channelId} />
      </PageLayout.Container>
    </PageLayout>
  )
}
