import React, { useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ToggleGroup, CalendarIcon, ListIcon } from '@buffer-mono/popcorn'
import {
  calendarWeek,
  calendarMonth,
  newCalendarSingleChannel,
  newCalendarAllChannels,
  getMatch,
  allChannels,
  channel,
} from '~publish/legacy/routes'
import { useCalendarFilters } from '~publish/pages/Calendar/hooks/useCalendarFilters'
import { useLocalStorageState } from '~publish/hooks/useLocalStorageState'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

import type { ViewMode } from '../Calendar/hooks/useViewMode'
import {
  DEFAULT_VIEW_MODE,
  LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
} from '../Calendar/hooks/useCalendar'

type ToggleOption = {
  title: string
  key: string
  href: string
  selected: boolean
  icon: JSX.Element
}

const useToggleOptions = (): ToggleOption[] => {
  const location = useLocation()
  const [isPublishRevamp] = usePublishRevamp()
  const isCalendarWeek = !!getMatch({
    pathname: location.pathname,
    route: calendarWeek.route,
  })
  const isCalendarMonth = !!getMatch({
    pathname: location.pathname,
    route: calendarMonth.route,
  })

  const singleChannelMatch = getMatch({
    pathname: location.pathname,
    route: channel.route,
  })
  const isSingleChannelView = !!singleChannelMatch

  const singleChannelCalendarMatch = getMatch({
    pathname: location.pathname,
    route: newCalendarSingleChannel.route,
  })
  const isCalendarInSingleChannelView = !!singleChannelCalendarMatch

  const allChannelsMatch = getMatch({
    pathname: location.pathname,
    route: newCalendarAllChannels.route,
  })
  const isCalendarInAllChannelsView = !!allChannelsMatch

  const { channelsFilter, tagsFilter } = useCalendarFilters()
  const channelIds = useMemo(() => {
    if (isSingleChannelView) {
      return [singleChannelMatch?.params?.id]
    }
    return channelsFilter.queryParams
  }, [isSingleChannelView, singleChannelMatch, channelsFilter])
  const tagIds = tagsFilter.queryParams
  const [viewMode] = useLocalStorageState<ViewMode>(
    LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_VIEW_MODE,
  )

  const firstChannelId = channelIds?.[0]
  const calendarRoute = useMemo(() => {
    if (isPublishRevamp) {
      if (isSingleChannelView && firstChannelId) {
        return newCalendarSingleChannel.getRoute(
          channelIds?.[0] ?? '',
          viewMode,
          {
            tagIds,
          },
        )
      }
      return newCalendarAllChannels.getRoute(viewMode, { channelIds, tagIds })
    }
    return calendarWeek.getRoute({ channelIds, tagIds })
  }, [
    isPublishRevamp,
    channelIds,
    tagIds,
    isSingleChannelView,
    firstChannelId,
    viewMode,
  ])
  const allChannelsRoute = allChannels.getRoute({ channelIds, tagIds })
  const singleChannelRoute = channel.getRoute(channelIds?.[0] ?? '', { tagIds })

  const isCalendar =
    isCalendarWeek ||
    isCalendarMonth ||
    isCalendarInSingleChannelView ||
    isCalendarInAllChannelsView
  const listRoute = isCalendarInSingleChannelView
    ? singleChannelRoute
    : allChannelsRoute

  return [
    {
      title: 'List',
      key: 'all-channels',
      href: listRoute,
      selected: !isCalendar,
      icon: <ListIcon />,
    },
    {
      title: 'Calendar',
      key: 'calendar',
      href: calendarRoute,
      selected: isCalendar,
      icon: <CalendarIcon />,
    },
  ]
}

export const PostViewToggle = (): JSX.Element => {
  const options = useToggleOptions()
  const selected = options.find((option) => option.selected) || options[0]
  return (
    <ToggleGroup value={selected?.key} size="large">
      {options.map((option) => (
        <ToggleGroup.Item
          data-tour-id={option.key}
          key={option.key}
          value={option.key}
          asChild
        >
          <Link to={option.href}>
            {option.icon}
            {option.title}
          </Link>
        </ToggleGroup.Item>
      ))}
    </ToggleGroup>
  )
}
