import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import { Text } from '@bufferapp/ui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from '~publish/gql'

import { useMutation, useQuery } from '@apollo/client'
import Notification from '../Notification'
import styles from './Notifications.module.css'

export const getEmailNotificationPreferencesQuery = graphql(`
  query getEmailNotificationPreferencesQuery {
    emailNotificationPreferences {
      __typename
      ... on EmailNotificationPreferencesResponse {
        preferences {
          id
          name
          description
          subscribed
          cioTopicId
        }
      }
      ... on EmailNotificationPreferenceError {
        message
      }
    }
  }
`)

export const setEmailNotificationPreferencesMutation = graphql(`
  mutation emailNotificationPreferences(
    $input: [EmailNotificationPreferenceInput!]!
  ) {
    emailNotificationPreferences(input: $input) {
      __typename
      ... on EmailNotificationPreferencesResponse {
        preferences {
          id
          name
          description
          subscribed
          cioTopicId
        }
      }
      ... on EmailNotificationPreferenceError {
        message
      }
    }
  }
`)

const Notifications = (): JSX.Element => {
  const { t } = useTranslation()

  // Set up query and mutation
  const { data } = useQuery(getEmailNotificationPreferencesQuery)
  const [setEmailNotificationPreferences, { data: mutationData }] = useMutation(
    setEmailNotificationPreferencesMutation,
  )

  // Handle errors
  useEffect(() => {
    if (
      mutationData?.emailNotificationPreferences.__typename ===
      'EmailNotificationPreferenceError'
    ) {
      alert(mutationData?.emailNotificationPreferences.message)
    }
  }, [mutationData?.emailNotificationPreferences])

  const preferences =
    data?.emailNotificationPreferences.__typename ===
    'EmailNotificationPreferencesResponse'
      ? data?.emailNotificationPreferences.preferences
      : null

  const error =
    data?.emailNotificationPreferences.__typename ===
    'EmailNotificationPreferenceError'
      ? data?.emailNotificationPreferences.message
      : null

  return (
    <div className={styles.notificationsWrapper}>
      <Text type="h2">{t('preferences.notifications.title')}</Text>
      <Text type="p">{t('preferences.notifications.description')}</Text>
      {error && <Text type="p">{error}</Text>}
      {preferences &&
        preferences.map((emailPreference) => {
          return (
            <React.Fragment key={emailPreference.id}>
              <Notification
                title={emailPreference.name}
                description={emailPreference.description}
                onToggleClick={(): void => {
                  setEmailNotificationPreferences({
                    variables: {
                      input: [
                        {
                          id: emailPreference.id,
                          subscribed: !emailPreference.subscribed,
                        },
                      ],
                    },
                  })
                }}
                toggleIsEnabled={emailPreference.subscribed}
                type={emailPreference.id}
              />
              <Divider />
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default Notifications
