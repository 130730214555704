import React from 'react'
import PropTypes from 'prop-types'

import { Card, Link } from '@buffer-mono/legacy-bufferapp-components'

import { Text, Button } from '@bufferapp/ui'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'

const pausedBarInnerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 1rem 1rem',
}

const pausedTextContainer = {
  marginTop: 0,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'handleClickUnpause' implicitly ha... Remove this comment to see the full error message
const QueuePausedBar = ({ handleClickUnpause, isManager }) => (
  // @ts-expect-error
  <Card noPadding color="off-white">
    <div style={pausedBarInnerStyle}>
      <div>
        <div style={pausedTextContainer}>
          <Text type="h3">Your queue is currently paused.</Text>
        </div>
        <div>
          {isManager && (
            <Text type="p">
              None of your posts will go out, and you can&apos;t re-order posts
              in your queue.&nbsp;
              <Link
                // @ts-expect-error
                newTab
                href={`https://support.buffer.com/article/515-pausing-your-queue?${HC_UTM_PARAMS}`}
              >
                Learn more
              </Link>
            </Text>
          )}
          {!isManager && (
            // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: (s... Remove this comment to see the full error message
            <Text size="p">
              Unfortunately you don&apos;t have permission to unpause it.&nbsp;
              <Link
                // @ts-expect-error
                newTab
                href={`https://support.buffer.com/article/515-pausing-your-queue?${HC_UTM_PARAMS}`}
              >
                Learn more
              </Link>
            </Text>
          )}
        </div>
        {isManager && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: any; ... Remove this comment to see the full error message
          <Button
            type="primary"
            size="small"
            onClick={handleClickUnpause}
            label="Resume Queue"
          />
        )}
      </div>
    </div>
  </Card>
)

QueuePausedBar.propTypes = {
  handleClickUnpause: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
}

export default QueuePausedBar
