import { useState } from 'react'
import { StoryCanvaDesignConfig } from '~publish/legacy/canva/constants'
import {
  getCanvaDesignConfig,
  openCanvaModal,
} from '~publish/legacy/canva/handlers'
import { MediaTypes } from '~publish/legacy/constants'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import {
  UploadcareIntegrations,
  UploadcareSources,
} from '~publish/legacy/uploadcare/constants'
import { openUploadcareModal } from '~publish/legacy/uploadcare/openUploadcareModal'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import type { Draft } from '../../entities/Draft'
import type { Media } from '../../entities/factories'
import ModalActionCreators from '../../shared-components/modal/actionCreators'
import AppStore from '../../stores/AppStore'
import type { ValidationCode, ValidationFeedback } from '../../stores/types'
import { useComposerUploader } from '../../hooks/useComposerUploader'
import { handleUploadError } from '../../action-creators/handleUpdloadError'

export const useMediaAttachmentFeedback = (
  mediaUrl: string,
  composerId: string,
  codes: Array<ValidationCode> = [
    VALIDATION_CODE.ASPECT_RATIO,
    VALIDATION_CODE.VIDEO_DURATION,
    VALIDATION_CODE.VIDEO_SIZE,
    VALIDATION_CODE.VIDEO_FRAME_RATE,
  ],
): ValidationFeedback[] => {
  return AppStore.whatPreventsSaving().filter((validation) => {
    const validationUrl =
      // mediaUrl is still used in some cases when we call ValidationFail
      validation?.metadata?.mediaUrl || validation?.metadata?.url
    return (
      validation.composerId === composerId &&
      validationUrl === mediaUrl &&
      codes.includes(validation.code)
    )
  })
}

export const useMediaAttachmentThumbnailHandlers = ({
  media,
  draft,
  canAddUserTag,
  shouldEditOnClick,
  showImageDescription,
}: {
  media: Media
  draft: Draft
  canAddUserTag: boolean
  shouldEditOnClick: boolean
  showImageDescription: boolean
}): {
  isHovering: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  onClick: () => void
  onMediaPreviewClick: () => void
  onEditButtonClick: () => void
  onAddTagClick: () => void
  onCanvaEditButtonClick: () => void
  onCloseButtonClick: () => void
} => {
  const { onUploadStarted, onUploadFinished } = useIsIntegrationUploading()
  const { onFileReady } = useComposerUploader({ draft })

  const [isHovering, setIsHovering] = useState(false)
  const onMouseEnter = (): void => setIsHovering(true)
  const onMouseLeave = (): void => setIsHovering(false)

  const onMediaPreviewClick = (): void => {
    ModalActionCreators.openModal('MediaZoomBox', {
      media,
      draftId: draft.id,
      showImageDescription,
    })
  }

  const onAddTagClick = (): void => {
    const image = draft.getImageByUrl(media.url)
    const userTags = image?.userTags
    ModalActionCreators.openModal('InstagramUserTags', {
      media,
      draftId: draft.id,
      userTags,
    })
  }

  const onCloseButtonClick = (): void => {
    switch (media.mediaType) {
      case MediaTypes.IMAGE:
        ComposerActionCreators.removeDraftImage(draft.id, media)
        break

      case MediaTypes.VIDEO:
        ComposerActionCreators.removeDraftVideo(draft.id)
        break

      case MediaTypes.GIF:
        ComposerActionCreators.removeDraftGif(draft.id)
        break

      default:
        break
    }
  }

  const onCanvaEditButtonClick = (): void => {
    openCanvaModal({
      onFileReady,
      // @ts-expect-error TS(2339) FIXME: Property 'source' does not exist on type 'Media'.
      designId: media.source?.id,
      designConfig: draft.isStoryPost()
        ? StoryCanvaDesignConfig
        : getCanvaDesignConfig(draft.service.name),
    })
  }

  const onEditButtonClick = (): void => {
    openUploadcareModal(
      draft.id,
      onFileReady,
      handleUploadError,
      UploadcareIntegrations.EDIT,
      onUploadStarted,
      onUploadFinished,
      UploadcareSources.EDIT,
      {
        url: media.url,
        deleteCallback: (): void =>
          ComposerActionCreators.removeDraftImage(draft.id, media),
      },
      draft?.updateType || undefined,
    )
  }

  const onClick = (): void => {
    if (shouldEditOnClick) {
      onEditButtonClick()
    } else if (canAddUserTag) {
      onAddTagClick()
    } else {
      onMediaPreviewClick()
    }
  }

  return {
    isHovering,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onMediaPreviewClick,
    onEditButtonClick,
    onAddTagClick,
    onCanvaEditButtonClick,
    onCloseButtonClick,
  }
}
