import React from 'react'
import {
  Avatar,
  Text,
} from '@buffer-mono/popcorn'

import { usePostData } from '../PostCardContext'

import styles from './ChannelName.module.css'

const Separator = (): JSX.Element => (
  <Text color="subtle" aria-hidden>
    •
  </Text>
)

const SubText = ({ children }: React.PropsWithChildren): JSX.Element => (
  <Text color="subtle" className={styles.nowrapText}>
    {children}
  </Text>
)

export const ChannelName = (): JSX.Element | null => {
  const { channel, metadata } = usePostData()

  return (
    <div className={styles.channelName}>
      <Text weight="bold" className={styles.nowrapText}>
        {channel.name}
      </Text>
      {channel.serverUrl && (
        <SubText>
          <Separator />
          &nbsp;
          {channel.serverUrl}
        </SubText>
      )}
      {channel.locationData?.location && (
        <SubText>
          <Separator />
          &nbsp;
          {channel.locationData.location}
        </SubText>
      )}
      {metadata?.__typename === 'PinterestPostMetadata' && (
        <div className={styles.pinterestBoard}>
          <Separator />
          <Avatar
            size="xsmall"
            src={metadata.board?.avatar ?? undefined}
            alt={metadata.board?.name || ''}
          />
          <SubText>{metadata.board?.name}</SubText>
        </div>
      )}
    </div>
  )
}
