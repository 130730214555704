/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import { DragDropContext } from 'react-beautiful-dnd'
import usePostLimit from '~publish/pages/Calendar/hooks/usePostLimit'
import { useMonthlyDates } from '~publish/pages/Calendar/hooks/useMonthlyDates'
import type { PostForCalendarHookResponse } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import dayCells from '../Days'
import { useHandleDragEndLegacy } from '~publish/pages/Calendar/hooks/useHandleDragEndLegacy'

const Row = styled.tr<{ numberWeeks: number }>`
  // We are adding 1 to the calculation to account for the header
  // this theoretically will set the height to be smaller than needed but then
  // the css will grow the elements proportionally to fill the space
  height: calc(100% / (${(props): number => props.numberWeeks} + 1));
`

type RowRenderer = {
  row: number
  cells: JSX.Element[]
}

type DaysOfMonthProps = {
  currentDate: Date
  selectedChannelIds: string[] | void
  onDropPostSuccess: (args: { dueAt: string; isDraft: boolean }) => void
  onDropPostError: (error?: { message: string } | Error | null) => void
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  postsQuery: PostForCalendarHookResponse
}

const DaysOfMonth = ({
  currentDate,
  selectedChannelIds,
  onDropPostSuccess,
  onDropPostError,
  setOpenModal,
  postsQuery,
}: DaysOfMonthProps): JSX.Element => {
  const cellsPerRow = 7
  const {
    monthStart,
    calendarStart,
    calendarEnd,
    numberOfWeeks,
    weekStartsOn,
  } = useMonthlyDates(currentDate)

  const { data } = postsQuery
  const handleDragEnd = useHandleDragEndLegacy({
    onDropPostError,
    onDropPostSuccess,
    postsQuery,
    preserveTime: true,
  })

  const postLimit = usePostLimit(numberOfWeeks)

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {dayCells({
        startDate: calendarStart,
        endDate: calendarEnd,
        monthStart,
        weekStartsOn,
        data,
        postLimit,
        setOpenModal,
        selectedChannelIds,
      })
        .reduce((row, cell, index) => {
          // create each row array with 7 cells:
          if (index % cellsPerRow === 0)
            row.push({
              row: row.length,
              cells: [],
            })
          row[row.length - 1].cells.push(cell)
          return row
        }, [] as RowRenderer[])
        .map((row) => {
          // surround every row with 'tr'
          return (
            <Row
              key={row.row}
              data-testid="monthly-row"
              numberWeeks={numberOfWeeks}
            >
              {row.cells}
            </Row>
          )
        })}
    </DragDropContext>
  )
}

export default DaysOfMonth
