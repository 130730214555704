import {
  createEntityAdapter,
  createSlice,
  type EntityState,
  type Slice,
  type SliceCaseReducers,
  type Action,
} from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import { type Idea, IdeaEvents, type Tag } from '../types'

const ideasAdapter = createEntityAdapter<Idea>({
  sortComparer: (a: Idea, b: Idea) => (b.createdAt || 0) - (a.createdAt || 0),
})

const removeTypenameFromTags = (idea: Idea): Idea => {
  if (idea.content.tags && idea.content.tags.length) {
    return {
      ...idea,
      content: {
        ...idea.content,
        tags: idea.content.tags.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ __typename, ...tagData }: Tag) => tagData,
        ),
      },
    }
  } else {
    return idea
  }
}
export const ideasSlice: Slice<
  EntityState<Idea> & {
    currentIdeaId: string | null
    currentUnsavedContentIsAiAssisted: boolean
  },
  SliceCaseReducers<
    EntityState<Idea> & {
      currentIdeaId: string | null
      currentUnsavedContentIsAiAssisted: boolean
    }
  >,
  'ideas'
> = createSlice({
  name: 'ideas',
  initialState: ideasAdapter.getInitialState<{
    currentIdeaId: string | null
    currentUnsavedContentIsAiAssisted: boolean
  }>({
    currentIdeaId: null,
    currentUnsavedContentIsAiAssisted: false,
  }),
  reducers: {
    ideaAdded: ideasAdapter.addOne,
    ideaUpdated: ideasAdapter.setOne,
    ideasReceived: (state, action) => {
      const updatedPayload = action.payload.map(removeTypenameFromTags)
      ideasAdapter.setMany(state, updatedPayload)
    },
    setIdeaAsAiAssisted: (state, action) => {
      const targetIdeaId = action.payload.id
      // Check for if a user starts in the Ideas editor and then create a post
      // without saving
      if (targetIdeaId && state.entities[targetIdeaId]) {
        state.entities[targetIdeaId]!.content.aiAssisted = true
      } else {
        state.currentUnsavedContentIsAiAssisted = true
      }
    },
    resetCurrentUnsavedAiStatus: (state) => {
      state.currentUnsavedContentIsAiAssisted = false
    },
    setCurrentIdeaId: (state, action) => {
      state.currentIdeaId = action.payload.id
    },
    ideaDeleted: ideasAdapter.removeOne,
    ideasDeleted: ideasAdapter.removeMany,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        `PUSHER_EVENT/${IdeaEvents.IDEAS_DELETED}`,
        (state, action: Action & { data?: { ideas?: Idea[] } }) => {
          if (!action.data?.ideas) return
          ideasAdapter.removeMany(
            state,
            action.data?.ideas?.map((i) => i.id),
          )
        },
      )
      .addCase(
        `PUSHER_EVENT/${IdeaEvents.IDEA_CREATED}`,
        (state, action: Action & { data: { idea: Idea } }) => {
          const updatedIdea = removeTypenameFromTags(action.data.idea)
          ideasAdapter.addOne(state, updatedIdea)
        },
      )
      .addCase(
        `PUSHER_EVENT/${IdeaEvents.IDEA_UPDATED}`,
        (state, action: Action & { data: { idea: Idea } }) => {
          const updatedIdea = removeTypenameFromTags(action.data.idea)
          ideasAdapter.setOne(state, updatedIdea)
        },
      )
  },
})

const {
  ideasReceived,
  ideasDeleted,
  markSource,
  setCurrentIdeaId,
  setIdeaAsAiAssisted,
  resetCurrentUnsavedAiStatus,
} = ideasSlice.actions

// globalized selectors
const { selectById: selectIdeaById, selectAll: selectAllIdeas } =
  ideasAdapter.getSelectors((state: RootState) => state[ideasSlice.name])

export {
  ideasReceived,
  selectIdeaById,
  selectAllIdeas,
  ideasDeleted,
  markSource,
  setCurrentIdeaId,
  setIdeaAsAiAssisted,
  resetCurrentUnsavedAiStatus,
}
