import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import { getURL } from '~publish/legacy/utils/formatters'
import { campaignsPage } from '~publish/legacy/routes'
import { actionTypes } from './reducer'
import type { Tag } from './types'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    const state = getState()
    switch (action.type) {
      case actionTypes.FETCH_CAMPAIGN: {
        const {
          campaignId,
          past,
          status,
          fullItems,
          count,
          pageNumber,
          isFetchingMore,
          sortDirection,
          sortField,
        } = action
        dispatch(
          dataFetchActions.fetch({
            name: 'getCampaign',
            args: {
              campaignId,
              status: status || undefined,
              past,
              fullItems: fullItems || false,
              count,
              pageNumber,
              isFetchingMore,
              sortDirection,
              sortField,
            },
          }),
        )
        break
      }

      case actionTypes.GO_TO_ANALYZE_REPORT: {
        const { id, name } = action.campaign

        const metadata = {
          tagId: id,
          tagName: name,
        }
        dispatch(analyticsActions.trackEvent('Tag Report Viewed', metadata))

        window.location.assign(`${getURL.getAnalyzeReportUrlForTags(id)}`)
        break
      }

      case `getCampaign_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        dispatch(campaignsPage.goTo())
        break

      case actionTypes.PUSHER_CAMPAIGN_DELETED: {
        const { id: orgId } = state.organizations.selected
        dispatch(
          dataFetchActions.fetch({
            name: 'getCampaignsList',
            args: { id: orgId },
          }),
        )
        break
      }

      case draftActionTypes.DRAFT_CREATED:
      case draftActionTypes.DRAFT_UPDATED:
      case draftActionTypes.DRAFT_DELETED:
      case draftActionTypes.DRAFT_APPROVED:
      case draftActionTypes.DRAFT_MOVED: {
        const { draft } = action
        const { tags } = draft
        const tagIds: string[] = tags.map((tag: Tag) => tag.id)
        const { campaign } = state

        if (tagIds.includes(campaign.campaignId)) {
          dispatch(
            dataFetchActions.fetch({
              name: 'getPostsCountForTag',
              args: {
                id: campaign.campaignId,
              },
            }),
          )
        }
        break
      }

      case queueActionTypes.POST_CREATED:
      case queueActionTypes.POST_UPDATED:
      case queueActionTypes.POST_SENT:
      case queueActionTypes.POST_DELETED: {
        const { post } = action
        const { tags } = post
        const tagIds: string[] = tags.map((tag: Tag) => tag.id)
        const { campaign } = state

        if (tagIds.includes(campaign.campaignId)) {
          dispatch(
            dataFetchActions.fetch({
              name: 'getPostsCountForTag',
              args: {
                id: campaign.campaignId,
              },
            }),
          )
        }
        break
      }

      default:
        break
    }
  }
