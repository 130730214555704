/**
 * A space delimited unit of text, containing metadata about the measurements
 * @property content - the text content
 * @property width - the width of the text in pixels
 * @property widthWithSpace - the width of the text in pixels, including a leading space character
 *   if appropriate
 */
export type Word = { content: string; width: number; widthWithSpace: number }

/**
 * A line of text, containing metadata about the measurements
 * @property words - the words in the line
 * @property width - the sum width of the words in the line including spaces (in pixels)
 * @property precededByNewLineChar - whether the line was preceded by a newline character
 */
export type Line = {
  words: Word[]
  width: number
  precededByNewLineChar: boolean
}

/**
 * Helper to sum the width of a list of words, including spaces
 * @param words list of words
 * @returns the sum of the width of the words
 */
const sumLineWidth = (words: Word[]): number =>
  words.reduce((acc, word) => acc + word.widthWithSpace, 0)

/**
 * Helper to create a new line
 * @param words list of words
 * @returns
 */
export const makeLine = (words: Word[] = []): Line => ({
  words,
  width: sumLineWidth(words),
  precededByNewLineChar: false,
})

/**
 * Helper to create a new line that follows a newline character
 * @param words list of words
 * @returns
 */
export const makeLineFollowingNewline = (words: Word[] = []): Line => ({
  words,
  width: sumLineWidth(words),
  precededByNewLineChar: true,
})

/**
 * Helper to create a new word
 * @param content Text content
 * @param width Width of the text in pixels
 * @param widthWithSpace Width of the text in pixels, including a leading space character
 * @returns
 */
export const makeWord = (
  content: string,
  width: number,
  widthWithSpace: number,
): Word => ({ content, width, widthWithSpace })

/**
 * Collapse a list of lines into a single string, accounting for
 * newlines and spaces. This output can be used to display the
 * text in a view
 * @param lines list of lines
 * @returns
 */
export const collapseLines = (lines: Line[]): string => {
  let output = ''
  let isFirst = true
  for (const line of lines) {
    const defaultLeadingSeparator = isFirst ? '' : ' '
    isFirst = false
    output += line.precededByNewLineChar ? '\n' : defaultLeadingSeparator
    output += line.words.map((word) => word.content).join(' ')
  }
  // remove trailing newlines with a single one
  // to avoid trailing blankspace for posts with a lede and gap then body
  // (common with linkedin posts)
  return output.replace(/\n+$/, '\n')
}

/**
 * Debugger utility to print out the contents of a list of lines
 * @param lines list of lines
 * @returns
 */
export const debugLines = (lines: Line[]): string => {
  const maxLineWidth = lines.reduce(
    (acc, line) =>
      Math.max(acc, line.words.map((l) => l.content).join(' ').length),
    0,
  )
  const horizontalLine = '═'.repeat(maxLineWidth)
  let output = `╔${horizontalLine}╗`
  for (const line of lines) {
    output += line.precededByNewLineChar ? '⏎\n║' : '\n║'
    output += line.words
      .map((word) => word.content)
      .join(' ')
      .padEnd(maxLineWidth, '░')
    output += '║'
  }
  output += `\n╚${horizontalLine}╝`
  return output
}
