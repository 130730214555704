import React from 'react'
import GoogleBusinessComposerBar from '~publish/legacy/composer/composer/components/google-business/GoogleBusinessComposerBar'
import PinterestComposerBar from '~publish/legacy/composer/composer/components/pinterest/PinterestComposerBar'
import StartPageComposerBar from '~publish/legacy/composer/composer/components/start-page/StartPageComposerBar'
import VideoTitleBar from '~publish/legacy/composer/composer/components/VideoTitleBar/VideoTitleBar'
import YoutubeComposerBar from '~publish/legacy/composer/composer/components/youtube/YoutubeComposerBar'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import { LinkedInChannelFields } from './components/LinkedInChannelFields'
import * as Styles from './styles'
import { MastodonChannelFields } from './components/MastodonChannelFields'
import TiktokComposerBar from '~publish/legacy/composer/composer/components/tiktok/TiktokComposerBar'

type Props = {
  draft: Draft
}

export const ChannelFields = ({ draft }: Props): JSX.Element => {
  return (
    <Styles.ChannelFieldsContainer
      $isImageFirst={draft.service.isYoutube()}
      $isExpanded={!draft?.isReminder}
      $noBorder={draft.service.isYoutube()}
      data-testid="composer-channel-fields"
    >
      <TiktokComposerBar draft={draft} />
      <GoogleBusinessComposerBar draft={draft} />
      <StartPageComposerBar draft={draft} />
      <PinterestComposerBar draft={draft} />
      <VideoTitleBar draft={draft} />
      <YoutubeComposerBar draft={draft} />
      <LinkedInChannelFields draft={draft} />
      <MastodonChannelFields draft={draft} />
    </Styles.ChannelFieldsContainer>
  )
}
