import { Button } from '@buffer-mono/popcorn'
import React from 'react'
import { useReconnectChannel } from '~publish/hooks/useReconnectChannel'
import { usePostData } from './PostCardContext'
import { PostCardNotice } from './PostCardNotice'

export const PostCardDisconnectedChannelNotice = (): JSX.Element | null => {
  const { channel, status } = usePostData()

  const reconnectChannel = useReconnectChannel()

  // Don't show the notice if the channel is not disconnected
  if (!channel.isDisconnected) {
    return null
  }

  // Don't show the notice if the post is already sent or errored
  if (status === 'sent' || status === 'error') {
    return null
  }

  const handleRefresh = (): void => {
    reconnectChannel({
      id: channel.id,
      service: channel.service,
      cta: 'publish-postList-postCard-refreshConnection-1',
    })
  }

  return (
    <PostCardNotice variant="critical">
      We&apos;ve lost access to post this on your behalf. Refresh the channel to
      fix it.
      <PostCardNotice.Action>
        <Button variant="critical" size="small" onClick={handleRefresh}>
          Refresh Connection
        </Button>
      </PostCardNotice.Action>
    </PostCardNotice>
  )
}

PostCardDisconnectedChannelNotice.displayName =
  'PostCardDisconnectedChannelNotice'
