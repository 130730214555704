import type {
  AdditionalProperties,
  TrackingData,
  coreMetadataProperties,
} from './trackingTypes'

import { Product, Client } from '@buffer-mono/tracking-plan'

import type {
  CommonTrackingProperties,
  Account,
} from '~publish/legacy/accountContext/types'

export const getCoreTrackingMetadata = (
  account: Account,
): coreMetadataProperties => {
  const organizationId = account?.currentOrganization?.id || null
  return {
    organizationId,
    product: Product.Publish,
    clientName: Client.PublishWeb,
  }
}

export const createEventTrackingData = (
  metadata: coreMetadataProperties,
  commonProps: CommonTrackingProperties,
  additionalProps: AdditionalProperties,
): TrackingData => ({
  ...metadata,
  ...commonProps,
  ...additionalProps,
})
