import React from 'react'
import clsx from 'clsx'
import {
  Badge,
  Button,
  VisuallyHidden,
  FilterIcon,
  type ButtonSize,
  type ButtonVariant,
  ChevronDownIcon,
} from '@buffer-mono/popcorn'

import styles from './FilterButton.module.css'

type FilterButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
  icon?: React.ReactNode
  count?: number
  size?: ButtonSize
  variant?: ButtonVariant
  className?: string
}

/**
 * Button to be used for filtering content, it displays cound badge and chevron icon.
 * Utilize it for filtering only, not for selecting.
 */
export const FilterButton = React.forwardRef<
  React.ElementRef<typeof Button>,
  FilterButtonProps
>(
  (
    {
      icon,
      count,
      children,
      className,
      size = 'large',
      variant = 'secondary',
      ...delegatedProps
    },
    forwardedRef,
  ): JSX.Element => {
    return (
      <Button
        size={size}
        variant={variant}
        className={clsx(styles.button, styles[size], className)}
        {...delegatedProps}
        ref={forwardedRef}
      >
        {icon ?? <FilterIcon />}
        <VisuallyHidden>Filter by</VisuallyHidden>
        {children}
        {count !== undefined && count > 0 && (
          <Badge className={styles.badge} variant="brand" size="xsmall">
            {count}
          </Badge>
        )}
        <ChevronDownIcon className={styles.chevron} />
      </Button>
    )
  },
)

FilterButton.displayName = 'FilterButton'
