import { useState } from 'react'
import { type DocumentNode, useMutation } from '@apollo/client'
import type {
  UpdateCalendarPostDueAtMutation,
  GetCalendarAndPostListQuery,
} from '~publish/gql/graphql'
import {
  UpdateCalendarPostDueAt,
  getSuccessFragmentData,
  type SuccessData,
} from './useUpdatePostDueAt'
import {
  GetCalendarAndPostList,
  type QueryVariables,
} from './useCalendarAndPostsList'

type PostEdges = NonNullable<GetCalendarAndPostListQuery['posts']['edges']>
type PostNode = PostEdges[number]['node']

const updatePostItem = (
  node: PostNode,
  targetId: string,
  dueAt: string | null | undefined,
): PostNode => {
  if (node.id === targetId) {
    return {
      ...node,
      dueAt,
    }
  }
  return node
}

// Keep the new post list due at date in sync with the cache
const updatePostDueAtCache = (
  edges: GetCalendarAndPostListQuery['posts']['edges'],
  postId: string,
  updatedDueAt: string | null | undefined,
): GetCalendarAndPostListQuery['posts']['edges'] => {
  if (!edges) return edges

  return edges.map((edge) => ({
    ...edge,
    node: updatePostItem(edge.node, postId, updatedDueAt),
  }))
}

type UseDropPostInSlotProps = {
  onSuccess: (post: SuccessData) => void
  onError: (error: { message: string } | undefined | null) => void
  variables: QueryVariables
}

type UseDropPostInSlotResponse = {
  error: { message: string } | undefined | null
  result: UpdateCalendarPostDueAtMutation | undefined | null
  updatePostDueAt: (args: {
    id: string
    dueAt: string
    isPinned: boolean
  }) => void
}

export function useDropPostInSlot({
  onSuccess,
  onError,
  variables,
}: UseDropPostInSlotProps): UseDropPostInSlotResponse {
  const [error, setError] = useState<{ message: string } | null>(null)
  const [updatePostDueAt, mutationResult] = useMutation(
    UpdateCalendarPostDueAt,
    {
      onCompleted: (data) => {
        const maybePost = getSuccessFragmentData(data)
        if (maybePost) {
          onSuccess(maybePost)
        }

        if (data.dropPost.__typename !== 'PostActionSuccess') {
          onError({ message: data.dropPost.message })
        }
      },
      onError: (err) => {
        onError(err)
        setError(err)
      },
      update: (cache, mutationResult) => {
        const updatedPost = getSuccessFragmentData(mutationResult.data)
        if (!updatedPost) return
        const readData = cache.readQuery({
          query: GetCalendarAndPostList as DocumentNode,
          variables,
        }) as GetCalendarAndPostListQuery

        if (!readData) return

        cache.writeQuery({
          query: GetCalendarAndPostList as DocumentNode,
          variables,
          data: {
            ...readData,
            posts: updatePostDueAtCache(
              readData.posts.edges,
              updatedPost.id,
              updatedPost.dueAt,
            ),
          },
        })
      },
      refetchQueries: [GetCalendarAndPostList as DocumentNode],
    },
  )

  return {
    error,
    result: mutationResult.data,
    updatePostDueAt: ({
      id,
      dueAt,
      isPinned,
    }: {
      id: string
      dueAt: string
      isPinned: boolean
    }): void => {
      updatePostDueAt({
        variables: { input: { id, dueAt, isPinned } },
        optimisticResponse: {
          dropPost: {
            post: {
              __typename: 'Post',
              id,
              dueAt,
            },
            __typename: 'PostActionSuccess',
          },
        },
      }).catch((e) => console.error(e))
    },
  }
}
