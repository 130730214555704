import { getTimezoneOffset } from 'date-fns-tz'
import React from 'react'

import {
  Button,
  ChevronDownIcon,
  Combobox,
  EmptyState,
  Flex,
  Heading,
  Paragraph,
  SearchIcon,
  SimpleSpinner,
  Text,
  toast,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import {
  selectCurrentProfile,
  selectCurrentProfileTimezone,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { formatTimezone } from '~publish/legacy/utils/timezone'

import { updateTimezone } from '../../thunks/updateTimezone'

import commonStyles from '../../PostingSchedule.module.css'
import styles from './TimezoneInputForm.module.css'
import { formatTimeZone } from '~publish/helpers/dateFormatters'

const TimezoneInputForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const profileTimezone = useAppSelector(selectCurrentProfileTimezone)
  const formattedTimezone = formatTimezone(profileTimezone)
  const [loading, setLoading] = React.useState(false)

  if (!profile) return <></>

  const disabled = !profile.isManager
  const profileId = profile.id

  const onComboboxChange = async (timezone: string): Promise<void> => {
    setLoading(true)
    const result = await dispatch(
      updateTimezone({ profileId, timezone, city: timezone }),
    )
    if (updateTimezone.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your timezone: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)
  }

  const timezones = Intl.supportedValuesOf('timeZone').sort((a, b) => {
    // sort by offset
    const aOffset = getTimezoneOffset(a)
    const bOffset = getTimezoneOffset(b)
    if (aOffset === bOffset) return 0
    return aOffset < bOffset ? -1 : 1
  })

  return (
    <Flex
      justify="between"
      align="center"
      style={{ width: '100%' }}
      data-testid="timezone-form-wrapper"
    >
      <Flex direction="column">
        <Heading size="small" as="h3">
          Timezone
        </Heading>
        <Paragraph className={commonStyles.postingScheduleParagraph}>
          Used for scheduling queue for this channel
        </Paragraph>
      </Flex>
      <Flex direction="row" align="center" gap="space-100">
        {loading && <SimpleSpinner />}
        <Combobox onChange={onComboboxChange} multiple={false}>
          <Combobox.Trigger>
            <Button
              disabled={disabled}
              size="large"
              variant="secondary"
              data-testid="timezone-form-button"
            >
              <VisuallyHidden>Timezone</VisuallyHidden>
              {formattedTimezone}
              <ChevronDownIcon />
            </Button>
          </Combobox.Trigger>
          <Combobox.Content>
            <Combobox.Input
              placeholder="Type a timezone"
              prefix={<SearchIcon />}
            />
            <Combobox.List>
              <Combobox.Empty>
                <EmptyState size="small">
                  <EmptyState.Icon>
                    <SearchIcon />
                  </EmptyState.Icon>
                  <EmptyState.Description>
                    No matching time zones found
                  </EmptyState.Description>
                </EmptyState>
              </Combobox.Empty>
              {timezones.map((item) => {
                const humanReadableTimezone = formatTimeZone(item)
                return (
                  <Combobox.Item
                    key={item}
                    value={item}
                    keywords={[humanReadableTimezone]}
                  >
                    <Flex
                      justify="between"
                      align="center"
                      className={styles.item}
                    >
                      {humanReadableTimezone}
                      <Text color={'subtle'}>
                        ({formatTimezoneOffset(item)})
                      </Text>
                    </Flex>
                  </Combobox.Item>
                )
              })}
            </Combobox.List>
          </Combobox.Content>
        </Combobox>
      </Flex>
    </Flex>
  )
}

export { TimezoneInputForm }

function formatTimezoneOffset(timezone: string): string {
  const offset = getTimezoneOffset(timezone)
  const totalMinutes = (offset / (1000 * 60)) * -1
  const sign = totalMinutes >= 0 ? '-' : '+'
  const absoluteMinutes = Math.abs(totalMinutes)
  const hours = Math.floor(absoluteMinutes / 60)
  const minutes = absoluteMinutes % 60

  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )}`
}
