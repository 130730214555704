let cachedContext: CanvasRenderingContext2D | null = null
const getCanvasContext = (): CanvasRenderingContext2D | null => {
  if (!cachedContext) {
    cachedContext = document.createElement('canvas').getContext('2d')
  }
  return cachedContext
}

export type MeasureTextOptions = {
  fontSize?: number
  fontFamily?: string
  fontWeight?: number
}

/**
 * Get the measurement options for a given element
 * @param element
 * @returns
 */
export const getMeasureTextProps = (
  element: HTMLElement,
): MeasureTextOptions => {
  const fontSize = parseInt(window.getComputedStyle(element).fontSize)
  const fontFamily = window.getComputedStyle(element).fontFamily
  const fontWeight = parseInt(window.getComputedStyle(element).fontWeight)

  return {
    fontSize,
    fontFamily,
    fontWeight,
  }
}

const measureTextCache = new Map<string, number>()
/**
 * Measure the width of a given text string
 * given the measurement options.
 *
 * Note: This function is memoized, so it's
 *   highly recommended to measure individual
 *   words to avoid unnecessary re-calculations
 *   of spaces and common words
 *
 * @param text input raw text content
 * @param options
 * @returns
 */
export const measureText = (
  text: string,
  options: MeasureTextOptions,
): number => {
  const cacheKey = `${text}-${options.fontSize}-${options.fontFamily}-${options.fontWeight}`
  const cacheValue = measureTextCache.get(cacheKey)
  if (cacheValue) {
    return cacheValue
  }

  const context = getCanvasContext()
  if (!context) return 0
  context.font = `${options.fontWeight} ${options.fontSize}px ${options.fontFamily}`
  context.fillStyle = 'black'
  context.textBaseline = 'top'
  const metrics = context?.measureText(text)

  const width = metrics?.width ?? 0
  measureTextCache.set(cacheKey, width)
  return width
}
