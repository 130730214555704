import styled from 'styled-components'

import { offWhite, borderWidth } from '@buffer-mono/legacy-bufferapp-components'
import { grayLight } from '@bufferapp/ui/style/colors'
import { SidebarListItem } from '@bufferapp/ui'

export const ProfileSidebarStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  background: ${offWhite};
  border-right: ${borderWidth} solid ${grayLight};
  height: 100%;
  justify-content: flex-start;
`

/**
 * Making this a ul is a temporary patch fix for the accessibility issue
 * Because ProfileListItem (from BDS) is a li element, it needs to be positioned within ul
 */
export const ProfileListStyle = styled.ul`
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const ListItem = styled(SidebarListItem)`
  & > span:first-child {
    margin-right: 10px;
    width: 32px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  label {
    font-size: 13px;
    font-weight: 500;
  }

  margin-bottom: 8px;
`

export const BadgesWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
`
