import { VisuallyHidden } from '@buffer-mono/popcorn'
import React from 'react'
import clsx from 'clsx'

import { MediaMarker } from '../MediaMarker'

import styles from './MediaCombinedPreview.module.css'

type MediaCombinedPreviewProps = {
  children?: React.ReactNode
  className?: string
}

/**
 * Displays images in a bento-box like layout with maximum of 4 images.
 * If there are more than 4 images, a marker will be displayed indicating the number of additional images.
 */
const MediaCombinedPreview = ({
  children,
  className,
  ...props
}: MediaCombinedPreviewProps): JSX.Element | null => {
  const mediaElements = React.Children.toArray(children)
  const displayedMediaElements = mediaElements.slice(0, 4)
  const moreCount = mediaElements.length - displayedMediaElements.length

  return (
    <div
      className={clsx(styles.container, className)}
      role="group"
      data-media-count={displayedMediaElements.length}
      {...props}
    >
      {displayedMediaElements.map((mediaElement, index) => (
        <div key={index} className={styles.item}>
          {mediaElement}
        </div>
      ))}
      {moreCount > 0 && (
        <MediaMarker className={styles.moreCount}>
          <span aria-hidden>+{moreCount}</span>
          <VisuallyHidden>{moreCount} more media</VisuallyHidden>
        </MediaMarker>
      )}
    </div>
  )
}

MediaCombinedPreview.displayName = 'MediaCombinedPreview'

export { MediaCombinedPreview }
