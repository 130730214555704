import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAppDispatch } from '~publish/legacy/store'
import { useAccount } from '~publish/legacy/accountContext'
import { useNotificationsEnabled } from '~publish/legacy/hooks/useNotificationsEnabled'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { CLIENT_NAMES } from '~publish/legacy/constants'

export function useTriggerRemindersSetupWizard({
  placement,
  cta,
}: {
  placement?: string
  cta: string
}) {
  const channelHasNotificationsEnabled = useNotificationsEnabled()
  const dispatch = useAppDispatch()
  const user = useAccount()

  const handleTriggerSetupWizard = () => {
    if (channelHasNotificationsEnabled) {
      return
    }

    dispatch(
      modalActions.showRemindersWizardModal({
        placement,
      }),
    )

    BufferTracker.remindersSetupCTAClicked({
      organizationId: user?.account?.currentOrganization?.id || null,
      clientName: CLIENT_NAMES.web,
      cta,
      placement,
    })
  }

  return handleTriggerSetupWizard
}
