import { AppEnvironments } from '~publish/legacy/constants'
import { store } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import AppStore from '../stores/AppStore'
import { Client } from '@buffer-mono/tracking-plan'

// @ts-expect-error TS(7031) FIXME: Binding element 'tabId' implicitly has an 'any' ty... Remove this comment to see the full error message
const getComposerSource = ({ tabId, emptySlotMode }) => {
  let sourceName = null
  switch (tabId) {
    case 'queue':
      /* emptyslotmode is true when a user opens the composer from an empty slot,
      which allows us to know the source is queue_list */
      sourceName = emptySlotMode ? 'queue_list' : 'queue'
      break
    case 'approvals':
      sourceName = 'approvals'
      break
    case 'drafts':
      sourceName = 'drafts'
      break
    case 'analytics':
      sourceName = 'share_again'
      break
    default:
      sourceName = tabId
      break
  }
  return sourceName
}

const getSegmentCampaignMetadata = ({
  post = {},
  profile = {},
  // @ts-expect-error TS(7031) FIXME: Binding element 'composerSource' implicitly has an... Remove this comment to see the full error message
  composerSource,
}) => ({
  itemType: 'post',
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
  itemId: post.id || null,
  // @ts-expect-error TS(2339) FIXME: Property 'campaign_details' does not exist on type... Remove this comment to see the full error message
  campaignId: post.campaign_details.id || null,
  // @ts-expect-error TS(2339) FIXME: Property 'campaign_details' does not exist on type... Remove this comment to see the full error message
  campaignName: post.campaign_details.name || null,
  // @ts-expect-error TS(2339) FIXME: Property 'profile_service' does not exist on type ... Remove this comment to see the full error message
  channel: post.profile_service || null,
  // @ts-expect-error TS(2339) FIXME: Property 'profile_id' does not exist on type '{}'.
  channelId: post.profile_id || null,
  // @ts-expect-error TS(2339) FIXME: Property 'serviceType' does not exist on type '{}'... Remove this comment to see the full error message
  channelType: profile.serviceType || null,
  addedFrom: composerSource || null,
  // @ts-expect-error TS(2339) FIXME: Property 'organization_id' does not exist on type ... Remove this comment to see the full error message
  organizationId: post.organization_id || null,
  // @ts-expect-error TS(2339) FIXME: Property 'service_update_id' does not exist on typ... Remove this comment to see the full error message
  isSent: !!(post.service_update_id && post.sent_at),
  // @ts-expect-error TS(2339) FIXME: Property 'draft' does not exist on type '{}'.
  isDraft: !!post.draft,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
const getSegmentChannelMetadata = ({ profile, organizationId, page }) => ({
  product: 'publish',
  organizationId:
    organizationId || selectCurrentOrganizationId(store.getState()),
  channelId: profile.id,
  channelServiceId: profile.serviceId,
  channelUsername: profile.service?.username || null,
  channelType: profile.serviceType,
  channel: profile.service.name,
  page: page || null,
  clientName: Client.PublishWeb,
})

function getCalendarVersion(): string {
  const isNewCalendar =
    !!window.location.href.match(
      /publish(?:\.local)?.buffer.com(?::\d{4})?\/calendar/,
    ) || false
  return isNewCalendar ? 'newCalendar' : 'classicCalendar'
}

const getCurrentPage = (tabId?: string, emptySlotMode?: boolean): string => {
  const { appEnvironment } = AppStore.getMetaData()

  if (appEnvironment === AppEnvironments.EXTENSION) return 'extension'
  if (appEnvironment === AppEnvironments.CALENDAR) return getCalendarVersion()
  if (appEnvironment === AppEnvironments.CAMPAIGNS) return 'campaigns'
  if (appEnvironment === AppEnvironments.CHANNEL) return 'channel'
  if (appEnvironment === AppEnvironments.ALL_CHANNELS) return 'allChannels'

  return (
    getComposerSource({
      tabId,
      emptySlotMode,
    }) || 'queue'
  )
}

export {
  getComposerSource,
  getSegmentCampaignMetadata,
  getSegmentChannelMetadata,
  getCurrentPage,
}
