import React, { useEffect } from 'react'
import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import styled from 'styled-components'
import { greenDark, greenLightest } from '@bufferapp/ui/style/colors'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAccount } from '~publish/legacy/accountContext'
import LinkedinIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'

const BufferXThreads = styled.div`
  padding: 2px;
  background: ${greenLightest};
  color: ${greenDark};
  border-radius: 16px;
  width: 70px;
  font-size: 16px;
  font-weight: 100;
  align-items: center;
  justify-content: space-around;
  display: flex;
  margin-bottom: 8px;
`

const LinkedinProfileRefreshBanner = (): JSX.Element | null => {
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )
  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  const onButtonClick = (): void => {
    BufferTracker.cTAClicked({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      cta: `publish-queue-banner-refreshLinkedin-1`,
      upgradePathName: 'accountChannels-upgrade',
      ...commonTrackingProperties,
    })
    window.appshell.actions.connectChannel({
      selectedService: 'linkedin',
      cta: `publish-queue-banner-refreshLinkedin-1`,
      isRefreshingConnection: true,
    })
  }

  useEffect(() => {
    BufferTracker.cTAViewed({
      organizationId,
      product: 'publish',
      cta: `publish-queue-banner-refreshLinkedin-1`,
      upgradePathName: 'accountChannels-upgrade',
      ...commonTrackingProperties,
    })
  })

  return (
    <PromotionalBanner>
      <PromotionalBanner.Content>
        <BufferXThreads>
          <BufferIcon color={greenDark} />
          &#215;
          <LinkedinIcon color={greenDark} />
        </BufferXThreads>
        <PromotionalBanner.Heading>
          LinkedIn Profile Sent Post Analytics are now available in Buffer
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          Refresh your channel connection to get access to LinkedIn Profile Sent
          Posts Analytics
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button variant="primary" onClick={onButtonClick}>
            Refresh connection
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
    </PromotionalBanner>
  )
}

export { LinkedinProfileRefreshBanner }
