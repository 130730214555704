import {
  Button,
  ChevronDownIcon,
  CriticalIcon,
  EmptyState,
  Separator,
  Skeleton,
  Text,
  useDebounce,
} from '@buffer-mono/popcorn'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useAccount,
  useAccountId,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'

import { actions as profileActions } from '~publish/legacy/profile-sidebar'
import { useAppDispatch } from '~publish/legacy/store'
import UserEntity from '~publish/legacy/user/UserEntity'

import { AllChannelsItem } from './AllChannels'
import { ChannelSearchInput } from './ChannelSearchInput'
import { ChannelSuggestions } from './ChannelSuggestions'
import { CHANNEL_SUGGESTION_THRESHOLD } from './constants'
import { DraggableChannelList } from './DraggableChannelList'
import { useChannelSuggestions } from './helpers'
import { ManagementLinks } from './ManagementLinks'
import { NewChannelButton } from './NewChannelButton'
import styles from './PageSidebar.module.css'
import { SideNavItem } from './SideNavItem'
import { useChannels } from './useChannels'

const SEARCH_CHANNELS_THRESHOLD = 5

export function PageSidebar(): JSX.Element {
  const dispatch = useAppDispatch()
  const { channels, loading, error } = useChannels()
  const [searchQuery, setSearchQuery] = useState('')
  const {
    channelSuggestions,
    isChannelSuggestionsExpanded,
    expandChannelSuggestions,
  } = useChannelSuggestions(channels)
  const { account } = useAccount()

  const isAdmin = UserEntity.isAdmin({ account })
  const shouldShowExpandChannelSuggestionsButton =
    isAdmin &&
    !error &&
    channels.length < CHANNEL_SUGGESTION_THRESHOLD &&
    !isChannelSuggestionsExpanded

  useSearchTracking(searchQuery, channels)

  const filteredChannels = useMemo(
    () => filterChannels(channels, searchQuery),
    [channels, searchQuery],
  )

  const onReorder = (dragIndex: number, hoverIndex: number): void => {
    // TODO: There is no GraphQL mutation to reorder channels, so we fallback to
    // the Redux implementation.
    // We need to first sort and then commit the changes in Redux.
    // This should go away once we have a GraphQL mutation to reorder channels.
    dispatch(
      profileActions.onDropProfile({
        commit: false,
        dragIndex,
        hoverIndex,
        profileLimit: 0,
      }),
    )
    dispatch(
      profileActions.onDropProfile({
        commit: true,
        dragIndex,
        hoverIndex,
        profileLimit: 0,
      }),
    )
  }

  return (
    <nav
      className={styles.container}
      aria-label="Sidebar"
      data-testid="channel-list-sidebar"
    >
      <ul className={styles.itemList}>
        {channels.length > SEARCH_CHANNELS_THRESHOLD && (
          <ChannelSearchInput
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
        )}

        {error && <ErrorState error={error} />}
        {loading && <ChannelListSkeleton />}

        {!error && !loading && (
          <>
            {!error && !searchQuery.trim() && (
              <>
                <AllChannelsItem />
                <Separator className={styles.separator} />
              </>
            )}
            {filteredChannels.length === 0 && channels.length > 0 && (
              <NoMatchesFound searchQuery={searchQuery} />
            )}
            {!error && (
              <ul className={styles.scrollableChannelList}>
                <DraggableChannelList
                  onReorder={onReorder}
                  filteredChannels={filteredChannels}
                />
                <ChannelSuggestions channelSuggestions={channelSuggestions} />
              </ul>
            )}
            {shouldShowExpandChannelSuggestionsButton && (
              <SideNavItem key={'show-all-channels-button'}>
                <Button
                  variant="tertiary"
                  size="large"
                  onClick={expandChannelSuggestions}
                >
                  <div className={clsx(styles.icon, styles.showMoreChannels)}>
                    <ChevronDownIcon />
                  </div>
                  Show more channels
                </Button>
              </SideNavItem>
            )}
            {!error && channels.length >= CHANNEL_SUGGESTION_THRESHOLD && (
              <NewChannelButton />
            )}
          </>
        )}
      </ul>
      <Separator className={styles.separator} style={{ marginTop: 'auto' }} />
      <ManagementLinks />
    </nav>
  )
}

function NoMatchesFound({
  searchQuery,
}: {
  searchQuery?: string
}): JSX.Element {
  return (
    <div>
      <div className={styles.emptyState}>
        No matches found {searchQuery && `for '${searchQuery}'`}
      </div>
      <Separator className={styles.separator} />
    </div>
  )
}

function ChannelListSkeleton(): JSX.Element {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <SideNavItem className={styles.skeleton} key={index}>
          <Skeleton width={32} height={32} rounded />
          <Skeleton width={100} height={12} rounded />
          <Skeleton
            width={22}
            height={18}
            rounded
            className={styles.skeletonCount}
          />
        </SideNavItem>
      ))}
    </>
  )
}

function ErrorState({ error }: { error: Error }): JSX.Element {
  return (
    <EmptyState variant="critical">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Failed to load</EmptyState.Heading>
      <EmptyState.Description>
        Error happened, please let our team know about it.{' '}
        <Text color="critical">{error.message}</Text>
      </EmptyState.Description>
    </EmptyState>
  )
}

function filterChannels(
  channels: ReturnType<typeof useChannels>['channels'],
  searchQuery: string | null | undefined,
): ReturnType<typeof useChannels>['channels'] {
  if (!searchQuery || searchQuery.trim() === '') return channels

  return channels.filter(
    (channel) =>
      channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      channel.service.toLowerCase().includes(searchQuery.toLowerCase()),
  )
}

function useSearchTracking(
  searchQuery: string,
  channels: ReturnType<typeof useChannels>['channels'],
): void {
  const debouncedSearchQuery = useDebounce(searchQuery, 1000)
  const organizationId = useCurrentOrganization()?.id
  const accountId = useAccountId()

  useEffect(
    function trackChannelsSearch() {
      if (debouncedSearchQuery.trim() === '') {
        return
      }
      const filteredChannels = filterChannels(channels, debouncedSearchQuery)
      BufferTracker.sidebarChannelsFiltered({
        organizationId,
        accountId: accountId || '',
        clientName: Client.PublishWeb,
        product: 'publish',
        searchQuery: debouncedSearchQuery,
        channelCount: channels.length,
        filteredChannelCount: filteredChannels.length,
        channelIDs: channels.map((channel) => channel.id),
        filteredChannelIDs: filteredChannels.map((channel) => channel.id),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to track when the search query changes
    [debouncedSearchQuery],
  )
}
