/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react'
import { useSelector } from 'react-redux'
import { getFragmentData } from '~publish/gql'
import type { FragmentType } from '~publish/gql'

import { selectWeekStartsMonday } from '~publish/legacy/user/selectors'

import DraggablePostItem from '../DraggablePostItem/DraggablePostItem'
import { usePostPermissions } from '../../../../../post/hooks'
import { PostEntity } from '../../../../../post/PostEntity'
import { selectIsManagerInChannel } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import PostItemContent, { CalendarPostCard_Post } from './PostItemContent'

export type PostItemProps = {
  post: FragmentType<typeof CalendarPostCard_Post>
  small: boolean
  scheduledDay: string
  inLastTwoRows: boolean
  index: number
  isDraggingOver: boolean
  setOpenModal:
    | null
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
}

export const PostItem = ({
  small,
  post: maskedPost,
  scheduledDay,
  inLastTwoRows,
  index,
  isDraggingOver,
  setOpenModal,
}: PostItemProps): JSX.Element => {
  const post = getFragmentData(CalendarPostCard_Post, maskedPost)
  const weekStartsOn = useSelector(selectWeekStartsMonday) ? 1 : 0
  const { hasEditPermission } = usePostPermissions(post)
  const isSent = PostEntity.isSent(post)
  const isManager = useAppSelector(selectIsManagerInChannel(post?.channelId))
  const canEdit = hasEditPermission && !isSent

  const hasError = post.status === 'error'

  const shouldDisableDrag = hasError || !canEdit || !isManager

  return (
    <DraggablePostItem
      postId={post?.id}
      index={index}
      shouldDisableDrag={shouldDisableDrag}
      isDraggingOver={isDraggingOver}
    >
      <PostItemContent
        post={maskedPost}
        small={small}
        scheduledDay={scheduledDay}
        inLastTwoRows={inLastTwoRows}
        canEdit={canEdit}
        weekStartsOn={weekStartsOn}
        setOpenModal={setOpenModal}
      />
    </DraggablePostItem>
  )
}
