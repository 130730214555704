import React from 'react'

import { Popover, Card } from '@buffer-mono/legacy-bufferapp-components'
import * as ModalScreens from './screens'
import type { TwoFactorModalProps } from '~publish/legacy/two-factor-auth/types'

type ModalWrapperProps = {
  children: React.ReactNode
  handleClose: () => void
}

const ModalWrapper = ({
  children,
  handleClose,
}: ModalWrapperProps): JSX.Element => (
  <Popover onOverlayClick={handleClose}>
    <div
      style={{
        width: '500px',
      }}
    >
      <Card shadowHeight={2}>
        <div style={{ margin: '16px 40px' }}>{children}</div>
      </Card>
    </div>
  </Popover>
)

const TwoFactorModal = ({
  machineState,
  transition,
  updatePhoneNumber,
  setPhoneNumber,
  submitPhoneNumber,
  loading,
  error,
  setupApp,
  qrCode,
  updateMethod,
  submitCode,
  handleRecoveryCodeSelect,
  editMode,
  recoveryCode,
  initKey,
  email,
}: TwoFactorModalProps): JSX.Element | null => {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const ModalScreen = ModalScreens[machineState]
  if (ModalScreen) {
    return (
      <ModalWrapper handleClose={(): void => transition('CLOSE')}>
        <ModalScreen
          transition={transition}
          setPhoneNumber={setPhoneNumber}
          updatePhoneNumber={updatePhoneNumber}
          submitPhoneNumber={submitPhoneNumber}
          loading={loading}
          error={error}
          setupApp={setupApp}
          qrCode={qrCode}
          updateMethod={updateMethod}
          submitCode={submitCode}
          handleRecoveryCodeSelect={handleRecoveryCodeSelect}
          editMode={editMode}
          recoveryCode={recoveryCode}
          initKey={initKey}
          email={email}
        />
      </ModalWrapper>
    )
  }
  return null
}

export default TwoFactorModal
