import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import GoogleIcon from '@bufferapp/ui/Icon/Icons/Google'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import MastodonIcon from '@bufferapp/ui/Icon/Icons/Mastodon'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import YoutubeIcon from '@bufferapp/ui/Icon/Icons/Youtube'
import XIcon from '@bufferapp/ui/Icon/Icons/X'
import React from 'react'

import {
  BufferIcon,
  CircleInstReminderIcon,
} from '@buffer-mono/legacy-bufferapp-components'

export const renderIcon = (args: {
  hasError: boolean
  isSent: boolean
  isCustomScheduled: boolean
  isReminder: boolean
  isPastReminder: boolean
  via?: string
  isRemindersEnabled: boolean
  dueAt?: number
}): JSX.Element | null => {
  const {
    hasError,
    isSent,
    isCustomScheduled,
    isReminder,
    isPastReminder,
    via,
    isRemindersEnabled,
    dueAt,
  } = args

  const getIconForVia = (via: string): JSX.Element | null => {
    switch (via) {
      case 'Buffer':
        return <BufferIcon color="shuttleGray" />
      case 'Instagram':
        return <InstagramIcon size="medium" color="shuttleGray" />
      case 'Youtube':
        return <YoutubeIcon size="medium" color="shuttleGray" />
      case 'Facebook':
        return <FacebookIcon size="medium" color="shuttleGray" />
      case 'Pinterest':
        return <PinterestIcon size="medium" color="shuttleGray" />
      case 'Twitter':
        return <XIcon size="medium" color="shuttleGray" />
      case 'Linkedin':
        return <LinkedInIcon size="medium" color="shuttleGray" />
      case 'Googlebusiness':
        return <GoogleIcon size="medium" color="shuttleGray" />
      case 'Mastodon':
        return <MastodonIcon size="medium" color="shuttleGray" />
      default:
        return null
    }
  }

  if (hasError) {
    return null
  }

  /* isSent and via are only available for posts that have been sent, not for Reminders */
  if (isSent && via) {
    return getIconForVia(via)
  }

  const isScheduledReminder = dueAt && isReminder
  if (!isRemindersEnabled && (isScheduledReminder || isPastReminder)) {
    return <CircleInstReminderIcon color="instagram" />
  }

  if (isRemindersEnabled && isPastReminder) {
    return <BufferIcon color="shuttleGray" />
  }

  if (dueAt && isCustomScheduled) {
    return <ClockIcon color="shuttleGray" />
  }

  return null
}
