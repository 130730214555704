import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'INIT_PROFILES':
        dispatch(
          dataFetchActions.fetch({
            name: 'profiles',
          }),
        )
        break
      case actionTypes.FETCH_SINGLE_PROFILE:
        dispatch(
          dataFetchActions.fetch({
            name: 'singleProfile',
            args: {
              profileId: action.profileId,
              message: action.message,
            },
          }),
        )
        break
      default:
        break
    }
  }
