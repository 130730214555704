import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actionTypes as orgActionTypes } from '~publish/legacy/organizations'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case orgActionTypes.ORGANIZATION_SELECTED: {
        const { id } = action.selected

        dispatch(
          dataFetchActions.fetch({
            name: 'getCampaignsList',
            args: { id },
          }),
        )
        break
      }

      case `getCampaignsList_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break

      default:
        break
    }
  }
