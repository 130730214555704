/* eslint-disable @typescript-eslint/naming-convention */
import keyMirror from 'keymirror'
import type { ComposerSaveButton } from './stores/types'

export { AttachmentTypes, Services } from '~publish/legacy/constants'

export { NotificationTypes, NotificationScopes } from './values/Notification'

export const DataImportEnvironments = keyMirror({
  BOOKMARKLET_PHP: null, // The document at /add, served through bookmarklet.php
  WEB_DASHBOARD: null, // Anywhere in the web dashboard
  CALENDAR: null, // In the calendar page
  CAMPAIGNS: null, // In the campaigns page
  EXTENSION: null, // In the extension compose page
  CHANNEL: null, // In the single channel page
  ALL_CHANNELS: null, // In all channels page
})

export const LinkAttachmentTextFieldTypes = keyMirror({
  TITLE: null,
  DESCRIPTION: null,
})

export const ErrorTypes = keyMirror({
  INLINE: null,
  FLOATING: null,
})

export const FloatingErrorCodes = [
  401, 403, 404, 405, 429, 1000, 1001, 1002, 1003, 1005, 1006, 1007, 1042, 1050,
  1051,
]

export const UpgradeErrorCodes = {
  queueLimit: 1023, // Reached profile queue limit
}

export const ComposerInitiators = {
  ImageBufferButtons: ['hover_button_image', 'menu-image'],
}

export const QueueingTypes = {
  NEXT: 'NEXT',
  QUEUE: 'QUEUE',
  NOW: 'NOW',
  CUSTOM: 'CUSTOM',
  SAVE: 'SAVE',
  SAVE_AND_APPROVE: 'SAVE_AND_APPROVE',
  ADD_DRAFT: 'ADD_DRAFT',
  NEXT_DRAFT: 'NEXT_DRAFT',
  CUSTOM_DRAFT: 'CUSTOM_DRAFT',
} as const

export const SaveButtonTypes = {
  ADD_TO_QUEUE: 'ADD_TO_QUEUE',
  SHARE_NEXT: 'SHARE_NEXT',
  SHARE_NOW: 'SHARE_NOW',
  SCHEDULE_POST: 'SCHEDULE_POST',
  SAVE: 'SAVE',
  SAVE_AND_APPROVE: 'SAVE_AND_APPROVE',
  ADD_TO_DRAFTS: 'ADD_TO_DRAFTS',
  SCHEDULE_DRAFT: 'SCHEDULE_DRAFT',
} as const

export const PaywallButtonTypes = keyMirror({
  REQUEST_APPROVAL: null,
})

export const InlineSaveButtonTypes: ComposerSaveButton[] = [
  SaveButtonTypes.SAVE,
  SaveButtonTypes.SAVE_AND_APPROVE,
]

export const ButtonsQueuingTypesMap = new Map([
  [SaveButtonTypes.ADD_TO_QUEUE, QueueingTypes.QUEUE],
  [SaveButtonTypes.SHARE_NEXT, QueueingTypes.NEXT],
  [SaveButtonTypes.SHARE_NOW, QueueingTypes.NOW],
  [SaveButtonTypes.SCHEDULE_POST, QueueingTypes.CUSTOM],
  [SaveButtonTypes.SAVE, QueueingTypes.SAVE],
  [SaveButtonTypes.SAVE_AND_APPROVE, QueueingTypes.SAVE_AND_APPROVE],
  [SaveButtonTypes.ADD_TO_DRAFTS, QueueingTypes.ADD_DRAFT],
  [SaveButtonTypes.SCHEDULE_DRAFT, QueueingTypes.CUSTOM_DRAFT],
])

export const AsyncOperationStates = keyMirror({
  PENDING: null,
  DONE: null,
})

export const MediaUploadConfig = {
  endpoint: '/upload/media',
}

export const bufferOriginRegex = /https?:\/\/(?:[^.]+\.)?buffer(?:app)?\.com/
export const bufferOrigins = new Map([
  ['local', 'https://local.buffer.com'],
  [
    'production',
    bufferOriginRegex.test(window.location.origin)
      ? window.location.origin
      : 'https://buffer.com',
  ],
])

export const InstagramAspectRatioLimits = {
  min: 0.8,
  max: 1.91,
}

export const InstagramThumbnailMaxSize = 500

export const COMPOSER_ERROR_CODE = {
  MISSING_PROFILE: 'MISSING_PROFILE',
  PROCESSING: 'PROCESSING',
  ALREADY_SAVED: 'ALREADY_SAVED',
  SCHEDULE_IN_THE_PAST: 'SCHEDULE_IN_THE_PAST',
  NOT_ENABLED: 'NOT_ENABLED',
} as const
