import {
  EyeIcon,
  Lightbox,
  type LightboxProps,
  VisuallyHidden,
  UnstyledButton,
} from '@buffer-mono/popcorn'
import React from 'react'
import clsx from 'clsx'

import { MediaCombinedPreview } from '~publish/components/MediaCombinedPreview'
import { isNotNull, isOfType } from '~publish/helpers/typeGuards'
import { type FragmentType, getFragmentData } from '~publish/gql'

import { PostMediaAsset, PostMediaAsset_Asset } from './PostMediaAsset'
import { getAltText } from './helpers'

import styles from './PostMedia.module.css'

const mapAssetsToLightboxSlides = (
  assets: FragmentType<typeof PostMediaAsset_Asset>[],
): LightboxProps['slides'] => {
  return getFragmentData(PostMediaAsset_Asset, assets)
    .map((asset) => {
      if (isOfType(asset, 'VideoAsset')) {
        return {
          type: 'video' as const,
          poster: asset.thumbnail,
          width: asset.video.width,
          height: asset.video.height,
          sources: [{ src: asset.source, type: asset.mimeType }],
        }
      }

      if (isOfType(asset, 'ImageAsset')) {
        return {
          type: 'image' as const,
          src: asset.source ?? asset.thumbnail,
          alt: getAltText(asset),
          width: asset.image.width,
          height: asset.image.height,
        }
      }
      return null
    })
    .filter(isNotNull)
}

const ClickableAsset = ({
  children,
  index,
}: {
  children: React.ReactNode
  index: number
}): JSX.Element => (
  <div className={styles.mediaWrapper} role="group">
    {children}
    <Lightbox.Trigger index={index} data-no-dnd asChild>
      <UnstyledButton className={styles.viewMedia}>
        <EyeIcon />
        <VisuallyHidden>View</VisuallyHidden>
      </UnstyledButton>
    </Lightbox.Trigger>
  </div>
)

type PostMediaPreviewProps = {
  className?: string
  assets: FragmentType<typeof PostMediaAsset_Asset>[]
}

const PostMediaBentoBoxPreview = ({
  assets,
  className,
}: PostMediaPreviewProps): JSX.Element | null => {
  if (!assets.length) {
    return null
  }

  const slides = mapAssetsToLightboxSlides(assets)

  return (
    <Lightbox slides={slides}>
      <MediaCombinedPreview className={clsx(styles.bento, className)}>
        {assets.map((asset, index) => {
          const { thumbnail } = getFragmentData(PostMediaAsset_Asset, asset)
          return (
            <ClickableAsset key={thumbnail} index={index}>
              <PostMediaAsset
                key={index}
                asset={asset}
                className={styles.item}
              />
            </ClickableAsset>
          )
        })}
      </MediaCombinedPreview>
    </Lightbox>
  )
}

PostMediaBentoBoxPreview.displayName = 'PostMediaBentoBoxPreview'

const PostMediaRowPreview = ({
  assets,
  className,
}: PostMediaPreviewProps): JSX.Element | null => {
  if (!assets.length) {
    return null
  }

  const slides = mapAssetsToLightboxSlides(assets)

  return (
    <Lightbox slides={slides}>
      <div className={clsx(styles.row, className)}>
        {assets.map((asset, index) => (
          <ClickableAsset key={index} index={index}>
            <PostMediaAsset key={index} asset={asset} className={styles.item} />
          </ClickableAsset>
        ))}
      </div>
    </Lightbox>
  )
}

PostMediaRowPreview.displayName = 'PostMediaRowPreview'

export { PostMediaBentoBoxPreview, PostMediaRowPreview }
