import { Plate, type TEditableProps } from '@udecode/plate'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AIContextualMenu from '~publish/legacy/ai/components/Triggers/AIContextualMenu'
import { renderPlaceholderWithAIButton } from '~publish/legacy/ai/helpers/renderPlaceholderWithAIButton'
import { getRandomNumber } from '~publish/legacy/utils/numbers'
import type { BufferEditor, BufferValue } from '../BufferEditor/types.plate'
import { EditorErrorBoundary } from '../components/EditorErrorBoundary'
import { EmojiCombobox } from '../plugins/emoji'

const baseEditableProps: TEditableProps<BufferValue> = {
  placeholder: '',
}

export const IdeasEditor = ({
  editor,
  onChange,
}: {
  editor: BufferEditor
  onChange: (value: BufferValue) => void
}): JSX.Element => {
  const { t } = useTranslation()

  const placeholder = t(`content.editor.placeholder.${getRandomNumber(10)}`)

  baseEditableProps.placeholder = placeholder.concat(' or ')
  baseEditableProps.renderPlaceholder = renderPlaceholderWithAIButton({
    placement: 'ideasEditor',
  })

  const editableProps: TEditableProps<BufferValue> = useMemo(
    () => ({
      ...baseEditableProps,
      spellCheck: true,
      autoFocus: true,
      readOnly: false,
      'data-draftid': 'ideas',
      'data-testid': 'ideas',
      style: { height: '100%' },
    }),
    [],
  )

  return (
    <EditorErrorBoundary editor={editor}>
      <Plate<BufferValue>
        editor={editor}
        onChange={onChange}
        editableProps={editableProps}
      >
        <EmojiCombobox maxSuggestions={8} />
        <AIContextualMenu editor={editor} placement="ideasEditor" />
      </Plate>
    </EditorErrorBoundary>
  )
}
