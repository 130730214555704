import type { Temporal } from '@js-temporal/polyfill'
import { createContext, useContext } from 'react'
import type { AddItemProps, CalendarItem, RenderItemFunction } from './types'

export type CalendarContextType = {
  renderItem: RenderItemFunction
  items: CalendarItem[] | undefined
  addItemProps: AddItemProps
  readOnly: boolean
  selectedDate: Temporal.ZonedDateTime
  is24HourFormat: boolean
  timezone: string
}

export const CalendarContext = createContext<CalendarContextType | undefined>(
  undefined,
)

export const useCalendarContext = (): CalendarContextType => {
  const context = useContext(CalendarContext)
  if (!context) {
    throw new Error('useCalendarContext must be used within a CalendarProvider')
  }
  return context
}
