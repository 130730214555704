import { Text } from '@bufferapp/ui'
import styled from 'styled-components'

import { red, redDark } from '@bufferapp/ui/style/colors'
import { fontSize } from '@bufferapp/ui/style/fonts'
import Tag from '@bufferapp/ui/Tag'

export const PostContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

export const StartContent = styled.div<{ small?: boolean }>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: ${(props): string =>
    props.small ? 'center' : 'space-between'};
  align-items: center;
  align-self: ${(props): string => (props.small ? 'center' : 'flex-start')};
  height: 100%;
`

export const Time = styled(Text)`
  font-weight: 500;
  size: ${fontSize};
  margin: 0 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PostText = styled.p<{ isDraft?: boolean }>`
  margin: 0 0 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: ${(props): number => (props.isDraft ? 1 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  color: var(--color-text-neutral);
  font-size: var(--font-size-sm);
`

export const MiddleContent = styled.div<{ small?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props): string => (props.small ? 'row' : 'column')};
  align-items: ${(props): string => (props.small ? 'center' : 'flex-start')};
  flex-grow: 1;
  overflow: hidden;
`

export const EndContent = styled.div`
  flex-shrink: 0;
  margin-left: 4px;
`

export const RemindersWrapper = styled.div<{ small?: boolean }>`
  margin-top: ${(props): string => (props.small ? '2px' : '0')};
`

export const Icon = styled.div<{ small?: boolean; color?: string }>`
  margin-top: ${(props): string => (props.small ? '0' : '6px')};
  margin-left: ${(props): string => (props.small ? '4px' : '0')};
  background-color: ${(props): string => (props.color ? props.color : red)};
  border-radius: 100%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  svg {
    height: 10px;
    width: 10px;
  }
`

export const ErrorCard = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${redDark};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`

export const Error = styled.div`
  position: absolute;
  right: -9px;
  top: -8px;
  background-color: white;
  border-radius: 100%;
  height: 16px;
`

export const TagContainer = styled.div`
  display: flex;
  /* flex-grow: 1; */
  overflow: hidden;
  max-width: 100%;
`

export const DraftTag = styled(Tag).attrs({
  color: 'blueLight',
})`
  font-size: 12px;
  height: 24px;
  padding: 0 6px;
  margin: 0 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ApprovalTag = styled(DraftTag).attrs({
  color: 'orangeLight',
})``

export const RelativeContainer = styled.div`
  position: relative;
`

export const TooltipWrapper = styled.div`
  svg {
    display: block;
  }
`
