import { type SetStateAction, useCallback, useEffect, useState } from 'react'

export function readLocalStorageValue<T>(key: string, defaultValue: T): T {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

function writeLocalStorageValue<T>(key: string, value: T): void {
  const stringifiedValue = JSON.stringify(value)
  const oldValue = window.localStorage.getItem(key)

  window.localStorage.setItem(key, stringifiedValue)
  const evt = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key,
    oldValue,
    newValue: stringifiedValue,
    url: window.location.href,
  })
  window.dispatchEvent(evt)
}

/**
 * Hook to save state in local storage
 */
export function useLocalStorageState<T>(
  key: string,
  defaultValue: T,
): [T, (value: SetStateAction<T>) => void] {
  const [value, setValue] = useState<T>(() =>
    readLocalStorageValue(key, defaultValue),
  )

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === key) {
        setValue(readLocalStorageValue(key, defaultValue))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, defaultValue])

  const setValueInLocalStorage = useCallback(
    (value: SetStateAction<T>): void => {
      setValue((prev) => {
        const updatedValue: T =
          typeof value === 'function'
            ? (value as (prevState: T) => T)(prev)
            : (value as T)

        try {
          writeLocalStorageValue(key, updatedValue)
        } catch (error) {
          // Ignore error, we should log it somewhere
          console.error(error)
        }

        return updatedValue
      })
    },
    [key],
  )

  return [value, setValueInLocalStorage]
}
