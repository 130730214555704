import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  ChannelAvatar,
  VisuallyHidden,
  IconButton,
  PlusIcon,
  Text,
  LockIcon,
  WarningIcon,
  NewBadge,
  Flex,
  GripVerticalIcon,
} from '@buffer-mono/popcorn'
import { useSortable } from '@dnd-kit/sortable'

import { type FragmentType, graphql, getFragmentData } from '~publish/gql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { usePostCounts } from '~publish/pages/AllChannels/PostList/usePostCounts'
import useDraggingCursor from '~publish/hooks/useDraggingCursor'
import { formatCompactNumber } from '~publish/helpers/numberFormatters'
import {
  channel as channelRoute,
  newCalendarSingleChannel,
  profileTabPages,
} from '~publish/legacy/routes'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import {
  parseQueryParams,
  type QueryParamValue,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import { isFacebookGroup } from '~publish/helpers/channels'

import { NewPostComposerTrigger } from '../NewPostComposerTrigger'
import { FALLBACK_AVATAR, NEW_CHANNELS } from './constants'
import { SideNavItem, type ItemProps } from './SideNavItem'

import styles from './PageSidebar.module.css'

export const ChannelItem_Channel = graphql(/* GraphQL */ `
  fragment ChannelItem_Channel on Channel {
    id
    name
    service
    avatar
    serverUrl
    locationData {
      location
    }
    isLocked
    isDisconnected
  }
`)

interface ChannelItemProps extends Omit<ItemProps, 'children'> {
  channel: FragmentType<typeof ChannelItem_Channel>
  dragged?: boolean
  dragDisabled?: boolean
}

export function ChannelItem({
  channel: passedChannel,
  dragDisabled,
  dragged,
  style,
  className,
}: ChannelItemProps): JSX.Element {
  const channel = getFragmentData(ChannelItem_Channel, passedChannel)
  const organizationId = useOrganizationId() ?? ''
  const url = useChannelPageRoute(channel.id, { keepSearch: true })

  const postCounts = usePostCounts({
    organizationId,
    channelIds: [channel.id],
  })
  const showNewPostButton = !channel.isLocked && !channel.isDisconnected
  const serverUrl = isFacebookGroup(channel) ? null : channel.serverUrl
  const additionalInfo = channel.locationData?.location ?? serverUrl
  const count = postCounts.counts?.queue
  const countLimit = postCounts.counts?.limit
  const isNewChannel = NEW_CHANNELS.includes(channel.service)
  const showCount =
    count !== undefined &&
    count !== null &&
    (!isNewChannel || count > 0) &&
    !channel.isDisconnected &&
    !channel.isLocked

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: channel.id, disabled: dragDisabled })

  useDraggingCursor(isDragging)

  const isDragShadow = !dragged && isDragging

  return (
    <SideNavItem
      id={channel.id}
      ref={setNodeRef}
      style={
        {
          '--translate-x': transform?.x ? `${transform.x}px` : undefined,
          '--translate-y': transform?.y ? `${transform.y}px` : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          transition,
          ...style,
        } as React.CSSProperties
      }
      className={clsx(
        {
          [styles.dragging]: dragged,
        },
        className,
      )}
    >
      {isDragShadow && <div className={styles.dragShadow} />}
      {!dragDisabled && !isDragShadow && (
        <div
          className={styles.dragHandle}
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
        >
          <GripVerticalIcon />
        </div>
      )}
      <NavLink
        to={url}
        className={(isActive: boolean): string =>
          clsx(isActive && styles.selected)
        }
      >
        <ChannelAvatar
          alt={channel.service}
          channel={channel.service}
          src={channel.avatar ?? FALLBACK_AVATAR}
          size="small"
        />
        <Flex direction="column" gap="space-25" className={styles.nameWrapper}>
          <Text
            className={styles.channelName}
            weight="medium"
            data-testid="channel-name"
          >
            {channel.name}
          </Text>
          {additionalInfo && (
            <Text size="xs" color="subtle" className={styles.channelName}>
              {additionalInfo}
            </Text>
          )}
        </Flex>
        {channel.isLocked && <LockIcon size="small" />}
        {channel.isDisconnected && (
          <WarningIcon size="small" color="critical" />
        )}
        {isNewChannel && count === 0 && <NewBadge size="medium" />}
        {showCount && (
          <div className={styles.itemCount}>
            {formatCompactNumber(count, countLimit)}
            <VisuallyHidden> scheduled posts</VisuallyHidden>
          </div>
        )}
        {showNewPostButton && (
          <NewPostComposerTrigger
            channels={[channel.id]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error - NewPostComposerTrigger has a more strict type for CTA, we keep the old CTA for now, just to keep consistency on the reports
            cta={SEGMENT_NAMES.PROFILE_SUGGESTION_SIDEBAR_NEW_POST}
          >
            <IconButton
              label="New Post"
              variant="secondary"
              tooltip="New post"
              size="small"
              className={styles.newPostButton}
            >
              <PlusIcon />
            </IconButton>
          </NewPostComposerTrigger>
        )}
      </NavLink>
    </SideNavItem>
  )
}

function useChannelPageRoute(id: string, { keepSearch = false } = {}): string {
  const [shouldRedirectToChannelPage] = usePublishRevamp()

  const params = useParams<
    { id: string; granularity: 'week' | 'month' } | { id: string }
  >()
  const currentSearch = parseQueryParams(useLocation().search)
  const currentRoute =
    'granularity' in params ? newCalendarSingleChannel : channelRoute

  const search: Record<string, QueryParamValue> = {}
  for (const { param, values: possibleValues } of currentRoute.queryParams) {
    const value = currentSearch[param]
    if (possibleValues && possibleValues.includes(value as any)) {
      search[param] = value
    } else if (!possibleValues && value) {
      search[param] = value
    }
  }

  const newRoute =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We know params is of the proper type because we're getting the params from the current route
    currentRoute.buildPathname({ ...params, id } as any) +
    (keepSearch ? `?${serializeQueryParams(search)}` : '')

  return shouldRedirectToChannelPage
    ? newRoute
    : profileTabPages.getRoute({ profileId: id })
}
