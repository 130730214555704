import React from 'react'
import PropTypes from 'prop-types'
import HashtagGroupItem from '../HashtagGroupItem'
import countHashtagsInText from '../../utils/HashtagCounter'

const contentStyle: React.CSSProperties = {
  flex: '1',
  overflowY: 'auto',
  paddingTop: '24px',
  paddingBottom: '46px',
}

const HashtagGroupList = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'hashtagGroups' implicitly has an ... Remove this comment to see the full error message
  hashtagGroups,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onInsertHashtagGroupClick' implic... Remove this comment to see the full error message
  onInsertHashtagGroupClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditHashtagGroupClick' implicit... Remove this comment to see the full error message
  onEditHashtagGroupClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteHashtagGroupClick' implic... Remove this comment to see the full error message
  onDeleteHashtagGroupClick,
}) => {
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  const children = hashtagGroups.map(({ name, text, _id }) => (
    <HashtagGroupItem
      key={_id}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      snippetId={_id}
      name={name}
      numberOfHashtags={countHashtagsInText(text).toString()}
      hashtags={text}
      onInsertHashtagGroupClick={() => onInsertHashtagGroupClick(name, text)}
      onEditHashtagGroupClick={onEditHashtagGroupClick}
      onDeleteHashtagGroupClick={onDeleteHashtagGroupClick}
    />
  ))
  return <div style={contentStyle}>{children}</div>
}

HashtagGroupList.propTypes = {
  hashtagGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      _id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteHashtagGroupClick: PropTypes.func.isRequired,
  onEditHashtagGroupClick: PropTypes.func.isRequired,
  onInsertHashtagGroupClick: PropTypes.func.isRequired,
}

export default HashtagGroupList
