import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { CtaButtonWrapper } from '~publish/components/CtaButtonWrapper'
import { capitalize } from '~publish/helpers/capitalize'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useHasChannelConnected } from '~publish/legacy/accountContext'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { RegisteredBanner } from '../../../components/RegisteredBanner'

const useCanDisplayBanner = (bannerId: string): boolean => {
  const banner = useDismissableBanner(bannerId)
  const hasBlueSkyChannel = useHasChannelConnected('bluesky')

  const selectedChannel = useSelectedChannel()
  const supportedChannels = ['twitter', 'linkedin', 'threads', 'mastodon']
  const isCurrentChannelSupported =
    !!selectedChannel && supportedChannels.includes(selectedChannel.service)

  return banner.isActive && !hasBlueSkyChannel && isCurrentChannelSupported
}

export const BlueskyPromotionalBanner = (): JSX.Element | null => {
  const bannerId = 'blueskyPromo'

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const selectedChannel = useSelectedChannel()

  const ctaName = `publish-queue-banner-connectBluesky${capitalize(
    selectedChannel?.service ?? '',
  )}-1` as const

  const onBlueskyConnectClick = (): void => {
    window.appshell.actions.connectChannel({
      selectedService: 'bluesky',
      cta: ctaName,
    })
  }

  const canRenderBanner = useCanDisplayBanner(bannerId)

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            Bluesky is now available in Buffer
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description color="subtle">
            Learn more about one of the fastest growing social networks and how
            to make it work for you.
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={ctaName}
              options={{ upgradePathName: 'accountChannels-upgrade' }}
            >
              <Button variant="primary" onClick={onBlueskyConnectClick}>
                Connect Bluesky
              </Button>
            </CtaButtonWrapper>
            <CtaButtonWrapper
              cta={`publish-queue-banner-readBeginnersGuideBluesky-1`}
            >
              <Button
                variant="secondary"
                onClick={(): void => {
                  window.open(
                    `https://buffer.com/resources/bluesky-content-ideas/`,
                    '_blank',
                  )
                }}
              >
                Read our Beginner&apos;s Guide
              </Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/bluesky-banner.png" />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
