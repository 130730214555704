import React, { useCallback } from 'react'
import { Select, Text, AllPostsIcon } from '@buffer-mono/popcorn'
import { FilterButton } from '~publish/components/FilterButton'
import { useMenuItems, useMenuState } from './useFilterState'

export const TypeFilter = (): React.ReactElement => {
  const [items] = useMenuItems()

  const { selectedItem, setFilterValue } = useMenuState()

  const onSelect = useCallback(
    (selected: string): void => {
      const item = items.find((i) => i.id === selected)
      if (item) {
        setFilterValue(item.id)
      }
    },
    [setFilterValue, items],
  )

  return (
    <Select onValueChange={onSelect} value={selectedItem.id}>
      <Select.Trigger placeholder="Fruits">
        <FilterButton icon={<AllPostsIcon />} variant="tertiary" size="medium">
          {selectedItem.title}
        </FilterButton>
      </Select.Trigger>
      <Select.Content>
        {items.map(({ id, title }) => (
          <Select.Item key={id} value={id}>
            <Text weight="medium">{title}</Text>
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  )
}
