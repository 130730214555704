import React from 'react'
import styled from 'styled-components'

import { Add } from '@bufferapp/ui/Icon'
import { fontFamily, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { blue, blueDark, blueLighter } from '@bufferapp/ui/style/colors'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  selectCurrentOrganizationId,
  selectShouldShowNBMigration,
} from '~publish/legacy/organizations/selectors'
import { store, useAppSelector } from '~publish/legacy/store'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { selectIsUploading } from '~publish/legacy/uploads/state/selectors'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import ComposerStore from '../stores/ComposerStore'
import { type Draft, DraftMethods } from '../entities/Draft'
import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'

const AddPostToThread = styled.button<{
  isDisabled: boolean
}>`
  color: ${blue};
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;

    ${(props): string | false =>
      !props.isDisabled &&
      `
        svg {
          color: ${blueDark};
          border-color: ${blueDark};
        }

        span {
          color: ${blueDark};
        }
      `}
  }
`

const StartThread = styled.span<{
  isDisabled: boolean
}>`
  font-size: 14px;
  font-weight: ${fontWeightMedium};
  font-family: ${fontFamily};
  margin-left: 4px;
  color: ${(props): string =>
    props.isDisabled ? `${blueLighter}` : `${blue}`};
`

const AddPostToThreadIcon = styled(Add)`
  border-radius: 50%;
  color: ${(props): string =>
    props.isDisabled ? `${blueLighter}` : `${blue}`};
  border: ${(props): string =>
    props.isDisabled ? `2px solid ${blueLighter}` : `2px solid ${blue}`};
  height: 21px !important;
  width: 21px !important;
  padding: 3px;
  box-sizing: border-box;
`

const StartThreadWrapper = styled.div`
  position: relative;
  display: flex;
`

const AddThread = ({ draft }: { draft: Draft }): JSX.Element | null => {
  const activeThreadId = ComposerStore.getActiveThreadId()

  const { isIntegrationUploading } = useIsIntegrationUploading()
  const isUploading = useAppSelector((state) =>
    selectIsUploading(state, DraftMethods.getUploaderId(draft)),
  )

  const areUploadsInProgress = isIntegrationUploading || isUploading

  if (!draft.service.supportsThread) {
    return null
  }

  const { hasErrors, errors } = draft.canAddToThread(activeThreadId)

  const addPostToThread = (): void => {
    if (areUploadsInProgress) {
      return
    }

    if (selectShouldShowNBMigration(store.getState())) {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      ModalActionCreators.openModal('ThreadsMPPaywall')
      return
    }

    const trackingParams = {
      organizationId: selectCurrentOrganizationId(store.getState()),
      clientName: 'publishWeb',
      product: 'publish',
    }
    /**
     * Calling 'Add Thread Clicked' before calling ComposerActionCreators here is important,
     * because after this call the draft should have a Thread already
     */
    if (!draft.hasThread()) {
      BufferTracker.threadsAddThreadClicked(trackingParams)
    }
    ComposerActionCreators.updateDraftAddPostToThread(draft.id)
    BufferTracker.threadsAddTweetClicked(trackingParams)
  }

  const tipNoErrorsContent = draft.hasThread()
    ? `Add ${draft.service.nameOfPost}`
    : ''
  const container = document.getElementById('composer-root') ?? undefined
  return (
    <StartThreadWrapper>
      {/* TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used */}
      <TempTooltip
        content={!hasErrors ? tipNoErrorsContent : errors.join('\n')}
        container={container}
      >
        <AddPostToThread
          onClick={addPostToThread}
          isDisabled={hasErrors || areUploadsInProgress}
          data-testid="add-post-to-thread-button"
        >
          <AddPostToThreadIcon isDisabled={hasErrors || areUploadsInProgress} />
          {!draft.hasThread() && (
            <StartThread isDisabled={hasErrors || areUploadsInProgress}>
              Start Thread
            </StartThread>
          )}
        </AddPostToThread>
      </TempTooltip>
    </StartThreadWrapper>
  )
}

export default AddThread
