import React, { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import { DropdownMenu } from '@buffer-mono/popcorn'

import { useAccount } from '~publish/legacy/accountContext'
import { selectCampaignTab } from '~publish/legacy/campaign/selectors'
import { ThreadsFreeOBPaywall } from '~publish/legacy/composer/composer/components/ThreadsFreeOBPaywall'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { selectPostById } from '~publish/legacy/post/slice'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { PublishingPost } from '~publish/gql/graphql'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { type RootState, useAppSelector } from '~publish/legacy/store'
import { useShowThreadsUpgrade } from '~publish/legacy/utils/canQueueMoreThreadsForAllProfiles'
import { PostTagsSelector } from '~publish/legacy/shared-components/PostFooter/PostTagsSelector'
import { usePostActions } from '~publish/legacy/post/hooks'
import { MoreActionsDropdown } from '~publish/legacy/shared-components/MoreActionsDropdown/MoreActionsDropdown'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { omitFields } from '~publish/helpers/object'
import { tagsPage } from '~publish/legacy/routes'

import { ButtonStyled } from '../../CardFooter/styles'
import { getThreadedUpdatesUpgradePath } from '~publish/legacy/composer/composer/utils/upgradePaths'

const omittedFields: (keyof RpcUpdate)[] = [
  'id',
  'due_at',
  'day',
  'scheduled_at',
  'scheduledAt',
  'serviceUpdateId',
  'createdAt',
  'statistics',
  'status',
  'serviceLink',
]

type OmittedField = (typeof omittedFields)[number]

type RpcUpdateWithoutOmittedFields = Omit<RpcUpdate, OmittedField>

const omitDuplicatedFields = (
  post: RpcUpdate,
): RpcUpdateWithoutOmittedFields => {
  const newPost = omitFields(post, omittedFields)

  return {
    ...newPost,
    isSent: false,
    isPastDue: false,
    isDraft: false,
  }
}

const DuplicateMenuItem = ({
  post,
}: {
  post: RpcUpdate
}): JSX.Element | null => {
  const { duplicatePostInComposer } = usePostComposer()
  const cta = 'publish-queue-postCard-duplicatePost-1'

  const handleDuplicatePost = useCallback(() => {
    const rpcPost = post
    const prefillPostData = omitDuplicatedFields(rpcPost) as RpcUpdate
    duplicatePostInComposer({
      cta,
      channels: [rpcPost.profileId],
      prefillPostData,
      duplicatedFrom: rpcPost.id,
    })
  }, [duplicatePostInComposer, post])

  return (
    <DropdownMenu.Item onClick={handleDuplicatePost}>
      Duplicate
    </DropdownMenu.Item>
  )
}

type PropType = {
  postId: string
  onShareAgainClick: (args?: { post?: PublishingPost | RpcUpdate }) => void
  onShareLinkClick: (args?: {
    post?: PublishingPost | RpcUpdate
    selectedProfileIds?: string[]
  }) => void
  serviceLink: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  button svg {
    width: 16px;
    height: 16px;
  }
`

export const SentPostFooterActions = ({
  postId,
  onShareAgainClick,
  onShareLinkClick,
  serviceLink,
}: PropType): JSX.Element => {
  const [openModal, setOpenModal] = useState(false)
  const isOnTagsPage = Boolean(useRouteMatch(tagsPage.route))
  const { account } = useAccount()
  const post = useAppSelector((state: RootState) =>
    selectPostById(state, postId),
  )
  const campaignTab = useAppSelector(selectCampaignTab)
  const {
    shouldShowThreadsMigrationModal,
    service: threadsMigrationModalService,
  } = useShowThreadsUpgrade(post)
  const profiles = useAppSelector(selectProfiles)
  const selectedProfileIds = profiles.map((profile) => profile.id)

  const onShareLinkClickWithArgs = (): void =>
    onShareLinkClick({ post, selectedProfileIds })
  const onShareAgainClickWithArgs = (): void => onShareAgainClick({ post })
  const { stackedActions, executePostActionById } = usePostActions({
    post,
    onShareLinkClick: onShareLinkClickWithArgs,
    onShareAgainClick: onShareAgainClickWithArgs,
  })

  const channelName = PostEntity.getChannelName(post)
  const postHasServiceLink = PostEntity.hasServiceLink(post)
  const isShareAgainActionSupported = PostEntity.isShareAgainActionSupported({
    post,
    channelName,
  })
  const isPostChannelSupportedNewPostAnalytics =
    PostEntity.isPostChannelSupportedNewPostAnalytics({
      post,
    })

  const closeModal = (): void => {
    setOpenModal(false)
  }
  return (
    <Container>
      {serviceLink && (
        <ButtonStyled
          type="text"
          size="small"
          label="View Post"
          onClick={(): void => {
            window.open(serviceLink, '_blank')
          }}
        />
      )}
      <PostTagsSelector postId={postId} />
      {((): JSX.Element | undefined => {
        if (shouldShowThreadsMigrationModal) {
          const commonTrackingProps =
            account?.currentOrganization?.commonTrackingProperties || null
          const upgradePathName = getThreadedUpdatesUpgradePath(
            threadsMigrationModalService,
          )

          BufferTracker.cTAViewed({
            organizationId: account?.currentOrganization?.id || null,
            cta: `publish-sentPosts-post-upgradeToScheduleThread-1`,
            upgradePathName,
            product: 'publish',
            ...commonTrackingProps,
          })
          return (
            <ButtonStyled
              data-testid="threads-free-paywall-button"
              type="primary"
              size="small"
              icon={<FlashIcon color="white" />}
              label="Upgrade to Schedule Thread"
              onClick={(): void => {
                BufferTracker.cTAClicked({
                  organizationId: account?.currentOrganization?.id || '',
                  cta: `publish-sentPosts-post-upgradeToScheduleThread-1`,
                  upgradePathName,
                  product: 'publish',
                  clientName: 'publishWeb',
                  ...commonTrackingProps,
                })
                setOpenModal(true)
              }}
            />
          )
        }

        if (
          postHasServiceLink &&
          isPostChannelSupportedNewPostAnalytics &&
          !campaignTab &&
          stackedActions.length > 0
        ) {
          const [firstAction, ...restStackedActions] = stackedActions

          return (
            <ButtonStyled
              type="secondary"
              size="small"
              isSplit={!!restStackedActions && restStackedActions.length > 0}
              items={restStackedActions}
              label={firstAction.title}
              onClick={firstAction.callback}
              onSelectClick={executePostActionById}
            />
          )
        }
        if (isShareAgainActionSupported && isOnTagsPage) {
          return (
            <ButtonStyled
              type="secondary"
              size="small"
              label="Share Again"
              onClick={(): void => onShareAgainClick({ post })}
            />
          )
        }
      })()}
      {openModal && (
        <ThreadsFreeOBPaywall
          closeFirstModal={closeModal}
          ctaString="sentPosts-upgradeButton-upgradeToScheduleThread-1"
          service={threadsMigrationModalService}
        />
      )}
      {!isOnTagsPage && (
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <DuplicateMenuItem post={post as RpcUpdate} />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      )}
    </Container>
  )
}
