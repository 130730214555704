import {
  type AnyObject,
  createPluginFactory,
  StyledLeaf,
  withProps,
  type RenderFunction,
} from '@udecode/plate'
import { css } from 'styled-components'
import { redLightest } from '@bufferapp/ui/style/colors'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'

export const CHARACTER_LIMIT_HIGHLIGHT_KEY = 'character-limit-highlight'

export const createCharacterLimitHighlightPlugin = createPluginFactory<
  AnyObject,
  BufferValue,
  BufferEditor
>({
  key: CHARACTER_LIMIT_HIGHLIGHT_KEY,
  isLeaf: true,
  component: withProps(StyledLeaf, {
    styles: {
      root: css`
        background-color: ${redLightest};
      `,
    },
  }) as RenderFunction<any>,
})
