import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import type {
  AdditionalProperties,
  CallbackMethods,
  CTAPayload,
  UpgradePathPayload,
  ChannelCTAPayload,
} from './trackingTypes'
import { useAccount } from '~publish/legacy/accountContext'

import {
  createEventTrackingData,
  getCoreTrackingMetadata,
} from './trackingHelpers'

export const useTracking = (): CallbackMethods => {
  const { account } = useAccount()

  const metadataAccount = getCoreTrackingMetadata(account)
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  const trackEvent = (
    method: keyof typeof BufferTracker,
    additionalProps: AdditionalProperties,
  ): void => {
    const eventData = createEventTrackingData(
      metadataAccount,
      commonTrackingProperties,
      additionalProps,
    )

    const trackingMethod = BufferTracker[method] as unknown as (
      eventData: any,
    ) => void
    trackingMethod(eventData)
  }

  /* CTAs EVENTS */
  const trackUpgradePathViewed = (payload: UpgradePathPayload): void =>
    trackEvent('upgradePathViewed', payload)
  const trackCTAViewed = (payload: CTAPayload): void =>
    trackEvent('cTAViewed', payload)
  const trackCTAClicked = (payload: CTAPayload): void =>
    trackEvent('cTAClicked', payload)

  /* CHANNELS EVENTS */
  const trackChannelCTAViewed = (payload: ChannelCTAPayload): void =>
    trackEvent('channelCTAViewed', payload)
  const trackChannelCTAClicked = (payload: ChannelCTAPayload): void =>
    trackEvent('channelCTAClicked', payload)

  return {
    trackUpgradePathViewed,
    trackCTAViewed,
    trackCTAClicked,
    trackChannelCTAViewed,
    trackChannelCTAClicked,
  }
}
