/**
 * @module IdeaComposer
 */

import { useControllableState } from '@buffer-mono/popcorn'
import React, { useCallback, useEffect } from 'react'
import { useIdeasEditor } from '~publish/pages/Create/hooks/useIdeasEditor'
import { useIdeasCount } from '~publish/pages/Create/hooks/useIdeasCount'
import { useOrganizationLimits } from '~publish/legacy/accountContext'
import { IdeaEditor } from '~publish/pages/Create/components/IdeaEditor'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectUserIdAndEmail } from '~publish/legacy/user/selectors'

import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { createPostFromIdea } from '~publish/pages/Create/state/thunks/createPostFromIdea'
import * as Dialog from '@radix-ui/react-dialog'
import type { NewIdea } from '~publish/pages/Create/types'

export type CTA = `publish-${string}-${string}-${string}-${number}`

interface IdeaComposerProps {
  children?: React.ReactNode
  cta?: CTA
  idea?: NewIdea
  open?: boolean
  modal?: boolean
  onOpenChange?: (open: boolean) => void
  onLimitReached?: () => void
}

/**
 * Props for the IdeaComposer component
 * @typedef {Object} IdeaComposerProps
 * @property {React.ReactNode} [children] - Child element to act as trigger
 * @property {string} [source] - CTA source identifier for the idea
 * @property {NewIdea} [idea] - Idea or prefilled data for the new idea
 * @property {() => void} [onLimitReached] - Callback function when idea limit is reached
 * @property {boolean} [open] - Controls whether the composer is open
 * @property {boolean} [modal] - Whether the composer should be rendered as a modal
 * @property {(open: boolean) => void} [onOpenChange] - Callback function when open state changes
 */

/**
 * IdeaComposer component
 *
 * This component provides a composable interface for creating new ideas.
 *
 * @param {IdeaComposerProps} props - The component props
 * @returns {JSX.Element | null} The rendered component
 *
 * @example
 * ```tsx
 * <IdeaComposer source="create-gallery-toolbar-newIdea-1" idea={{content: {text: "New idea"}}}>
 *  <Button>New Idea</Button>
 * </IdeaComposer>
 * ```
 *
 * @example
 * ```tsx
 * <IdeaComposer open={isOpen} onOpenChange={setIsOpen} idea={idea}/>
 * ```
 */
const IdeaComposer = ({
  children,
  cta: source,
  idea,
  onLimitReached,
  open: openProp,
  modal,
  onOpenChange: onOpenChangeProp,
}: IdeaComposerProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useControllableState({
    prop: openProp,
    defaultProp: false,
    onChange: onOpenChangeProp,
  })
  const {
    openEditor,
    setGroupId,
    setTitle,
    setSelectedTags,
    saveIdea,
    getCurrentContent,
    closeEditor,
    plateEditor,
    tags,
    groupId,
    title,
    errorMessage,
  } = useIdeasEditor()
  const { ideasCount } = useIdeasCount()
  const { ideas: ideasLimit } = useOrganizationLimits()
  const user = useAppSelector(selectUserIdAndEmail)
  const profiles = useAppSelector(selectProfiles)
  const dispatch = useAppDispatch()

  const handleOpenTrigger = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.preventDefault()

      if (ideasCount >= ideasLimit) {
        // When the idea limit is reached, call the onLimitReached callback and don't open the editor
        onLimitReached?.()
        return
      }

      setIsOpen(true)
    },
    [ideasCount, ideasLimit, setIsOpen, onLimitReached],
  )

  useEffect(() => {
    if (idea?.groupId) {
      setGroupId(idea.groupId)
    }
    if (idea?.content?.title) {
      setTitle(idea.content.title)
    }
    if (idea?.content?.tags) {
      setSelectedTags(idea.content.tags)
    }

    openEditor(idea, { source })
  }, [
    ideasCount,
    ideasLimit,
    idea,
    openEditor,
    source,
    setIsOpen,
    onLimitReached,
    setGroupId,
    setTitle,
    setSelectedTags,
  ])

  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (!open) {
        closeEditor()
      }
    },
    [closeEditor, setIsOpen],
  )

  const onSaveIdea = useCallback(() => {
    console.log('onSaveIdea')
    saveIdea(source)
    closeEditor()
    setIsOpen(false)
  }, [closeEditor, saveIdea, source, setIsOpen])

  const onCreatePost = useCallback(() => {
    const idea = getCurrentContent()
    if (idea) {
      dispatch(createPostFromIdea({ profiles, idea, user }))
      closeEditor()
      setIsOpen(false)
    }
  }, [getCurrentContent, dispatch, profiles, user, closeEditor, setIsOpen])

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={onOpenChange} modal={modal}>
        {children && (
          <Dialog.Trigger
            asChild
            onClick={handleOpenTrigger}
            aria-controls="ideas-content-wrapper"
          >
            {children}
          </Dialog.Trigger>
        )}
        <Dialog.Portal>
          <IdeaEditor
            onSaveIdea={onSaveIdea}
            errorMessage={errorMessage}
            onCreatePost={onCreatePost}
            onOpenChange={onOpenChange}
            currentIdea={getCurrentContent()}
            plateEditor={plateEditor}
            onSelectTags={setSelectedTags}
            preSelectedTags={tags}
            groupId={groupId}
            setGroupId={setGroupId}
            title={title}
            setTitle={setTitle}
          />
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export { IdeaComposer }
export type { IdeaComposerProps }
