import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { client, gql } from '~publish/legacy/apollo-client'
import type { UploadDetails } from '~publish/gql/graphql'
import type { Profile } from '~publish/legacy/profile-sidebar/types'
import type { UserTag } from '~publish/legacy/user/types'
import {
  parseIdeaToComposerPost,
  getFirstVideo,
  getFirstDocument,
} from '~publish/pages/Create/helpers/parsers'
import type {
  Media,
  ExtendedIdea,
  ExtendedMedia,
  Idea,
  NewIdea,
} from '~publish/pages/Create/types'
import { isExtendedMedia } from '~publish/pages/Create/helpers'

const GET_UPLOAD = gql`
  query upload($input: UploadIdInput!) {
    upload(input: $input) {
      ... on UploadDetails {
        id
        type
        height
        width
        duration
        durationMs
        location
        transcodedLocation
      }
      ... on CoreWebAppCommonError {
        success
        message
      }
    }
  }
`

export const createPostFromIdea = createAsyncThunk<
  void,
  { profiles: Profile[]; idea: Idea | NewIdea; user: UserTag }
>('ideas/createPostFromIdea', async ({ idea, user }, { dispatch }) => {
  try {
    const mediaToParse = idea?.content?.media || []
    const firstVideo = getFirstVideo(mediaToParse) as ExtendedMedia

    let extendedVideo: ExtendedMedia | undefined

    if (firstVideo) {
      const uploadId = firstVideo.id
      if (!uploadId) return

      const response = await client.query<
        {
          upload: UploadDetails
        },
        { input: { id: string } }
      >({
        query: GET_UPLOAD,
        variables: { input: { id: uploadId } },
      })

      if (response.data.upload) {
        const {
          height,
          width,
          duration,
          durationMs,
          location,
          transcodedLocation,
        } = response.data.upload
        extendedVideo = {
          ...firstVideo,
          height,
          width,
          duration,
          durationMs,
          location: location ?? undefined,
          transcodedLocation: transcodedLocation ?? undefined,
        }
      }
    }

    const firstDocument = getFirstDocument(mediaToParse)
    let updatedMedia = mediaToParse

    if (extendedVideo && isExtendedMedia(extendedVideo)) {
      updatedMedia = mediaToParse.map((media) =>
        media?.id === firstVideo?.id ? (extendedVideo as Media) : media,
      )
    } else if (firstDocument) {
      updatedMedia = mediaToParse.map((media) =>
        media?.id === firstDocument?.id ? (firstDocument as Media) : media,
      )
    }

    const payload = {
      selectedProfileIds: [],
      shouldResetComposerData: false,
      post: parseIdeaToComposerPost(
        {
          ...(idea as ExtendedIdea),
          content: { ...idea.content, media: updatedMedia },
        },
        user,
      ),
      idea: idea as ExtendedIdea,
    }

    dispatch(composerPopoverActions.handleCreatePostClick(payload))
  } catch (error) {
    toast.error(
      `Sorry! Something went wrong while creating your post. Would you be up for trying again?`,
    )
  }
})
