import React from 'react'

import {
  Button,
  Dialog,
  Switch,
  Textarea,
  useControllableState,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { Text } from '@bufferapp/ui'

import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'
import { Row } from '~publish/legacy/shared-components'

import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import styles from './NewPublishExperience.module.css'

const OptOutDialog = ({
  children,
  onOptedOut,
  onOpenChange: onOpenChangeProp,
  open: openProp,
}: {
  children?: React.ReactNode
  onOptedOut: () => void
  onOpenChange?: (open: boolean) => void
  open?: boolean
}): JSX.Element => {
  const [open, onOpenChange] = useControllableState({
    defaultProp: false,
    prop: openProp,
    onChange: onOpenChangeProp,
  })
  const organizationId = useOrganizationId()
  const { account } = useAccount()
  const [feedback, setFeedback] = React.useState<string>('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    BufferTracker.feedbackFormSubmitted({
      clientName: 'publishWeb',
      organizationId: organizationId ?? '',
      userId: account.id ?? '',
      widgetId: 'single-channel-opt-out-1',
      source: 'publish',
      text: feedback ?? '(no feedback provided)',
    })

    setFeedback('')
    onOptedOut()
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content asChild>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Dialog.Header>
            <Dialog.Title>Sorry to see you go 😞</Dialog.Title>
            <Dialog.Description>
              Let us know why you&apos;ve disabled this new experience. You can
              always enable it again in the future.
            </Dialog.Description>
          </Dialog.Header>

          <Dialog.Body>
            <div>
              <VisuallyHidden as="label" htmlFor="feedback">
                Feedback
              </VisuallyHidden>
              <Textarea
                id="feedback"
                rows={3}
                resize="none"
                placeholder="Share your feedback"
                maxLength={500}
                value={feedback}
                onChange={(event): void => setFeedback(event.target.value)}
              />
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button variant="secondary">Keep Enabled</Button>
            </Dialog.Close>
            <Button type="submit" variant="primary">
              Disable New Experience
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  )
}

export const NewPublishExperience = (): JSX.Element | null => {
  const [
    isNewPublishEnabled,
    { setPreference, isSplitEnabled: isPublishRevampSplitEnabled },
  ] = usePublishRevamp()
  const [optOutDialogOpen, setOptOutDialogOpen] = React.useState(false)

  const handleNewPublishOptOut = (): void => {
    setPreference('disabled')
    setOptOutDialogOpen(false)
  }

  const handleNewPublishOptIn = (): void => {
    setPreference('enabled')
  }

  if (!isPublishRevampSplitEnabled) {
    return null
  }

  return (
    <Row>
      <div>
        <Text type="h3">New Publish Experience</Text>
        <div
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Text type="p">
            Temporarily enable/disable the new Publish experience. This
            experience <strong>will be permanently enabled soon.</strong>
          </Text>
        </div>
      </div>

      <OptOutDialog
        onOptedOut={handleNewPublishOptOut}
        open={optOutDialogOpen}
      />

      <Switch
        id="new-publish-experience"
        checked={isNewPublishEnabled}
        onCheckedChange={(status): void => {
          if (status) {
            handleNewPublishOptIn()
          } else {
            setOptOutDialogOpen(true)
          }
        }}
      >
        {isNewPublishEnabled ? 'Enabled' : 'Disabled'}
      </Switch>
    </Row>
  )
}
