import React from 'react'
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom'

import {
  ideaDetailsRoute,
  ideaEditRoute,
  ideaNewRoute,
} from '~publish/legacy/routes'

import { IdeaCreationDialog } from './IdeaCreationDialog'
import { IdeaDetailsDialog } from './IdeaDetailsDialog'
import { IdeaEditDialog } from './IdeaEditDialog'

export function IdeaManagementRouter(): JSX.Element {
  const match = useRouteMatch()
  const { search } = useLocation()

  return (
    <Switch>
      <Route path={`${match.path}${ideaNewRoute.route}`}>
        <IdeaCreationDialog parent={match.url + search} />
      </Route>
      <Route path={`${match.path}${ideaEditRoute.route}`}>
        <IdeaEditDialog parent={match.url + search} />
      </Route>
      <Route path={`${match.path}${ideaDetailsRoute.route}`}>
        <IdeaDetailsDialog parent={match.url + search} />
      </Route>
    </Switch>
  )
}
