import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { createNextState } from '@reduxjs/toolkit'
import { parseNotes } from '~publish/legacy/duplicate-server/parsers/postParser'
import { noteAdded, noteDeleted, noteUpdated } from '~publish/legacy/post/slice'
import { PostEntity } from '../post/PostEntity'

export const actionTypes = {
  DRAFT_CREATED: 'DRAFTS__DRAFT_CREATED',
  DRAFT_UPDATED: 'DRAFTS__DRAFT_UPDATED',
  DRAFT_MOVED: 'DRAFTS__DRAFT_MOVED',
  DRAFT_DELETED: 'DRAFTS__DRAFT_DELETED',
  DRAFT_APPROVED: 'DRAFTS__DRAFT_APPROVED',
  DRAFT_APPROVE: 'DRAFTS__DRAFT_APPROVE',
  DRAFT_NEEDS_APPROVAL: 'DRAFTS__DRAFT_NEEDS_APPROVAL',
  DRAFT_CONFIRMED_DELETE: 'DRAFTS__DRAFT_CONFIRMED_DELETE',
}

export const initialState = {
  byProfileId: {},
}

const profileInitialState = {
  loading: true,
  loadingMore: false,
  moreToLoad: false,
  page: 1,
  drafts: {},
  total: 0,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const getProfileId = (action) => {
  if (action.profileId) {
    return action.profileId
  }
  if (action.args) {
    return action.args.profileId
  }
  if (action.profile) {
    return action.profile.id
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const getDraftUpdateId = (action) => {
  if (action.updateId) {
    return action.updateId
  }
  if (action.args) {
    return action.args.updateId
  }
  if (action.draft) {
    return action.draft.id
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const determineIfMoreToLoad = (action, currentPosts) => {
  const currentPostCount = Object.keys(currentPosts).length
  const { drafts = {} } = action.result
  const resultUpdatesCount = Object.keys(drafts).length
  return action.result.total > currentPostCount + resultUpdatesCount
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const draftReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DRAFT_CREATED:
      return action.draft
    case actionTypes.DRAFT_CONFIRMED_DELETE:
      return {
        ...state,
        isConfirmingDelete: false,
        isDeleting: true,
      }
    case actionTypes.DRAFT_APPROVE:
      return {
        ...state,
        isWorking: true,
      }
    case actionTypes.DRAFT_NEEDS_APPROVAL:
      return {
        ...state,
        isMoving: true,
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const draftsReducer = (state = {}, action) => {
  switch (action.type) {
    case `draftPosts_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const { drafts = {} } = action.result
      if (
        action.args.isFetchingMore ||
        Object.keys(state).length > Object.keys(drafts).length
      ) {
        return { ...state, ...drafts }
      }
      return drafts
    }
    case actionTypes.DRAFT_DELETED: {
      // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
      const { [getDraftUpdateId(action)]: deleted, ...currentState } = state
      return currentState
    }
    case actionTypes.DRAFT_APPROVED: {
      // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
      const { [getDraftUpdateId(action)]: approved, ...newState } = state
      return newState
    }
    case actionTypes.DRAFT_UPDATED:
    case actionTypes.DRAFT_MOVED:
    case actionTypes.DRAFT_CREATED: {
      return { ...state, [getDraftUpdateId(action)]: action.draft }
    }
    case actionTypes.DRAFT_CONFIRMED_DELETE:
    case actionTypes.DRAFT_APPROVE:
    case actionTypes.DRAFT_NEEDS_APPROVAL:
      return {
        ...state,
        [getDraftUpdateId(action)]: draftReducer(
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          state[getDraftUpdateId(action)],
          action,
        ),
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const profileReducer = (state = profileInitialState, action) => {
  switch (action.type) {
    case `draftPosts_${dataFetchActionTypes.FETCH_START}`:
      if (action.args.clear) {
        return {
          loading: !action.args.isFetchingMore,
          loadingMore: action.args.isFetchingMore,
          moreToLoad: false,
          page: 1,
          drafts: {},
          total: 0,
        }
      }
      return {
        ...state,
        loading: !action.args.isFetchingMore,
        loadingMore: action.args.isFetchingMore,
      }
    case `draftPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        moreToLoad: determineIfMoreToLoad(action, state.drafts),
        page: state.page + 1,
        drafts: draftsReducer(state.drafts, action),
        total: action.result.total,
      }
    case `draftPosts_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.DRAFT_CREATED:
    case actionTypes.DRAFT_UPDATED:
    case actionTypes.DRAFT_MOVED:
    case actionTypes.DRAFT_DELETED:
    case actionTypes.DRAFT_APPROVED:
    case actionTypes.DRAFT_CONFIRMED_DELETE:
    case actionTypes.DRAFT_APPROVE:
    case actionTypes.DRAFT_NEEDS_APPROVAL:
      return {
        ...state,
        drafts: draftsReducer(state.drafts, action),
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  let profileId
  switch (action.type) {
    case `draftPosts_${dataFetchActionTypes.FETCH_START}`:
    case `draftPosts_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `draftPosts_${dataFetchActionTypes.FETCH_FAIL}`:
    case actionTypes.DRAFT_CREATED:
    case actionTypes.DRAFT_UPDATED:
    case actionTypes.DRAFT_MOVED:
    case actionTypes.DRAFT_DELETED:
    case actionTypes.DRAFT_APPROVED:
    case actionTypes.DRAFT_CONFIRMED_DELETE:
    case actionTypes.DRAFT_APPROVE:
    case actionTypes.DRAFT_NEEDS_APPROVAL:
      profileId = getProfileId(action)
      if (profileId) {
        return {
          ...state,
          byProfileId: {
            ...state.byProfileId,
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            [profileId]: profileReducer(state.byProfileId[profileId], action),
          },
        }
      }
      return state
    case noteAdded.type:
    case noteUpdated.type:
    case noteDeleted.type: {
      const { postId, profileId: currentProfileId, notes } = action.payload
      return createNextState(state, (draftState) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const post = draftState.byProfileId[currentProfileId]?.drafts?.[postId]
        if (post) post.notes = parseNotes(notes)
        return draftState
      })
    }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  handleApproveClick: ({ draft }) => ({
    type: actionTypes.DRAFT_APPROVE,
    updateId: draft.id,
    isScheduled: PostEntity.isScheduled(draft),
    draft,
    profileId: draft.profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  handleRequestApprovalClick: ({ draft, needsApproval, profileId = null }) => ({
    type: actionTypes.DRAFT_NEEDS_APPROVAL,
    updateId: draft.id,
    needsApproval,
    draft,
    profileId,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  handleDeleteConfirmClick: ({ draft, profileId }) => ({
    type: actionTypes.DRAFT_CONFIRMED_DELETE,
    updateId: draft.id,
    draft,
    profileId,
  }),
}
