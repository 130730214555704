import {
  actions as asyncDataFetch,
  actionTypes as asyncDataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { actionTypes } from './actions'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    const { hasTwentyFourHourTimeFormat, week_starts_monday } = getState().user
    switch (action.type) {
      case actionTypes.CHANGE_TWENTY_FOUR_HOUR_FORMAT:
        if (hasTwentyFourHourTimeFormat !== action.twentyFourHourFormat) {
          dispatch(
            asyncDataFetch.fetch({
              name: 'changeDateTimePreferences',
              args: {
                twentyfour_hour_time: action.twentyFourHourFormat,
              },
            }),
          )
        }
        break
      case actionTypes.CHANGE_START_OF_WEEK:
        if (week_starts_monday !== action.weekStartsOnMonday) {
          dispatch(
            asyncDataFetch.fetch({
              name: 'changeDateTimePreferences',
              args: {
                week_starts_monday: action.weekStartsOnMonday,
              },
            }),
          )
        }
        break
      case `changeDateTimePreferences_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          notificationActions.createNotification({
            message: 'Great! Your preferences have been saved',
          }),
        )
        break
      default:
        break
    }
    return next(action)
  }
