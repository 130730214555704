import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import { InfoIcon, Tooltip } from '@buffer-mono/popcorn'

import { useAppSelector } from '~publish/legacy/store'
import { showInstagramNotificationsPreview } from '~publish/legacy/reminders/selectors'
import { InstagramNotificationsPreview } from './components/InstagramNotificationsPreview'

import * as Styles from './PostPreview.styles'
import { PREVIEWS_MAP } from './previews'
import type { DraftPreview, ProfilePreview } from './previews/types'
import { DEFAULT_AVATAR } from './previews/Common/utils'

export const PostPreview: React.FC<{
  draftPreview: DraftPreview
  profilePreview: ProfilePreview
}> = ({ draftPreview, profilePreview }) => {
  const { isEnabled: isInstagramRemindersEnabled } = useSplitEnabled(
    'instagram-reminders',
  )

  const { name: serviceName } = profilePreview ?? {}

  const showNotificationsPreviewFromState = useAppSelector(
    showInstagramNotificationsPreview,
  )

  if (!profilePreview || !draftPreview || draftPreview.service.isOmni) {
    return null
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const preview = PREVIEWS_MAP[serviceName] || null
  if (!preview) return null

  const { title, helpText, Preview: PreviewComponent } = preview

  const isInstagramReminder =
    isInstagramRemindersEnabled &&
    draftPreview.isReminder &&
    serviceName.toLowerCase() === 'instagram'

  const showInstagramRemindersMobilePreview =
    isInstagramReminder && showNotificationsPreviewFromState

  return (
    <Styles.Wrapper id="post-preview">
      <Styles.HeaderWrapper>
        <Styles.TitleWrapper>
          <Styles.Title>{title}</Styles.Title>
          <Tooltip content={helpText}>
            <Styles.Icon>
              <InfoIcon size="small" color="subtle" />
            </Styles.Icon>
          </Tooltip>
        </Styles.TitleWrapper>
      </Styles.HeaderWrapper>
      {showInstagramRemindersMobilePreview ? (
        <InstagramNotificationsPreview />
      ) : (
        <PreviewComponent
          draft={draftPreview}
          profile={{
            ...profilePreview,
            avatar: profilePreview.avatar || DEFAULT_AVATAR,
          }}
        />
      )}
    </Styles.Wrapper>
  )
}
