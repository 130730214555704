import React from 'react'
import { Link } from 'react-router-dom'
import { GlobeIcon, Tooltip, Button } from '@buffer-mono/popcorn'
import {
  formatTimeZone,
  getCurrentTimeZone,
} from '~publish/helpers/dateFormatters'
import * as routes from '~publish/legacy/routes'

const getShortenedTimezone = (timezone: string): string => {
  const timezoneParts = timezone.split('/')
  return timezoneParts[timezoneParts.length - 1]
}

export const TimezoneDisplay = ({
  channelId,
  disabled,
  timezone,
}: {
  channelId?: string
  disabled?: boolean
  timezone?: string
}): JSX.Element => {
  const displayTimezone = formatTimeZone(timezone ?? getCurrentTimeZone())

  const content = (
    <>
      <GlobeIcon aria-hidden={false} aria-label="Time zone" />
      {getShortenedTimezone(displayTimezone)}
    </>
  )

  if (channelId) {
    return (
      <Tooltip content={`Channel time zone: ${displayTimezone}`}>
        <Button
          variant="tertiary"
          as={Link}
          to={routes.channelSettings.getRoute({
            channelId,
            tab: 'posting-schedule',
          })}
          disabled={disabled}
        >
          {content}
        </Button>
      </Tooltip>
    )
  }
  return (
    <Tooltip content={`Current time zone: ${displayTimezone}`}>
      <Button variant="tertiary" disabled>
        {content}
      </Button>
    </Tooltip>
  )
}
