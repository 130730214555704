import React, { useEffect } from 'react'
import * as RadixSelect from '@radix-ui/react-select'

import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

import { SelectTrigger } from './SelectTrigger'
import { SelectOptions } from './SelectOptions'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import { useRemindersToggle } from '../../../reminders-bar/hooks'

export const Select = ({
  draft,
  disabled,
  disableOptionDescription,
  isAutomaticPostingPossible,
  id,
}: {
  draft: Draft
  disabled?: boolean | undefined
  id: string
  disableOptionDescription: string
  isAutomaticPostingPossible: boolean
}): React.JSX.Element => {
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const { isOn, toggleReminder } = useRemindersToggle(draft)

  const handleSchedulingTypeChange = ({
    draft,
    schedulingType,
  }: {
    draft: Draft
    schedulingType: string
  }): void => {
    const isReminder = schedulingType === 'notifyMe'
    toggleReminder(isReminder)

    BufferTracker.schedulingTypeChanged({
      organizationId,
      channel: draft.service.name,
      clientName: Client.PublishWeb,
      schedulingType: isReminder ? 'notification' : 'automatic',
      updateType: draft.updateType ?? 'post',
    })
  }

  useEffect(() => {
    if (!isAutomaticPostingPossible && !draft.isReminder) {
      handleSchedulingTypeChange({
        draft,
        schedulingType: 'notifyMe',
      })
    }
  })

  return (
    <RadixSelect.Root
      name="notifySetting"
      value={isOn ? 'notifyMe' : 'automatic'}
      onValueChange={(value): void =>
        handleSchedulingTypeChange({
          draft,
          schedulingType: value,
        })
      }
      disabled={disabled}
    >
      <SelectTrigger
        draft={draft}
        disabled={disabled}
        id={id}
        isAutomaticPostingPossible={isAutomaticPostingPossible}
      />
      <SelectOptions
        draft={draft}
        disableOptionDescription={disableOptionDescription}
        isAutomaticPostingPossible={isAutomaticPostingPossible}
      />
    </RadixSelect.Root>
  )
}
