import { useEffect } from 'react'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import type { ComposerProfile } from '~publish/legacy/composer/composer/stores/types'

export const useRemindersToggle = (
  draft: Draft,
): {
  isOn: boolean
  toggleReminder: (isReminder?: boolean) => void
} => {
  const selectedProfiles: ComposerProfile[] = AppStore.getSelectedProfiles()

  const { editMode, shareAgainMode } = AppStore.getMetaData()
  const defaultToReminders = selectedProfiles?.some(
    (profile) => profile.defaultToReminders,
  )

  const isOn = draft.isReminder ?? false

  const toggleReminder = (isReminder = !isOn): void => {
    ComposerActionCreators.updateDraftIsReminder(draft.id, isReminder)
    /* Stories sent as reminders can have up to 10 images, so we need to update maxAttachableImagesCount */
    ComposerActionCreators.updateDraftUpdateType(draft.id, draft?.updateType)
  }

  // If we're in editMode or shareAgainMode or isReminder is true,
  // it means the isOn/isReminder value should not be affected by
  // the defaultToReminders setting
  const hasReminderValueBeenSet = editMode || shareAgainMode || draft.isReminder

  useEffect(() => {
    if (hasReminderValueBeenSet) {
      return
    }
    if (defaultToReminders) {
      toggleReminder(true)
    } else {
      toggleReminder(false)
    }
    // We only want this to run once, so we don't need to add defaultToReminders to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isOn,
    toggleReminder,
  }
}
