import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const containerStyle: React.CSSProperties = {
  overflowY: 'auto',
  marginTop: '1rem',
  paddingRight: '1rem',
  paddingLeft: '1.1rem',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  height: '1px',
  scrollbarColor: 'rgba(89, 98, 106, 0.4) transparent', // firefox
}

function ScrollableContainer({
  // @ts-expect-error TS(7031) FIXME: Binding element 'tabId' implicitly has an 'any' ty... Remove this comment to see the full error message
  tabId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  profileId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loadingMore' implicitly has an 'a... Remove this comment to see the full error message
  loadingMore,
  // @ts-expect-error TS(7031) FIXME: Binding element 'page' implicitly has an 'any' typ... Remove this comment to see the full error message
  page,
  // @ts-expect-error TS(7031) FIXME: Binding element 'moreToLoad' implicitly has an 'an... Remove this comment to see the full error message
  moreToLoad,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'growthSpace' implicitly has an 'a... Remove this comment to see the full error message
  growthSpace,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onReachBottom' implicitly has an ... Remove this comment to see the full error message
  onReachBottom,
}) {
  const containerEl = useRef(null)

  function handleScroll() {
    const reachedBottom =
      Math.floor(
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        containerEl.current.scrollHeight - containerEl.current.scrollTop,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      ) === containerEl.current.clientHeight
    if (reachedBottom) {
      onReachBottom()
    }
  }

  useEffect(() => {
    if (containerEl.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'scrollTop' does not exist on type 'never... Remove this comment to see the full error message
      containerEl.current.scrollTop = 0
    }
  }, [tabId, profileId])

  useEffect(() => {
    const container = containerEl.current
    if (container) {
      // @ts-expect-error TS(2339) FIXME: Property 'addEventListener' does not exist on type... Remove this comment to see the full error message
      container.addEventListener('scroll', handleScroll)
      // @ts-expect-error TS(2339) FIXME: Property 'removeEventListener' does not exist on t... Remove this comment to see the full error message
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [profileId, tabId, moreToLoad, loadingMore, page])

  return (
    <div
      ref={containerEl}
      style={{
        ...containerStyle,
        flexGrow: growthSpace || 0,
      }}
      data-testid="scrollable-container"
    >
      {children}
    </div>
  )
}

ScrollableContainer.propTypes = {
  profileId: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  page: PropTypes.number,
  children: PropTypes.node.isRequired,
  growthSpace: PropTypes.number,
  moreToLoad: PropTypes.bool,
  onReachBottom: PropTypes.func,
}

export default ScrollableContainer
