import React, { useCallback } from 'react'
import clsx from 'clsx'

import {
  PlusIcon,
  Tooltip,
  UnstyledButton,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import styles from './AddButton.module.css'
import { useCalendarContext } from './context'

interface AddButtonProps {
  labelDate: string
  fullWidth?: boolean
  timestamp: number
  className?: string
}

export const AddButton = React.memo(
  ({
    labelDate,
    fullWidth = false,
    timestamp,
    className,
  }: AddButtonProps): JSX.Element => {
    const {
      addItemProps: { onClick, ...attributes },
    } = useCalendarContext()

    const handleClick = useCallback((): void => {
      onClick(timestamp)
    }, [onClick, timestamp])

    const addButtonContent = (
      <Tooltip content="Add a post">
        <UnstyledButton
          {...attributes}
          className={clsx(
            styles.addUpdateButton,
            {
              [styles.fullWidth]: fullWidth,
            },
            className,
          )}
          tabIndex={-1}
          onClick={handleClick}
        >
          <PlusIcon size={fullWidth ? 'small' : 'medium'} />
          <VisuallyHidden>Schedule post for {labelDate}</VisuallyHidden>
        </UnstyledButton>
      </Tooltip>
    )

    return fullWidth ? (
      addButtonContent
    ) : (
      <div className={styles.buttonWrapper}>{addButtonContent}</div>
    )
  },
)

AddButton.displayName = 'AddButton'
