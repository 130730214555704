import Text from '@bufferapp/ui/Text'
import Tooltip from '@bufferapp/ui/Tooltip'
import * as Dialog from '@radix-ui/react-dialog'

import isEqual from 'lodash/isEqual'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/contentGeneration/slice'
import { useUploader } from '~publish/legacy/uploads/hooks/useUploader'
import { getPlainText } from '../../../../legacy/editor/BufferEditor'
import type {
  BufferEditor,
  BufferValue,
} from '../../../../legacy/editor/BufferEditor/types.plate'
import { IdeasEditor } from '../../../../legacy/editor/IdeasEditor'
import { IntegrationsBar } from '../../../../legacy/integrations-bar/IntegrationsBar'
import type { IntegrationsContext } from '../../../../legacy/integrations-bar/types'
import { useIntegrationServices } from '../../../../legacy/integrations-bar/useIntegrationServices'
import { store, useAppSelector, useAppDispatch } from '../../../../legacy/store'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'
import {
  MAX_THUMBNAILS,
  MediaManager,
} from '../../../../legacy/uploads/components/MediaManager'
import {
  selectCompletedUploads,
  selectIncludedMedia,
  selectPendingCount,
} from '../../../../legacy/uploads/state/selectors'
import type { Idea, IdeaContent, NewIdea } from '../../types'
import { isMediaConvertibleToPost } from '../../helpers'
import { IDEAS_UPLOADER_ID } from '../config'
import { useCMDEnterKeys } from '../../../../hooks/useCMDEnterKeys'
import { useVariableHeight } from '../../hooks/useVariableHeight'
import { ConfirmCloseModal } from '../modals/ConfirmCloseModal'

import {
  ButtonWrapper,
  CloseButton,
  HeaderText,
  IdeatorHeader,
  IdeatorContent,
  IdeatorInnerWrapper,
  IdeatorWrapper,
  IntegrationsWrapper,
  MediaManagerWrapper,
  StyledButton,
  StyledNotice,
  StyledOverlay,
  IdeatorWrapperWithSidePanel,
  CloseIcon,
  IdeaTitleInput,
} from './styles'
import { TagsSelector } from '~publish/legacy/shared-components'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import { AIAssistantIdeasSidePanel } from './components/AIAssistantSidePanel'
import { AIAssistantTrigger } from '~publish/legacy/ai/components/Triggers'
import {
  BarButton,
  VerticalDivider,
} from '~publish/legacy/integrations-bar/styles'

import {
  toggleAIAssistant,
  selectAIAssistantOpened,
  selectAIAssistantResponsive,
  setAIAssistantPlacement,
} from '~publish/legacy/ai/state/slice'
import { useAnimatedVisibility } from '~publish/legacy/hooks/useAnimatedVisibility'
import { white } from '@bufferapp/ui/style/colors'
import { IdeaGroupSelector } from './components/IdeaGroupSelector'
import { Flex, VisuallyHidden } from '@buffer-mono/popcorn'
import { EmojiPicker } from '~publish/legacy/editor/plugins'
import { EmojiPickerIcon } from '~publish/legacy/editor/plugins/emoji'
import { useMergeRefs } from '@buffer-mono/popcorn/src/hooks/useMergeRefs'

type IdeaEditorProps = {
  currentIdea: NewIdea | undefined
  onSaveIdea: () => void
  preSelectedTags: SelectedTag[]
  onSelectTags: (tags: SelectedTag[]) => void
  errorMessage: string | null
  containerRef?: HTMLElement | null
  onCreatePost: () => void
  onOpenChange: (open: boolean) => void
  plateEditor: BufferEditor
  groupId?: string
  setGroupId: (groupId: string | undefined) => void
  title?: string
  setTitle: (title: string | undefined) => void
}

const IdeaEditor = forwardRef<HTMLDivElement, IdeaEditorProps>(
  (
    {
      currentIdea,
      onSaveIdea,
      preSelectedTags,
      onSelectTags,
      onCreatePost,
      errorMessage,
      containerRef,
      onOpenChange,
      plateEditor,
      groupId,
      setGroupId,
      title,
      setTitle,
    },
    ref,
  ): JSX.Element => {
    const wrapperRef = React.useRef(null)
    const mergedRef = useMergeRefs(ref, wrapperRef)

    const [editorValue, setEditorValue] = useState<BufferValue | null>(null)
    const [currentText, setCurrentText] = useState<string | null>(null)
    const [ctaPostTooltipLabel, setCtaPostTooltipLabel] = useState<
      string | null
    >(null)

    const { onFileReady } = useUploader({ id: IDEAS_UPLOADER_ID })

    const dispatch = useAppDispatch()

    const pendingCount = useAppSelector((state) =>
      selectPendingCount(state, IDEAS_UPLOADER_ID),
    )
    const completedUploads = useAppSelector((state) =>
      selectCompletedUploads(state, IDEAS_UPLOADER_ID),
    )

    const AIAssistantOpened = useAppSelector((state) =>
      selectAIAssistantOpened(state),
    )
    const AIAssistantResponsive = useAppSelector((state) =>
      selectAIAssistantResponsive(state),
    )

    const completedCount = completedUploads.length

    const isUploading = pendingCount > 0
    const context: IntegrationsContext = 'ideasEditor'

    const { services, activeService } = useIntegrationServices({
      onFileReady,
      context,
    })

    const media = useAppSelector((state) =>
      selectIncludedMedia(state, IDEAS_UPLOADER_ID),
    )

    const { contentGenerationInProgress, contentGenerationError } =
      useAppSelector((state) => selectContentGenerationStatus(state))

    useEffect(() => {
      if (editorValue) setCurrentText(getPlainText(plateEditor).trim())
    }, [editorValue, plateEditor])

    const onCloseAIAssistant = useCallback((): void => {
      dispatch(toggleAIAssistant(false))
    }, [dispatch])

    useEffect(() => {
      return (): void => {
        // close and reset content generation state
        onCloseAIAssistant()
      }
    }, [onCloseAIAssistant])

    const createPostDisabled = useMemo(() => {
      const hasMedia = completedCount !== 0
      return isUploading || (!currentText && !hasMedia)
    }, [currentText, isUploading, completedCount])

    const saveButtonDisabled = useMemo(() => {
      const hasMedia = completedCount !== 0
      return isUploading || (!currentText && !hasMedia && !title)
    }, [currentText, isUploading, title, completedCount])

    const postButtonsDisabled = useMemo(() => {
      const content: IdeaContent = { text: currentText || undefined, media }

      const idea: NewIdea = { content }
      const { valid, message } = isMediaConvertibleToPost(idea as Idea)
      setCtaPostTooltipLabel(message)
      return !valid
    }, [currentText, media])

    const { editorHeightStyles } = useVariableHeight({ completedCount })

    const postTooltipLabel =
      createPostDisabled && !contentGenerationInProgress
        ? 'Include copy or media to create a post from this Idea'
        : ''

    const saveTooltipLabel =
      saveButtonDisabled && !contentGenerationInProgress
        ? 'Include a title, copy, or media to save this Idea'
        : ''

    const [isOpen, setIsOpen] = useState(false)

    const onOpenChangeConfirmModal = (open: boolean): void => {
      if (!open) {
        setIsOpen(false)
        return
      }

      // Defaults to [] when currentIdea is a new idea
      const currentIdeaMedia = currentIdea?.content?.media || []
      const isIdeaTextUpdated =
        currentText !== (currentIdea?.content?.text?.trim() || null)
      const isIdeaMediaUpdated = !isEqual(
        media.map((el) => el.url),
        currentIdeaMedia.map((el) => el.url),
      )
      const areTagsUpdated = !isEqual(
        preSelectedTags.map((el) => el.id),
        currentIdea?.content?.tags?.map((el) => el.id) || [],
      )
      const isIdeaUpdated =
        isIdeaTextUpdated || isIdeaMediaUpdated || areTagsUpdated

      setIsOpen(isIdeaUpdated)
      if (!isIdeaUpdated) {
        onOpenChange(false)
      }
    }

    const preventDefault = useCallback(
      (e: { preventDefault: () => void }) => e.preventDefault(),
      [],
    )

    const onLeave = (event: React.MouseEvent | KeyboardEvent): void => {
      preventDefault(event)
      onOpenChangeConfirmModal(true)
    }

    const onOverlayClick = (event: React.MouseEvent | KeyboardEvent): void => {
      const target = event.target as HTMLElement
      const wrapper = wrapperRef.current as HTMLElement | null

      if (wrapper?.contains(target)) return

      onLeave(event)
    }

    const onOpenAIAssistant = (): void => {
      // Sets the placement (source) for tracking
      // Must be set before the upgrade modal is triggered as the modal
      // relies on the placement for tracking.
      dispatch(setAIAssistantPlacement({ placement: 'ideasEditor' }))

      // Display a upgrade flow for multi-product users
      // Restrict AI Assistant feature to New Buffer users only
      if (selectShouldShowNBMigration(store.getState())) {
        ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
          ctaButton: 'integrationsBar',
        })
        return
      }
      dispatch(toggleAIAssistant(true))
    }

    useCMDEnterKeys(
      onSaveIdea,
      !saveButtonDisabled && !contentGenerationInProgress,
    )

    const {
      isVisibleInDOM: AIPanelVisible,
      shouldBeVisible: AIPanelShouldBeVisible,
      onAnimationEnd: AIPanelAnimationEnd,
    } = useAnimatedVisibility({
      visibilityCondition: AIAssistantOpened,
    })

    return (
      <>
        <StyledOverlay onClick={onOverlayClick} />
        <IdeatorWrapper
          id="ideas-content-wrapper"
          onInteractOutside={preventDefault}
          onEscapeKeyDown={onLeave}
          ref={mergedRef}
        >
          <IdeatorWrapperWithSidePanel>
            {/* AI ASSISTANT SIDE PANEL */}
            {AIPanelVisible && !AIAssistantResponsive && (
              <AIAssistantIdeasSidePanel
                editor={plateEditor}
                onClose={onCloseAIAssistant}
                state={AIPanelShouldBeVisible ? 'open' : 'closed'}
                onAnimationEnd={AIPanelAnimationEnd}
              />
            )}
            <IdeatorInnerWrapper>
              {(errorMessage || contentGenerationError) && (
                <StyledNotice type="alert" disableAnimation="true" className="">
                  <Text type="span">
                    {contentGenerationError || errorMessage}
                  </Text>
                </StyledNotice>
              )}
              <IdeatorHeader>
                <Dialog.DialogTitle asChild>
                  <HeaderText>
                    {currentIdea?.id ? 'Edit' : 'New'} Idea
                  </HeaderText>
                </Dialog.DialogTitle>
                <Flex gap="space-100" align="center">
                  <IdeaGroupSelector
                    selectedGroupId={groupId}
                    onGroupSelect={setGroupId}
                  />
                  <TagsSelector
                    source="ideasComposer"
                    preSelectedTags={preSelectedTags}
                    onSelectTags={onSelectTags}
                  />
                </Flex>
              </IdeatorHeader>
              <VisuallyHidden as="label" htmlFor="idea-title">
                Idea title
              </VisuallyHidden>
              <IdeaTitleInput
                id="idea-title"
                placeholder="Give your idea a title"
                type="text"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setTitle(e.target.value)
                }
              />
              <IdeatorContent style={editorHeightStyles}>
                <IdeasEditor editor={plateEditor} onChange={setEditorValue} />
              </IdeatorContent>
              <MediaManagerWrapper id="ideas-media-manager">
                <MediaManager id="ideas" containerRef={containerRef} />
              </MediaManagerWrapper>
              <IntegrationsWrapper>
                <IntegrationsBar
                  context={context}
                  services={services}
                  activeService={activeService}
                  disableBar={completedCount + pendingCount >= MAX_THUMBNAILS}
                >
                  <VerticalDivider />
                  <EmojiPicker editor={plateEditor}>
                    <BarButton
                      data-testid="emoji-picker-button"
                      aria-label="empji picker"
                    >
                      <EmojiPickerIcon />
                    </BarButton>
                  </EmojiPicker>
                </IntegrationsBar>
                <VerticalDivider />
                <AIAssistantTrigger onClick={onOpenAIAssistant} />
              </IntegrationsWrapper>
              <ButtonWrapper id="ideas-actions">
                {/* FIXME: Use Popcorn Tooltip */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string | null; }... Remove this comment to see the full error message */}
                <Tooltip label={postTooltipLabel || ctaPostTooltipLabel}>
                  <StyledButton
                    type="secondary"
                    label="Create Post"
                    onClick={onCreatePost}
                    disabled={
                      createPostDisabled ||
                      postButtonsDisabled ||
                      contentGenerationInProgress
                    }
                  />
                </Tooltip>
                {/* FIXME: Use Popcorn Tooltip */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
                <Tooltip label={saveTooltipLabel}>
                  <StyledButton
                    type="primary"
                    label="Save Idea"
                    onClick={onSaveIdea}
                    disabled={saveButtonDisabled || contentGenerationInProgress}
                  />
                </Tooltip>
              </ButtonWrapper>
            </IdeatorInnerWrapper>
            {AIPanelVisible && AIAssistantResponsive && (
              <AIAssistantIdeasSidePanel
                editor={plateEditor}
                onClose={onCloseAIAssistant}
                state={AIPanelShouldBeVisible ? 'open' : 'closed'}
                onAnimationEnd={AIPanelAnimationEnd}
              />
            )}
          </IdeatorWrapperWithSidePanel>

          <Dialog.Close asChild>
            <CloseButton onClick={onLeave}>
              <CloseIcon size="medium" verticalAlign="top" color={white} />
            </CloseButton>
          </Dialog.Close>

          <Dialog.Root open={isOpen} onOpenChange={onOpenChangeConfirmModal}>
            <Dialog.Portal container={containerRef}>
              <ConfirmCloseModal
                onCancel={(): void => onOpenChangeConfirmModal(false)}
                onConfirmClose={(): void => onOpenChange(false)}
              />
            </Dialog.Portal>
          </Dialog.Root>
        </IdeatorWrapper>
      </>
    )
  },
)

IdeaEditor.displayName = 'IdeaEditor'

export default IdeaEditor
