import styled, { type FlattenSimpleInterpolation } from 'styled-components'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

export const PostPreviewFooter = styled.div<{ alignContentToRight?: boolean }>`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props): CSSRule =>
    props.alignContentToRight ? 'flex-end' : 'flex-start'};
  min-height: 32px;
`
