import React from 'react'
import { Button, CoachMark, Image, NewBadge } from '@buffer-mono/popcorn'

import styles from './ChannelTour.module.css'
import { TourStep } from './ChannelTour'

type StepPropTypes = {
  index: number
  open?: boolean
  step: {
    title?: React.ReactNode
    content?: React.ReactNode
    data?: {
      image: string
    }
    callback?: () => void
    placement?: string
  }
  size: number
  isLastStep: boolean
  closeProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  primaryProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  handleResumeTour: () => void
  isPaused?: boolean
}

const getSpotlightPosition = (index: number): { top: string; left: string } => {
  switch (index) {
    case TourStep.CALENDAR:
      return { top: '-42px', left: '-50px' }
    case TourStep.ALL_CHANNELS_SIDEBAR:
      return { top: '0px', left: '-170px' }
    case TourStep.TAGS_FILTER:
      return { top: '-41px', left: '0' }
    default:
      return { top: '-45px', left: '0' }
  }
}

export function StepCoachmark({
  index,
  open,
  step,
  size,
  isLastStep,
  closeProps,
  primaryProps,
  handleResumeTour,
  isPaused = false,
}: StepPropTypes): JSX.Element {
  const currentStepForDisplay = index + 1
  const totalStepsForDisplay = size

  const handleCloseGuide = (event: React.MouseEvent<HTMLElement>): void => {
    closeProps.onClick(event)

    if (step.callback) {
      step.callback()
    }
  }

  const handleNextStep = (event: React.MouseEvent<HTMLElement>): void => {
    primaryProps.onClick(event)
    if (isPaused) {
      handleResumeTour()
    }
  }

  const { top, left } = getSpotlightPosition(index)

  return (
    <CoachMark
      open={open}
      onDismiss={(): void =>
        handleCloseGuide({} as React.MouseEvent<HTMLElement>)
      }
    >
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': top,
            '--spotlight-left': left,
          }}
        />
      </CoachMark.Overlay>

      <CoachMark.Content
        className={styles.coachmark}
        showCloseIcon={false}
        side={step.placement as 'top' | 'left' | 'right' | 'bottom'}
      >
        {step.data?.image && (
          <CoachMark.Description>
            <Image src={step.data.image} alt="" />
          </CoachMark.Description>
        )}
        <CoachMark.Title>
          <NewBadge /> {step.title}
        </CoachMark.Title>
        <CoachMark.Description>{step.content}</CoachMark.Description>
        <CoachMark.Footer>
          {!isLastStep && (
            <Button variant="tertiary" onClick={handleCloseGuide}>
              Close
            </Button>
          )}
          <CoachMark.Dismiss>
            <Button onClick={handleNextStep}>
              {isLastStep
                ? 'Close'
                : `Next (${currentStepForDisplay}/${totalStepsForDisplay})`}
            </Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
