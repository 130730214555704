import { useCallback, useMemo } from 'react'

import { graphql } from '~publish/gql'
import { useMutation, useQuery } from '@apollo/client'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

export const GetDismissedBanners = graphql(/* GraphQL */ `
  query GetDismissedBanners {
    account {
      id
      dismissedBanners
    }
  }
`)

export const DismissBanner = graphql(/* GraphQL */ `
  mutation DismissBanner($id: String!) {
    dismissBanner(banner: $id) {
      __typename
      ... on DismissBannerResponse {
        success
      }
      ... on DismissBannerError {
        cause
      }
    }
  }
`)

type DismissableBanner = {
  isActive: boolean
  dismiss: () => void
}

/**
 * Hook to use dismissable banner, for example, banners, one-time modals, popups, coach marks etc.
 * @param id - id of the banner
 * @param options.trackDismiss - whether to track the banner dismiss event
 * @example
 * ```tsx
 * const banner = useDismissableBanner('content-planner-view-coach-mark')
 * if (banner.isActive) {
 *   <Banner onDismiss={banner.dismiss} />
 * }
 *```
 * @example
 * With tracking
 ```tsx
 * const banner = useDismissableBanner(
 *  'content-planner-view-coach-mark',
 *  { trackDismiss: true },
 * )
 * if (banner.isActive) {
 *   <Banner onDismiss={banner.dismiss} />
 * }
 *```
 */
export const useDismissableBanner = (
  id: string,
  options?: { trackDismiss?: boolean },
): DismissableBanner => {
  const { data, refetch } = useQuery(GetDismissedBanners, {
    fetchPolicy: 'cache-first',
  })
  const [dismissBanner] = useMutation(DismissBanner, {
    variables: { id },
    update: (cache) => {
      if (!data?.account) {
        return
      }

      const account = data.account

      // optimistic response
      cache.writeQuery({
        query: GetDismissedBanners,
        data: {
          account: {
            ...account,
            dismissedBanners: [...(account?.dismissedBanners ?? []), id],
          },
        },
      })
    },
  })
  const organizationId = useOrganizationId()
  const trackingData = useMemo(
    () => ({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: id,
    }),
    [organizationId, id],
  )

  const dismissedBanners = data?.account?.dismissedBanners
  const isActive = !!dismissedBanners && !dismissedBanners.includes(id)

  const shouldTrackBannerDismiss = !!options?.trackDismiss
  const dismiss = useCallback(async () => {
    if (shouldTrackBannerDismiss) {
      BufferTracker.bannerDismissed(trackingData)
    }

    const response = await dismissBanner()

    if (response.data?.dismissBanner.__typename !== 'DismissBannerResponse') {
      // refetch to get the latest dismissed banners after successful response
      refetch()
    }
  }, [dismissBanner, refetch, trackingData, shouldTrackBannerDismiss])

  // memoize the banner object to avoid unnecessary changes
  // when used in other hooks/components
  const banner = useMemo(() => ({ isActive, dismiss }), [isActive, dismiss])

  return banner
}
