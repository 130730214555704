import React from 'react'

import MessageFilledIcon from '@bufferapp/ui/Icon/Icons/MessageFilled'
import WorldIcon from '@bufferapp/ui/Icon/Icons/World'
import { SERVICE_LINKEDIN } from '~publish/legacy/constants'

import type {
  Image,
  Link,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseLinkedInLinks } from '~publish/legacy/utils/channels/linkedin/helpers'
import type { LinkedInContent, ProfilePreview } from '../../../types'
import {
  AvatarSquare,
  DotsIcon,
  DotsIconSmall,
  PostWrapper,
} from '../../../Common/styles'
import {
  DEFAULT_AVATAR,
  getMediaProperties,
  getPreviewCharLimit,
  removeLinkFromEndOfText,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'
import { formatImagesInRows } from '../../utils'
import { VideoPlayer } from '../../../Common/VideoPlayer'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'
import * as Styles from './styles'
import { DocumentPreview } from '~publish/legacy/composer/composer/components/document-attachment/DocumentPreview'

const maxLength = getPreviewCharLimit(SERVICE_LINKEDIN)
const maxLengthComment = 500

const getTextToDisplay = (
  hasLink: boolean,
  length: number,
  text?: string,
  link?: string | null,
): string | undefined => {
  let textToDisplay = text
  if (hasLink && textToDisplay) {
    textToDisplay = removeLinkFromEndOfText(textToDisplay, link)
  }

  textToDisplay = truncateText(textToDisplay, length)

  return textToDisplay
}

const shortenUsername = (name: string): string => {
  if (name.length > 25) {
    return name.substring(0, 16) + '…' + name.substring(name.length - 5)
  }
  return name
}

const Avatar = ({
  avatar,
  username,
  serviceType,
  size,
}: {
  avatar: string
  username: string
  serviceType: string
  size: string
}): JSX.Element => {
  if (serviceType === 'page') {
    return (
      <AvatarSquare
        data-testid="avatar"
        src={avatar}
        alt={username}
        fallbackUrl={DEFAULT_AVATAR}
      />
    )
  }

  return (
    <Styles.AvatarIcon
      data-testid="avatar"
      src={avatar}
      alt={username}
      size={size}
      fallbackUrl={DEFAULT_AVATAR}
    />
  )
}

const FirstCommentIndicator = (): JSX.Element => (
  <Styles.FirstCommentIndicator>1 comment</Styles.FirstCommentIndicator>
)

const FirstComment = ({
  avatar,
  username,
  serviceType,
  commentText,
}: {
  avatar: string
  username: string
  serviceType: string
  commentText: string
}): JSX.Element => {
  const textToDisplay = getTextToDisplay(
    false,
    maxLengthComment,
    commentText,
    null,
  )
  const usernameToDisplay = shortenUsername(username)
  return (
    <Styles.SingleCommentSection>
      <Avatar
        avatar={avatar}
        username={username}
        serviceType={serviceType}
        size="small"
      />
      <Styles.CommentWrapper>
        <Styles.CommentHeader>
          <Styles.CommentUsernameWrapper>
            {usernameToDisplay} <Styles.AuthorTag>Author</Styles.AuthorTag>
          </Styles.CommentUsernameWrapper>
          <DotsIconSmall />
        </Styles.CommentHeader>
        <div>
          <Styles.LinkifiedCommentText
            links={parseLinkedInLinks(textToDisplay, [])}
            size="mini"
            whitespace="pre-wrap"
            newTab
          >
            {textToDisplay}
          </Styles.LinkifiedCommentText>
          {commentText &&
            textExceedsCharLimit(commentText, maxLengthComment) && (
              <Styles.SeeMoreComments> ...see more</Styles.SeeMoreComments>
            )}
        </div>
      </Styles.CommentWrapper>
    </Styles.SingleCommentSection>
  )
}

const LinkedInPost = ({
  content,
  profile,
}: {
  content: LinkedInContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username, serviceType } = profile
  const { text, images, gif, link: linkFromPost, video, annotations } = content
  const commentText = content?.channelData?.linkedin?.comment_text
  const [linkPreview, linkText, scrapedData] = useLinkPreviewEffect({
    linkFromPost,
    text,
    checkValidUrl: false,
    compareWithScraper: true,
    useScraperData: false,
  })
  const hasScrapedData = !!(scrapedData && scrapedData.thumbnail)
  const hasLinkData = !!(linkPreview && linkPreview.thumbnail)
  const thumbnailEdited =
    hasScrapedData &&
    hasLinkData &&
    scrapedData.thumbnail?.url !== linkPreview.thumbnail?.originalUrl

  const { hasImages, hasVideo, hasLink } = getMediaProperties(
    content,
    linkPreview,
  )
  const hasDocument = !!content.document?.id
  const textToDisplay = getTextToDisplay(hasLink, maxLength, text, linkText)
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])
  const title = content.document?.title || 'Untitled document'

  return (
    <PostWrapper>
      <Styles.Header>
        <Avatar
          avatar={avatar}
          username={username}
          serviceType={serviceType}
          size="large"
        />
        <Styles.UsernameWrapper>
          <Styles.Username>{username}</Styles.Username>
          <Styles.FormattedUsername>
            {`1h · `}
            <WorldIcon />
          </Styles.FormattedUsername>
        </Styles.UsernameWrapper>
        <DotsIcon />
      </Styles.Header>
      <Styles.Body>
        <Styles.Content>
          <Styles.LinkifiedText
            links={parseLinkedInLinks(textToDisplay, annotations)}
            size="mini"
            whitespace="pre-wrap"
            newTab
          >
            {textToDisplay}
          </Styles.LinkifiedText>
          {text && textExceedsCharLimit(text, maxLength) && (
            <Styles.SeeMore> ...see more</Styles.SeeMore>
          )}
        </Styles.Content>
      </Styles.Body>
      {hasImages && (
        <Styles.ImagesGrid
          images={allImages}
          isRowFormat={allImages.length !== 2}
          formatImages={formatImagesInRows}
        />
      )}
      {hasDocument && content?.document?.url && (
        // width is .previewViewport minus minus .page padding
        <DocumentPreview title={title} url={content.document.url} width={290} />
      )}
      {hasVideo && <VideoPlayer video={video as Video} />}
      {hasLink && (
        <Styles.LinkPreview
          link={linkPreview as Link}
          titleOnTop
          imageCover
          showEmptyThumbnail
          clickable={false}
          showCompact={thumbnailEdited}
        />
      )}
      {commentText && <FirstCommentIndicator />}
      {!hasDocument && <Styles.Separator />}
      {hasDocument && commentText && <Styles.Separator />}
      <Styles.Footer>
        <Styles.Action>
          <Styles.LikeIcon />
          Like
        </Styles.Action>
        <Styles.Action>
          <Styles.MessageIcon />
          Comment
        </Styles.Action>
        <Styles.Action>
          <Styles.ReshareIcon />
          Repost
        </Styles.Action>
        <Styles.Action>
          <MessageFilledIcon />
          Send
        </Styles.Action>
      </Styles.Footer>
      {commentText && (
        <FirstComment
          avatar={avatar}
          username={username}
          serviceType={serviceType}
          commentText={commentText}
        />
      )}
    </PostWrapper>
  )
}

export default LinkedInPost
