import React, { useEffect } from 'react'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { getFragmentData } from '~publish/gql'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import { PostCard_Post } from '../PostCard'
import { useOnPostComposerClose } from './helpers'
import { GetPost } from './PostDetailsDialog'
import { PostErrorDialog } from './PostErrorDialog'

export function PostEditDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const match = useRouteMatch()
  const { postId, id: channelId } = useParams<{ postId: string; id: string }>()
  const { editPostInComposer } = usePostComposer()
  const history = useHistory()
  const { data, loading, error } = useQuery(GetPost, {
    variables: {
      id: postId,
    },
  })

  useEffect(() => {
    if (data?.post) {
      editPostInComposer({
        postId,
      })
    }
  }, [editPostInComposer, postId, channelId, data])

  useOnPostComposerClose(() => {
    history.push(parent)
  })

  if (error) {
    return <PostErrorDialog error={error} parent={parent} />
  }

  if (data && !loading) {
    const post = getFragmentData(PostCard_Post, data.post)
    if (channelId && post?.channel?.id && channelId !== post.channel.id) {
      history.push(match.url.replace(channelId, post.channel.id))
    }
  }

  return null
}
