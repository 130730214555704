import React, { type FC, type AnchorHTMLAttributes } from 'react'

interface DropdownTriggerProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  className?: string
  type?: string
}

const DropdownTrigger: FC<DropdownTriggerProps> = ({
  children,
  className = '',
  ...dropdownTriggerProps
}) => {
  return (
    <a
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={`dropdown__trigger ${className}`}
      {...dropdownTriggerProps}
    >
      {children}
    </a>
  )
}

DropdownTrigger.displayName = 'DropdownTrigger'

export default DropdownTrigger
