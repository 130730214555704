import React from 'react'
import { Text } from '@bufferapp/ui'
import style from './style'

class FittedFallbackComponent extends React.Component<
  React.PropsWithChildren<{ error: any; info: any; clearError: any }>
> {
  static onRefresh(): void {
    location.reload()
  }

  render(): JSX.Element {
    return (
      <div style={style.errorBoundaryFit as React.CSSProperties}>
        <Text type="h2">Well this is embarrassing...</Text>
        <div style={style.errorBoundaryMessage}>
          Something’s gone wrong. I’ve notified my human
          <br />
          creators who will look into this shortly.
        </div>
      </div>
    )
  }
}

export default FittedFallbackComponent
