import React from 'react'

import { PostCardProvider, PostCard } from '~publish/components/PostCard'
import PostPreview, {
  type PostPreviewProps,
} from '~publish/legacy/calendar/components/PostPreview'
import type { PublishingPost } from '~publish/gql/graphql'

import { useLoadCalendarPostCard } from '../hooks/useLoadCalendarPostCard'
import { useLoadLegacyCalendarPostCard } from '../hooks/useLegacyCalendarPost'
import { getCalendarViewPostFragment } from '../hooks/usePostFragment'

import { usePopcornCalendarPostCard } from '../hooks/usePopcornCalendarPostCard'

import styles from './PostCardHoverCard.module.css'

const usePublishingPost = (postId: string): PublishingPost | null => {
  const { data } = useLoadLegacyCalendarPostCard(postId)
  const calendarPost = getCalendarViewPostFragment(data?.calendarPost)
  if (calendarPost) {
    // legacy API assumes that calendarPost is a PublishingPost -
    // updating all types would be a massive chore, so I'm just casting as
    // a PublishingPost here as we're deprecating this soon
    return calendarPost as PublishingPost
  }
  return null
}

type CalendarPostHoveredCardProps = {
  postId: string
} & Omit<PostPreviewProps, 'post'>

const LegacyCalendarPostHoveredCard = (
  props: CalendarPostHoveredCardProps,
): JSX.Element => {
  const { postId, ...legacyProps } = props
  const publishingPost = usePublishingPost(postId)
  if (!publishingPost) return <div className={styles.legacyLoading} />
  return <PostPreview post={publishingPost} {...legacyProps} />
}

export const CalendarPostHoveredCard = (
  props: CalendarPostHoveredCardProps,
): JSX.Element => {
  const { postId } = props
  const isPopcorn = usePopcornCalendarPostCard()
  const { data } = useLoadCalendarPostCard(postId)
  const post = data?.post

  if (isPopcorn) {
    if (!post) return <div className={styles.loading} />
    return (
      <PostCard post={post} borderless className={styles.postCardWrapper} />
    )
  }

  if (!post) return <div className={styles.legacyLoading} />

  return (
    <PostCardProvider post={post}>
      <LegacyCalendarPostHoveredCard {...props} />
    </PostCardProvider>
  )
}
