import React, { useCallback } from 'react'
import clsx from 'clsx'
import {
  Text,
  UnstyledButton,
  Badge,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { useTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { useQueryParam } from '~publish/hooks/useQueryParam'

import styles from './QueueSlot.module.css'

type OccupiedQueueSlotProps = {
  date: string
}

export const OccupiedQueueSlot = ({
  date,
}: OccupiedQueueSlotProps): JSX.Element => {
  const timeFormatter = useTimeFormatter()
  const [, setTagFilter] = useQueryParam<string[]>('tags')
  const onRemoveFilterClick = useCallback(() => {
    setTagFilter([])
  }, [setTagFilter])

  return (
    <div
      className={clsx(styles.slot, styles.occupied)}
      data-datetime={date}
      data-testid="occupied-queue-slot"
    >
      <Text weight="medium" aria-hidden className={styles.badgeContainer}>
        {timeFormatter(date)}
        <Badge size="small" variant="neutral">
          Occupied
        </Badge>
      </Text>
      <Text className={clsx(styles.overlay, styles.occupied)} weight="medium">
        <VisuallyHidden>The slot for {timeFormatter(date)} is</VisuallyHidden>
        Occupied by a post hidden by filters.&nbsp;
        <UnstyledButton
          className={styles.clearFiltersButton}
          onClick={onRemoveFilterClick}
        >
          Clear filters
        </UnstyledButton>
      </Text>
    </div>
  )
}

OccupiedQueueSlot.displayName = 'OccupiedQueueSlot'
