import { RPCClient } from '@buffer-mono/legacy-rpc-utils'

const rpc = new RPCClient({
  url: '/rpc',
  sendCredentials: 'same-origin',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'methodName' implicitly has an 'any' typ... Remove this comment to see the full error message
export default function callRpc(methodName, args): Promise<any> {
  return rpc.call(methodName, args)
}
