import React from 'react'
import PropTypes from 'prop-types'
import { Text, Select } from '@bufferapp/ui'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import languageLabel from '../../utils'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'hasTranslationsFlip' implicitly h... Remove this comment to see the full error message
const LanguagePreferences = ({ hasTranslationsFlip, onSelectLanguage }) => {
  const { t, i18n } = useTranslation()

  if (!hasTranslationsFlip) {
    return null
  }

  const { language = 'en-US' } = i18n

  // @ts-expect-error TS(7006) FIXME: Parameter 'locale' implicitly has an 'any' type.
  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale)
    onSelectLanguage(locale)
  }

  return (
    <>
      <Divider />
      <Wrapper>
        <Text type="h3">{t('language')}</Text>
        <Select
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          hideSearch
          label={languageLabel(language)}
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => selectedItem.onItemClick()}
          items={[
            {
              title: languageLabel('en-US'),
              selected: language === 'en-US',
              onItemClick: () => changeLanguage('en-US'),
            },
            {
              title: languageLabel('es-ES'),
              selected: language === 'es-ES',
              onItemClick: () => changeLanguage('es-ES'),
            },
          ]}
        />
      </Wrapper>
    </>
  )
}

LanguagePreferences.propTypes = {
  hasTranslationsFlip: PropTypes.bool.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
}

export default LanguagePreferences
