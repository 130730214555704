import { createAsyncThunk } from '@reduxjs/toolkit'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import callRpc from '~publish/legacy/utils/call-rpc'
import { logError } from '~publish/legacy/utils/logError'

export const refreshSingleProfile = createAsyncThunk<
  unknown,
  { profileId: string; message?: string }
>(
  'profiles/refreshSingleProfile',
  async ({ profileId, message }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callRpc('singleProfile', {
        profileId,
      })

      if (!response) {
        throw new Error('No profile was returned.')
      }

      if (message) {
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message,
          }),
        )
      }

      return response
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(error)

      return rejectWithValue(null)
    }
  },
)
