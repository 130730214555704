import dayjs from 'dayjs'
import dayTimezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { updatePausedSchedules } from '../../thunks/updatePausedSchedules'
import styles from './PostingTimeForm.module.css'

import {
  Button,
  ChevronDownIcon,
  DropdownMenu,
  Flex,
  Label,
  ScrollArea,
  toast,
} from '@buffer-mono/popcorn'
import { setHours, setMinutes } from 'date-fns'
import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'
import {
  selectCurrentProfile,
  selectCurrentProfileMergedSchedules,
  selectCurrentProfilePausedSchedules,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { addTimeToSchedulesForApi } from '../../utils/scheduleUtils'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

interface WeekdaysOptions {
  name: string
  value: string
}

const weekdaysMap: Record<string, string> = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
  everyday: 'Every Day',
  weekdays: 'Weekdays',
  weekends: 'Weekends',
}

const weekdaysOptions: WeekdaysOptions[] = [
  { name: 'Every Day', value: 'everyday' },
  { name: 'Weekdays', value: 'weekdays' },
  { name: 'Weekends', value: 'weekends' },
  { name: 'Monday', value: 'mon' },
  { name: 'Tuesday', value: 'tue' },
  { name: 'Wednesday', value: 'wed' },
  { name: 'Thursday', value: 'thu' },
  { name: 'Friday', value: 'fri' },
  { name: 'Saturday', value: 'sat' },
  { name: 'Sunday', value: 'sun' },
]

const twelveHours = Array.from({ length: 12 }, (_, i) =>
  (i + 1).toString().padStart(2, '0'),
)
const twentyFourHours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0'),
)
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0'),
)

const PostingTimeForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const twentyfourHourTime = useTimeFormatPreference() === '24'
  const now = new Date()

  const getHours = (date: Date): string => {
    const hour = twentyfourHourTime
      ? date.getHours()
      : date.getHours() % 12 || 12
    return hour.toString().padStart(2, '0')
  }

  const amPm = now.getHours() >= 12 ? 'PM' : 'AM'

  const [selectedWeekday, setSelectedWeekday] = React.useState(
    weekdaysOptions[0],
  )
  const [selectedTime, setSelectedTime] = React.useState(now)
  const [selectedAmPm, setSelectedAmPm] = React.useState(amPm)
  const [submitting, setSubmitting] = React.useState(false)

  const displayHours = twentyfourHourTime ? twentyFourHours : twelveHours
  const mergedSchedules = useAppSelector(selectCurrentProfileMergedSchedules)
  const pausedSchedules = useAppSelector(selectCurrentProfilePausedSchedules)
  const currentOrganization = useCurrentOrganization()
  const currentOrganizationId = currentOrganization.id

  if (!profile) return <></>

  const handleSubmit = async (): Promise<void> => {
    const hours = selectedTime.getHours().toString().padStart(2, '0')
    const minutes = selectedTime.getMinutes().toString().padStart(2, '0')
    const dayName = selectedWeekday.value
    const newSchedules = addTimeToSchedulesForApi(
      { hours, minutes, dayName },
      structuredClone(mergedSchedules),
      structuredClone(pausedSchedules),
    )
    BufferTracker.postingTimeAdded({
      product: Product.Publish,
      organizationId: currentOrganizationId ?? '',
      clientName: Client.PublishWeb,
      daysAdded: weekdaysMap[dayName].toLowerCase(),
      timeAdded: `${hours}:${minutes}`,
      channel: profile.service,
      channelType: profile.service_type,
      channelId: profile.id,
      channelServiceId: profile.serviceId,
      channelUsername: profile.username,
    })
    const result = await dispatch(
      updatePausedSchedules({
        profileId: profile.id,
        schedules: newSchedules.schedules,
        pausedSchedules: newSchedules.pausedSchedules,
      }),
    )
    if (updatePausedSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setSubmitting(false)
  }

  return (
    <Flex
      align="center"
      gap="space-100"
      justify="start"
      data-testid="postingtime-form-wrapper"
    >
      <Label>Add a new posting time for</Label>
      <DropdownMenu
        trigger={
          <Button
            data-testid="postingtime-form-days-selector"
            size="large"
            variant="secondary"
          >
            {selectedWeekday.name} <ChevronDownIcon />
          </Button>
        }
      >
        {weekdaysOptions.map((weekday) => (
          <DropdownMenu.Item
            onClick={(): void => {
              setSelectedWeekday(weekday)
            }}
            key={weekday.value}
          >
            {weekday.name}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu>
      <Label>at</Label>
      <DropdownMenu
        trigger={
          <Button
            data-testid="postingtime-form-hours-selector"
            size="large"
            variant="secondary"
          >
            {getHours(selectedTime)} <ChevronDownIcon />
          </Button>
        }
        className={styles.dropdown}
      >
        <ScrollArea>
          {displayHours.map((hour) => (
            <DropdownMenu.Item
              onClick={(): void => {
                setSelectedTime((time: Date): Date => {
                  if (twentyfourHourTime) {
                    return setHours(time, Number(hour))
                  }
                  if (selectedAmPm === 'AM' && hour === '12') {
                    return setHours(time, 0)
                  }
                  if (selectedAmPm === 'PM' && hour !== '12') {
                    return setHours(time, Number(hour) + 12)
                  }
                  return setHours(time, Number(hour))
                })
              }}
              key={hour}
            >
              {hour}
            </DropdownMenu.Item>
          ))}
        </ScrollArea>
      </DropdownMenu>
      <DropdownMenu
        trigger={
          <Button
            data-testid="postingtime-form-minutes-selector"
            size="large"
            variant="secondary"
          >
            {selectedTime.getMinutes().toString().padStart(2, '0')}{' '}
            <ChevronDownIcon />
          </Button>
        }
        className={styles.dropdown}
      >
        <ScrollArea>
          {minutes.map((minute) => (
            <DropdownMenu.Item
              onClick={(): void => {
                setSelectedTime((time: Date): Date => {
                  return setMinutes(time, Number(minute))
                })
              }}
              key={minute}
            >
              {minute}
            </DropdownMenu.Item>
          ))}
        </ScrollArea>
      </DropdownMenu>
      {!twentyfourHourTime && (
        <DropdownMenu
          trigger={
            <Button
              data-testid="postingtime-form-am-pm-selector"
              size="large"
              variant="secondary"
            >
              {selectedAmPm} <ChevronDownIcon />
            </Button>
          }
          className={styles.dropdown}
        >
          {['AM', 'PM'].map((amPm) => (
            <DropdownMenu.Item
              onClick={(): void => {
                setSelectedTime((time: Date): Date => {
                  if (selectedAmPm === amPm) {
                    return time
                  }
                  if (amPm === 'AM') {
                    return setHours(time, Number(selectedTime.getHours() - 12))
                  } else {
                    return setHours(time, Number(selectedTime.getHours() + 12))
                  }
                })
                setSelectedAmPm(amPm)
              }}
              key={amPm}
            >
              {amPm}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu>
      )}
      <Button
        size="large"
        variant="primary"
        disabled={submitting}
        onClick={(): void => {
          setSubmitting(true)
          handleSubmit()
        }}
        data-testid="postingtime-form-submit-button"
      >
        Add Posting Time
      </Button>
    </Flex>
  )
}

export { PostingTimeForm }
