import { Flex, Label, Switch, toast } from '@buffer-mono/popcorn'
import capitalize from 'lodash/capitalize'
import React, { useEffect, useState } from 'react'
import { updatePausedSchedules } from '~publish/legacy/posting-schedule/thunks/updatePausedSchedules'
import {
  addDayToPausedSchedulesForApi,
  addPausedDayBackToScheduleForApi,
  removeDayFromPausedSchedulesForApi,
  removePausedDaysFromScheduleForApi,
} from '~publish/legacy/posting-schedule/utils/scheduleUtils'
import {
  selectCurrentProfile,
  selectCurrentProfileMergedSchedules,
  selectCurrentProfilePostingDays,
  selectCurrentProfilePostingDaysIsEmpty,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { DAYSMAP } from '../utils'
import styles from './ScheduleTableHeader.module.css'
import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { useCurrentOrganization } from '~publish/legacy/accountContext'

interface ScheduleTableHeaderProps {
  dayName: string
  displayOn: boolean
  onTogglePause: () => void
}

const ScheduleTableHeader = ({
  dayName,
  displayOn,
  onTogglePause,
}: ScheduleTableHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const mergedSchedules = useAppSelector(selectCurrentProfileMergedSchedules)
  const days = useAppSelector(selectCurrentProfilePostingDays)
  const emptySchedule = useAppSelector(selectCurrentProfilePostingDaysIsEmpty)
  const currentOrganization = useCurrentOrganization()
  const currentOrganizationId = currentOrganization.id
  const hasTimes =
    (days.find((day) => day.dayName.toLowerCase() === dayName)?.times ?? [])
      .length > 0

  // We are displaying off in switch if user has no times scheduled
  const [isOn, setIsOn] = useState(displayOn)

  // ensure paused state updates
  useEffect(() => {
    setIsOn(displayOn)
  }, [displayOn])

  if (!profile) return <></>

  const trackPauseDay = (): void => {
    BufferTracker.postingDayToggled({
      product: Product.Publish,
      organizationId: currentOrganizationId ?? '',
      clientName: Client.PublishWeb,
      dayToggled: dayName,
      state: !isOn ? 'enabled' : 'disabled',
      channel: profile.service,
      channelType: profile.service_type,
      channelId: profile.id,
      channelServiceId: profile.serviceId,
      channelUsername: profile.username,
    })
  }

  const pauseDayClick = async (): Promise<void> => {
    onTogglePause()
    const schedules = removePausedDaysFromScheduleForApi(
      [DAYSMAP[dayName]],
      profile.schedules,
    )
    const pausedSchedules = addDayToPausedSchedulesForApi(
      DAYSMAP[dayName],
      mergedSchedules,
      days,
    )
    const result = await dispatch(
      updatePausedSchedules({
        profileId: profile.id,
        schedules,
        pausedSchedules,
      }),
    )
    if (updatePausedSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
      trackPauseDay()
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setIsOn(false)
  }

  const unpauseDayClick = async (): Promise<void> => {
    onTogglePause()
    const pausedSchedules = removeDayFromPausedSchedulesForApi(
      DAYSMAP[dayName],
      profile.pausedSchedules,
      days,
    )
    const schedules = addPausedDayBackToScheduleForApi(
      DAYSMAP[dayName],
      profile.schedules,
      mergedSchedules,
    )
    const result = await dispatch(
      updatePausedSchedules({
        profileId: profile.id,
        schedules,
        pausedSchedules,
        emptyPausedSchedules: pausedSchedules.length === 0 ? true : undefined,
      }),
    )
    if (updatePausedSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
      trackPauseDay()
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setIsOn(true)
  }

  return (
    <Flex
      direction="column"
      align="center"
      gap="space-200"
      className={styles.header}
    >
      <Label>{capitalize(dayName)}</Label>
      {!emptySchedule && (
        <Flex
          align="center"
          gap="space-50"
          className={
            hasTimes ? `${styles.switchVisible}` : `${styles.switchHidden}`
          }
        >
          <Label className={styles.switchLabel}>{isOn ? 'On' : 'Off'}</Label>
          <Switch
            checked={isOn}
            onCheckedChange={(): void => {
              if (isOn) pauseDayClick()
              if (!isOn) unpauseDayClick()
              setIsOn(!isOn)
            }}
            id={`${dayName}-schedule`}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { ScheduleTableHeader }
