import {
  closestCorners,
  getFirstCollision,
  KeyboardCode,
  type DroppableContainer,
  type KeyboardCoordinateGetter,
} from '@dnd-kit/core'

const directions: string[] = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
]

export const calendarKeyboardCoordinateGetter: KeyboardCoordinateGetter = (
  event,
  {
    context: {
      active,
      collisionRect,
      droppableRects,
      droppableContainers,
      over,
    },
  },
) => {
  if (directions.includes(event.code)) {
    event.preventDefault()
    if (!active || !collisionRect) {
      return
    }

    // Filter containers based on the direction of movement
    const filteredContainers: DroppableContainer[] = []
    droppableContainers.getEnabled().forEach((entry) => {
      if (!entry || entry?.disabled) {
        return
      }
      const rect = droppableRects.get(entry.id)
      if (!rect) {
        return
      }

      // Ignore container where the item is contained
      if (
        rect.bottom > collisionRect.bottom &&
        rect.top < collisionRect.top &&
        rect.right < collisionRect.right &&
        rect.left > collisionRect.left
      ) {
        return
      }

      // Only consider containers in the direction of movement
      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Up:
          if (collisionRect.top > rect.bottom) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Left:
          if (collisionRect.left > rect.right) {
            filteredContainers.push(entry)
          }
          break
        case KeyboardCode.Right:
          if (collisionRect.right < rect.left) {
            filteredContainers.push(entry)
          }
          break
      }
    })

    // Find the closest droppable using the closestCorners algorithm
    const collisions = closestCorners({
      active,
      collisionRect,
      droppableRects,
      droppableContainers: filteredContainers,
      pointerCoordinates: null,
    })

    let closestId = getFirstCollision(collisions, 'id')

    // If the closest is the current container and there are other options, choose the next one
    if (closestId === over?.id && collisions.length > 1) {
      closestId = collisions[1].id
    }

    if (closestId != null) {
      const newDroppable = droppableContainers.get(closestId)
      const newRect = newDroppable ? droppableRects.get(newDroppable.id) : null
      const newNode = newDroppable?.node.current

      if (newNode && newRect && newDroppable) {
        // Calculate the center coordinates of the new droppable
        const centerX = newRect.left + newRect.width / 2
        const centerY = newRect.top + newRect.height / 2

        // Calculate the offset to center the dragged item
        const offsetX = collisionRect.width / 2
        const offsetY = collisionRect.height / 2

        // Apply the offset to get the final coordinates
        const newCoordinates = {
          x: centerX - offsetX,
          y: centerY - offsetY,
        }

        return newCoordinates
      }
    }
  }

  return undefined
}
