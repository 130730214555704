import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import {
  PostActionNotificationSuccess,
  PostActionNotificationError,
} from '../types'

export const changeDraftStatus = createAsyncThunk<
  boolean,
  { postId: string; needsApproval: boolean },
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/changeDraftStatus',
  async ({ postId, needsApproval }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callRpc('changeDraftStatus', {
        updateId: postId,
        needsApproval,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      const message = needsApproval
        ? PostActionNotificationSuccess.REQUEST_APPROVAL
        : PostActionNotificationSuccess.REVERT_TO_DRAFT

      dispatch(
        notificationActions.createNotification({
          notificationType: 'success',
          message,
        }),
      )

      return true
    } catch (error) {
      const message = needsApproval
        ? PostActionNotificationError.REQUEST_APPROVAL
        : PostActionNotificationError.REVERT_TO_DRAFT

      dispatch(
        notificationActions.createNotification({
          notificationType: 'error',
          message,
        }),
      )

      return rejectWithValue(message)
    }
  },
)
