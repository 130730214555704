import { useEffect, useRef, useState } from 'react'

/**
 * A custom React hook that detects whether an element has been scroll.
 *
 * @returns {Object} An object containing:
 *   - scrollingElementRef: A React ref object to be attached to the scrollable element.
 *   - hasScrolled: A boolean indicating whether the element has scroll.
 */
export function useHasElementScroll<T extends HTMLElement>(): {
  scrollingElementRef: React.RefObject<T>
  hasScroll: boolean
} {
  const [hasScroll, setHasScrolled] = useState(false)
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleScroll = (): void => {
      if (ref.current) {
        setHasScrolled(ref.current.scrollTop > 0)
      }
    }

    const currentRef = ref.current
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return {
    scrollingElementRef: ref,
    hasScroll,
  }
}
