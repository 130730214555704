import React from 'react'
import { Temporal } from '@js-temporal/polyfill'

import styles from './Header.module.css'
import clsx from 'clsx'
import { format, isSameDay } from '~publish/helpers/temporal'

type HeaderProps = {
  startDate: Temporal.ZonedDateTime
  withDay: boolean
  className?: string
}

export const Header = ({
  startDate,
  withDay,
  className,
}: HeaderProps): JSX.Element => {
  const today = Temporal.Now.zonedDateTimeISO(startDate.timeZoneId)
  const days = Array.from({ length: 7 }, (_, i) => startDate.add({ days: i }))
  const dateFormat = withDay ? 'EEEE d' : 'EEEE'
  return (
    <thead>
      <tr className={clsx(styles.headerRow, styles.stickyHeader, className)}>
        {days.map((day) => {
          const isActive = isSameDay(day, today) && withDay
          return (
            <th
              key={day.toString()}
              className={clsx(styles.day, {
                [styles.active]: isActive,
              })}
            >
              {format(day, dateFormat)}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
