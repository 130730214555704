import type React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { type planType, usePlan } from '~publish/legacy/accountContext'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import type { PostStatus } from '~publish/gql/graphql'

export interface CalendarFilter {
  type?: ('post' | 'draft' | 'story')[]
  status?: PostStatus[]
  needsApproval?: boolean
}

export interface CalendarFilterItem {
  id: 'all' | 'draft' | 'scheduled' | 'sent' | 'needs_approval'
  title:
    | 'All Posts'
    | 'Drafts'
    | 'Scheduled'
    | 'Sent'
    | 'Pending Approval'
    | 'Awaiting Approval'
  filter: CalendarFilter
}

const DEFAULT_FILTER = 'all'

const menuItems: CalendarFilterItem[] = [
  {
    id: 'all',
    title: 'All Posts',
    filter: {},
  },
  {
    id: 'draft',
    filter: {
      type: ['draft'],
      status: ['draft', 'needs_approval'],
    },
    title: 'Drafts',
  },
  {
    id: 'scheduled',
    filter: {
      type: ['post'],
      status: ['scheduled', 'error'],
    },
    title: 'Scheduled',
  },
  {
    id: 'sent',
    title: 'Sent',
    filter: {
      type: ['post'],
      status: ['sending', 'sent'],
    },
  },
]

export function getPlanItems(plan: planType | undefined): CalendarFilterItem[] {
  if (!plan) return []

  if (plan.id) {
    if (!['team', 'agency'].includes(plan.id) && plan.isOneBufferOrganization) {
      return []
    }
  }

  return [
    {
      id: 'needs_approval',
      title: plan.isAdmin ? 'Pending Approval' : 'Awaiting Approval',
      filter: {
        type: ['draft'],
        status: ['needs_approval'],
      },
    },
  ]
}

export const useMenuItems = (): [
  CalendarFilterItem[],
  React.Dispatch<React.SetStateAction<CalendarFilterItem[]>>,
] => {
  const plan = usePlan()
  const [items, setItems] = useState([...menuItems])

  useEffect(() => {
    setItems([...menuItems, ...getPlanItems(plan)])
  }, [plan])

  return [items, setItems]
}

const useSelectedItem = (
  items: CalendarFilterItem[],
  selected: string | undefined,
): CalendarFilterItem => {
  return useMemo(
    () => items.find((i) => i.id === selected) || items[0],
    [items, selected],
  )
}

type MenuState = {
  selectedItem: CalendarFilterItem
  setFilterValue: (id: CalendarFilterItem['id']) => void
}

export const useMenuState = (): MenuState => {
  const [items] = useMenuItems()
  const [filterPresetId, setFilterValue] =
    useQueryParam<CalendarFilterItem['id']>('status')

  const selectedFilterPresetId = filterPresetId ?? DEFAULT_FILTER

  const selectedItem = useSelectedItem(items, selectedFilterPresetId)

  const setFilter = useCallback(
    (filter: CalendarFilterItem['id']) => {
      if (filter === DEFAULT_FILTER) {
        setFilterValue(undefined)
        return
      }
      if (filter !== selectedItem.id) {
        setFilterValue(filter)
      }
    },
    [selectedItem.id, setFilterValue],
  )

  return {
    selectedItem,
    setFilterValue: setFilter,
  }
}

export const useActiveFilter = (): CalendarFilter => {
  const { selectedItem } = useMenuState()
  return selectedItem.filter
}
