import { createAsyncThunk } from '@reduxjs/toolkit'
import callRpc from '~publish/legacy/utils/call-rpc'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import {
  PostActionNotificationSuccess,
  PostActionNotificationError,
} from '../types'
import type { SelectedTag } from '~publish/legacy/campaign/types'

export const assignTagsToPost = createAsyncThunk<
  boolean,
  { postId: string; tags: SelectedTag[]; disableNotification?: boolean },
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/assignTagsToPost',
  async (
    { postId, tags, disableNotification = false },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await callRpc('assignTagsToPost', {
        postId,
        tags,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      const message = PostActionNotificationSuccess.ASSIGN_TAGS_TO_POST

      if (!disableNotification) {
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message,
          }),
        )
      }

      return true
    } catch (error) {
      const message = PostActionNotificationError.ASSIGN_TAGS_TO_POST

      dispatch(
        notificationActions.createNotification({
          notificationType: 'error',
          message,
        }),
      )

      return rejectWithValue(message)
    }
  },
)
