import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'

import { useSplitEnabled } from '@buffer-mono/features'
import MessageIcon from '@bufferapp/ui/Icon/Icons/Message'
import PersonIcon from '@bufferapp/ui/Icon/Icons/Person'
import ThreadIcon from '@bufferapp/ui/Icon/Icons/Thread'
import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import { grayDark } from '@bufferapp/ui/style/colors'
import { DropdownMenu } from '@buffer-mono/popcorn'

import { PostEntity } from '~publish/legacy/post/PostEntity'
import { usePostActions } from '~publish/legacy/post/hooks'
import { SentPostFooterText } from '~publish/legacy/shared-components/PostFooter/SentPostFooterText'
import SharingStatusText from '~publish/legacy/shared-components/PostFooter/SharingStatusText'
import { useAppDispatch } from '~publish/legacy/store'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { MoreActionsDropdown } from '~publish/legacy/shared-components/MoreActionsDropdown/MoreActionsDropdown'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { omitFields } from '~publish/helpers/object'
import { tagsPage } from '~publish/legacy/routes'

import {
  ButtonStyled,
  ButtonWrapper,
  CardFooterContent,
  CommentIconWrapper,
  EditButton,
  IconWrapper,
  Message,
  ThreadCounter,
  ThreadIconWrapper,
  UserTagsIconWrapper,
} from '../CardFooter/styles'
import ConfirmDeleteButton from '../ConfirmDeleteButton'
import { movePostToDrafts } from '../../post/thunks/movePostToDrafts'
import CardFooter from '../CardFooter'
import { renderIcon } from './utils'
import { SentPostFooterActions } from './SentPostFooterActions'

const omittedFields: (keyof RpcUpdate)[] = [
  'id',
  'due_at',
  'day',
  'scheduled_at',
  'scheduledAt',
  'serviceUpdateId',
  'createdAt',
  'statistics',
  'status',
  'serviceLink',
]

type OmittedField = (typeof omittedFields)[number]

type RpcUpdateWithoutOmittedFields = Omit<RpcUpdate, OmittedField>

const omitDuplicatedFields = (
  post: RpcUpdate,
): RpcUpdateWithoutOmittedFields => {
  const newPost = omitFields(post, omittedFields)

  return {
    ...newPost,
    isSent: false,
    isPastDue: false,
    isDraft: false,
  }
}

const DuplicateMenuItem = ({
  post,
}: {
  post: RpcUpdate
}): JSX.Element | null => {
  const { duplicatePostInComposer } = usePostComposer()
  const cta = 'publish-queue-postCard-duplicatePost-1'

  const handleDuplicatePost = useCallback(() => {
    const prefillPostData = omitDuplicatedFields(post) as RpcUpdate
    duplicatePostInComposer({
      cta,
      channels: [post.profileId],
      prefillPostData,
      duplicatedFrom: post.id,
    })
  }, [duplicatePostInComposer, post])

  return (
    <DropdownMenu.Item onClick={handleDuplicatePost}>
      Duplicate
    </DropdownMenu.Item>
  )
}

interface PostFooterProps {
  post: RpcUpdate & { workingMessage?: string }
  postId: string
  profileId: string
  isDeleting: boolean
  isConfirmingDelete: boolean
  isWorking: boolean
  onDeleteConfirmClick: () => void
  onEditClick: () => void
  onShareNowClick: () => void
  onShareAgainClick: () => void
  onShareLinkClick: () => void
  postAction: string
  dragging: boolean
  onRequeueClick: () => void
  serviceLink: string
  isSent: boolean
  isManager: boolean
  isPastReminder: boolean
  day: string
  hasCommentEnabled: boolean
  hasCampaignsFeature: boolean
  commentText: string
  hasUserTags: boolean
  shouldShowEditButton: boolean
  threadCounter: number
  isReminder: boolean
}

const PostFooter = ({
  post,
  postId,
  profileId,
  isDeleting,
  isConfirmingDelete,
  isWorking,
  onDeleteConfirmClick,
  onRequeueClick,
  onEditClick,
  onShareNowClick,
  onShareAgainClick,
  onShareLinkClick,
  postAction,
  dragging,
  serviceLink,
  isSent,
  isManager,
  isPastReminder,
  commentText,
  hasCommentEnabled,
  hasUserTags,
  shouldShowEditButton,
  threadCounter,
  isReminder,
}: PostFooterProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const isOnTagsPage = Boolean(useRouteMatch(tagsPage.route))

  const hasError = PostEntity.hasError(post)
  const hideButtons = !isManager || isSent || isPastReminder
  const showSentPostActions = isSent || isPastReminder
  const deletingMessage = (isDeleting || isConfirmingDelete) && 'Deleting...'
  const submittingMessage = isWorking && (post?.workingMessage || 'Sharing...')

  const shouldShowSharingReel =
    isWorking &&
    post?.workingMessage &&
    post?.profile_service === 'facebook' &&
    post?.updateType === 'facebook_reel'

  const actionMessage = deletingMessage || submittingMessage || ''
  const isPerformingAction = !!actionMessage

  // eslint-disable-next-line no-nested-ternary
  // const message = isPastReminder ? day : hasError ? '' : postAction
  const message = hasError ? '' : postAction
  const isCustomScheduled = PostEntity.isCustomScheduled(post)
  const dueAt = PostEntity.getDueAt(post)

  const icon = renderIcon({
    hasError,
    isSent,
    isCustomScheduled,
    isReminder,
    isPastReminder,
    via: post.via,
    isRemindersEnabled,
    dueAt,
  })
  const messageColor = isSent ? grayDark : ''
  const hasFirstComment = hasCommentEnabled && !!commentText

  const refetchQueue = (): void => {
    dispatch(
      dataFetchActions.fetch({
        name: 'queuedPosts',
        args: {
          profileId,
          isFetchingMore: false,
          hideLoading: true,
          count: 300,
        },
      }),
    )
  }

  const hideAllButtons = hideButtons || isPerformingAction || serviceLink

  const { stackedActions, executePostActionById } = usePostActions({
    post,
    onSharePostNowClick: onShareNowClick,
    onRequeueClick,
    onMovePostToDraftsClick: () =>
      dispatch(movePostToDrafts({ postId, profileId })).then(refetchQueue),
  })
  const [firstAction, ...restStackedActions] = stackedActions

  return (
    <CardFooter isDragging={dragging}>
      <CardFooterContent>
        {threadCounter > 0 && (
          <>
            <ThreadIconWrapper>
              <ThreadIcon
                color={grayDark}
                size="medium"
                role="img"
                title="This is a Thread post"
              />
            </ThreadIconWrapper>
            <ThreadCounter
              type="p"
              textColor={messageColor}
              data-testid="threads-counter"
            >
              {threadCounter}
            </ThreadCounter>
          </>
        )}
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <SentPostFooterText
          message={message}
          messageColor={messageColor}
          isSent={isSent}
          serviceLink={serviceLink}
        />

        {hasFirstComment && (
          <CommentIconWrapper title="Post includes a comment">
            <MessageIcon
              data-tip="Includes first comment"
              data-padding="8px 8px 10px 8px"
            />
          </CommentIconWrapper>
        )}
        {hasUserTags && (
          <UserTagsIconWrapper
            title="Post includes tagged users"
            hasFirstComment={hasFirstComment}
          >
            <PersonIcon />
          </UserTagsIconWrapper>
        )}
      </CardFooterContent>
      {showSentPostActions && (
        <SentPostFooterActions
          postId={postId}
          onShareAgainClick={onShareAgainClick}
          onShareLinkClick={onShareLinkClick}
          serviceLink={serviceLink}
        />
      )}
      {!hideAllButtons && (
        <ButtonWrapper data-testid="post-footer-buttons">
          {onDeleteConfirmClick && (
            <ConfirmDeleteButton onDeleteClick={onDeleteConfirmClick} />
          )}
          {shouldShowEditButton && onEditClick && (
            <EditButton
              label="Edit"
              size="small"
              onClick={onEditClick}
              type="text"
            />
          )}
          {firstAction && (
            <ButtonStyled
              size="small"
              type="secondary"
              isSplit={!!restStackedActions && restStackedActions.length > 0}
              items={restStackedActions}
              label={firstAction.title}
              onClick={firstAction.callback}
              onSelectClick={executePostActionById}
            />
          )}
          {!isOnTagsPage && (
            <MoreActionsDropdown>
              <MoreActionsDropdown.Group>
                <DuplicateMenuItem post={post as RpcUpdate} />
              </MoreActionsDropdown.Group>
            </MoreActionsDropdown>
          )}
        </ButtonWrapper>
      )}
      {shouldShowSharingReel && <SharingStatusText></SharingStatusText>}
      {!shouldShowSharingReel && isPerformingAction && (
        <Message type="p">{actionMessage}</Message>
      )}
    </CardFooter>
  )
}

PostFooter.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    via: PropTypes.string,
    workingMessage: PropTypes.string,
    profile_service: PropTypes.string,
    updateType: PropTypes.string,
  }).isRequired,
  postId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool,
  isConfirmingDelete: PropTypes.bool,
  isWorking: PropTypes.bool,
  onDeleteConfirmClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onShareNowClick: PropTypes.func,
  onShareAgainClick: PropTypes.func,
  onShareLinkClick: PropTypes.func,
  postAction: PropTypes.string,
  dragging: PropTypes.bool,
  onRequeueClick: PropTypes.func,
  serviceLink: PropTypes.string,
  isSent: PropTypes.bool,
  isManager: PropTypes.bool,
  isPastReminder: PropTypes.bool,
  day: PropTypes.string,
  hasCommentEnabled: PropTypes.bool,
  commentText: PropTypes.string,
  hasUserTags: PropTypes.bool,
  shouldShowEditButton: PropTypes.bool,
  threadCounter: PropTypes.number,
}

PostFooter.defaultProps = {
  isDeleting: false,
  isConfirmingDelete: false,
  isWorking: false,
  onDeleteConfirmClick: null,
  onEditClick: null,
  onShareNowClick: null,
  onShareAgainClick: null,
  onShareLinkClick: null,
  onRequeueClick: null,
  serviceLink: null,
  day: null,
  dragging: false,
  isManager: true,
  isSent: false,
  isPastReminder: false,
  hasCommentEnabled: false,
  hasCampaignsFeatureEnabled: true,
  shouldShowEditButton: true,
  commentText: null,
  hasUserTags: false,
  postAction: null,
  threadCounter: 0,
}

export default PostFooter
