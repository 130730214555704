import React, { forwardRef, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import type { Temporal } from '@js-temporal/polyfill'
import { Button } from '@buffer-mono/popcorn'
// TODO: we should export this from @buffer-mono/popcorn
import { mergeRefs } from '@buffer-mono/popcorn/src/hooks/useMergeRefs'

import {
  endOfHour,
  format,
  getZonedNow,
  isPast,
  isSameDay,
  startOfHour,
} from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import { DraggableItem } from './DraggableItem'
import { useCalendarContext } from './context'
import { Item } from './Item'

import styles from './Hour.module.css'

const HOURS_WITH_LABELS = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]
const HOUR_FORMAT = {
  '24': 'H:00',
  '12': 'h a',
}
const DEFAULT_ITEMS_LIMIT = 5
const FIVE_MINUTES = 300

type HourProps = {
  hour: Temporal.ZonedDateTime
  showLabel: boolean
}

const Hour = forwardRef<React.ElementRef<'td'>, HourProps>(
  ({ hour, showLabel = false }, forwardedRef): JSX.Element => {
    const { items, readOnly, is24HourFormat, timezone } = useCalendarContext()

    const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)

    const now = getZonedNow(timezone).round('minute')

    const startOfHourTimestamp = startOfHour(hour).toInstant().epochMilliseconds
    const endOfHourTimestamp = endOfHour(hour).toInstant().epochMilliseconds
    const currentTimestamp = now.toInstant().epochMilliseconds

    const creationTimestamp =
      startOfHourTimestamp > currentTimestamp
        ? startOfHourTimestamp
        : currentTimestamp + FIVE_MINUTES

    const hourLabel = format(hour, HOUR_FORMAT[is24HourFormat ? '24' : '12'])

    const isCurrentHour = isSameHour(hour, now)

    const dropDisabled = (isPast(hour) && !isCurrentHour) || readOnly

    const itemsToRender = useMemo(
      () =>
        items?.filter((item) => {
          return (
            item.timestamp >= startOfHourTimestamp &&
            item.timestamp <= endOfHourTimestamp
          )
        }),
      [items, startOfHourTimestamp, endOfHourTimestamp],
    )

    const showHourLabel = showLabel && HOURS_WITH_LABELS.includes(hour.hour)

    const hourTimeStamp = hour.toInstant().epochMilliseconds
    const { setNodeRef, isOver, active } = useDroppable({
      id: hourTimeStamp,
      disabled: dropDisabled,
      data: {
        type: 'hour',
        timestamp: hour.toInstant().epochMilliseconds,
      },
    })

    const showAddButton = !dropDisabled && !active

    return (
      <td
        className={clsx(styles.hour, {
          [styles.disabled]: dropDisabled,
          [styles.draggingOver]: isOver,
          [styles.dragHappening]: Boolean(active),
        })}
        ref={mergeRefs(forwardedRef, setNodeRef)}
        data-datetime={hour.toString()}
        data-hour-label={showHourLabel ? hourLabel : ''}
      >
        {itemsToRender?.slice(0, limit).map((item, index) => {
          return (
            <DraggableItem
              key={item.id}
              id={item.id}
              index={index}
              timestamp={hourTimeStamp}
              className={clsx(styles.item, {
                [styles.activeItem]: active?.id === item.id,
              })}
              shouldDisableDrag={
                readOnly || item.shouldDisableDrag === undefined
                  ? item.timestamp < now.epochSeconds
                  : item.shouldDisableDrag
              }
            >
              <Item {...item} index={index} />
            </DraggableItem>
          )
        })}
        {itemsToRender && itemsToRender.length > limit && (
          <Button
            variant="tertiary"
            onClick={(): void => setLimit((prev) => prev + 5)}
          >
            + More
          </Button>
        )}
        {showAddButton && (
          <AddButton
            fullWidth
            timestamp={creationTimestamp}
            labelDate={`${format(hour, 'eeee, d MMMM y')} at ${hourLabel}`}
            className={styles.addButton}
          />
        )}
      </td>
    )
  },
)

Hour.displayName = 'Hour'

const memoHour = React.memo(Hour)
export { memoHour as Hour }

function isSameHour(
  date: Temporal.ZonedDateTime,
  otherDate: Temporal.ZonedDateTime,
): boolean {
  return isSameDay(date, otherDate) && date.hour === otherDate.hour
}
