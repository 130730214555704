import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@bufferapp/ui'
import { Text } from '@buffer-mono/legacy-bufferapp-components'

export type LinkType = {
  url: string
  displayString: string
  indices: number[]
  className?: string
}

export type OptionType = {
  newTab: boolean
}

type Element = string | JSX.Element

// TODO: replace with <Link> from Popcorn
const linkDataToElement = (
  options: OptionType,
  link: LinkType,
  key: number,
): JSX.Element => (
  <Link
    href={link.url}
    key={key}
    newTab={options.newTab}
    className={link.className}
    fontWeight={400}
  >
    {link.displayString}
  </Link>
)

const getLinkifiedText = (
  options: OptionType,
  links: LinkType[],
  text: string,
): Element[] => {
  const elements: Element[] = []
  let currentIndex = 0

  if (!links || links.length === 0) {
    elements.push(text)
    return elements
  }

  links.forEach((link) => {
    const startIndex = link.indices[0]
    const endIndex = link.indices[1]

    if (startIndex < text.length) {
      if (currentIndex !== startIndex) {
        elements.push(text.substring(currentIndex, startIndex))
      }

      elements.push(linkDataToElement(options, link, elements.length))
      currentIndex = endIndex
    }
  })

  if (currentIndex !== text.length) {
    elements.push(text.substring(currentIndex))
  }

  return elements
}

const LinkifiedText = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'color' implicitly has an 'any' ty... Remove this comment to see the full error message
  color,
  // @ts-expect-error TS(7031) FIXME: Binding element 'links' implicitly has an 'any' ty... Remove this comment to see the full error message
  links,
  // @ts-expect-error TS(7031) FIXME: Binding element 'newTab' implicitly has an 'any' t... Remove this comment to see the full error message
  newTab,
  // @ts-expect-error TS(7031) FIXME: Binding element 'size' implicitly has an 'any' typ... Remove this comment to see the full error message
  size,
  // @ts-expect-error TS(7031) FIXME: Binding element 'whitespace' implicitly has an 'an... Remove this comment to see the full error message
  whitespace,
  // @ts-expect-error TS(7031) FIXME: Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
  className,
}): JSX.Element => {
  const options = { newTab }
  // TODO: replace with <Text> from Popcorn
  return (
    <Text
      whitespace={whitespace}
      size={size}
      color={color}
      className={className}
    >
      {getLinkifiedText(options, links, children)}
    </Text>
  )
}

LinkifiedText.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      displayString: PropTypes.string,
      className: PropTypes.string,
      indices: PropTypes.arrayOf(PropTypes.number),
    }),
  ),
  newTab: PropTypes.bool,
  size: PropTypes.oneOf([
    'extra-small',
    'large',
    'extra-large',
    'mini',
    'small',
  ]),
  whitespace: PropTypes.oneOf([
    'normal',
    'pre',
    'pre-line',
    'pre-wrap',
    'nowrap',
  ]),
  className: PropTypes.string,
}

LinkifiedText.defaultProps = {
  links: [],
  newTab: true,
  children: '',
  color: 'shuttleGray',
  size: 'mini',
  whitespace: 'normal',
  className: null,
}

export default LinkifiedText
