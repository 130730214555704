import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'

import { pusher } from '~publish/services/pusher'
import { actionTypes as campaignActionTypes } from '~publish/legacy/campaign/reducer'
import AppDispatcher from '~publish/legacy/composer/composer/dispatcher'
import { ActionTypes } from '~publish/legacy/composer/composer/state/ActionTypes'
import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import { actionTypes as organizationsActionTypes } from '~publish/legacy/organizations/reducer'
import { actionTypes as profileSidebarActionTypes } from '~publish/legacy/profile-sidebar/reducer'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { IdeaEvents } from '~publish/pages/Create/types'
import { PostEvents } from '~publish/legacy/post/types'
import { postParser } from '~publish/legacy/duplicate-server/parsers'
import { noteAdded, noteDeleted, noteUpdated } from '~publish/legacy/post/slice'

import { actionTypes as pusherActionTypes } from './reducer'

const updateEventActionMap = {
  sent_update: queueActionTypes.POST_SENT,
  updated_update: queueActionTypes.POST_UPDATED,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
const bindProfileUpdateEvents = (channel, profileId, dispatch) => {
  // Bind other events
  // @ts-expect-error TS(7006) FIXME: Parameter 'order' implicitly has an 'any' type.
  channel.bind('reordered_updates', (order) => {
    dispatch({
      type: queueActionTypes.REORDERED_UPDATES,
      profileId,
      order,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'paused' implicitly has an 'any' type.
  channel.bind('queue_paused', (paused) => {
    dispatch({
      type: profileSidebarActionTypes.PUSHER_PROFILE_PAUSED_STATE,
      paused,
      profileId,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('deleted_update', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('added_update', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('sent_update', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('updated_update', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('collaboration_draft_moved', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('collaboration_draft_updated', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  channel.bind('collaboration_draft_approved', (data) => {
    dispatch({
      type: queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED,
      profileId: data.profile_id,
      canQueueMoreThreads: data.can_queue_more_threads,
    })
  })
}

const channelsByProfileId = {}
const setupProfilePusherEvents = (
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  { profileId, profile: { service } },
  // @ts-expect-error TS(7006) FIXME: Parameter 'pusher' implicitly has an 'any' type.
  pusher,
  // @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  dispatch,
) => {
  if (profileId) {
    // If the profile is not subscribed to any channels, subscribes to private-updates channel:
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const newProfileChannels = channelsByProfileId[profileId] || {
      updates: pusher.subscribe(`private-updates-${profileId}`),
    }

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    channelsByProfileId[profileId] = newProfileChannels

    bindProfileUpdateEvents(
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      channelsByProfileId[profileId].updates,
      profileId,
      dispatch,
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'orgChannel' implicitly has an 'any' typ... Remove this comment to see the full error message
const bindOrganizationEvents = (orgChannel, dispatch) => {
  // Campaigns
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('create_campaign', (data) => {
    dispatch({
      type: campaignActionTypes.PUSHER_CAMPAIGN_CREATED,
      campaign: data,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('update_campaign', (data) => {
    dispatch({
      type: campaignActionTypes.PUSHER_CAMPAIGN_UPDATED,
      campaign: data,
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('delete_campaign', (data) => {
    dispatch({
      type: campaignActionTypes.PUSHER_CAMPAIGN_DELETED,
      campaignId: data.id,
    })
  })
  // Posts and Drafts
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('added_update', (data) => {
    if (data.update.draft) {
      dispatch({
        type: draftActionTypes.DRAFT_CREATED,
        profileId: data.profile_id,
        draft: postParser(data.update),
      })
    } else {
      dispatch({
        type: queueActionTypes.POST_CREATED,
        profileId: data.profile_id,
        post: postParser(data.update),
      })
    }
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('deleted_update', (data) => {
    if (data.update.draft) {
      dispatch({
        type: draftActionTypes.DRAFT_DELETED,
        profileId: data.profile_id,
        draft: postParser(data.update),
      })
    } else {
      dispatch({
        type: queueActionTypes.POST_DELETED,
        profileId: data.profile_id,
        post: postParser(data.update),
      })
    }
  })
  // Bind moved drafts event
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('collaboration_draft_moved', (data) => {
    dispatch({
      type: draftActionTypes.DRAFT_MOVED,
      profileId: data.profile_id,
      draft: postParser(data.draft),
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('collaboration_draft_approved', (data) => {
    dispatch({
      type: draftActionTypes.DRAFT_APPROVED,
      profileId: data.profile_id,
      draft: postParser(data.draft),
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind('collaboration_draft_updated', (data) => {
    dispatch({
      type: draftActionTypes.DRAFT_UPDATED,
      profileId: data.profile_id,
      draft: postParser(data.draft),
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind(PostEvents.POST_NOTE_ADDED, (data) => {
    dispatch(
      noteAdded({
        profileId: data.profile_id,
        postId: data.update_id,
        notes: data.update.notes,
      }),
    )
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind(PostEvents.POST_NOTE_UPDATED, (data) => {
    dispatch(
      noteUpdated({
        profileId: data.profile_id,
        postId: data.update_id,
        notes: data.update.notes,
      }),
    )
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  orgChannel.bind(PostEvents.POST_NOTE_DELETED, (data) => {
    dispatch(
      noteDeleted({
        profileId: data.profile_id,
        postId: data.update_id,
        notes: data.update.notes,
      }),
    )
  })
  Object.entries(updateEventActionMap).forEach(([pusherEvent, actionType]) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    orgChannel.bind(pusherEvent, (data) => {
      dispatch({
        type: actionType,
        profileId: data.profile_id,
        post: postParser(data.update),
      })
    })
  })
  Object.values(IdeaEvents).forEach((eventName) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
    orgChannel.bind(eventName, (data) => {
      dispatch({
        eventName,
        type: `PUSHER_EVENT/${eventName}`,
        data,
      })
    })
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'userChannel' implicitly has an 'any' ty... Remove this comment to see the full error message
const bindUserEvents = (userChannel, dispatch) => {
  // Profile Groups
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  userChannel.bind('created_profile_group', (data) => {
    const { id, name, profile_ids: profileIds } = data
    AppDispatcher.handlePusherAction({
      actionType: ActionTypes.COMPOSER_PROFILE_GROUP_CREATED,
      groupData: { id, name, profileIds },
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  userChannel.bind('edited_profile_group', (data) => {
    const { id, name, profile_ids: profileIds } = data
    AppDispatcher.handlePusherAction({
      actionType: ActionTypes.COMPOSER_PROFILE_GROUP_UPDATED,
      groupData: { id, name, profileIds },
    })
  })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  userChannel.bind('deleted_profile_group', (data) => {
    const { id } = data
    AppDispatcher.handlePusherAction({
      actionType: ActionTypes.COMPOSER_PROFILE_GROUP_DELETED,
      groupData: { id },
    })
  })

  // Video transcoded
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  userChannel.bind('transcode_done', (data) => {
    // Dispatch video transcoded for all pages
    dispatch({
      type: pusherActionTypes.EVENT_VIDEO_TRANSCODED,
      args: {
        uploadId: data.upload_id,
        durationMs: data.media.video.details?.duration_millis,
        size: data.media.video.details?.file_size,
        width: data.media.video.details?.width,
        height: data.media.video.details?.height,
        url: data.media.video.details?.transcoded_location,
        thumbnail: data.media.thumbnail,
      },
    })

    // Dispatch video transcoded for the composer
    AppDispatcher.handlePusherAction({
      actionType: ActionTypes.COMPOSER_VIDEO_PROCESSED,
      processedVideoData: {
        uploadId: data.upload_id,
        name: data.media.video.title,
        duration: data.media.video.details?.duration,
        durationMs: data.media.video.details?.duration_millis,
        size: data.media.video.details?.file_size,
        width: data.media.video.details?.width,
        height: data.media.video.details?.height,
        url: data.media.video.details?.transcoded_location,
        originalUrl: data.media.video.details?.location,
        thumbnail: data.media.thumbnail,
        availableThumbnails: data.media.video.thumbnails,
      },
    })
  })
}

/**
 * Middleware
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'organizationId' implicitly has an 'any'... Remove this comment to see the full error message
  const subscribeOrganizationEvents = (organizationId) => {
    if (organizationId) {
      const channelName = `private-updates-org-${organizationId}`
      const orgChannel = pusher.subscribe(channelName)
      bindOrganizationEvents(orgChannel, dispatch)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
  const subscribeUserEvents = (userId) => {
    if (userId) {
      const channelName = `private-updates-${userId}`
      const userChannel = pusher.subscribe(channelName)
      bindUserEvents(userChannel, dispatch)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  return (next) => (action) => {
    next(action)

    switch (action.type) {
      case profileSidebarActionTypes.SELECT_PROFILE:
        setupProfilePusherEvents(action, pusher, dispatch)
        break
      case organizationsActionTypes.ORGANIZATION_SELECTED: {
        const { id: organizationId } = action.selected
        subscribeOrganizationEvents(organizationId)
        break
      }
      case `user_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const { id: userId } = action.result
        subscribeUserEvents(userId)
        break
      }
      default:
        break
    }
  }
}
