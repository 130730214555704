import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'

import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case 'INIT_ORGANIZATIONS': {
        if (
          typeof window !== 'undefined' &&
          typeof window.bufferData !== 'undefined' &&
          typeof window.bufferData.organizations !== 'undefined' &&
          window.bufferData.organizations
        ) {
          dispatch(
            dataFetchActions.fetchSuccess({
              name: 'organizations',
              result: [...window.bufferData.organizations],
            }),
          )
          // make sure we only bootstrap this data once
          window.bufferData.organizations = undefined
        } else {
          dispatch(
            dataFetchActions.fetch({
              name: 'organizations',
            }),
          )
        }
        break
      }
      case actionTypes.SET_CURRENT_ORGANIZATION: {
        const { list = [], selected } = getState().organizations
        const { organizationId } = action
        if (selected?.id !== organizationId) {
          const selectedOrg = list?.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'org' implicitly has an 'any' type.
            (org) => org.id === organizationId,
          )[0]
          // Select the org
          dispatch({
            type: actionTypes.ORGANIZATION_SELECTED,
            selected: selectedOrg,
          })
        }

        break
      }
      default:
        break
    }
  }
