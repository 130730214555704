import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSplitEnabled } from '@buffer-mono/features'

import CalendarIcon from '@bufferapp/ui/Icon/Icons/Calendar'
import CopyIcon from '@bufferapp/ui/Icon/Icons/Copy'
import CaretDown from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUp from '@bufferapp/ui/Icon/Icons/CaretUp'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'

import { NewTag } from './sidebarButtonTags'

import { SidebarListItem, ButtonWrapper, SidebarDividerWrapper } from './styles'

interface SidebarButtonsProps {
  onCalendarButtonClick: () => void
  isCalendarSelected: boolean
  onBlueprintsButtonClick: () => void
  isBlueprintsSelected: boolean
  toggleQueueExpanded: () => void
  queueExpanded: boolean
}

const SidebarButtons = ({
  onCalendarButtonClick,
  isCalendarSelected,
  onBlueprintsButtonClick,
  isBlueprintsSelected,
  toggleQueueExpanded,
  queueExpanded,
}: SidebarButtonsProps): JSX.Element => {
  const { t } = useTranslation()

  const { isEnabled: isBlueprintsEnabled, isReady: isBlueprintsReady } =
    useSplitEnabled('build-week-blueprints')
  const showBlueprints = isBlueprintsEnabled && isBlueprintsReady

  return (
    <ButtonWrapper>
      <SidebarListItem
        id="calendar"
        title={t('calendar.common.title')}
        onItemClick={onCalendarButtonClick}
        selected={isCalendarSelected}
        icon={<CalendarIcon />}
      />

      {showBlueprints && (
        <SidebarListItem
          id="blueprints"
          title="Recipes"
          onItemClick={onBlueprintsButtonClick}
          selected={isBlueprintsSelected}
          badges={NewTag}
          icon={<CopyIcon />}
        />
      )}

      <SidebarDividerWrapper aria-hidden="true">
        <Divider color="grayLight" marginTop="12px" marginBottom="12px" />
      </SidebarDividerWrapper>

      <SidebarListItem
        id="queues"
        title={t('profile-sidebar.queues')}
        onItemClick={toggleQueueExpanded}
        selected={false}
        icon={queueExpanded ? <CaretUp /> : <CaretDown />}
      />
    </ButtonWrapper>
  )
}

export default SidebarButtons
