/**
 * Component that shows the list of available profiles
 */
import React from 'react'

import { Flex, VisuallyHidden } from '@buffer-mono/popcorn'
import { Button } from '@bufferapp/ui'

import AppActionCreators from '../action-creators/AppActionCreators'
import type { Notification } from '../values/Notification'
import ProfileGroups from './ProfileGroups'
import Profile from './Profile'

import styles from './css/ProfileSection.module.css'

type ProfileType = {
  id: string
  isSelected: boolean
  service: { name: string }
}

type UserData = {
  profileGroups: {
    id: string
    name: string
    profileIds: string[]
  }[]
}

type ProfileSectionProps = {
  expandedProfileSubprofileDropdownId: string | null
  profiles: ProfileType[]
  userData: UserData
  visibleNotifications: Notification[]
  organizations?: {
    selected?: {
      id: string
      hasProfileGroupsFeature: boolean
    }
  }
}

type ScrollHandler = (
  e: React.UIEvent<HTMLDivElement>,
  scrollTop: number,
) => void

export const ProfileSection = ({
  expandedProfileSubprofileDropdownId,
  profiles,
  userData,
  visibleNotifications,
  organizations,
}: ProfileSectionProps): JSX.Element => {
  const profilesScrollContainer = React.useRef(null)
  const [scrollHandlers] = React.useState<Set<ScrollHandler>>(new Set())

  const onScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (e.target !== profilesScrollContainer.current) return
      const { scrollTop } = e.target as HTMLDivElement
      scrollHandlers.forEach((handler) => handler(e, scrollTop))
    },
    [scrollHandlers],
  )

  const onProfilesToggleClick = (): void =>
    AppActionCreators.toggleAllProfiles()

  const addContainerScrollHandler = React.useCallback(
    (handler: ScrollHandler) => {
      scrollHandlers.add(handler)
    },
    [scrollHandlers],
  )

  const removeContainerScrollHandler = React.useCallback(
    (handler: ScrollHandler) => {
      scrollHandlers.delete(handler)
    },
    [scrollHandlers],
  )

  const { hasProfileGroupsFeature } = organizations?.selected || {}
  const { profileGroups } = userData

  const shouldBeConsideredBusinessUser = hasProfileGroupsFeature
  const hasEnoughProfiles = profiles.length >= 4

  const selectedProfilesIds = profiles
    .filter((profile) => profile.isSelected)
    .map((profile) => profile.id)

  const hasNoProfilesSelected = selectedProfilesIds.length === 0

  return (
    <section className={styles.profileSection}>
      <Flex direction="column">
        {hasEnoughProfiles && (
          <div style={{ alignSelf: 'flex-start' }}>
            {/* @ts-expect-error - Use legacy button until Composer is migrated */}
            <Button
              onClick={onProfilesToggleClick}
              size="small"
              label={
                hasNoProfilesSelected
                  ? 'Select All Channels'
                  : 'Deselect All Channels'
              }
            />
          </div>
        )}

        {shouldBeConsideredBusinessUser && hasEnoughProfiles && (
          <ProfileGroups
            groups={profileGroups}
            selectedProfilesIds={selectedProfilesIds}
          />
        )}
      </Flex>

      <div className={styles.profilesContainer}>
        <div
          role="group"
          aria-labelledby="selectProfilesLabel"
          onScroll={onScroll}
          className={styles.profilesScrollContainer}
          ref={profilesScrollContainer}
        >
          {/* it's hidden from visual UI but will still be used as an accessible name */}
          <VisuallyHidden>Select channels</VisuallyHidden>

          {profiles.map((profile) => (
            <Profile
              profile={profile}
              organizationId={organizations?.selected?.id}
              expandedProfileSubprofileDropdownId={
                expandedProfileSubprofileDropdownId
              }
              addContainerScrollHandler={addContainerScrollHandler}
              removeContainerScrollHandler={removeContainerScrollHandler}
              visibleNotifications={visibleNotifications}
              className={styles.profile}
              key={profile.id}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
