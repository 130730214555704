import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PlayIcon from '@bufferapp/ui/Icon/Icons/Play'
import type { Video } from '~publish/legacy/composer/composer/entities/factories'

import * as Styles from './styles'

export type PlayerProps = {
  video: Video
  width?: string
  height?: string
  showSoundOffIcon?: boolean
  hidePlayButton?: boolean
  autoPlay?: boolean
  loop?: boolean
  className?: string
  withBlur?: boolean
}

const Player = ({
  video,
  width,
  height,
  showSoundOffIcon,
  className,
  hidePlayButton,
  autoPlay,
  loop,
  withBlur,
}: PlayerProps): JSX.Element => {
  const { thumbnail, url } = video || {}

  const videoRef = React.useRef<HTMLVideoElement>(null)
  const playButtonRef = React.useRef<HTMLButtonElement>(null)

  const onClick = (): void => {
    if (!videoRef.current || !playButtonRef.current) {
      return
    }

    videoRef.current?.play()
    videoRef.current.setAttribute('controls', 'true')
    playButtonRef.current.style.display = 'none'
  }

  const onEnded = (): void => {
    if (!videoRef.current || !playButtonRef.current) {
      return
    }

    videoRef.current.removeAttribute('controls')
    playButtonRef.current.style.display = 'flex'
  }

  return (
    <div
      data-testid="preview-video"
      className={className}
      style={{
        overflow: withBlur ? 'hidden' : 'visible',
      }}
    >
      <video
        aria-label="video-player"
        data-testid="video-player"
        ref={videoRef}
        poster={thumbnail}
        src={url}
        preload="auto"
        muted
        onEnded={onEnded}
        crossOrigin={'anonymous'}
        height={height || '100%'}
        width={width || '100%'}
        style={{
          filter: withBlur ? 'blur(35px)' : 'none',
        }}
        autoPlay={autoPlay}
        loop={loop}
      />
      {!hidePlayButton && !withBlur && (
        <button type="button" onClick={onClick} ref={playButtonRef}>
          <PlayIcon size="large" />
        </button>
      )}
      {withBlur && <Styles.ShowMore>Tap to reveal</Styles.ShowMore>}
      {showSoundOffIcon && <Styles.SoundOff />}
      <div className="overlay" />
    </div>
  )
}

Player.propTypes = {
  video: PropTypes.shape({
    thumbnail: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  showSoundOffIcon: PropTypes.bool,
}

Player.defaultProps = {
  className: null,
  showSoundOffIcon: false,
}

const StyledVideoPlayer = styled(Player)`
  ${Styles.Wrapper}
`

export const VideoPlayer = (props: PlayerProps): JSX.Element => {
  if (props.className) {
    return <Player {...props} />
  }

  return <StyledVideoPlayer {...props} />
}
