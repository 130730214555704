import React from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  Text,
  LoadingAnimation,
} from '@buffer-mono/legacy-bufferapp-components'
import { TranslationReplacer } from '~publish/legacy/i18n'

import { BDSButton } from '~publish/legacy/shared-components'

import { profilesPropType } from '~publish/legacy/composer/composer/components/ComposerPropTypes'
import styles from './instagramFirstCommentModal.module.css'

class InstagramFirstCommentModal extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      authResponse: null,
      status: null,
      needsSignin: true,
      loadedFB: false,
      canRequestMorePermission: false,
      userNeedsLogoutSignin: false,
      loading: true,
      checkedServiceAccess: false,
    }

    this.updateState = this.updateState.bind(this)
    if (this.loadFB()) {
      // @ts-expect-error TS(2339) FIXME: Property 'loadedFB' does not exist on type 'Readon... Remove this comment to see the full error message
      this.state.loadedFB = true
    }
  }

  componentDidMount() {
    this.getFBLoginStatus()
  }

  componentDidUpdate() {
    // @ts-expect-error TS(2339) FIXME: Property 'appId' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { appId, selectedProfiles } = this.props
    if (selectedProfiles.length > 0 && appId) {
      if (this.canPostComment()) {
        // @ts-expect-error TS(2339) FIXME: Property 'hideModal' does not exist on type 'Reado... Remove this comment to see the full error message
        this.props.hideModal()
        return
      }
    }

    // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.status) {
      // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.state.status === 'connected') {
        this.checkServiceAccess()
      } else {
        /*
        inform user we need them to log into "profile name" to grant permissions for IG
        -> click button + trigger auth flow
        */
        this.userNeedsSignin()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('updateIGFirstCommentState', this.updateState)
  }

  getCantPostAccounts() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedProfiles' does not exist on type... Remove this comment to see the full error message
    const { selectedProfiles } = this.props
    return selectedProfiles.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      (p) =>
        p.service === 'instagram' && p.should_post_direct && !p.canPostComment,
    )
  }

  getFBLoginStatus() {
    /* eslint-disable no-undef */
    window.addEventListener('updateIGFirstCommentState', this.updateState)

    const getLoginStatus = () => {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'FB'.
      FB.init({
        // @ts-expect-error TS(2339) FIXME: Property 'appId' does not exist on type 'Readonly<... Remove this comment to see the full error message
        appId: this.props.appId,
        cookie: true,
        // the session
        xfbml: false,
        version: 'v3.0',
      })

      // @ts-expect-error TS(2304) FIXME: Cannot find name 'FB'.
      FB.getLoginStatus(({ authResponse, status }) => {
        this.sendStateUpdate({
          authResponse,
          status,
        })
      })
    }

    // @ts-expect-error TS(2339) FIXME: Property 'fbAsyncInit' does not exist on type 'Win... Remove this comment to see the full error message
    if (typeof window.fbAsyncInit === 'undefined') {
      // @ts-expect-error TS(2339) FIXME: Property 'fbAsyncInit' does not exist on type 'Win... Remove this comment to see the full error message
      window.fbAsyncInit = () => {
        getLoginStatus()
      }
    } else {
      getLoginStatus()
    }
    /* eslint-enable no-undef */
  }

  canPostComment() {
    return this.getCantPostAccounts().length === 0
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  sendStateUpdate(message) {
    window.dispatchEvent(
      new CustomEvent('updateIGFirstCommentState', {
        bubbles: false,
        detail: {
          message,
        },
      }),
    )
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  updateState(event) {
    this.setState(event.detail.message)
  }

  checkServiceAccess() {
    // @ts-expect-error TS(2339) FIXME: Property 'checkedServiceAccess' does not exist on ... Remove this comment to see the full error message
    if (!this.state.checkedServiceAccess) {
      // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      const { profile } = this.props
      const { serviceId } = profile

      /* eslint-disable no-undef */
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'FB'.
      FB.api(`${serviceId}`, (data) => {
        this.sendStateUpdate({
          checkedServiceAccess: true,
        })
        if (data && typeof data.error !== 'undefined') {
          /*
          inform user they need to log out of FB before granting permissions and will
          need to log into "profile name" account
          */
          this.userNeedsLogoutSignin()
        } else {
          this.userCanRequestMorePermissions()
        }
      })
    }
    /* eslint-enable no-undef */
  }

  userCanRequestMorePermissions() {
    this.sendStateUpdate({
      canRequestMorePermission: true,
      loading: false,
    })
  }

  userNeedsLogoutSignin() {
    this.sendStateUpdate({
      userNeedsLogoutSignin: true,
      loading: false,
    })
  }

  loadFB() {
    // @ts-expect-error TS(2339) FIXME: Property 'loadedFB' does not exist on type 'Readon... Remove this comment to see the full error message
    if (!this.state.loadedFB) {
      // @ts-expect-error TS(2339) FIXME: Property 'loadFacebook' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.loadFacebook()
      return true
    }
  }

  userNeedsSignin() {
    // @ts-expect-error TS(2339) FIXME: Property 'needsSiginin' does not exist on type 'Re... Remove this comment to see the full error message
    if (!this.state.needsSiginin) {
      this.sendStateUpdate({
        needsSiginin: true,
        loading: false,
      })
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'translations' does not exist on type 'Re... Remove this comment to see the full error message
    const { translations, hideModal, profile, launchRequestMorePermission } =
      this.props

    const canPostComment = this.canPostComment()

    if (typeof canPostComment === 'undefined' || canPostComment) {
      return null
    }

    const profileList = this.getCantPostAccounts()
    let missingPermisisonProfile = profile

    if (profileList.length > 0) {
      missingPermisisonProfile = profileList[0]
    }

    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.state.loading) {
      return (
        <div
          style={{
            position: 'fixed',
            zIndex: '3000',
            borderRadius: '4px',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
          }}
        >
          <Popover>
            <div className={styles.card}>
              <div className={styles.mainDiv}>
                <div style={{ padding: '25px', textAlign: 'center' }}>
                  <LoadingAnimation />
                </div>
              </div>
              <div className={styles.barBottomStyle}>
                <div className={styles.divButton}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: any; type: string;... Remove this comment to see the full error message */}
                  <BDSButton onClick={hideModal} type="textOnly">
                    {translations.cancel}
                  </BDSButton>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      )
    }
    // @ts-expect-error TS(2339) FIXME: Property 'canRequestMorePermission' does not exist... Remove this comment to see the full error message
    if (this.state.canRequestMorePermission) {
      return (
        <div
          style={{
            position: 'fixed',
            zIndex: '3000',
            borderRadius: '4px',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
          }}
        >
          <Popover>
            <div className={styles.card}>
              <div className={styles.mainDiv}>
                <div style={{ padding: '25px' }}>
                  <Text color="black" weight="bold">
                    {translations.headline}
                  </Text>
                  <div>
                    <Text size="mini">
                      {translations.postOnYourBehalf}
                      {translations.canRequestMorePermission}
                    </Text>
                  </div>
                </div>
              </div>
              <div className={styles.barBottomStyle}>
                <div className={styles.divButton}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: any; type: string;... Remove this comment to see the full error message */}
                  <BDSButton onClick={hideModal} type="textOnly">
                    {translations.cancel}
                  </BDSButton>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: () => any; }' is n... Remove this comment to see the full error message */}
                  <BDSButton
                    onClick={() =>
                      launchRequestMorePermission({
                        profileId: missingPermisisonProfile.id,
                      })
                    }
                  >
                    {translations.continue}
                  </BDSButton>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      )
    }
    // @ts-expect-error TS(2339) FIXME: Property 'userNeedsLogoutSignin' does not exist on... Remove this comment to see the full error message
    if (this.state.userNeedsLogoutSignin) {
      const profileName = [
        {
          replaceString: '{signout}',
          replaceWith: <Text weight="bold">{translations.signout}</Text>,
        },
        {
          replaceString: '{profileName}',
          replaceWith: (
            <Text weight="bold">
              {missingPermisisonProfile.service_username}
            </Text>
          ),
        },
      ]

      return (
        <div
          style={{
            position: 'fixed',
            zIndex: '3000',
            borderRadius: '4px',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
          }}
        >
          <Popover>
            <div className={styles.card}>
              <div className={styles.mainDiv}>
                <div style={{ padding: '25px' }}>
                  <Text color="black" weight="bold">
                    {translations.headline}
                  </Text>
                  <div>
                    <Text size="mini">
                      {translations.postOnYourBehalf}
                      <TranslationReplacer
                        translation={translations.userNeedsLogoutSignin}
                        replacementStrings={profileName}
                      />
                    </Text>
                  </div>
                </div>
              </div>
              <div className={styles.barBottomStyle}>
                <div className={styles.divButton}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: any; type: string;... Remove this comment to see the full error message */}
                  <BDSButton onClick={hideModal} type="textOnly">
                    {translations.cancel}
                  </BDSButton>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: () => any; }' is n... Remove this comment to see the full error message */}
                  <BDSButton
                    onClick={() =>
                      launchRequestMorePermission({
                        profileId: missingPermisisonProfile.id,
                      })
                    }
                  >
                    {translations.continue}
                  </BDSButton>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      )
    }
    return (
      <div
        style={{
          position: 'fixed',
          zIndex: '3000',
          borderRadius: '4px',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.16)',
        }}
      >
        <Popover>
          <div className={styles.card}>
            <div className={styles.mainDiv}>
              <div style={{ padding: '25px' }}>
                <Text color="black" weight="bold">
                  {translations.headline}
                </Text>
                <div>
                  <Text size="mini">
                    {translations.postOnYourBehalf}
                    {translations.body}
                  </Text>
                </div>
              </div>
            </div>
            <div className={styles.barBottomStyle}>
              <div className={styles.divButton}>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: any; type: string;... Remove this comment to see the full error message */}
                <BDSButton onClick={hideModal} type="textOnly">
                  {translations.cancel}
                </BDSButton>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any; onClick: () => any; }' is n... Remove this comment to see the full error message */}
                <BDSButton
                  onClick={() =>
                    launchRequestMorePermission({
                      profileId: missingPermisisonProfile.id,
                    })
                  }
                >
                  {translations.continue}
                </BDSButton>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
InstagramFirstCommentModal.propTypes = {
  selectedProfiles: profilesPropType,
  loadFacebook: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired, // eslint-disable-line
  hideModal: PropTypes.func.isRequired,
  launchRequestMorePermission: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string,
    canPostComment: PropTypes.bool,
  }),
  appId: PropTypes.string.isRequired,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
InstagramFirstCommentModal.defaultProps = {
  profile: null,
  selectedProfiles: [],
}

export default InstagramFirstCommentModal
