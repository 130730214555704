import type { Modifier, ClientRect } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'

// Code coming form the dnd-kit library because the `restrictToBoundingRect`
// method is not exposed.
// https://github.com/clauderic/dnd-kit/blob/694dcc2f62e5269541fc941fa6c9af46ccd682ad/packages/modifiers/src/utilities/restrictToBoundingRect.ts#L4
function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
): Transform {
  const value = {
    ...transform,
  }

  if (rect.top + transform.y <= boundingRect.top) {
    value.y = boundingRect.top - rect.top
  } else if (
    rect.bottom + transform.y >=
    boundingRect.top + boundingRect.height
  ) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom
  }

  if (rect.left + transform.x <= boundingRect.left) {
    value.x = boundingRect.left - rect.left
  } else if (
    rect.right + transform.x >=
    boundingRect.left + boundingRect.width
  ) {
    value.x = boundingRect.left + boundingRect.width - rect.right
  }

  return value
}

// This modifier restricts the movement of the dragging node to the bounding
// rectangle of the specified ref. The code is inspired by the
// `restrictToParentElement`, for dnd-kit library
// https://github.com/clauderic/dnd-kit/blob/694dcc2f62e5269541fc941fa6c9af46ccd682ad/packages/modifiers/src/restrictToParentElement.ts
export const restrictToRef = <T extends HTMLElement>(
  ref: React.RefObject<T>,
): Modifier => {
  const refRect = ref.current?.getBoundingClientRect()

  const modifier: Modifier = (args) => {
    const { draggingNodeRect, transform, windowRect } = args
    const boundingRect = refRect ?? windowRect

    if (!draggingNodeRect || !boundingRect) {
      return transform
    }

    return restrictToBoundingRect(transform, draggingNodeRect, boundingRect)
  }
  return modifier
}
