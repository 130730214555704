import React, {
  useEffect,
  useState,
  useRef,
  type ReactNode,
  type MouseEvent,
  useCallback,
} from 'react'
import clsx from 'clsx'
import DropdownTrigger from './dropdown-trigger'
import DropdownContent from './dropdown-content'

interface DropdownProps {
  disabled?: boolean
  active?: boolean
  onHide?: () => void
  onShow?: () => void
  children: ReactNode
  className?: string
  removeElement?: boolean
  style?: React.CSSProperties
}

const Dropdown: React.FC<DropdownProps> = ({
  disabled = false,
  active: controlledActive,
  onHide,
  onShow,
  children,
  className = '',
  removeElement = false,
  ...props
}) => {
  const [active, setActive] = useState<boolean>(!!controlledActive)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const hide = useCallback(() => {
    setActive(false)
    if (onHide) onHide()
  }, [onHide])

  useEffect(() => {
    const handleWindowClick = (event: Event): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        active
      ) {
        hide()
      }
    }

    window.addEventListener('click', handleWindowClick)
    window.addEventListener('touchstart', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
      window.removeEventListener('touchstart', handleWindowClick)
    }
  }, [active, hide])

  const isActive = (): boolean =>
    typeof controlledActive === 'boolean' ? controlledActive : active

  const show = (): void => {
    setActive(true)
    if (onShow) onShow()
  }

  const toggle = (event: MouseEvent): void => {
    event.preventDefault()
    isActive() ? hide() : show()
  }

  const boundChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) return child

    if (child.type === DropdownTrigger) {
      const originalOnClick = child.props.onClick
      return React.cloneElement(child as React.ReactElement, {
        ref: dropdownRef,
        onClick: (event: MouseEvent) => {
          if (!disabled) {
            toggle(event)
            if (originalOnClick) originalOnClick(event)
          }
        },
      })
    } else if (child.type === DropdownContent && removeElement && !isActive()) {
      return null
    }
    return child
  })

  const dropdownClasses = clsx('dropdown', {
    'dropdown--active': isActive(),
    'dropdown--disabled': disabled,
  })

  return (
    <div
      ref={dropdownRef}
      className={`${dropdownClasses} ${className}`}
      {...props}
    >
      {boundChildren}
    </div>
  )
}

export { DropdownTrigger, DropdownContent }
export default Dropdown
