import {
  actionTypes as dataFetchActionTypes,
  actions as dataFetchActions,
} from '@buffer-mono/async-data-fetch'
import { actionTypes as organizationActionTypes } from '~publish/legacy/organizations'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import { actionTypes } from './reducer'
import countHashtagsInText from './utils/HashtagCounter'

// @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const refreshHashtagGroups = (dispatch, organizationId) => {
  if (organizationId) {
    dispatch(
      dataFetchActions.fetch({
        name: 'getHashtagGroups',
        args: {
          organizationId,
        },
      }),
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const getTrackingMetadataFromStoryGroup = (name, text) => {
  return {
    hashtagGroupName: name,
    hashtagCount: countHashtagsInText(text),
    product: 'publish',
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'getState' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ getState, dispatch }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    const organizationId = getState().organizations?.selected?.id
    switch (action.type) {
      case actionTypes.SAVE_HASHTAG_GROUP: {
        const { name, text } = getState().hashtagGroups
        dispatch(
          dataFetchActions.fetch({
            name: 'createHashtagGroup',
            args: {
              organizationId,
              name,
              text,
            },
          }),
        )
        break
      }
      case actionTypes.UPDATE_HASHTAG_GROUP: {
        const { name, text, snippetId } = getState().hashtagGroups
        dispatch(
          dataFetchActions.fetch({
            name: 'updateHashtagGroup',
            args: {
              organizationId,
              snippetId,
              name,
              text,
            },
          }),
        )
        break
      }
      case actionTypes.DELETE_HASHTAG_GROUP:
        dispatch(
          dataFetchActions.fetch({
            name: 'deleteHashtagGroup',
            args: {
              organizationId,
              snippetId: action.snippetId,
            },
          }),
        )

        dispatch(
          analyticsActions.trackEvent(
            'Hashtag Group Deleted',
            getTrackingMetadataFromStoryGroup(action.name, action.text),
          ),
        )
        break
      case `createHashtagGroup_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break
      case `updateHashtagGroup_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        refreshHashtagGroups(dispatch, organizationId)
        break
      case `deleteHashtagGroup_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        refreshHashtagGroups(dispatch, organizationId)
        break
      case organizationActionTypes.ORGANIZATION_SELECTED:
        refreshHashtagGroups(dispatch, organizationId)
        break
      case actionTypes.INSERT_HASHTAG_GROUP:
        dispatch(
          analyticsActions.trackEvent(
            'Hashtag Group Inserted',
            getTrackingMetadataFromStoryGroup(action.name, action.text),
          ),
        )
        break
      case `createHashtagGroup_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          analyticsActions.trackEvent(
            'Hashtag Group Created',
            getTrackingMetadataFromStoryGroup(
              action.args.name,
              action.args.text,
            ),
          ),
        )
        break
      case `updateHashtagGroup_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        dispatch(
          analyticsActions.trackEvent(
            'Hashtag Group Updated',
            getTrackingMetadataFromStoryGroup(
              action.args.name,
              action.args.text,
            ),
          ),
        )
        refreshHashtagGroups(dispatch, organizationId)
        break
      }
      default:
        break
    }
  }
