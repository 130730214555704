import React from 'react'
import {
  CHANNEL_SUGGESTIONS,
  type ChannelSuggestion,
  CHANNEL_SUGGESTION_THRESHOLD,
} from './constants'

export function useChannelSuggestions(channels: ChannelSuggestion[]): {
  channelSuggestions: ChannelSuggestion[]
  isChannelSuggestionsExpanded: boolean
  expandChannelSuggestions: () => void
} {
  const [isChannelSuggestionsExpanded, setIsChannelSuggestionsExpanded] =
    React.useState(false)

  const expandChannelSuggestions = (): void => {
    setIsChannelSuggestionsExpanded(!isChannelSuggestionsExpanded)
  }

  const filteredChannels = CHANNEL_SUGGESTIONS.filter((suggestedChannel) => {
    return !channels.some(
      (channel) => channel.service === suggestedChannel.service,
    )
  })

  return {
    channelSuggestions: isChannelSuggestionsExpanded
      ? filteredChannels
      : filteredChannels.slice(
          0,
          CHANNEL_SUGGESTION_THRESHOLD - channels.length,
        ),
    isChannelSuggestionsExpanded,
    expandChannelSuggestions,
  }
}
