import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { actionTypes as campaignActionTypes } from '~publish/legacy/campaign/reducer'
import { campaignParser } from '~publish/legacy/duplicate-server/parsers'
import { sortCampaignsAlphabetically } from '~publish/legacy/utils/sortTags'
import type { Campaign } from '~publish/legacy/campaign/types'

export const initialState = {
  campaigns: null,
  isLoading: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `getCampaignsList_${dataFetchActionTypes.FETCH_START}`: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case `getCampaignsList_${dataFetchActionTypes.FETCH_FAIL}`: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case `getCampaignsList_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        campaigns: action.result,
        isLoading: false,
      }
    }
    case `canViewReport_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const { tagId } = action.args
      const canViewReport = action.result
      const campaignsData: Campaign[] = state.campaigns || []
      const campaigns = campaignsData.map((campaign) => {
        if (campaign.id === tagId) {
          return {
            ...campaign,
            canViewReport,
          }
        }
        return campaign
      })
      return {
        ...state,
        campaigns,
        isLoading: false,
      }
    }
    case campaignActionTypes.PUSHER_CAMPAIGN_CREATED: {
      const parsedCampaign = campaignParser(action.campaign)
      const updatedCampaigns = [...(state.campaigns || []), parsedCampaign]
      return {
        ...state,
        campaigns: sortCampaignsAlphabetically(updatedCampaigns),
      }
    }
    case campaignActionTypes.PUSHER_CAMPAIGN_UPDATED: {
      const parsedCampaign = campaignParser(action.campaign)
      // @ts-expect-error TS(2339) FIXME: Property 'findIndex' does not exist on type 'never... Remove this comment to see the full error message
      const updatedIndex = state.campaigns?.findIndex(
        // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
        (i) => i.id === parsedCampaign.id,
      )
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const updatedCampaigns = state.campaigns.map((item, index) =>
        index === updatedIndex ? parsedCampaign : item,
      )
      return {
        ...state,
        campaigns: sortCampaignsAlphabetically(updatedCampaigns),
      }
    }
    case campaignActionTypes.PUSHER_CAMPAIGN_DELETED: {
      const { campaignId } = action
      // @ts-expect-error TS(2339) FIXME: Property 'findIndex' does not exist on type 'never... Remove this comment to see the full error message
      const deletedIndex = state.campaigns?.findIndex(
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        (item) => item.id === campaignId,
      )
      // @ts-expect-error TS(2339) FIXME: Property 'filter' does not exist on type 'never'.
      const updatedCampaigns = state.campaigns?.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter '_item' implicitly has an 'any' type.
        (_item, index) => index !== deletedIndex,
      )
      return {
        ...state,
        campaigns: updatedCampaigns,
      }
    }

    default:
      return state
  }
}
