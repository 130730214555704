import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { useQueryParam } from '~publish/hooks/useQueryParam'
import type { NewIdea } from '~publish/pages/Create/types'
import { IdeaCard_Idea } from '~publish/pages/Create/components/IdeaCard/IdeaCard'
import { getFragmentData } from '~publish/gql/fragment-masking'

import { IdeaComposer } from '../IdeaComposer'
import { GetIdea } from './IdeaDetailsDialog'

export function IdeaEditDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const { ideaId } = useParams<{ ideaId: string; id: string }>()
  const [source] = useQueryParam('source')
  const history = useHistory()
  const { data, loading } = useQuery(GetIdea, {
    variables: {
      input: {
        id: ideaId,
      },
    },
  })

  const [isOpen, setIsOpen] = useState(false)

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      history.push(parent)
    },
    [parent, history],
  )

  useEffect(() => {
    if (data?.idea) {
      setIsOpen(true)
    }
  }, [data?.idea, history, source])

  if (loading) {
    return <></>
  }

  if (data?.idea?.__typename !== 'Idea' || !data?.idea) {
    history.push(parent)
    return null
  }

  const idea = getFragmentData(IdeaCard_Idea, data.idea)
  console.log({ source })

  return (
    <IdeaComposer
      cta={source as CTA}
      open={isOpen}
      onOpenChange={handleOpenChange}
      idea={(idea as NewIdea) ?? undefined}
    />
  )
}
